import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';


//ACTIONS
import { historialDesignaciones } from '../../redux/action-types/auditoriaActions';

// MUI componentes
import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
} from '@mui/material';

// Utils
import { obtenerFecha } from '../../utils/obtenerFecha';
import { verificarPermiso } from '../../utils/verificarPermiso';


const ListadoAuditoria = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const dniActual  = useSelector(state => state.acceso.dni);
    const { id } = useParams();

    const permisos = window.localStorage.getItem("permisos");

    const { historial_versiones } = useSelector(state => state.auditoria);
    const { designacion } = useSelector(state => state.designaciones);

    const handleClickCreador = (dni, creador) => {
        window.sessionStorage.setItem('urlAnterior', `/designaciones/${dni}`);
        navigate(`/perfil/${creador}`);
        };

    const handleClickDependencia = (dni, id) => {
        window.sessionStorage.setItem('urlAnterior', `/designaciones/${dni}`);
        navigate(`/dependencia/${id}`);
    };

    useEffect(() => {
        if(verificarPermiso(permisos, 'super_usuario')){
            dispatch(historialDesignaciones(id));
            };
    },[dispatch, permisos,id, dniActual]) 

  return (
    <>       
    <Grid item xs={10} sm={10} md={12} lg={12}>
    <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
        <Table aria-label="simple table"> 
        <TableHead key={"head"}>
            <TableRow key={"0"} sx={{bgcolor:"auditory.main"}}>
            <TableCell key={"action"} align='center'>Accion</TableCell>
            <TableCell key={"date_init"} align="center">Fecha inicio</TableCell>
            <TableCell key={"date_end"} align="center">Fecha fin</TableCell>            
            <TableCell key={"codigo_cargo"} align='center'>Codigo cargo</TableCell>
            <TableCell key={"tipo_cargo"} align='center'>Tipo cargo</TableCell>
            <TableCell key={"descripcion_cargo"} align='center'>Descripcion cargo</TableCell>
            <TableCell key={"dependencia"} align='center'>Dependencia</TableCell>
            <TableCell key={"office"} align="center">Oficina</TableCell>
            <TableCell key={"deletedby"} align="center">Modificado por</TableCell>
            <TableCell key={"deletedat"} align="center">Fecha modificacion</TableCell>
            <TableCell key={"deletedathour"} align="center">Hora modificacion</TableCell>
            </TableRow>
        </TableHead>
        {
            <TableBody>
            {
                historial_versiones && historial_versiones.map((designacion_h, index) => {
                const esPrimeraDesignacion = index === 0;
                const designacionAnterior = esPrimeraDesignacion ? designacion : historial_versiones[index - 1];

                const colorFondoFechaInicio = designacion_h.fecha_inicio !== designacionAnterior.fecha_inicio ? "auditory.third" : null;
                const colorFondoFechaFin = designacion_h.fecha_fin !== designacionAnterior.fecha_fin ? "auditory.third" : null;
                const colorFondoCargo = designacion_h.cargo?.codigo !== designacionAnterior.cargo?.codigo ? "auditory.third" : null;
                const colorFondoDependencia = designacion_h.cabecera?.name !== designacionAnterior.cabecera?.name ? "auditory.third" : null;
            
                return (
                    <TableRow
                    key={`${designacion_h.fecha_inicio}${designacion_h.cargo.codigo}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell>{designacion_h.action === 'CLOSE' ? "Cierre" : designacion_h.action === 'REVIVE' ? 'Revivir' : 'Edición'}</TableCell>
                    <TableCell align="cen"
                    sx={{
                        bgcolor: colorFondoFechaInicio 
                      }}>{obtenerFecha(designacion_h.fecha_inicio).fecha}</TableCell>
                    <TableCell align="center"
                    sx={{
                        bgcolor: colorFondoFechaFin 
                      }}>{designacion_h.fecha_fin ? obtenerFecha(designacion_h.fecha_fin).fecha : '-'}</TableCell>
                    <TableCell align='center'
                    sx={{
                        bgcolor: colorFondoCargo 
                      }}>{designacion_h.cargo?.codigo}</TableCell>
                    <TableCell align='center'
                    sx={{
                        bgcolor: colorFondoCargo 
                      }}>{designacion_h.cargo?.tipo}</TableCell>
                    <TableCell align='center'
                    sx={{
                        bgcolor: colorFondoCargo 
                      }}>{designacion_h.cargo?.descripcion}</TableCell>
                    <TableCell align="center"
                    sx={{
                        bgcolor: colorFondoDependencia,
                        '&:hover': {
                            cursor: 'pointer',
                            borderRadius: '.5em',
                            boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                        }
                        }}
                        onClick={() => handleClickDependencia(designacion_h.usuario_dni, designacion_h.cabecera?.id)}                             
                        >
                            {designacion_h.cabecera?.name}
                    </TableCell>
                    {designacion_h.cabecera?.id !== designacion_h.dependency_id ?
                    <TableCell align="center"                             
                        sx={{
                            bgcolor: colorFondoDependencia,
                            '&:hover': {
                                cursor: 'pointer',
                                borderRadius: '.5em',
                                boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                            }
                            }}
                            onClick={() => handleClickDependencia(designacion_h.usuario_dni, designacion_h.dependency_id)}
                        >
                            {designacion_h.dependency?.name }
                    </TableCell>
                    :<TableCell align='center'>-</TableCell>}

                    <TableCell align="center" 
                        sx={{
                        '&:hover': {
                            cursor: 'pointer',
                            borderRadius: '.5em',
                            boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                        }
                        }}
                        onClick={() => handleClickCreador(designacion_h.usuario_dni, designacion_h.updated_by)}
                    >
                        {designacion_h.modificador}
                    </TableCell>
                    <TableCell align="left">{obtenerFecha(designacion_h.updated_at).fecha}</TableCell>
                    <TableCell align="left">{obtenerFecha(designacion_h.updated_at).horario}</TableCell>
                    
                    </TableRow>
                );
                })
            }
            </TableBody>
            }
            </Table>
        </TableContainer>
        </Grid>        
    </>
  );
};

export default ListadoAuditoria;
