import { createSlice } from '@reduxjs/toolkit';

import { ordenarPorNombre } from '../../utils/ordenarPorNombre';

const initialState = {
  status: '',
  text: '',
  usuarios: [],
  usuario: null,
};

export const usuarioSlice = createSlice({
  name: 'usuarioReducer',
  initialState,
  reducers: {
    crear: (state, { payload }) => {
      return {
        ...state,
        status: payload.status,
        text: payload.text,
      };
    },
    editar: (state, { payload }) => {
      return {
        ...state,
        status: payload.status,
        text: payload.text,
      };
    },
    eliminar: (state, { payload }) => {
      return {
        ...state,
        status: payload.status,
        text: payload.text,
      };
    },
    creacion: (state, { payload }) => {
      const conjuntoDependencias = new Set(payload.data.dependencias.map(dependencia => dependencia.id));
      const dependencias = Array.from(conjuntoDependencias).map(id => {
        return payload.data.dependencias.find(dependencia => dependencia.id === id);
      });

      return {
        ...state,
        tipo_cargos: payload.data.tipo_cargos,
        dependencias: ordenarPorNombre(dependencias, 'name'),
      };
    },
    buscar: (state, { payload }) => {
      return {
        ...state,
        usuarios: payload.usuarios ? ordenarPorNombre(payload.usuarios, 'nombre') : payload.usuarios,
        mensaje: payload.mensaje,
      };
    },
    buscar_dni: (state, { payload }) => {
      return {
        ...state,
        usuario: payload.data.dni ? payload.data : null,
        text: payload.data.mensaje ? payload.data.mensaje : null,
        flag: !payload.data.flag ? payload.data.flag : null,
      };
    },
    buscar_dni_audit: (state, { payload }) => {
      return {
        ...state,
        usuario: payload.data.dni ? payload.data : null,
        text: payload.data.mensaje ? payload.data.mensaje : null,
        flag: !payload.data.flag ? payload.data.flag : null,
      };
    },
    cambiar_contraseña: (state, { payload }) => {
      return {
        ...state,
        status: payload.status,
        text: payload.text,
      }
    },
    restaurar: (state) => {
      return {
        ...state,
        usuarios: [],
        usuario: null,
      };
    },
    restaurar_mensajes: (state) => {
      return{
        ...state,
        status: '',
        text: ''
      }
    }
  },
});

export const { crear, editar, eliminar, creacion, buscar, buscar_dni, buscar_dni_audit, cambiar_contraseña, restaurar, restaurar_mensajes } = usuarioSlice.actions;

export default usuarioSlice.reducer;
