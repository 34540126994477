import axios from "axios";

import { 
  justificaciones_por_dni, justificaciones_generales,
  crear_justificacion, editar_justificacion, tipo_por_id,
  tipos, buscar_id, eliminar, editar_justificacion_general,
  restaurar_mensajes, justificaciones_personales, crear_tipo,
  crear_justificacion_general, eliminar_general, eliminar_tipo,
  editar_tipo_justificacion, restaurar_tipos
} from '../reducers/justificacionesReducer';

export const justificaciones_dni = (dni) => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/justificaciones/${dni}`)
      .then(response => {
        dispatch(justificaciones_por_dni({data: response.data.justificaciones, status: response.data.status, text: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
}; 

export const buscar_tipos = () => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/tipos-justificaciones`)
      .then(response => {
        dispatch(tipos({data: response.data.tipos}));
      })
      .catch(error => console.log(error));
  };
};

export const buscar_tipos_nombre = (nombre) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/tipos-justificaciones/nombre`, nombre)
      .then(response => {
        dispatch(tipos({data: response.data.tipos}));
      })
      .catch(error => console.log(error));
  };
}; 

export const buscar_tipos_eliminados = () => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/tipos-justificaciones-eliminados`)
      .then(response => {
        dispatch(tipos({data: response.data.tipos}));
      })
      .catch(error => console.log(error));
  };
};

export const buscar_tipos_eliminados_nombre = (nombre) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/tipos-justificaciones-eliminados/nombre`, nombre)
      .then(response => {
        dispatch(tipos({data: response.data.tipos}));
      })
      .catch(error => console.log(error));
  };
}; 

export const buscar_tipos_generales = () => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/tipos-justificaciones-generales`)
      .then(response => {
        dispatch(tipos({data: response.data.tipos}));
      })
      .catch(error => console.log(error));
  };
};

export const buscar_tipo_por_id = (id) => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/tipo-justificacion/${id}`)
      .then(response => {
        dispatch(tipo_por_id({data: response.data}));
      })
      .catch(error => console.log(error));
  };
}; 

export const justificacion_crear = (data) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/justificacion/crear`, data)
      .then(response => {
        dispatch(crear_justificacion({data: response.data.data, status: response.data.status, text: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
}; 

export const tipo_justificacion_crear = (data) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/tipo-justificacion/crear`, data)
      .then(response => {
        dispatch(crear_tipo({data: response.data.data, status: response.data.status, text: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
}; 

export const justificacion_editar = (data) => {
  return function(dispatch) {
    return axios.put(`${process.env.REACT_APP_BASE_URL}/justificacion-editar`, data)
      .then(response => {
        dispatch(editar_justificacion({data: response.data.data, status: response.data.status, text: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
}; 

export const justificacion_editar_general = (data) => {
  return function(dispatch) {
    return axios.put(`${process.env.REACT_APP_BASE_URL}/justificacion-general-editar`, data)
      .then(response => {
        dispatch(editar_justificacion_general({data: response.data.data, status: response.data.status, text: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
}; 

export const tipo_justificacion_editar = (data) => {
  return function(dispatch) {
    return axios.put(`${process.env.REACT_APP_BASE_URL}/tipo-justificacion/editar`, data)
      .then(response => {
        dispatch(editar_tipo_justificacion({data: response.data.data, status: response.data.status, text: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
}; 

export const justificacion_personal = (data) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/justificaciones-personales`, data)
      .then(response => {
        dispatch(justificaciones_personales({data: response.data.justificaciones}));
      })
      .catch(error => console.log(error));
  };
}; 

export const justificacion_general = (data) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/justificaciones-generales`, data)
      .then(response => {
        dispatch(justificaciones_generales({data: response.data.justificaciones, status: response.data.status, text: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
}; 

export const justificacion_general_crear = (data) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/justificacion-general/crear`, data)
      .then(response => {
        dispatch(crear_justificacion_general({status: response.data.status, text: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
}; 

export const justificacion_por_id = (id) => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/justificacion/${id}`)
      .then(response => {
        dispatch(buscar_id({justificacion: response.data.justificacion, creador: response.data.creador
          , status: response.status, mensaje: response.statusText}));
      })
      .catch(error => console.log(error));
  };
};


export const eliminar_justificacion = (data) => {
  return function(dispatch) {
    return axios.delete(`${process.env.REACT_APP_BASE_URL}/justificacion-personal/eliminar`, {
      headers: {
        'X-Data': JSON.stringify(data)
      },
    })
      .then(response => {
        dispatch(eliminar({status: response.data.status, mensaje: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
};

export const eliminar_justificacion_general = (data) => {
  return function(dispatch) {
    return axios.delete(`${process.env.REACT_APP_BASE_URL}/justificacion-general/eliminar`, {
      headers: {
        'X-Data': JSON.stringify(data)
      },
    })
      .then(response => {
        dispatch(eliminar_general({status: response.data.status, mensaje: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
};

export const eliminar_tipo_justificacion = (data) => {
  return function(dispatch) {
    return axios.delete(`${process.env.REACT_APP_BASE_URL}/tipo-justificacion/eliminar`, {
      headers: {
        'X-Data': JSON.stringify(data)
      },
    })
      .then(response => {
        dispatch(eliminar_tipo({status: response.data.status, mensaje: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
};

export const reactivar_tipo_justificacion = (data) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/tipo-justificacion/reactivar`, data)
      .then(response => {
        dispatch(eliminar_tipo({status: response.data.status, mensaje: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
};

export const restaurarMensajes = () => {
  return function(dispatch) {
    dispatch(restaurar_mensajes());
  };
};

export const restaurarTipos = () => {
  return function(dispatch) {
    dispatch(restaurar_tipos());
  };
};

