import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  horario: [],
};

export const horarioSlice = createSlice({
  name: 'horarioReducer',
  initialState,
  reducers: {
    crear: (state, { payload }) => {
      return {
        ...state,
        status: payload.status,
        text: payload.text,
      };
    },
    editar: (state, { payload }) => {
      return {
        ...state,
        status: payload.status,
        text: payload.text,
      };
    },
    buscar: (state, { payload }) => {
      return {
        ...state,
        usuarios: payload.usuarios,
        mensaje: payload.mensaje,
      };
    },
  },
});

export const { crear, editar, buscar } = horarioSlice.actions;

export default horarioSlice.reducer;