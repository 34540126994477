import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import ModalComponent from '../ModalComponent/ModalComponent';
import Pie from '../Pie/Pie';

// Actions
import { buscar_dependencias_hijas, buscar_por_id, editarDependencia } from '../../redux/action-types/dependenciaActions';
import { obtener_cabeceras } from '../../redux/action-types/rolActions';

// MUI componentes
import { 
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

// MUI iconos
import ApartmentIcon from '@mui/icons-material/Apartment';
import SchemaIcon from '@mui/icons-material/Schema';

// const ALPHA = /^[\p{L}\s]+$/u;
const ALPHA = /^[\p{L}\p{M}\s]+$/u;
const DIRE = 'presi.unlp.edu.ar';

const validate = (state) => {
  let errors = {};

  if(state.nombreInput === '') {
    errors.nombreInput = '';
  } else if(state.nombreInput.length < 3) {
    errors.nombreInput = 'El nombre debe tener al menos 3 caracteres...';
  } else if(!ALPHA.test(state.nombreInput)) {
    errors.nombreInput = 'Solo se aceptan letras y espacios...';
  };

  if(state.emailInput === '') {
    errors.emailInput = '';
  } else if(!state.emailInput.split('@')[0]) {
    errors.emailInput = 'Ingresa el usuario...';
  } else if(DIRE !== state.emailInput.split('@')[1]) {
    errors.emailInput = 'La dirección debe terminar con el formato "@presi.unlp.edu.ar"';
  };

  return errors;
};

const initialState = {
  nombreInput: '',
  descripcionInput: '',
  telefonoInput: '',
  emailInput: '',
  padreInput: '',
  hijoInput: '',
};

const EditarDependencia = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const { cabeceras } = useSelector(state => state.rol);
  const { padre, hijas, status, text, dependencia } = useSelector(state => state.dependencia);
  const dniActual  = useSelector(state => state.acceso.dni);

  const [ state, setState ] = useState(initialState);
  const [ errors, setErrors ] = useState(initialState);

  const [isOpenModal, openModal, closeModal] = useModal();

  const handleChange = event => {
    const { name, value } = event.target;
    setErrors(validate({
      ...state,
      [name]: value
    }));
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleChangePadre = event => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value || '',
      hijoInput: '',
    });
    if(value) dispatch(buscar_dependencias_hijas(value));
  };

  const handleChangeHija = (event) => {
    const { name, value } = event.target;
      setState({
        ...state,
        [name]: value,
      });
  };

  const handleClick = (event) => {
    navigate(`/dependencia/${id}`);
  };

  const handleSubmit = event => {
    event.preventDefault();
    const data = {...state, idInput: id, usuarioActualInput: dniActual};
    dispatch(editarDependencia(data));
    openModal();
    setTimeout(() => {
      navigate(`/dependencia/${id}`);
    }, 2000);
    setState(initialState);
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      nombreInput: dependencia.name || '',
      descripcionInput: dependencia.descripcion || '',
      telefonoInput: dependencia.telefono || '',
      emailInput: dependencia.email || '',
      padreInput: '',
      hijoInput: '',
    }));
    dispatch(obtener_cabeceras());
    dispatch(buscar_por_id(id));
  }, [dispatch, id]);

  useEffect(() => {
    if(padre && padre.id){
      setState((state) => ({
        ...state,
        padreInput: padre.id,
      }));
      dispatch(buscar_dependencias_hijas(padre.id))
    } else {
      setState((state) => ({
        ...state,
        padreInput: '',
      }));
    };
  }, [dispatch, padre]);

  if(!dependencia) {
    return (
      <>
        {/* Rotulo de la Pág.*/}
        <Rotulo titulo={"Editar Dependencia"} />
        <Grid container>
          <CircularProgress 
            sx={{
              width: "auto",
              height: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />  
        </Grid>
        {/* Pie de Pág. */}
        <Pie />
      </>
    );
  };

  return (
    <>
      {/* Rotulo de la Pág.*/}
      <Rotulo titulo={"Editar Dependencia"} />
      <Grid id={"editarDependencia"}  container component="form" noValidate onSubmit={handleSubmit} spacing={6} 
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3vh",
        }}
      > 
        {/* Contenedor Grid de los Items   */}
        <Grid item container xs={12} sm={10} md={8} lg={6} spacing={6}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        > 
          {/* Item 1 */}
          <Grid item xs={10} sm={10} md={8} lg={6}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}> 
              <ApartmentIcon />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                Editar Información
              </Typography>
            </Box>   

            {/* Formulario para Modificar */}
            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Nombre:
              </Typography>
              <TextField 
                id='nombreInput' 
                name="nombreInput"
                type='text'
                value={state.nombreInput}
                placeholder={dependencia.name || ''} 
                error={errors.nombreInput && "error"}
                onChange={handleChange}
                inputProps={{
                  autoComplete: 'off'
                }}
                InputLabelProps={{
                  sx: {
                    color: 'rgb(150, 150, 150)',
                  }
                }}
              />
              {
                errors.nombreInput && 
                <FormHelperText
                  sx={{
                    color: "red",
                  }}
                >
                  {errors.nombreInput}
                </FormHelperText>
              }
            </Box>

            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}} >
              <Typography mr={"1em"} fontWeight={"bold"}>
                  Descripción:
              </Typography>
              <TextField 
                id='descripcionInput' 
                name="descripcionInput"
                type='text'
                value={state.descripcionInput}
                placeholder={dependencia.descripcion} 
                onChange={handleChange}
                inputProps={{
                  autoComplete: 'off'
                }}
                InputLabelProps={{
                  sx: {
                    color: 'rgb(150, 150, 150)',
                  }
                }}
              />
            </Box>

            <Box sx={{with: "100%", display: "flex", justifyContent: "space-between"}}>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Teléfono:
                </Typography>
                <TextField 
                  id='telefonoInput' 
                  name="telefonoInput"
                  type='text'
                  value={state.telefonoInput}
                  placeholder={dependencia.telefono} 
                  onChange={handleChange}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                />
              </Box>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  E-mail:
                </Typography>
                <TextField 
                  id='emailInput' 
                  name="emailInput"
                  type='mail'
                  value={state.emailInput}
                  placeholder={dependencia.email} 
                  error={errors.emailInput && "error"}
                  onChange={handleChange}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                />
                {
                  errors.emailInput && 
                  <FormHelperText
                    sx={{
                      color: "red",
                    }}
                  >
                    {errors.emailInput}
                  </FormHelperText>
                }
              </Box>
            </Box>
          </Grid>
          {/* Item 2 */}
          <Grid item xs={10} sm={10} md={8} lg={6}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}> 
              <SchemaIcon />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                Organigrama
              </Typography>
            </Box> 

            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Dependencia Padre:
              </Typography>
              <TextField 
                labelid="padreInput"
                id='padreInput' 
                name="padreInput"
                value={state.padreInput || ''}
                onChange={handleChangePadre}
                select
                inputProps={{
                  autoComplete: 'off'
                }}
                InputLabelProps={{
                  sx: {
                    color: 'rgb(150, 150, 150)',
                  }
                }}
              >
                <MenuItem value="">
                  <em>Ninguno</em>
                </MenuItem> 
                {
                  cabeceras && cabeceras.map(dependencia => {
                    return (
                      <MenuItem key={dependencia.id}
                        value={dependencia.id}
                      >
                        {dependencia.name}
                      </MenuItem>
                    )
                  })
                }
              </TextField>
            </Box>
            {/* Oficinas */}
            {
              hijas && hijas.length > 0 ?
              <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Oficina Padre:
                </Typography>
                <TextField 
                  labelid="hijoInput"
                  id='hijoInput' 
                  name="hijoInput"
                  value={state.hijoInput || ''}
                  onChange={handleChangeHija}
                  select
                  disabled={!state.padreInput || !hijas}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                > 
                  <MenuItem value="">
                    <em>Ninguno</em>
                  </MenuItem>
                  {
                    hijas && hijas.map(dependencia => {
                      return (
                        <MenuItem key={dependencia.id}
                         value={dependencia.id}
                        >
                          {dependencia.name}
                        </MenuItem>
                      )
                    })
                  }
                </TextField>
              </Box>
              :
                null
            }
            
          </Grid>
          {/* Item 3 */}
          <Grid item container xs={10} sm={10} md={8} lg={12}>
            <Button 
                type="submit"
                variant="contained"
                sx={{
                  mb: 2,
                  mr: 1,
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleClick}
              >
               Cancelar
            </Button>
            { 
              (
                (errors.nombreInput?.length > 0 || errors.emailInput?.length > 0)
                ||
                (state.nombreInput === '' && state.padreInput === '' && state.emailInput === '' 
                 && state.telefonoInput === '' && state.descripcionInput === '')
              )
              ?
                <Button
                  type="submit"
                  disabled
                  variant="contained"
                  sx={{
                    mb: 2,
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Guardar
                </Button>
              :
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mb: 2,
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Guardar
                </Button>
            }
          </Grid>
        </Grid>
      </Grid>
      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {status}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {text}
        </Typography>
      </ ModalComponent>
      {/* Pie de Pág. */}
      <Pie />
    </>
  );
};

export default EditarDependencia;