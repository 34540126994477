import palette from "./lightPalette";
import "@fontsource/open-sans";

// Light Typography
const typography = {
  fontFamily: "'Open Sans', 'Helvetica', 'Arial', 'sans-serif'",
  h1: {
    color: palette.text.primary,
    fontWeight: 300,
    fontSize: '5rem',
    letterSpacing: "-0.24px",
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 300,
    fontSize: '3.5rem',
    letterSpacing: "-0.24px",
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 300,
    fontSize: '3rem',
    letterSpacing: "-0.06px",
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 300,
    fontSize: '2.5rem',
    letterSpacing: "-0.06px",
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 300,
    fontSize: '1.5rem',
    letterSpacing: "-0.05px",
  },
  h6: {
    color: palette.text.primary,
    fontWeight:300,
    fontSize: '1.3rem',
    letterSpacing: "-0.05px",
  },
};

export default typography; 