export const obtenerFecha = (fecha) => {
  if (fecha==null){
    return {
      fecha: "-"
    };
  }
  else{
    let date = fecha.length === 10 ? new Date(fecha.split('/').reverse().join('/')) : new Date(fecha);
    //let time = date.getTime(); --> me va a servir para sacar las horas de entrada y salida, y para diferencia de dias
    let hora = (date.getHours() < 10 ? '0' : '') + date.getHours();
    let minutos = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    let segundos = (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();
    
    let show = date.toLocaleDateString([], {
      timeZone: 'UTC',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      //weekday: 'long',
    }).split('/');

    let meses_invierno = ["05","06","07","08"]
    let esInvierno = meses_invierno.includes(show[1])

    let show2 = date.toUTCString().split(" ")
    let anio2 = parseInt(show[2], 10) +1
    return {
      dia:show[1], 
      mes:show[0], 
      anio:show[2], 
      anio2: anio2,
      fecha:`${show[0]}/${show[1]}/${show[2]}`,
      fecha2:`${show[2]}-${show[1]}-${show[0]}`,
      hora,
      minutos,
      segundos,
      horario: `${hora}:${minutos}:${segundos}`,
      horario2: `${show2[4]}`,
      esInvierno: esInvierno
    };
  };
};
