import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import { useModal } from '../../../hooks/useModal';

// Componentes
import Rotulo from '../../Rotulo/Rotulo';
import ModalComponent from '../../ModalComponent/ModalComponent';
import Pie from '../../Pie/Pie';

// Actions
import { crearHorario } from '../../../redux/action-types/horarioActions';

// Componentes de MUI
import { 
  Box, 
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography, 
} from '@mui/material';

// MUI iconos
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

const initialStateHorario = {
  horaLEntrada: '',
  horaL: '',
  horaMEntrada: '',
  horaM: '',
  horaMiEntrada: '',
  horaMi: '',
  horaJEntrada: '',
  horaJ: '',
  horaVEntrada: '',
  horaV: '',
  horaSEntrada: '',
  horaS: '',
  horaDEntrada: '',
  horaD: '',
};

const CrearHorarioNuevo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dni } = useParams();
  
  const { status, text } = useSelector(state => state.usuario);
  const dniActual  = useSelector(state => state.acceso.dni); 

  const [ horarios, setHorarios ] = useState(initialStateHorario);

  const [ isOpenModal, openModal, closeModal ] = useModal();

  const handleReplica = () => {
    setHorarios({
      ...horarios,
      horaMEntrada: horarios.horaLEntrada,
      horaM: horarios.horaL,
      horaMiEntrada: horarios.horaLEntrada,
      horaMi: horarios.horaL,
      horaJEntrada: horarios.horaLEntrada,
      horaJ: horarios.horaL,
      horaVEntrada: horarios.horaLEntrada,
      horaV: horarios.horaL,
    });
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setHorarios({
      ...horarios,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const dataHorario = {
      dniInput: dni, 
      usuarioActualInput: dniActual,
      horarios: [
        { 
          diaInput: "Lunes",
          entradaInput: horarios.horaLEntrada  || "00:00:00",
          horasInput: horarios.horaL  || "0",
        },
        {
          diaInput: "Martes",
          entradaInput: horarios.horaMEntrada  || "00:00:00",
          horasInput: horarios.horaM  || "0",
        },
        {
          diaInput: "Miercoles",
          entradaInput: horarios.horaMiEntrada  || "00:00:00",
          horasInput: horarios.horaMi  || "0",
        },
        {
          diaInput: "Jueves",
          entradaInput: horarios.horaJEntrada  || "00:00:00",
          horasInput: horarios.horaJ  || "0",
        },
        {
          diaInput: "Viernes",
          entradaInput: horarios.horaVEntrada  || "00:00:00",
          horasInput: horarios.horaV  || "0",
        },
        {
          diaInput: "Sabado",
          entradaInput: horarios.horaSEntrada  || "00:00:00",
          horasInput: horarios.horaS  || "0",
        },
        {
          diaInput: "Domingo",
          entradaInput: horarios.horaDEntrada  || "00:00:00",
          horasInput: horarios.horaD  || "0",
        },
      ],
    };
    dispatch(crearHorario({dataHorario}));
    openModal();
    setTimeout(() => {
      navigate(`/perfil/${dni}`);
    }, 3000);
    setHorarios(initialStateHorario);
  };
  
  const handleClick = () => {
    navigate(`/perfil/${dni}`);
    setHorarios(initialStateHorario);
  };

  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={'Crear Horario'} />
      {/* Aca arranca el componente Crear Horario*/}
      <Grid container component="form" noValidate onSubmit={handleSubmit} spacing={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* Contenedor Grid de los Items   */}
        <Grid item container xs={12} sm={10} md={8} lg={6} spacing={6}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item xs={10} sm={10} md={8} lg={7}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em", mt:"2em"}}> 
              <AccessTimeFilledIcon />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                Horario
              </Typography>
            </Box> 

            <Box sx={{width: "100%", display: "flex"}}>
              <Box sx={{width: "30%", display: "flex"}}></Box>
              <Box sx={{width: "70%", display: "flex", justifyContent: "space-between", mb: 2}}>
                { (horarios.horaLEntrada === '' || (horarios.horaL === '0' || horarios.horaL === '')) 
                  ?
                  <Button        
                    variant="contained"
                    disabled
                    sx={{
                      fontSize: ".8em",
                      fontWeight: "bold",
                      bgcolor: 'background.paper',
                      '&:hover': {
                        bgcolor: 'background.paper',
                        transform: 'scale(1.01)',
                      }
                    }}
                  >
                    Replicar Lunes a Viernes
                  </Button>
                  :
                  <Button        
                    variant="contained"
                    sx={{
                      fontSize: ".8em",
                      fontWeight: "bold",
                      bgcolor: 'background.paper',
                      '&:hover': {
                        bgcolor: 'background.paper',
                        transform: 'scale(1.01)',
                      }
                    }}
                    onClick={handleReplica}
                  >
                    Replicar Lunes a Viernes
                  </Button>
                }
              </Box>
            </Box>

            <Box sx={{display: "flex", width:"100%"}}>
              <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", mb: 1}}>
                <Typography  fontWeight={"bold"}>
                  Lunes*
                </Typography>
                <Box sx={{width: "70%", display: "flex", justifyContent: "space-between"}}>
                  <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                    <Typography >
                      Entrada:
                    </Typography>
                    <TextField 
                      name="horaLEntrada"
                      type="time"
                      value={horarios.horaLEntrada}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                  <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                    <Typography  >
                      Horas:
                    </Typography>
                    <TextField 
                      name="horaL"
                      select
                      value={horarios.horaL || ''}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            {
                              horarios.horaL
                              ?
                              null
                              :
                              <span style={{ color: 'black' }}> {0} </span>
                            }
                          </InputAdornment>
                        ),
                      }}
                    >
                      <MenuItem key={'0'} value='0'>0</MenuItem>
                      <MenuItem key={'1'} value='1'>1</MenuItem>
                      <MenuItem key={'2'} value='2'>2</MenuItem>
                      <MenuItem key={'3'} value='3'>3</MenuItem>
                      <MenuItem key={'4'} value='4'>4</MenuItem>
                      <MenuItem key={'5'} value='5'>5</MenuItem>
                      <MenuItem key={'6'} value='6'>6</MenuItem>
                      <MenuItem key={'7'} value='7'>7</MenuItem>
                      <MenuItem key={'8'} value='8'>8</MenuItem>
                      <MenuItem key={'9'} value='9'>9</MenuItem>
                      <MenuItem key={'10'} value='10'>10</MenuItem>
                    </TextField>
                  </Box>                
                </Box>                            
              </Box>
            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", mb: 1}}>
              <Typography  fontWeight={"bold"}>
                Martes*
              </Typography>
              <Box sx={{width: "70%", display: "flex", justifyContent: "space-between" }}>
                <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                  <Typography >
                    Entrada:
                  </Typography>
                  <TextField 
                    name="horaMEntrada"
                    type="time"
                    value={horarios.horaMEntrada}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                  <Typography  >
                    Horas:
                  </Typography>
                  <TextField 
                    name="horaM"
                    select
                    value={horarios.horaM || ''}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          {
                            horarios.horaM
                            ?
                            null
                            :
                            <span style={{ color: 'black' }}> {0} </span>
                          }
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem key={'0'} value='0'>0</MenuItem>
                    <MenuItem key={'1'} value='1'>1</MenuItem>
                    <MenuItem key={'2'} value='2'>2</MenuItem>
                    <MenuItem key={'3'} value='3'>3</MenuItem>
                    <MenuItem key={'4'} value='4'>4</MenuItem>
                    <MenuItem key={'5'} value='5'>5</MenuItem>
                    <MenuItem key={'6'} value='6'>6</MenuItem>
                    <MenuItem key={'7'} value='7'>7</MenuItem>
                    <MenuItem key={'8'} value='8'>8</MenuItem>
                    <MenuItem key={'9'} value='9'>9</MenuItem>
                    <MenuItem key={'10'} value='10'>10</MenuItem>
                  </TextField>
                </Box>
              </Box>
            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", mb: 1}}>
              <Typography  fontWeight={"bold"}>
                Miércoles*
              </Typography>
              <Box sx={{width: "70%", display: "flex", justifyContent: "space-between" }}>
                <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                  <Typography >
                    Entrada:
                  </Typography>
                  <TextField 
                    name="horaMiEntrada"
                    type="time"
                    value={horarios.horaMiEntrada}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                  <Typography  >
                    Horas:
                  </Typography>
                  <TextField 
                    name="horaMi"
                    select
                    value={horarios.horaMi || ''}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          {
                            horarios.horaMi
                            ?
                            null
                            :
                            <span style={{ color: 'black' }}> {0} </span>
                          }
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem key={'0'} value='0'>0</MenuItem>
                    <MenuItem key={'1'} value='1'>1</MenuItem>
                    <MenuItem key={'2'} value='2'>2</MenuItem>
                    <MenuItem key={'3'} value='3'>3</MenuItem>
                    <MenuItem key={'4'} value='4'>4</MenuItem>
                    <MenuItem key={'5'} value='5'>5</MenuItem>
                    <MenuItem key={'6'} value='6'>6</MenuItem>
                    <MenuItem key={'7'} value='7'>7</MenuItem>
                    <MenuItem key={'8'} value='8'>8</MenuItem>
                    <MenuItem key={'9'} value='9'>9</MenuItem>
                    <MenuItem key={'10'} value='10'>10</MenuItem>
                  </TextField>
                </Box>
              </Box>
            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", mb: 1}}>
              <Typography  fontWeight={"bold"}>
                Jueves*
              </Typography>
              <Box sx={{width: "70%", display: "flex", justifyContent: "space-between" }}>
                <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                  <Typography >
                    Entrada:
                  </Typography>
                  <TextField 
                    name="horaJEntrada"
                    type="time"
                    value={horarios.horaJEntrada}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                  <Typography  >
                    Horas:
                  </Typography>
                  <TextField 
                    name="horaJ"
                    select
                    value={horarios.horaJ || ''}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          {
                            horarios.horaJ
                            ?
                            null
                            :
                            <span style={{ color: 'black' }}> {0} </span>
                          }
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem key={'0'} value='0'>0</MenuItem>
                    <MenuItem key={'1'} value='1'>1</MenuItem>
                    <MenuItem key={'2'} value='2'>2</MenuItem>
                    <MenuItem key={'3'} value='3'>3</MenuItem>
                    <MenuItem key={'4'} value='4'>4</MenuItem>
                    <MenuItem key={'5'} value='5'>5</MenuItem>
                    <MenuItem key={'6'} value='6'>6</MenuItem>
                    <MenuItem key={'7'} value='7'>7</MenuItem>
                    <MenuItem key={'8'} value='8'>8</MenuItem>
                    <MenuItem key={'9'} value='9'>9</MenuItem>
                    <MenuItem key={'10'} value='10'>10</MenuItem>
                  </TextField>
                </Box>
              </Box>
            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", mb: 1}}>
              <Typography  fontWeight={"bold"}>
                Viernes*
              </Typography>
              <Box sx={{width: "70%", display: "flex", justifyContent: "space-between" }}>
                <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                  <Typography >
                    Entrada:
                  </Typography>
                  <TextField 
                    name="horaVEntrada"
                    type="time"
                    value={horarios.horaVEntrada}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                  <Typography  >
                    Horas:
                  </Typography>
                  <TextField 
                    name="horaV"
                    select
                    value={horarios.horaV || ''}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          {
                            horarios.horaV
                            ?
                            null
                            :
                            <span style={{ color: 'black' }}> {0} </span>
                          }
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem key={'0'} value='0'>0</MenuItem>
                    <MenuItem key={'1'} value='1'>1</MenuItem>
                    <MenuItem key={'2'} value='2'>2</MenuItem>
                    <MenuItem key={'3'} value='3'>3</MenuItem>
                    <MenuItem key={'4'} value='4'>4</MenuItem>
                    <MenuItem key={'5'} value='5'>5</MenuItem>
                    <MenuItem key={'6'} value='6'>6</MenuItem>
                    <MenuItem key={'7'} value='7'>7</MenuItem>
                    <MenuItem key={'8'} value='8'>8</MenuItem>
                    <MenuItem key={'9'} value='9'>9</MenuItem>
                    <MenuItem key={'10'} value='10'>10</MenuItem>
                  </TextField>
                </Box>
              </Box>
            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", mb: 1}}>
              <Typography  fontWeight={"bold"}>
                Sábado*
              </Typography>
              <Box sx={{width: "70%", display: "flex", justifyContent: "space-between" }}>
                <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                  <Typography >
                    Entrada:
                  </Typography>
                  <TextField 
                    name="horaSEntrada"
                    type="time"
                    value={horarios.horaSEntrada}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                  <Typography  >
                    Horas:
                  </Typography>
                  <TextField 
                    name="horaS"
                    select
                    value={horarios.horaS || ''}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          {
                            horarios.horaS
                            ?
                            null
                            :
                            <span style={{ color: 'black' }}> {0} </span>
                          }
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem key={'0'} value='0'>0</MenuItem>
                    <MenuItem key={'1'} value='1'>1</MenuItem>
                    <MenuItem key={'2'} value='2'>2</MenuItem>
                    <MenuItem key={'3'} value='3'>3</MenuItem>
                    <MenuItem key={'4'} value='4'>4</MenuItem>
                    <MenuItem key={'5'} value='5'>5</MenuItem>
                    <MenuItem key={'6'} value='6'>6</MenuItem>
                    <MenuItem key={'7'} value='7'>7</MenuItem>
                    <MenuItem key={'8'} value='8'>8</MenuItem>
                    <MenuItem key={'9'} value='9'>9</MenuItem>
                    <MenuItem key={'10'} value='10'>10</MenuItem>
                  </TextField>
                </Box>
              </Box>
            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", mb: 1}}>
              <Typography  fontWeight={"bold"}>
                Domingo*
              </Typography>
              <Box sx={{width: "70%", display: "flex", justifyContent: "space-between" }}>
                <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                  <Typography >
                    Entrada:
                  </Typography>
                  <TextField 
                    name="horaDEntrada"
                    type="time"
                    value={horarios.horaDEntrada}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                  <Typography  >
                    Horas:
                  </Typography>
                  <TextField 
                    name="horaD"
                    select
                    value={horarios.horaD || ''}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          {
                            horarios.horaD
                            ?
                            null
                            :
                            <span style={{ color: 'black' }}> {0} </span>
                          }
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem key={'0'} value='0'>0</MenuItem>
                    <MenuItem key={'1'} value='1'>1</MenuItem>
                    <MenuItem key={'2'} value='2'>2</MenuItem>
                    <MenuItem key={'3'} value='3'>3</MenuItem>
                    <MenuItem key={'4'} value='4'>4</MenuItem>
                    <MenuItem key={'5'} value='5'>5</MenuItem>
                    <MenuItem key={'6'} value='6'>6</MenuItem>
                    <MenuItem key={'7'} value='7'>7</MenuItem>
                    <MenuItem key={'8'} value='8'>8</MenuItem>
                    <MenuItem key={'9'} value='9'>9</MenuItem>
                    <MenuItem key={'10'} value='10'>10</MenuItem>
                  </TextField>
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* Item 2 */}
          <Grid item container xs={10} sm={10} md={8} lg={7}>
            <Button 
              variant="contained"
              sx={{
                mb: 2,
                mr: 1,
                fontSize: ".8em",
                fontWeight: "bold",
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
              onClick={handleClick}
            >
              Cancelar
            </Button>
            {
              ((horarios.horaLEntrada === '' && horarios.horaMEntrada === '' && horarios.horaMiEntrada === '' && horarios.horaJEntrada === ''
                && horarios.horaVEntrada === '' && horarios.horaSEntrada === '' && horarios.horaDEntrada === '' && horarios.horaL === ''
                && horarios.horaM === '' && horarios.horaMi === '' && horarios.horaJ === '' && horarios.horaV === ''
                && horarios.horaS === '' && horarios.horaD === ''))
              ?
                <Button
                  type="submit"
                  variant="contained"
                  disabled
                  sx={{
                    mb: 2,
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Crear
                </Button>
              :
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mb: 2,
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Crear
                </Button>
            }
          </Grid>
        </Grid>
      </Grid>
       {/* Componente de Notificaciones */}
       <ModalComponent open={isOpenModal} handleClose={closeModal} >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {status}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {text}
        </Typography>
      </ ModalComponent>
      {/* Copmponente de Pie de Pag. */}
      <Pie />
    </>
  );
};

export default CrearHorarioNuevo;