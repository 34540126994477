import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  mensaje: '',

};

export const messageSlice = createSlice({
  name: 'messageReducer',
  initialState,
  reducers: {
    agregar: (state, { payload }) => {
      return {
        ...state,
        mensaje: payload.mensaje,
      };
    },
    limpiar_mensajes: (state) => {
      return {
        ...state,
        mensaje: '',
      };
    },
  },
});

export const { agregar, limpiar_mensajes } = messageSlice.actions;

export default messageSlice.reducer;