import axios from "axios";

import { acceder, reacceder, cerrar, reset, limpiar } from "../reducers/accesoReducer";

import { setAuthorizationInterceptor } from "../../utils/tokenAuthorization";

setAuthorizationInterceptor();

export const acceso = (data) => {
  return function (dispatch) {
    return axios
      .post(`${process.env.REACT_APP_BASE_URL}/acceso`, data)
      .then((response) => {
        dispatch(
          acceder({
            data: response.data,
            status: response.status,
            text: response.statusText,
          })
        );
      })
      .catch((error) => console.log("/acceso", error));
  };
};

export const reacceso = (dni, isAuth, recordar, expiracion) => {
  return function (dispatch) {
    if (Date.parse(expiracion) < Date.now())
      dispatch(cerrar())
    else
      dispatch(reacceder({ dni, isAuth, recordar }));
  };
};

export const cerrarSesion = () => {
  // return function(dispatch) {
  //   return axios(``)
  //     .then(() => {
  //       dispatch(cerrar());
  //     })
  //     .catch(error => console.log(error.message));
  // };
  return function (dispatch) {
    dispatch(cerrar());
  };
};

export const resetPassword = (email) => {
  return function (dispatch) {
    return axios(``)
      .then(() => {
        dispatch(reset());
      })
      .catch((error) => console.log(error.message));
  };
};

export const restaurarMensajes = () => {
  return function(dispatch) {
        dispatch(limpiar());
      }
};

