import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from '../../hooks/useModal';
import dayjs from 'dayjs';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import ModalComponent from '../ModalComponent/ModalComponent';
import Pie from '../Pie/Pie';

// Acciones
import { editarReloj, buscarReloj } from '../../redux/action-types/relojActions';

// MUI componentes
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

// MUI Date Picker
import { DatePicker } from '@mui/x-date-pickers';

// MUI iconos
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';

const initialState = {
  nombreInput: '',
  ubicacionInput: '',
  ipInput: '',
  mascaraInput: '',
  routerInput: '',
  modeloInput: '',
  soapInput: '',
  algoritmoInput: '',
  fechaInput: '',
  marcaInput: '',
  activoInput: '',
};

const EditarReloj = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const { status, text, reloj } = useSelector(state => state.reloj);
  const dniActual  = useSelector(state => state.acceso.dni);

  const [ state, setState ] = useState(initialState);

  const [isOpenModal, openModal, closeModal] = useModal();

  const handleChange = event => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleClickCancelar = () => {
    navigate(`/relojes/reloj/${id}`);
  };

  const handleClickGuardar = event => {
    event.preventDefault();
    const data = {...state, idInput: id, usuarioActualInput: dniActual};
    dispatch(editarReloj(data));
    openModal();
    setTimeout(() => {
      navigate(`/relojes/reloj/${id}`);
    }, 3000);
    setState(initialState);
  };

  useEffect(() => {
    dispatch(buscarReloj(id));
    setState(s => ({
      nombreInput: reloj.nombre,
      ubicacionInput: reloj.ubicacion,
      ipInput: reloj.ip,
      mascaraInput: reloj.mascara,
      routerInput: reloj.router,
      soapInput: reloj.soap,
      modeloInput: reloj.modelo,
      algoritmoInput: reloj.algoritmo,
      fechaInput: reloj.fecha_instalacion,
      marcaInput: reloj.marca,
      activoInput: reloj.activo
    }))
  }, [dispatch, id, reloj.nombre, reloj.ubicacion, reloj.ip, reloj.mascara, reloj.router, reloj.soap,
      reloj.modelo, reloj.algoritmo, reloj.fecha_instalacion, reloj.activo, reloj.marca]);




  if(!reloj) {
    return(
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={'Detalles del Reloj'} />
        <CircularProgress
          sx={{
            width: "auto",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />
        {/* Copmponente de Pie de Pag. */}
        <Pie />
      </>
    );
  };

  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={'Editar Reloj'} />
      {/* Contenedor del componente */}
      <Grid id={"editarReloj"}  container component="form" noValidate onSubmit={handleClickGuardar} spacing={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3vh",
        }}
      >
        <Grid item container xs={12} sm={10} md={8} lg={6} spacing={6}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* Item 1 */}
          <Grid item xs={10} sm={10} md={8} lg={8}>
            {/* Contenedor de la cabecera */}
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}>
              <ManageHistoryIcon />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                Editar Información
              </Typography>
            </Box>

            <Box sx={{display: "flex", justifyContent: "space-between", mb:"1em"}}>
              <Box sx={{width: "47%", display: "flex",  flexDirection: "column"}} >
                <Typography fontWeight={"bold"} >
                  Nombre:
                </Typography>
                <TextField
                  id='nombreInput'
                  name="nombreInput"
                  type='text'
                  value={state.nombreInput}
                  // placeholder={reloj.nombre}
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      // set the color of the label when not shrinked
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                />
              </Box>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"}>
                  Ubicación:
                </Typography>
                <TextField
                  id='ubicacionInput'
                  name="ubicacionInput"
                  type='text'
                  value={state.ubicacionInput}
                  placeholder={reloj.ubicacion}
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      // set the color of the label when not shrinked
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                />
              </Box>
            </Box>

            <Box sx={{display: "flex", justifyContent: "space-between", mb:"1em"}}>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"}>
                  IP:
                </Typography>
                <TextField
                  id='ipInput'
                  name="ipInput"
                  type='text'
                  value={state.ipInput}
                  placeholder={reloj.ip}
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      // set the color of the label when not shrinked
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                />
              </Box>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"}>
                  Soap URL:
                </Typography>
                <TextField
                  id='soapInput'
                  name="soapInput"
                  type='text'
                  value={state.soapInput}
                  placeholder={reloj.soap}
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      // set the color of the label when not shrinked
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                />
              </Box>
            </Box>

            <Box sx={{display: "flex", justifyContent: "space-between", mb:"1em"}}>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"}>
                  Máscara de Red:
                </Typography>
                <TextField
                  id='mascaraInput'
                  name="mascaraInput"
                  type='text'
                  value={state.mascaraInput}
                  placeholder={reloj.mascara}
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      // set the color of the label when not shrinked
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                />
              </Box>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"}>
                  Algoritmo*:
                </Typography>
                <TextField
                  labelid="generoInputLabel"
                  id="algoritmoInput"
                  name="algoritmoInput"
                  value={state.algoritmoInput}
                  fullWidth
                  onChange={handleChange}
                  select
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                >
                  <MenuItem key={'viejo'} value={'viejo'}>PYZK</MenuItem>
                  <MenuItem key={'nuevo'} value={'nuevo'}>pyzkaccess (new)</MenuItem>
                </TextField>
              </Box>
            </Box>

            <Box sx={{display: "flex", justifyContent: "space-between", mb:"1em"}}>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"}>
                  Router:
                </Typography>
                <TextField
                  id='routerInput'
                  name="routerInput"
                  type='text'
                  value={state.routerInput}
                  placeholder={reloj.router}
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      // set the color of the label when not shrinked
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                />
              </Box>

            </Box>

            <Box sx={{display: "flex", justifyContent: "space-between", mb:"1em"}}>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"}>
                  Modelo:
                </Typography>
                <TextField
                  id='modeloInput'
                  name="modeloInput"
                  type='text'
                  value={state.modeloInput}
                  placeholder={reloj.modelo}
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      // set the color of the label when not shrinked
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                />
              </Box>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"}>
                  Fecha Instalación:
                </Typography>
                <DatePicker
              id={'fechaInput'}
              name="fechaInput"
              value={dayjs(reloj.fecha_instalacion)}
              views={['year', 'month', 'day']}
              onChange={date => handleChange({target: {value: date, name: 'fechaInput'}})}
              >
            </DatePicker>
                {/* <TextField
                  id='fechaInput'
                  name="fechaInput"
                  type='text'
                  value={state.fechaInput}
                  placeholder={reloj.fecha_instalacion}
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      // set the color of the label when not shrinked
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                /> */}
              </Box>
            </Box>

            <Box sx={{display: "flex", justifyContent: "space-between", mb:"1em"}}>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"}>
                  Marca:
                </Typography>
                <TextField
                  id='marcaInput'
                  name="marcaInput"
                  type='text'
                  value={state.marcaInput}
                  placeholder="Ingrese la marca..."
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      // set the color of the label when not shrinked
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                />
              </Box>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"}>
                  Estado:
                </Typography>
                <TextField
                  id='activoInput'
                  name="activoInput"
                  select
                  value={state.activoInput}
                  placeholder="Ingrese el estado..."
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                >
                  <MenuItem key={1} value={true}> Activo </MenuItem>
                  <MenuItem key={2} value={false}> Inactivo </MenuItem>
                </TextField>
              </Box>
            </Box>
          </Grid>

          {/* Item 2 */}
          <Grid item container xs={10} sm={10} md={8} lg={8}>
            <Button
                type="submit"
                variant="contained"
                sx={{
                  mr: 1,
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleClickCancelar}
              >
               Cancelar
            </Button>
            {
              ((state.nombreInput === reloj.nombre && state.ubicacionInput === reloj.ubicacion && state.ipInput === reloj.ip
              && state.mascaraInput === reloj.mascara && state.routerInput === reloj.router && state.modeloInput === reloj.modelo
              && state.soapInput === reloj.soap && state.algoritmoInput === reloj.algoritmo && state.fechaInput === reloj.fecha_instalacion
              && state.marcaInput === reloj.marca && state.activoInput === reloj.activo))
              ?
                <Button
                  type="submit"
                  disabled
                  variant="contained"
                  sx={{
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Guardar
                </Button>
              :
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Guardar
                </Button>
            }
          </Grid>
        </Grid>
      </Grid>
      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {status}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {text}
        </Typography>
      </ ModalComponent>
      {/* Pie de Pág */}
      <Pie />
    </>
  );
};

export default EditarReloj;
