import axios from "axios";

import { crear, listar, listar_tipo_cargos,buscar_por_codigo, buscar_por_tipo, buscar, editar, eliminar, restaurar_mensajes } from "../reducers/cargosReducer";

export const crearCargo = (data) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/cargo/crear`, data)
      .then(response => {
        dispatch(crear({status: response.data.status, text: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
};

export const listar_cargos = (cargos) => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/cargos`, cargos) 
      .then(response => {
          dispatch(listar({cargos: response.data.cargos,
                            status: response.status,
                            text: response.statusText}))
      })
      .catch(error => console.log(error));
  };
};

export const listar_tipos = (cargos) => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/cargos-tipos`, cargos) 
      .then(response => {
          dispatch(listar_tipo_cargos({tipo_cargos: response.data.tipos,
                            status: response.status,
                            text: response.statusText}))
      })
      .catch(error => console.log(error));
  };
};

export const cargo_por_codigo = (codigo) => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/cargo-codigo/${codigo}`)
    .then(response => {
        dispatch(buscar_por_codigo({data: response.data.cargo, status: response.status, mensaje: response.statusText}));
      })
      .catch(error => console.log(error));
  };
};

export const cargo_por_tipo = (tipo) => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/cargo/${tipo}`)
    .then(response => {
        dispatch(buscar_por_tipo({data: response.data.cargos}));
      })
      .catch(error => console.log(error));
  };
};

export const buscar_cargo = (cargo) => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/buscar-cargo/${cargo}`)
    .then(response => {
        dispatch(buscar({data: response.data.cargo, status: response.status, mensaje: response.statusText}));
      })
      .catch(error => console.log(error.response.data));
  };
};

export const editarCargo = (data) => {
  return (dispatch) => {
    return axios.put(`${process.env.REACT_APP_BASE_URL}/cargo/editar`, data)
      .then(response => {
        dispatch(editar({status: response.data.status, text: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
};

export const eliminar_cargo = (data) => {
  return function(dispatch) {
    return axios.delete(`${process.env.REACT_APP_BASE_URL}/cargo/eliminar/${data.codigo}`, {
      headers: {
        'X-Data': JSON.stringify(data)
      },
    })
      .then(response => {
        dispatch(eliminar({status: response.data.status, mensaje: response.data.mensaje, borrado: response.data.borrado}));
      })
      .catch(error => console.log(error));
  };
};

export const restaurarMensajes = () => {
    return function(dispatch) {
      dispatch(restaurar_mensajes());
    };
};