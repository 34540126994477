import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useDecrypt } from '../../../hooks/useDecrypt';

// Actions
import { buscar_cabecera_padre } from '../../../redux/action-types/dependenciaActions';

// MUI componentes
import { 
  Box,
  Grid,
  Typography,
} from '@mui/material';

// Funciones de ayuda
import { obtenerFecha } from '../../../utils/obtenerFecha';

// MUI iconos
import ApartmentIcon from '@mui/icons-material/Apartment';

// Utils
import { verificarPermiso } from '../../../utils/verificarPermiso';

const AcercaDesignacion = ({ dependencia, cargo, desc, inicio, fin, cargoTipo, dni, estado }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { padre } = useSelector(state => state.dependencia);

  const permisos = window.localStorage.getItem("permisos");
  // const permisos = useDecrypt('permisos');

  const handleClick = () => {
    window.sessionStorage.setItem('urlAnterior', `/perfil/${dni}`)
    navigate(`/designaciones/${dni}`);
  };

  useEffect(() => {
    if(!dependencia.cabecera){
      dispatch(buscar_cabecera_padre(dependencia.id))
    }
    
  }, [dispatch, dependencia])

  return (
    <Grid item xs={10} sm={10} md={8} lg={6}>
      <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}> 
        <ApartmentIcon />
        <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
          Designación
        </Typography>
      </Box>    

      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"1em"}}>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Dependencia:
          </Typography>
          <Typography>
            {dependencia.cabecera ? dependencia.name : padre?.name}
          </Typography>
        </Box>
        {!dependencia.cabecera ?
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Oficina:
          </Typography>
          <Typography>
            {dependencia?.name}
          </Typography>
          </Box>
          :
        <></>}
      </Box>

      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"1em"}}>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Cargo:
          </Typography>
          <Typography>
            {cargo} - {desc}
          </Typography>
        </Box>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Tipo de Cargo:
          </Typography>
          <Typography>
            {cargoTipo}
          </Typography>
        </Box>
      </Box>

      <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
        <Typography mr={"1em"} fontWeight={"bold"}>
          Estado:
        </Typography>
        <Typography>
          {estado ? "Histórica" : "Vigente"}
        </Typography>
      </Box>

      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between"}}>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Fecha de Inicio:
          </Typography>
          <Typography>
            {inicio ? obtenerFecha(inicio).fecha : "Sin Detalle"}
          </Typography>
        </Box>
        {fin ?
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Fecha de Fin:
          </Typography>
          <Typography>
            {fin ? obtenerFecha(fin).fecha : "Sin Detalle"} 
          </Typography>
        </Box>
        :
        null
        }
      </Box>

      {
        verificarPermiso(permisos, 'usuario_consulta') ?
          <Typography
            onClick={handleClick}
            
            sx={{
              maxWidth: "auto",
              display: "inline-block",
              mt: "1em",
              background: 'none',
              border: 'none',
              fontWeight: "bold",
              color: "primary.dark",
              "&:hover": {
                cursor: 'pointer',
              }
            }}
          >
            Designaciones*
          </Typography>
        :
          null
      }
          
    </Grid>
  );
};  

export default AcercaDesignacion;