export const convertirCadena = (texto) => {
  // Divido la cadena
  
  const palabras = texto.split('_');
  // Capitalizo la primera letra de cada palabra y las uno con un espacio
  const resultado = palabras.map(palabra => palabra.charAt(0).toUpperCase() + palabra.slice(1)).join(' ');
  return resultado;

  
};
