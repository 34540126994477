// import React, { useEffect } from 'react';
// import { useDispatch } from "react-redux";
// import { Navigate } from "react-router-dom";
// // import { useDecrypt } from '../../hooks/useDecrypt';

// // Actions
// import { reacceso } from '../../redux/action-types/accesoActions';
// import { agregarMensaje } from '../../redux/action-types/messageActions.js';

// // Utils
// import { verificarPermiso } from '../../utils/verificarPermiso.js';

// const PrivateRoute = ({ children, permission }) => {
//   const dispatch = useDispatch();

//   let dni = window.localStorage.getItem("dni");
//   let isAuth = window.localStorage.getItem("isAuth");
//   // let permisos = window.localStorage.getItem("permisos");
//   let permisos = useDecrypt('permisos');
//   let recordar = window.localStorage.getItem("recordar");
//   let expiracion = window.localStorage.getItem("expiracion");

//   useEffect(() => {
//     if(isAuth) {
//       dispatch(reacceso(dni, isAuth, recordar, expiracion));
//     };
    
//   }, [dni, isAuth, recordar, expiracion, dispatch]);
  
//   // Verificar si el usuario tiene el permiso requerido
//   const hasRequiredRole = verificarPermiso(permisos, permission);

//   if(!isAuth) {
//     // Si no está logeado, redirijo a /acceso
//     return <Navigate to="/acceso" />;
//   } else if(permission && !hasRequiredRole) {
//     // Si no tiene el rol requerido, lo redirijo a home
//     dispatch(agregarMensaje("Lo sentimos. No tiene los permisos necesarios para acceder."))
//     return <Navigate to="/" />;
//   };
//   // Si está autorizado, retorno el componente
//   return children;
// };

// export default PrivateRoute;


import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

import { reacceso } from '../../redux/action-types/accesoActions';
import { agregarMensaje } from '../../redux/action-types/messageActions.js';

const PrivateRoute = ({ children, permission }) => {
  const dispatch = useDispatch();

  let dni = window.localStorage.getItem("dni");
  let isAuth = window.localStorage.getItem("isAuth");
  let permisos = window.localStorage.getItem("permisos");
  let recordar = window.localStorage.getItem("recordar");
  let expiracion = window.localStorage.getItem("expiracion");

  useEffect(() => {
    if(isAuth) {
      dispatch(reacceso(dni, isAuth, recordar, expiracion));
    }
  }, [dni, isAuth, recordar, expiracion, dispatch]);

  // Verificar si el usuario tiene el rol requerido
  const userRolesArray = permisos?.split(","); // Convierte los permisos del usuario en un array

  const hasRequiredRole = userRolesArray?.includes(permission);

  if(!isAuth) {
    // Si no está logeado, redirijo a /acceso
    return <Navigate to="/acceso" />;
  } else if(permission && !hasRequiredRole) {
    // Si no tiene el rol requerido, lo redirijo a home
    dispatch(agregarMensaje("Lo sentimos. No tiene los permisos necesarios para acceder"))
    return <Navigate to="/" />;
  };
  // Si está autorizado, retorno el componente
  return children;
};

export default PrivateRoute;
