import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import Pie from '../Pie/Pie';
import ModalComponent from '../ModalComponent/ModalComponent';

// Utils
import { obtenerFecha } from '../../utils/obtenerFecha';
import { traducirDetalle } from '../../utils/traducirDetalle';

// Acciones
import { buscar_por_dni, buscar_por_dni_actual } from '../../redux/action-types/usuarioActions';
import { vacaciones_por_dni, historial_actual_dni,restaurar_mensajes, editar_vacacion } from '../../redux/action-types/vacacionesActions';
import { historialVacaciones } from '../../redux/action-types/auditoriaActions';

// MUI Componentes
import {
  Button, 
  Box,
  CircularProgress,
  Divider,  
  FormHelperText,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import DownhillSkiingIcon from '@mui/icons-material/DownhillSkiing';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import HistoryIcon from '@mui/icons-material/History';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

//Utils
import { verificarPermiso } from '../../utils/verificarPermiso';


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'primary.light',
    color: 'white',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const NUMBER = /^\d+$/;

const validateDatos = (state) => {
  let errorsDatos = {};

  if(state.cantidadInput === '') {
    errorsDatos.cantidadInput = '';
  } else if(!NUMBER.test(state.cantidadInput)) {
    errorsDatos.cantidadInput = 'Solo deben ingresarse números';

  };

  if(state.motivoInput === '' ) {
    errorsDatos.motivoInput = '';
  } else if(state.motivoInput.length >= 100) {
    errorsDatos.motivoInput = 'Solo puede ingresar 100 caracteres.'
  }
  return errorsDatos;
}

const initialState = {
  cambio: '',
  mes: true,
  cantidadInput: '',
  motivoInput: ''
};

const VacacionesUsuario = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { dni } = useParams();

    const dniActual = window.localStorage.getItem("dni");
    const permisos = window.localStorage.getItem("permisos");

    const { usuario_vacacion, dias_tomados, viejas_tomadas, historial, mensaje, status } = useSelector(state => state.vacaciones);
    const { usuario } = useSelector(state => state.usuario);
    const { historial_versiones } = useSelector(state => state.auditoria);

    const [isOpenModal, openModal, closeModal] = useModal();
    const [ state, setState  ] = useState(initialState);
    const [ errors, setErrors ] = useState(initialState);

    const esInvierno = obtenerFecha(new Date()).esInvierno

    const handleChange = event => {
      const { name, value } = event.target;
      setErrors(validateDatos({
        ...state,
        [name]: value
      }));
      setState({
        ...state,
        [name]: value,
      });
    };

    const handleMonth = () => {
      state.cambio= 'MESES';
      state.mes = true;
      dispatch(restaurar_mensajes());
      openModal();
    };

    const handleOld = () => {
      state.cambio= 'DIAS';
      state.mes = false;
      dispatch(restaurar_mensajes());
      openModal();
    };

    const handleClose = () =>{
      closeModal();
      setState(initialState);
      setErrors(initialState);
    };

    const handleSubmit = event => {
      event.preventDefault();
      const data = {...state,
          dniInput: dni,
          updated_by: dniActual,
          valor_anterior: state.mes ? usuario_vacacion.meses_externos : usuario_vacacion.viejas};
      dispatch(editar_vacacion(data));
      setTimeout(() => {         
        dispatch(restaurar_mensajes());
        closeModal();   
      }, 3000);
      
      setState(initialState);
    };

    const handleAtras = () => {
      navigate("/vacaciones-usuario");
    };

    const handleClickJustificacion = (id) => {
      window.sessionStorage.setItem('urlAnterior', `/vacaciones/${dni}`);
      navigate(`/detalle-justificacion/${dni}/${id}`);
    };

    const handleHistoricas = () => {
      window.sessionStorage.setItem('urlAnterior', `/vacaciones/${dni}`);
      navigate(`/vacaciones-historicas/${dni}`);      
    };

    const handleClickModificador = creador => {
      window.sessionStorage.setItem('urlAnterior', `/vacaciones/${dni}`);
      navigate(`/perfil/${creador}`);
    };

    useEffect(() => {
        dispatch(dni === dniActual ? buscar_por_dni_actual(dniActual) : buscar_por_dni(dni ? dni : dniActual));
            }, [dispatch, dni, dniActual, permisos]);

    useEffect(()=> {
      dispatch(vacaciones_por_dni(dni))
      dispatch(historial_actual_dni(dni));
      if(verificarPermiso(permisos, 'super_usuario')){
        dispatch(historialVacaciones(dni));
      }
    }, [dispatch, dni, permisos, status]);

  if (!usuario){
    return(
      <>
      <Rotulo titulo={"Vacaciones"} />
        <CircularProgress
          sx={{
            width: "auto",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />
        {/* Pie de Pág. */}
        <Pie />
      </>
    )
  };

  if (usuario && !usuario_vacacion){
    return(
      <>
      <Rotulo titulo={"Vacaciones"} />
      <Grid container spacing={6} 
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1vh",
        }}
        >
        <Grid item container xs={12} sm={10} md={8} lg={8} ml={2}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
      <Grid item xs={10} sm={10} md={8} lg={7}>
      <Grid item container xs={11} sm={11} md={12} lg={12}>
        <Button 
          variant="contained"
          sx={{
            mr: 2, 
            mb: 2,
            fontSize: ".8em",
            fontWeight: "bold",
            bgcolor: 'background.paper',
            '&:hover': {
              bgcolor: 'background.paper',
              transform: 'scale(1.01)',
            }
          }}
          onClick={handleAtras}
        >
          Atrás
        </Button>
        <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
      </Grid>

      <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}> 
        <Typography component={"h3"} variant={"h6"} fontSize={"1.4em"} >          
          {`Vacaciones de ${usuario.nombre} ${usuario.apellido}`}
        </Typography>
      </Box>
      
      <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}> 
        <Typography component={"h3"} variant={"h6"} fontSize={"1.2em"} >          
          {`A este usuario no le corresponden vacaciones`}
        </Typography>
        <SentimentDissatisfiedIcon />
      </Box>
      
      </Grid>
      </Grid>
      </Grid>
      {/* Modal */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {mensaje}
        </Typography>
      </ ModalComponent>
      {/* Pie de Pág. */}
      <Pie />
    </>

    )
  };

  return (
    <>
      <Rotulo titulo={"Vacaciones"} />
      {/* Contenedor del componente */}
      <Grid container spacing={6} 
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1vh",
        }}
        >
        <Grid item container xs={12} sm={10} md={8} lg={8} ml={2}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
      <Grid item xs={10} sm={10} md={10} lg={10}>
      <Grid item container xs={11} sm={11} md={12} lg={12}
      sx={{display: "flex", justifyContent: "space-between"}}>
        <Button 
          variant="contained"
          sx={{
            mr: 2, 
            mb: 2,
            fontSize: ".8em",
            fontWeight: "bold",
            bgcolor: 'background.paper',
            '&:hover': {
              bgcolor: 'background.paper',
              transform: 'scale(1.01)',
            }
          }}
          onClick={handleAtras}
        >
          Atrás
        </Button>
        <Button 
          variant="contained"
          sx={{
            mr: 2, 
            mb: 2,
            fontSize: ".8em",
            fontWeight: "bold",
            bgcolor: 'background.paper',
            '&:hover': {
              bgcolor: 'background.paper',
              transform: 'scale(1.01)',
            }
          }}
          onClick={handleHistoricas}
        >
          Vacaciones históricas
        </Button>
        <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
      </Grid>

      <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "2em"}}> 
        {esInvierno? <DownhillSkiingIcon /> :<BeachAccessIcon />}
        <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.6em"} >          
          {`Vacaciones de ${usuario.nombre} ${usuario.apellido}`}
        </Typography>
      </Box>

      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", flexDirection: "column", marginBottom:"2em"}}>
      <Typography fontWeight={"bold"}>{`Datos de antiguedad del usuario`}</Typography>
      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between"}}>
      <Box sx={{width: "47%", display: "flex", flexGrow: 1, height: "100%"}}>
      
        <Box sx={{width: "100%", display: "flex", alignItems:"center", justifyContent: "space-between", marginBottom:"0.5em"}} >
          <Typography mr={"1em"}>
            Fecha de ingreso a la universidad: 
          </Typography>
          <Typography sx={{ml: "auto", marginRight: "3em"}}>
            {obtenerFecha(usuario_vacacion.ingreso_universidad).fecha}
          </Typography>
        </Box>
      </Box>
      <Box sx={{width: "47%", display: "flex", flexDirection:"column",flexGrow: 1, height: "100%"}}>
        <Box sx={{width: "100%", display: "flex", alignItems:"center", justifyContent: "space-between", marginBottom:"0.5em"}} >
          <Typography mr={"1em"}>
            Meses externos trabajados: 
          </Typography>
          <HtmlTooltip placement="right" arrow title={
            <React.Fragment>
            <Button onClick={() => handleMonth()}
            sx={{
              color: "white",
              fontWeight: "bold"
            }}>
              <ModeEditIcon fontSize='small' />
              Editar
              </Button>
            </React.Fragment>}
            >
            <Typography sx={{ml: "auto", marginRight: "2em",
              '&:hover': {
                      cursor: "pointer",
                    }
            }}>
              {usuario_vacacion.meses_externos}
            </Typography>
          </HtmlTooltip>          
        </Box>       
        
        </Box>
      </Box>
      </Box>
      

      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "stretch",marginBottom:"2em"}}>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column", flexGrow: 1, height: "100%"}}>
        <Typography fontWeight={"bold"}>{`Vacaciones del período Dic. ${obtenerFecha(usuario_vacacion.ultima_actualizacion).anio} - Nov ${(obtenerFecha(usuario_vacacion.ultima_actualizacion).anio2)}`}</Typography>
        
          <Box sx={{width: "100%", display: "flex", alignItems:"center", justifyContent: "space-between", marginBottom:"0.5em"}} >
            <Typography mr={"1em"}>
              Días correspondientes:
            </Typography>
            <Typography sx={{ml: "auto", marginRight: "3em"}}>
              {usuario_vacacion.actuales}
            </Typography>
          </Box>
          <Box sx={{width: "100%", display: "flex", alignItems:"center", justifyContent: "space-between", marginBottom:"0.5em"}} >
            <Typography mr={"1em"}>
              Días tomados:
            </Typography>
            <Typography sx={{ml: "auto", marginRight: "3em"}}>
              {dias_tomados}
            </Typography>
          </Box>
          <Box sx={{width: "100%", display: "flex", alignItems:"center", justifyContent: "space-between"}} >
            <Typography mr={"1em"}>
              Días disponibles:
            </Typography>
            <Typography sx={{ml: "auto", marginRight: "3em"}}>
              {usuario_vacacion.actuales - dias_tomados}
            </Typography>
          </Box>     
        </Box>

        <Box sx={{width: "47%", display: "flex", flexDirection: "column", flexGrow: 1, height: "100%"}}>
        <Typography fontWeight={"bold"}>{`Vacaciones periodos anteriores`}</Typography>
          <Box sx={{width: "100%", display: "flex", alignItems:"center", justifyContent: "space-between", marginBottom:"0.5em"}} >
            <Typography mr={"1em"} >
            Días periodos anteriores disponibles
            </Typography>
            <HtmlTooltip placement="right" arrow title={
              <React.Fragment>
              <Button onClick={() => handleOld()}
              sx={{
                color: "white",
                fontWeight: "bold"
              }}>
                <ModeEditIcon fontSize='small' />
                Editar
                </Button>
              </React.Fragment>}
              >
              <Typography sx={{ml: "auto", marginRight: "2em",
                '&:hover': {
                        cursor: "pointer",
                      }
              }}>
                {usuario_vacacion.viejas}
              </Typography>
          </HtmlTooltip>
            
          </Box>
          <Box sx={{width: "100%", display: "flex", alignItems:"center", justifyContent: "space-between", marginBottom:"0.5em"}} >
            <Typography mr={"1em"}>
            Días periodos anteriores tomadas en el año
            </Typography>
            <Typography sx={{ml: "auto", marginRight: "2em"}}>
            {viejas_tomadas}
            </Typography>
          </Box>          
        </Box>
      </Box>

      
      <Box mb={"2em"}>
        <Box mb={"1em"}>
      <Typography fontWeight={"bold"}>{`Detalle de vacaciones en el período`}</Typography>
      </Box>     
      <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
       <Table aria-label="simple table"> 
          <TableHead key={"head"}>
            <TableRow key={"0"} sx={{bgcolor:"primary.main"}}>
              <TableCell key={"date_init"} align="center">Fecha inicio</TableCell>
              <TableCell key={"date_end"} align="center">Fecha fin</TableCell>                  
              <TableCell key={"justify"} align="center">Cantidad días</TableCell>
              <TableCell key={"deletedby"} align="center">Detalle</TableCell>
            </TableRow>
          </TableHead>
          {
            <TableBody>
              {
                historial && historial.map((entrada) => {
                  return (
                    <TableRow
                      key={`${entrada.id}$`}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="center"
                      >{obtenerFecha(entrada.fecha_inicio).fecha}</TableCell>
                      <TableCell align="center"                            
                      >{entrada.fecha_inicio === entrada.fecha_fin ? "-": obtenerFecha(entrada.fecha_fin).fecha}</TableCell>
                      <TableCell align="center"                             
                      >
                        {entrada.dias}
                      </TableCell>
                      {entrada.justificacion ?
                      <TableCell align="center" 
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                          borderRadius: '.5em',
                          boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                        }
                      }}
                      onClick={() => handleClickJustificacion(entrada.justificacion.id)}
                    >
                      {traducirDetalle(entrada.operacion)}
                    </TableCell>
                      :
                      <TableCell align="center">{traducirDetalle(entrada.operacion)}</TableCell>}
                    </TableRow>
                  );
                })
              }
            </TableBody>
          }
        </Table>
      </TableContainer>
      </Box>
      {verificarPermiso(permisos, 'super_usuario')  ?
        <Grid container xs={12} sm={12} md={12} lg={12} mt={"3em"}
        sx={{
          display: "flex",
          justifyContent: "center", 
        }}>
          <Grid item xs={12} sm={12} md={12} lg={12} mb={"1em"}>
          <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", ml: "-3px"}}> 
            <HistoryIcon />
            <Typography component={"h3"} variant={"h6"} fontSize={"1.4em"} ml={"2px"}>
              {`Historial de versiones`}
            </Typography>
            </Box>  
          </Grid>
          {historial_versiones && historial_versiones.length > 0 ?
          <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
            <Table aria-label="simple table" > 
              <TableHead key={"head"}>
                <TableRow key={"0"} sx={{bgcolor:"auditory.main"}}>
                  <TableCell key={"value"} align="center" >Valor cambiado</TableCell>
                  <TableCell key={"old_value"} align="center" >Valor anterior</TableCell>                  
                  <TableCell key={"new_value"} align="center">Valor nuevo</TableCell>                  
                  <TableCell key={"updatedby"} align="center" >Modificado por</TableCell>
                  <TableCell key={"updatedat"} align="center" >Fecha modificacion</TableCell>
                  <TableCell key={"updatedathour"} align="center" >Hora modificacion</TableCell>
                  <TableCell key={"motive"} align="center" >Motivo</TableCell>
                </TableRow>
              </TableHead>
              {
                <TableBody>
                  {
                    historial_versiones && historial_versiones.map((vacacion_h) => {
                      return (
                        <TableRow
                          key={`${vacacion_h.id}$`}
                          sx={{'&:last-child td, &:last-child th': { border: 0 } }}

                        >
                          <TableCell align="center" >
                            {vacacion_h.cambio === "DIAS" ? "Vacaciones anteriores": "Meses externos"}</TableCell>
                          <TableCell align="center" >{vacacion_h.valor_anterior}</TableCell>
                          <TableCell align="center" >
                            {vacacion_h.valor_nuevo}
                          </TableCell>                   
                          <TableCell align="center" 
                            sx={{
                              '&:hover': {
                                cursor: 'pointer',
                                borderRadius: '.5em',
                                boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                              }
                            }}
                            onClick={() => handleClickModificador(vacacion_h.updated_by)}
                          >
                            {vacacion_h.modificado_por}
                          </TableCell>
                          <TableCell align="center" >{obtenerFecha(vacacion_h.updated_at).fecha}</TableCell>
                          <TableCell align="center" >{obtenerFecha(vacacion_h.updated_at).horario}</TableCell>
                          <TableCell align={vacacion_h.motivo ? "left" : "center"} width={'50%'}>
                            {vacacion_h.motivo ? vacacion_h.motivo: "-"}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              }
            </Table>
          </TableContainer>
          :
          <Grid item xs={12} sm={12} md={12} lg={12} mb={"2em"}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center"}}> 
              <Typography component={"h6"} variant={"h6"} fontSize={"1em"}>
                {`Esta vacación nunca fue modificada`}
              </Typography>
            </Box>  
          </Grid>
        }
      </Grid>
        :
          null
        }

      </Grid>
      </Grid>
      </Grid>
      {/* Modal */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        {mensaje ? 
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {mensaje}
        </Typography>
        :        
        <>
        <Grid id={"editar_vacacion"} container component="form" noValidate onSubmit={handleSubmit}>
        <Box sx={{marginBottom: "1em", width:"100%"}}>
          
            <Typography id="modal-modal-title" component="h2" sx={{marginBottom:"0.5em" }}>
                {state.mes ? "Ingrese la cantidad total de meses externos trabajados" : "Ingrese la cantidad de días de vacaciones anteriores disponibles:"}
            </Typography>
            <TextField
                id='cantidadInput'
                name="cantidadInput"
                type='text'
                value={state.cantidadInput}
                placeholder='Ingrese la cantidad en números'
                error={!!errors.cantidadInput}
                fullWidth
                onChange={handleChange}
                InputLabelProps={{
                  sx: {
                    color: 'rgb(150, 150, 150)',
                  }
                }}
            />
            {
              errors.cantidadInput &&
              <FormHelperText
                sx={{
                  color: "red",
                }}
              >
                {errors.cantidadInput}
              </FormHelperText>
            }
        </Box>
        <Box sx={{marginBottom: "1em", width:"100%"}}>
          <Typography id="modal-modal-title" component="h2" sx={{marginBottom:"0.5em" }}>
              Ingrese el detalle o el motivo del cambio {state.mes ? "(requerido)":""}:
          </Typography>
          <TextField
              id='motivoInput'
              name="motivoInput"
              type='text'
              value={state.motivoInput}
              placeholder='Max. 100 caracteres'
              error={!!errors.motivoInput}
              fullWidth
              multiline
              rows={3}
              onChange={handleChange}
              InputLabelProps={{
                sx: {
                  color: 'rgb(150, 150, 150)',
                }
              }}
          />
          {
            errors.motivoInput &&
            <FormHelperText
              sx={{
                color: "red",
              }}
            >
              {errors.motivoInput}
            </FormHelperText>
          }
          </Box>
          {
            state.mes ?
            <Typography id="modal-modal-title" component="h2" sx={{marginBottom:"0.5em", fontSize: "0.8em" }}>
                Importante: Tenga en cuenta que si edita este campo, se volverá a calcular la cantidad de días de vacaciones correspondientes al período
            </Typography>
            :
            null
          }
          <Box>
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                marginRight: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={!state.cantidadInput || !!errors.cantidadInput || !!errors.motivoInput || (!state.motivoInput && state.mes)}
              sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'primary.light',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Confirmar
            </Button>
            </Box>
          </Grid>
          </>
        }
      </ ModalComponent>
      {/* Pie de Pág. */}
      <Pie />
    </>
  );
};

export default VacacionesUsuario;