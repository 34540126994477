import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import ModalComponent from '../ModalComponent/ModalComponent';
import Pie from '../Pie/Pie';

// Actions
import { cargo_por_codigo, editarCargo, listar_tipos } from '../../redux/action-types/cargoActions'; 

// MUI componentes
import { 
  Box, 
  Button, 
  CircularProgress,
  FormHelperText,
  Grid, 
  MenuItem,
  TextField,
  Typography, 
} from '@mui/material';

import { inputLabelClasses } from "@mui/material/InputLabel";

// MUI iconos
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const validate = (state) => {
  let errors = {};

  if (state.descripcionInput === '') {
    errors.descripcionInput = '';
  } else if (state.descripcionInput.length > 100) {
    errors.descripcionInput = 'La descripcion no puede superar los 100 caracteres'
  };

  if (state.tipoInput === '') {
    errors.tipoInput = '';
  };

  return errors;
};

const initialState = {
  descripcionInput: '',
  tipoInput: '',
};

const EditarCargo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { codigo } = useParams();
  
  const { tipos, status, text, cargo } = useSelector(state => state.cargos);
  const dniActual  = useSelector(state => state.acceso.dni);

  const [ state, setState ] = useState(initialState);
  const [ errors, setErrors ] = useState(initialState);

  const [ isOpenModal, openModal, closeModal ] = useModal();

  const handleChange = event => {
    const { name, value } = event.target;
    setErrors(validate({
      ...state,
      [name]: value
    }));
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleClick = (event) => {
    navigate(`/administrar-cargos/codigo/${codigo}`);
  };

  const handleSubmit = event => {
    event.preventDefault();
    const data = {...state, codigoInput: codigo, usuarioActualInput: dniActual};
    dispatch(editarCargo(data));
    openModal();
    setTimeout(() => {
      navigate(`/administrar-cargos/codigo/${codigo}`);
    }, 2000);
    setState(initialState);
    setErrors(initialState);
  };

  useEffect(() => {
    dispatch(listar_tipos());
    dispatch(cargo_por_codigo(codigo));
  }, [dispatch, codigo]);

  useEffect(() => {
    setState({
      descripcionInput: cargo.descripcion,
      tipoInput: cargo.tipo,
    })
  }, [dispatch, cargo]);

  // Si no hay datos del cargo
  if(!cargo) {
    return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={'Editar Cargo'} />
        <CircularProgress 
          sx={{
            width: "auto",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />  
        {/* Copmponente de Pie de Pag. */}
        <Pie />
      </>
    );
  };
  // Si tiene autorizacion
  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={'Editar Cargo'} />
      {/* Aca arranca el componente Crear Usuario */}
      <Grid container component="form" noValidate onSubmit={handleSubmit} spacing={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3vh",
        }}
      >
        {/* Contenedor Grid de los Items   */}
        <Grid item container xs={12} sm={10} md={8} lg={6} spacing={6}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >  
          {/* Item 1 */}
          <Grid item xs={10} sm={10} md={8} lg={6}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}> 
              <ManageAccountsIcon />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                Información del Cargo
              </Typography>
            </Box>   

            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Código:
              </Typography>
              <TextField 
                  id='codigoInput' 
                  name="codigoInput"
                  type='text'
                  disabled
                  value={cargo.codigo}
                  InputLabelProps={{
                    sx: {
                      // set the color of the label when not shrinked
                      color: 'rgb(150, 150, 150)',
                      [`&.${inputLabelClasses.shrink}`]: {
                        // set the color of the label when shrinked (usually when the TextField is focused)
                        // color: "orange"
                      }
                    }
                  }}
              />
            </Box>
            
            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Descripción:
              </Typography>
              <TextField 
                  id='descripcionInput' 
                  name="descripcionInput"
                  type='text' 
                  value={state.descripcionInput}
                  placeholder={cargo.descripcion}
                  error={!!errors.descripcionInput}
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
              />
              {
                errors.descripcionInput && 
                <FormHelperText
                  sx={{
                    color: "red",
                  }}
                >
                  {errors.descripcionInput}
                </FormHelperText>
              } 
            </Box>
            
            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Tipo:
              </Typography>
              <TextField 
                  id='tipoInput' 
                  name="tipoInput"
                  type='text'
                  value={state.tipoInput}
                  error={!!errors.tipoInput}
                  onChange={handleChange}
                  select
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
              >
                {
                tipos && tipos.map(tipo => {
                  return (
                    <MenuItem key={tipo}
                      value={tipo}
                    >
                      {tipo}
                    </MenuItem>
                  )
                })
              }
              </TextField>
              {
                errors.tipoInput && 
                <FormHelperText
                  sx={{
                    color: "red",
                  }}
                >
                  {errors.tipoInput}
                </FormHelperText>
              }
            </Box>
          </Grid>

          {/* Item 3.1 */}
          <Grid item container xs={10} sm={10} md={8} lg={6.001}>
            <Button 
                type="submit"
                variant="contained"
                sx={{
                  mb: 2,
                  mr: 1,
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleClick}
              >
              Cancelar
            </Button>
            {
              (
              (errors.descripcionInput?.length > 0 || errors.tipoInput?.length > 0)
              ||
              (state.descripcionInput === cargo.descripcion && state.tipoInput === cargo.tipo) ||
              (state.descripcionInput === '' && state.tipoInput === '')
              )
              ?
                <Button
                  type="submit"
                  variant="contained"
                  disabled
                  sx={{
                    mb: 2,
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Guardar
                </Button>
              :
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mb: 2,
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Guardar
                </Button>
            }
          </Grid>
        </Grid>
      </Grid>
      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {status}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {text}
        </Typography>
      </ ModalComponent>
      {/* Copmponente de Pie de Pag. */}
      <Pie />
    </>
  );
};

export default EditarCargo;