import axios from "axios";

import { listar, buscar_id, usuarios_dependencia, crear, editar, eliminar, revivir, cerrar, restore_messages } from '../reducers/designacionReducer'

export const listar_designaciones_actual = (dni) => {
  return function(dispatch) {
      return axios.get(`${process.env.REACT_APP_BASE_URL}/designaciones-actual/${dni}`)
        .then(response => {
          dispatch(listar({
            vigentes: response.data.vigentes,
            historicas: response.data.historicas,
            status: response.status,
            text: response.statusText
          }));
        })
        .catch(error => console.log(error));
  };
};

export const listar_designaciones = (dni) => {
    return function(dispatch) {
        return axios.get(`${process.env.REACT_APP_BASE_URL}/designaciones/${dni}`)
          .then(response => {
            dispatch(listar({
              vigentes: response.data.vigentes,
              historicas: response.data.historicas,
              status: response.status,
              text: response.statusText
            }));
          })
          .catch(error => console.log(error));
    };
};

export const buscar_por_id = (id) => {
    return function(dispatch) {
      return axios.get(`${process.env.REACT_APP_BASE_URL}/designacion/${id}`)
        .then(response => {
          dispatch(buscar_id({data: response.data.designacion, status: response.status, text: response.statusText}));
        })
        .catch(error => console.log(error));
    };
};

export const listar_usuarios_dependencia = (id_dep) => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/usuarios-dependencia/${id_dep}`)
      .then(response => {
        dispatch(usuarios_dependencia({data: response.data, status: response.status, text: response.statusText}));
      })
      .catch(error => console.log(error));
  };
};

export const editar_designacion = (data) => {
  return function(dispatch) {
    return axios.put(`${process.env.REACT_APP_BASE_URL}/designacion-editar`, data)
      .then(response => {
        dispatch(editar({data: response.data.data, status: response.data.status, text: response.data.mensaje}))
      })
      .catch(error => console.log(error));
  };
};

export const editar_designacion_historica = (data) => {
  return function(dispatch) {
    return axios.put(`${process.env.REACT_APP_BASE_URL}/designacion-editar-historica`, data)
      .then(response => {
        dispatch(editar({data: response.data.data, status: response.data.status, text: response.data.mensaje}))
      })
      .catch(error => console.log(error));
  };
};

export const crear_designacion = (data) => {
  return function(dispatch) {
    return axios.put(`${process.env.REACT_APP_BASE_URL}/designacion-crear`, data)
      .then(response => {
        dispatch(crear({data: response.data.data, status: response.data.status, text: response.data.mensaje}))
      })
      .catch(error => console.log(error));
  };
};

export const eliminar_designacion = (data) => {
  return function(dispatch) {
    return axios.put(`${process.env.REACT_APP_BASE_URL}/designacion-eliminar`, data)
      .then(response => {
        dispatch(eliminar({data: response.data.data, status: response.data.status, text: response.data.mensaje}))
      })
      .catch(error => console.log(error))
  };
};

export const revivir_designacion = (data) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/designacion-revivir`, data)
      .then(response => {
        dispatch(revivir({data: response.data.data, status: response.data.status, text: response.data.mensaje}))
      })
      .catch(error => console.log(error))
  };
};

export const cerrar_designacion = (data) => {
  return function(dispatch) {
    return axios.put(`${process.env.REACT_APP_BASE_URL}/designacion-cerrar`, data)
      .then(response => {
        dispatch(cerrar({data: response.data.data, status: response.data.status, text: response.data.mensaje}))
      })
      .catch(error => console.log(error));
  };
};

export const restaurar_mensajes = () => {
  return function(dispatch) {
    dispatch(restore_messages());
  };
};



