import axios from "axios";

import { crear, buscar_todos, buscar_reloj, editar } from '../reducers/relojReducer';

export const crearReloj = (data) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/relojes/crear`, data)
      .then(response => {
        dispatch(crear({status: response.data.status, text: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
};

export const buscarRelojes = () => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/relojes`)
      .then(response => {
        dispatch(buscar_todos(response.data));
      })
      .catch(error => console.log(error));
  };
};

export const buscarReloj = (id) => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/reloj/id/${id}`)
      .then(response => {
        dispatch(buscar_reloj(response.data));
      })
      .catch(error => console.log(error));
  };
};

export const editarReloj = (data) => {
  return function(dispatch) {
    return axios.put(`${process.env.REACT_APP_BASE_URL}/reloj/editar`, data)
      .then(response => {
        dispatch(editar({status: response.data.status, text: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
};
