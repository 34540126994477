import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

// Componentes
import Rotulo from '../../Rotulo/Rotulo';
import SearchInput from '../../SearchInput/SearchInput';
import Usuario from '../../Usuario/Usuario';
import Pie from '../../Pie/Pie';

// Acciones
import { buscarUsuario, restaurarEstado } from '../../../redux/action-types/usuarioActions';

// MUI componentes
import { 
  Box,
  Button,
  Divider,  
  Grid,
} from '@mui/material';

const DashboardRoles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { usuarios } = useSelector(state => state.usuario);

  const [ usuario, setUsuario ] = useState('');

  const handleChange = (event) => {
    setUsuario(event.target.value);
  };
  
  const handleClick = () => {
    if(usuario.length > 0) dispatch(buscarUsuario({usuario}));
  };

  const handleAtras = () => {
    navigate('/dashboard');
  };

  const handleRoles = () => {
    navigate('/roles-sistema');
  };

  const handleUserRol = (dni) => {
    navigate(`/rol/usuario/${dni}`);
  };

  useEffect(() => {
    return () => {
      dispatch(restaurarEstado());
    };
  }, [dispatch]);

  return (
    <>
      {/* Rotulo. */}
      <Rotulo titulo={"Roles"} />
      {/* Contenedor del componente */}
      <Grid container
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "3vh",
        }}
      >
        <Grid item container xs={12} sm={10} md={10} lg={8} 
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item xs={10} sm={10} md={10} lg={8}>
          <Grid 
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}>
            <Button 
              variant="contained"
              sx={{
                alignSelf: "start",
                fontSize: ".8em",
                fontWeight: "bold",
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
              onClick={handleAtras}
            >
              Dashboard
            </Button>
            <Button 
              variant="contained"
              sx={{
                alignSelf: "start",
                fontSize: ".8em",
                fontWeight: "bold",
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
              onClick={handleRoles}
            >
              Roles en el sistema
            </Button>
            </Grid>
            <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>

            <Box
              sx={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {/* Search Input */}
              <SearchInput handleChange={handleChange} handleClick={handleClick} placeholder={"Ingrese el nombre o dni"} />
              {/* Renderizado de resultados */}
              {
                usuarios && usuarios.map(usuario => {
                  return (
                    <Usuario 
                      key={usuario.dni ? usuario.dni : usuario.usuario.dni}
                      nombre={usuario.nombre ? usuario.nombre : usuario.usuario.nombre} 
                      apellido={usuario.apellido ? usuario.apellido : usuario.usuario.apellido} 
                      dni={usuario.dni ? usuario.dni : usuario.usuario.dni} 
                      handleClick={() => handleUserRol(usuario.dni ? usuario.dni : usuario.usuario.dni)}
                    />
                  )
                })
              }
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {/* Pie de Pág. */}
      <Pie />
    </>
  );
};

export default DashboardRoles;
