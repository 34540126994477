import React from 'react';

// MUI componentes
import {
  Grid,
} from '@mui/material';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// CSS
import "./styles.css";

const Slider = () => {
  return (
    <>
      <Grid item xs={10} sm={12} md={12} lg={12}
        sx={{
          bgcolor: 'background.paper',
          boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)',
          borderRadius: '.5em',
        }}
      >
        <Swiper navigation={true} modules={[Navigation]} style={{
          "--swiper-navigation-color": 'rgb(0, 95, 97)',
        }}>
          <SwiperSlide>Acá</SwiperSlide>
          <SwiperSlide>irán</SwiperSlide>
          <SwiperSlide>estadísticas</SwiperSlide>
          <SwiperSlide>dice Fede</SwiperSlide>
          <SwiperSlide>¯\_(ツ)_/¯</SwiperSlide>
        </Swiper>
      </Grid>
    </>
  );
};

export default Slider;
