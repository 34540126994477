import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Container,
  Grid,
  Typography,
  Tooltip
} from '@mui/material';

import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar';
import SignalCellularConnectedNoInternet3BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet3Bar';
import SignalCellularNodataIcon from '@mui/icons-material/SignalCellularNodata';

import { obtenerFecha } from '../../utils/obtenerFecha';

const Reloj = ({img, id, ip, ubicacion,nombre, activo, conexion, time}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/relojes/reloj/${id}`);
  };

  return (
    <Tooltip title={ip} arrow placement="left" enterDelay={600} leaveDelay={100}>
    <Container component="container"
      sx={{
        width: '20vw',
        minWidth: '280px',
        height: 'auto',
        padding: '.5em',
        display:"flex",
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)',
        bgcolor: 'background.paper',
        borderRadius: '.5em',
        marginBottom: '1em',
        '&:hover': {
          cursor: 'pointer',
          transform: 'scale(1.01)',
        }
      }}
      onClick={handleClick}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {
          img ?
          <img src={img} alt={`Reloj ubicado en ${ubicacion}`} />
          :
          <SafetyCheckIcon fontSize='large' />

        }
        {
            activo ?
              conexion ?
                <SignalCellular3BarIcon sx={{color: 'green', marginRight: '.5em'}} />
              :
                <SignalCellularConnectedNoInternet3BarIcon sx={{color: 'red', marginRight: '.5em'}} />
            :
            <SignalCellularNodataIcon sx={{color: 'grey', marginRight: '.5em'}}/>
          }
      </Box>
      <Grid item container 
        sx={{
          width: '80%',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
      <Box
        sx={{
          width: '100%',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            justifyContent: 'center',
            gap: "0.3rem"
          }}
        >

            <Typography 
            sx={{
              fontWeight: 'bold'
            }}>
              {nombre} 
            </Typography>
            {/* <Typography> ({ip})</Typography> */}

        </Box>
        <Box
          sx={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            justifyContent: time ? 'space-evenly' : 'center',
          }}
        >
          <Box>
            <Typography>{time ? obtenerFecha(time).fecha : ""}</Typography>
          </Box>
          <Box>
            <Typography>{time ? obtenerFecha(time).horario : ""}</Typography>
          </Box>
        </Box>

      </Box>
      </Grid>
    </Container>
    </Tooltip>
  );
};

export default Reloj;
