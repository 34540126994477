import { setOpen, setClose, setRoute } from '../reducers/sideBarReducer';

export const openBar = () => {
  return function(dispatch) {
    dispatch(setOpen());
  };
}; 

export const closeBar = () => {
  return function(dispatch) {
    dispatch(setClose());
  };
}; 

export const setSelected = (selected) => {
  return function(dispatch) {
    dispatch(setRoute({"principal": selected.principal, "secondary": selected.secondary}))
  }
}
