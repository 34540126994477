import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import "dayjs/locale/es";

import reportWebVitals from './reportWebVitals';

// MUI Themes
import { ThemeProvider } from '@mui/material/styles';
import { lightTheme } from './theme/index';

import store from './redux/store/index';
import interceptor from './utils/tokenAuthorization'

import App from './App';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault('America/Argentina/Buenos_Aires');

interceptor.interceptor(store)
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={lightTheme}>
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='es'>
            <App />
          </LocalizationProvider>
        </BrowserRouter>
      </ThemeProvider>  
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();