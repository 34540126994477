import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';

// Componentes
import Rotulo from '../../Rotulo/Rotulo';
import DependenciaItem from './DependenciaItem';
import SearchInput from '../../SearchInput/SearchInput';
import Pie from '../../Pie/Pie';

// Actions
import { obtener_dependencias, restaurar_estado } from '../../../redux/action-types/rolActions';
import { buscarDependencia, restaurarEstado } from '../../../redux/action-types/dependenciaActions';

// MUI componentes
import { 
  Box,
  Button,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

// Icons
import ApartmentIcon from '@mui/icons-material/Apartment';
import DomainAddIcon from '@mui/icons-material/DomainAdd';

const EditarDependencias = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, dni } = useParams();

  const { asignadas, id_rol } = useSelector(state => state.rol);
  const busqueda = useSelector(state => state.dependencia.dependencias);

  const [ dependencia, setDependencia ] = useState('');

  const handleAtras = () => {
    navigate(`/rol/usuario/${dni}`);
  };

  const handleChange = (event) => {
    setDependencia(event.target.value);
  };

  const handleClick = () => {
    if(dependencia.length > 0) dispatch(buscarDependencia({name: dependencia}));
    setDependencia('');
  };

  useEffect(() => {
    dispatch(obtener_dependencias(id_rol ? id_rol : id));
    return () => {
      dispatch(restaurarEstado());
      dispatch(restaurar_estado());
    };
  }, [dispatch, id_rol, id]);

  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={"Modificar Dependencias"} />
      {/* Contenedor General */}
      <Grid container 
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3vh",
        }}
      >
        {/* Item 1 */}
        <Grid item container xs={12} sm={10} md={10} lg={8} mb={1}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        > 
          <Grid item container xs={10} sm={10} md={10} lg={8}
            sx={{
              display: "flex", 
              justifyContent: "center",
              alignItems: "center"
            }}
          >  
            <Button 
              variant="contained"
              sx={{
                position: "relative",
                left: "-45%",
                fontSize: ".8em",
                fontWeight: "bold",
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
              onClick={handleAtras}
            >
              Atrás
            </Button>
            <Divider sx={{ width: "100%", height: "1em", mb: "1em"}}/>
            <ApartmentIcon />
            <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
              Dependencias Actuales:
            </Typography>
          </Grid>  
          <Grid item container xs={12} sm={10} md={10} lg={8} mt={3}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {
                asignadas && asignadas.map(dependencia => {
                  return (
                    <DependenciaItem 
                      key={dependencia.id}
                      id={dependencia.id}
                      dni={dni}
                      nombre={dependencia.name} 
                      add={false}
                    />
                  )
                })
              } 
          </Grid>
        </Grid>
        
        {/* Item 2 */}
        <Grid item container xs={12} sm={10} md={10} lg={8} mb={1}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        > 
        <Grid item container xs={10} sm={10} md={10} lg={8} 
            sx={{
              display: "flex", 
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Divider sx={{ width: "100%", height: "1em", mb: "1em"}}/>
            <Grid item container mb={2}
              sx={{
                display: "flex", 
                justifyContent: "center",
                alignItems: "center"
              }}
            > 
              <DomainAddIcon />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                Buscar Dependencias:
              </Typography>
            </Grid> 

            <Box
              sx={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {/* Input de busqueda */}
              <SearchInput handleChange={handleChange} handleClick={handleClick} placeholder={"Ingrese el lugar"} />
              {/* Renderizado de resultados */}
              {
                busqueda && busqueda.map(dependencia => {
                  return (
                    <DependenciaItem 
                      key={dependencia.id}
                      id={dependencia.id}
                      dni={dni}
                      nombre={dependencia.name} 
                      add={true}
                    />
                  )
                })
              }
            </Box>
          </Grid> 
        </Grid>
      </Grid>
      {/* Pie de Pág. */}
      <Pie /> 
    </>
  );
};

export default EditarDependencias;
