// Componentes
import InputDate from "../../Marcaciones/Components/InputDate";
import Dependencia from "../../Dependencias/Dependencia";

// MUI componentes
import {
    Button,
    Divider,
    Grid
  } from "@mui/material";


const Encabezado = ({ dependencia, fecha, handleChange, handleSubmit, handleClick, isProcessing, setIsProcessing, con_hijos }) => {
    if(dependencia){
        return(
            <>
            
            <Grid item xs={11} sm={11} md={10} lg={7}>
                <Button
                    variant="contained"
                    sx={{
                    alignSelf: "start",
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: "background.paper",
                    "&:hover": {
                        bgcolor: "background.paper",
                        transform: "scale(1.01)",
                    },
                    }}
                    onClick={handleClick}
                >
                    Atrás
                </Button>
                <Divider sx={{ width: "100%", height: "1em", mb: "1em" }} />
            </Grid>
            <Grid
            item
            container
            xs={12}
            sm={10}
            md={12}
            lg={7}
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
            >
                <Grid
                    item
                    xs={12}
                    sm={10}
                    md={8}
                    lg={5}
                    sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    }}
                >
                    {/* Componente que muestra los datos del usuario */}
                    <Dependencia
                    key={dependencia?.id}
                    id={dependencia?.id}
                    nombre={dependencia?.name}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={7}
                    md={6}
                    lg={7}
                    sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    }}
                >
                    {/* Componente que toma la fecha para el rango de marcaciones */}
                    <InputDate
                    inicio={fecha.inicio}
                    fin={fecha.fin}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    dependencia_id={dependencia.id}
                    dependencia_name={dependencia.name}
                    isProcessing={isProcessing}
                    setIsProcessing={setIsProcessing}
                    con_hijos={con_hijos}
                    />
                </Grid>
            </Grid>
    
            <Grid
            item
            mt={2}
            xs={12}
            sm={11}
            md={10}
            lg={7}
            marginLeft={".8em"}
            marginRight={".8em"}
            >
            <Divider />
            </Grid>
            </>
        );
    }
    
    };

export default Encabezado
