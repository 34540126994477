import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';
// import { useDecrypt } from '../../hooks/useDecrypt';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import ModalComponent from '../ModalComponent/ModalComponent';
import Pie from '../Pie/Pie';

// Actions
import { buscar_por_dni } from '../../redux/action-types/usuarioActions';
import { auditarUsuarios, setPeriodo } from '../../redux/action-types/auditoriaActions';

// MUI componentes
import {
  Button,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

// MUI iconos
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import InfoIcon from '@mui/icons-material/Info';
import { obtenerFecha } from '../../utils/obtenerFecha';

const AuditoriaUsuarios = ({ titulo }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { dni } = useParams();

  // Obtengo la parte final de la URL
  const urlParams = location.pathname.split('/').pop().split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1));

  const dniActual = window.localStorage.getItem("dni");
  const tipo_rol = window.localStorage.getItem("rol");
  // const tipo_rol = useDecrypt('rol');

  const { usuario, flag, text } = useSelector(state => state.usuario);
  const { usuarios_audit, periodo } = useSelector(state => state.auditoria);

  const [timeExceeded, setTimeExceeded] = useState(false);
  const [ datos, setDatos ]  = useState('')

  const [isOpenModal, openModal, closeModal] = useModal();

  const handleAtras = () => {
    navigate(`/auditoria/usuario/${dni}`);
  };

  const handleDetalle = (dni) => {
    window.sessionStorage.setItem('urlAnterior', location.pathname)
    navigate(`/detalle-perfil/${dni}`);
  };

  useEffect(() => {
    dispatch(buscar_por_dni(dni));

    if(!periodo){
      dispatch(setPeriodo(7));
    };

  }, [dispatch, dni, periodo, dniActual, tipo_rol]);

  useEffect(() => {
    if(periodo){
      dispatch(auditarUsuarios({dni, periodo}));
    }
  }, [dispatch, dni, periodo])

  useEffect(() => {
    if(urlParams[1].slice(0, -1) === "Creado"){
      setDatos(usuarios_audit.creados);
    }else{
      setDatos(usuarios_audit.modificados);
    }
  }, [usuarios_audit, urlParams])

  useEffect(() => {
    // Configura el tiempo límite
    const timeoutId = setTimeout(() => {
      setTimeExceeded(true);
      if(!usuario) {
        setTimeout(() => {
          openModal();
          setTimeout(() => {
            navigate("/buscar-usuario-auditoria");
          }, 3000);
        }, 0);
      };
    }, 5000);
    // Limpia el temporizador
    return () => clearTimeout(timeoutId);
  }, [usuario, openModal, navigate]);

  // Si no hay datos del usuario
  if(!usuario && !timeExceeded) {
    return (
      <>
        {/* Navegación */}
        <Rotulo titulo={urlParams.length === 2 ? `Auditoría ${urlParams[0]} ${urlParams[1]}` : `Auditoría ${urlParams[0]} ${urlParams[1]} ${urlParams[2]}`} />
        <Grid container>
          <CircularProgress
            sx={{
              width: "auto",
              height: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </Grid>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  };

  // Si el usuario no puede acceder a esa informacion
  if(!usuario && timeExceeded && !flag) {
    return (
      <>
        {/* Navegación */}
        <Rotulo titulo={urlParams.length === 2 ? `Auditoría ${urlParams[0]} ${urlParams[1]}` : `Auditoría ${urlParams[0]} ${urlParams[1]} ${urlParams[2]}`} />
        {/* Componente de Notificaciones */}
        <ModalComponent open={isOpenModal} handleClose={closeModal} >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Redireccionando...
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {text}
          </Typography>
        </ ModalComponent>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  };

  return (
    <>
      <Rotulo titulo={urlParams.length === 2 ? `Auditoría ${urlParams[0]} ${urlParams[1]}` : `Auditoría ${urlParams[0]} ${urlParams[1]} ${urlParams[2]}`} />
      <Grid container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={11} sm={8.5} md={7} lg={8} mt={6} mb={2}>
          <Button
            variant="contained"
            sx={{
              alignSelf: "start",
              fontSize: ".8em",
              fontWeight: "bold",
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'background.paper',
                transform: 'scale(1.01)',
              }
            }}
            onClick={handleAtras}
          >
            Atrás
          </Button>
          <Divider sx={{ width: "100%", height: "1em"}}/>
        </Grid>
        <Grid item container xs={11} sm={8.5} md={7} lg={8} mt={1} mb={3}>
          <VerifiedUserIcon sx={{ mt: "4px" }}/>
          <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
            Usuarios {urlParams[1]} por {usuario.nombre} {usuario.apellido} {periodo === 10000 ? "desde siempre": `en los últimos ${periodo} días`}.
          </Typography>
        </Grid>

        {/* Inicio Tabla */}
        {datos && datos.length > 0 ?
        <Grid item container xs={12} sm={11} md={10} lg={8}>
          <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow key={"0"} sx={{bgcolor:"primary.light"}}>
                  <TableCell key={"nombre"} align="center">Nombre</TableCell>
                  <TableCell key={"apellido"} align="center">Apellido</TableCell>
                  <TableCell key={"dni"} align="center">DNI</TableCell>
                  <TableCell key={"fecha"} align="center">
                    {urlParams.length === 2 ? `Fecha ${urlParams[1].slice(0, -1)}` : `Fecha ${urlParams[2].slice(0, -1)}`}
                  </TableCell>
                  <TableCell key={"hora"} align="center">
                    {urlParams.length === 2 ? `Hora ${urlParams[1].slice(0, -1)}` : `Hora ${urlParams[2].slice(0, -1)}`}
                  </TableCell>
                  <TableCell key={"estado"} align='center'>Estado</TableCell>
                  <TableCell key={"detalle"} align="center">Detalle</TableCell>
                </TableRow>
              </TableHead>

                <TableBody>
                  {
                     datos.map(data => {
                      return (
                        <TableRow
                          key={`${data.dni}, ${data.fecha}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 }, bgcolor: data.eliminado ? "auditory.fourth" : "" }}
                        >
                          <TableCell key={"nombre"} align="center" width={'20%'}>{data.nombre}</TableCell>
                          <TableCell key={"apellido"} align="center" width={'20%'}>{data.apellido}</TableCell>
                          <TableCell key={"dni"} align="center" width={'20%'}>{data.dni}</TableCell>
                          <TableCell key={"fecha"} align="center" width={'20%'}>{obtenerFecha(urlParams[1].slice(0, -1) === "Creado" ? data.created_at : data.updated_at).fecha}</TableCell>
                          <TableCell key={"hora"} align="center" width={'20%'}>{obtenerFecha(urlParams[1].slice(0, -1) === "Creado" ? data.created_at : data.updated_at).horario}</TableCell>
                          <TableCell key={"estado"}>{data.eliminado ? "DNI mal cargado" : "OK"}</TableCell>
                          <TableCell key={"detalle"} align="center" width={'20%'}>
                            <InfoIcon sx={{
                              color: "primary.light",
                            cursor: 'pointer' }} onClick={() => handleDetalle(data.dni)} />
                          </TableCell>
                        </TableRow>
                      );
                    })

                  }
                </TableBody>

            </Table>
          </TableContainer>
        </Grid>

      :
        <Grid item xs={12} sm={11} md={10} lg={8} mb={"2em"}>
          <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", ml: "-3px"}}>
            <Typography component={"h6"} variant={"h6"} fontSize={"1em"} ml={"2px"}>
              {`Este usuario no tiene usuarios ${urlParams[1]} en el periodo seleccionado`}
            </Typography>
          </Box>
        </Grid>
      }
      </Grid>
      {/* Pie de Pág */}
      <Pie />
    </>
  );
};

export default AuditoriaUsuarios;
