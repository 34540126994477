// Obtiene el dia de la semana a partir de una fecha proporcionada
export const obtenerDia = (fecha) => {
  if (fecha==null){
    return "-"
  }
  else{
    let date = fecha.length === 10 ? new Date(fecha.split('/').reverse().join('/')) : new Date(fecha);
    let show = date.toLocaleDateString([], {
      timeZone: 'UTC',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      weekday: 'long',
    }).split(",");

    if (show[0] === "miércoles"){
      show[0] = "miercoles";
    }
    if (show[0] === "sábado"){
      show[0] = "sabado";
    }

    return show[0].charAt(0).toUpperCase() + show[0].slice(1);
  };
};
