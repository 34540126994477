import { createSlice } from '@reduxjs/toolkit';

import { ordenarPorNombre } from '../../utils/ordenarPorNombre'; 

const initialState = {
  cargos: [],
  cargo: {},
};

export const cargosSlice = createSlice({
    name: 'cargosSlice',
    initialState,
    reducers: {
      crear: (state, { payload })=> {
        return {
            ...state,
            status: payload.status,
            mensaje: payload.text
        };
      },
      listar: (state, { payload })=> {
        return {
            ...state,
            cargos: ordenarPorNombre(payload.cargos, 'codigo'),
            status: payload.status,
            text: payload.text
        };
      },
      listar_tipo_cargos: (state, { payload })=> {
        return {
            ...state,
            tipos: ordenarPorNombre(payload.tipo_cargos, ''),
        };
      },
      buscar_por_codigo: (state, { payload })=> {
        return {
            ...state,
            cargo: payload.data,
            status: payload.status,
            mensaje: payload.mensaje
        };
      },
      buscar_por_tipo: (state, { payload })=> {
        return {
            ...state,
            cargos: payload.data,
        };
      },
      buscar: (state, { payload })=> {
        return {
            ...state,
            cargos: payload.data,
            status: payload.status,
            mensaje: payload.mensaje
        };
      },
      editar: (state, { payload }) => {
        return {
          ...state,
          status: payload.status,
          text: payload.text,
        };
      },
      eliminar: (state, { payload })=> {
        return {
            ...state,
            status: payload.status,
            mensaje: payload.mensaje,
            borrado: payload.borrado
        };
      },
      restaurar_mensajes: (state) => {
        return {
          ...state,
          status: '',
          mensaje: '',
        };
      },
    },
});

export const { crear, listar, listar_tipo_cargos, buscar_por_codigo, buscar_por_tipo, buscar, editar, eliminar, restaurar_mensajes } = cargosSlice.actions;

export default cargosSlice.reducer;
