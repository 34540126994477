import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useModal } from '../../hooks/useModal';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import Pie from '../Pie/Pie';
import ModalComponent from '../ModalComponent/ModalComponent';

// Actions
import { buscar_por_dni_actual } from '../../redux/action-types/usuarioActions';
import { restaurarMensajes } from '../../redux/action-types/messageActions';

// MUI componentes
import { 
  Box, 
  Grid,
  Typography,
  useMediaQuery
} from '@mui/material';

// Imagenes
import logoAsistencia from "../../images/BANNER.jpg";
import logoCel from "../../images/celular.png"

  
const Home = () => {
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:600px)');

  const { dni } = useSelector(state => state.acceso);
  const { mensaje } = useSelector(state => state.mensaje)

  const [isOpenModal, openModal, closeModal] = useModal();

  useEffect(() => {
    dispatch(buscar_por_dni_actual(dni));
    if(mensaje){
      openModal()
      setTimeout(() => {        
        closeModal();
        dispatch(restaurarMensajes());
      }, 3000);
    }
  }, [dispatch, mensaje, openModal, closeModal, dni]);

  if (matches) {
    return (
      <>
        <Rotulo titulo={"Inicio"} />
        <Grid >
          <Box component="main"
            sx={{
              minHeight:{xs:"80vh", sm:'80vh', md:'80vh', lg:'auto'},
              display:"flex",
              alignItems:"center",
              justifyContent:"center",
              borderBlockColor: "black"
            }}
          >
            <Box
              component="img"
              sx={{
                width: { xs: '100%', md: '100%' }, // Establece el ancho al 100% en dispositivos pequeños
                height: 'auto', // Altura automática para mantener la relación de aspecto
                objectFit: 'contain', // Establece cómo la imagen debe ajustarse al contenedor
                maxWidth: '100%' ,
                maxHeight: '100%' , // Limita la altura para evitar que sea demasiado grande en dispositivos pequeños
                overflow: 'hidden',
              }}
              alt="Logo SiCAP."
              src={logoAsistencia}
            />
          </Box>
        </Grid>
        {/* Componente de Notificaciones */}
        <ModalComponent open={isOpenModal} handleClose={closeModal} >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {mensaje}
          </Typography>
        </ ModalComponent>
        <Pie margen={false}/>
      </>
    );
  };

  
  return (
    <>
      <Rotulo titulo={"Inicio"} />
      <Grid>
        <Box component="main"
          sx={{
            minHeight:{xs:"80vh", sm:'80vh', md:'80vh', lg:'auto'},
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
          }}
        >
          <Box
            component="img"
            sx={{
              width: { xs: '100%', md: '100%' }, // Establece el ancho al 100% en dispositivos pequeños
              height: 'auto', // Altura automática para mantener la relación de aspecto
              objectFit: 'contain', // Establece cómo la imagen debe ajustarse al contenedor
              maxWidth: '100%' ,
              maxHeight: '100%' , // Limita la altura para evitar que sea demasiado grande en dispositivos pequeños
              overflow: 'hidden'
            }}
            alt="Logo SiCAP."
            src={logoCel}
          />
        </Box>
      </Grid>
      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {mensaje}
        </Typography>
      </ ModalComponent>
      <Pie margen={false}/>
    </>
  );
  
};

export default Home;
