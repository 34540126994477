import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';
// import { useDecrypt } from '../../hooks/useDecrypt';
import dayjs from 'dayjs';

// Componentes
import InputJustificaciones from './InputJustificaciones';
import ModalComponent from '../ModalComponent/ModalComponent';

// Actions
import { justificacion_general, restaurarMensajes } from '../../redux/action-types/justificacionesActions';
import { justificacionesGeneralesEnRango } from '../../redux/action-types/auditoriaActions';

// Funciones de ayuda
import { obtenerDia } from '../../utils/obtenerDia';
import { obtenerFecha } from '../../utils/obtenerFecha';

// MUI componentes
import { 
  Box, 
  CircularProgress,
  Grid, 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography, 
} from '@mui/material';

//Utils
import { verificarPermiso } from '../../utils/verificarPermiso';
// MUI iconos
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { Delete } from '@mui/icons-material';
import { calcularDiasEntreFechas } from '../../utils/calcularDiasEntreFechas';

var fechaActual = dayjs();
var añoActual = fechaActual.year();
var fecha_inicio = new Date(añoActual, 0, 1)
var fechaInicio = dayjs(fecha_inicio).format('DD/MM/YYYY');
var suma = fechaActual.add(1, 'month');
var fechaFin = dayjs(suma);

// Estado inicial de busqueda
const initialState = {
  fechaInicioInput: fechaInicio,
  fechaFinInput: fechaFin,
};

const ListadoJustificaciones = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const buscar_justificaciones_generales = useSelector(state => state.justificaciones.justificaciones_generales);
  const { generales_audit } = useSelector(state => state.auditoria)

  const { dni }  = useSelector(state => state.acceso);

  const permisos = window.localStorage.getItem("permisos");
  // const permisos = useDecrypt('permisos');

  const [ fecha, setFecha ] = useState(initialState);
  const [datosObtenidos, setDatosObtenidos] = useState(false);
  
  const [isOpenModal, openModal, closeModal] = useModal();

  const handleChange = event => {
    const { name, value } = event.target;
    setFecha({
      ...fecha,
      [name]: value,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    if(fecha.fechaInicioInput !== initialState.fechaInicioInput || fecha.fechaFinInput !== initialState.fechaFinInput) {
      const data = {usuarioActualInput: dni, fechaInicioInput: fecha.fechaInicioInput, fechaFinInput: fecha.fechaFinInput};
      dispatch(justificacion_general(data));
      if(verificarPermiso(permisos, 'super_usuario')){
        dispatch(justificacionesGeneralesEnRango(data));
      }
    } else {
      openModal();
    };
  };

  const handleClickCreador = creador => {
    navigate(`/perfil/${creador}`);
  };

  const handleClickJustificacion = (dni, id) => {
    window.sessionStorage.setItem('urlAnterior', `/justificaciones-generales`);
    navigate(`/detalle-justificacion/${dni}/${id}`);
  };

  const handleClickEliminada = (dni_creador, id) => {
    window.sessionStorage.setItem('urlAnterior', `/justificaciones-generales`);
    navigate(`/detalle-justificacion-eliminada/${dni_creador}/${id}`);
  };

  const handleClickDependencia = (id) => {
    window.sessionStorage.setItem('urlAnterior', `/justificaciones-generales`);
    navigate(`/dependencia/${id}`);
  };

  useEffect(() => {
    const data = {usuarioActualInput: dni, fechaInicioInput: initialState.fechaInicioInput, fechaFinInput: initialState.fechaFinInput};
    dispatch(justificacion_general(data));
    if(verificarPermiso(permisos, 'super_usuario')){
      dispatch(justificacionesGeneralesEnRango(data));
    };

    const temporizador = setTimeout(() => {
      setDatosObtenidos(true);
    }, 1000); 
    
    return () => {
      dispatch(restaurarMensajes());
      clearTimeout(temporizador);
    };
  }, [dispatch, dni, permisos]);

  useEffect(() => {
  }, [buscar_justificaciones_generales, generales_audit])

  if(!datosObtenidos && buscar_justificaciones_generales.length < 1) {
    return (
      <Grid container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3vh",
        }}
      >
        <Grid item container xs={12} sm={10} md={8} lg={6} 
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >  
          {/* Item 1 */}
          <Grid item xs={10} sm={10} md={8} lg={12} mb={"2em"}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", ml: "-3px"}}> 
              <FormatListNumberedIcon />
              <Typography component={"h3"} variant={"h6"} fontSize={"1.4em"} ml={"2px"}>
                Listado de Justificaciones Generales
              </Typography>
            </Box>  
          </Grid>
          <Grid item xs={10} sm={10} md={8} lg={12}>
            <CircularProgress 
              sx={{
                width: "auto",
                height: "auto",
              }}
            />  
          </Grid> 
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "3vh",
      }}
    >
      {/* Contenedor Grid de los Items */}
      <Grid item container xs={12} sm={10} md={8} lg={6} 
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >  
        {/* Item 1 */}
        <Grid item xs={10} sm={10} md={10} lg={12} mb={"2em"}>
          <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", ml: "-3px"}}> 
            <FormatListNumberedIcon />
            <Typography component={"h3"} variant={"h6"} fontSize={"1.4em"} ml={"2px"}>
              Listado de Justificaciones Generales
            </Typography>
          </Box>  
        </Grid>
        {/* Componente que toma la fecha para el rango de marcaciones */}
        <InputJustificaciones fechaInicioInput={fecha.fechaInicioInput} fechaFinInput={fecha.fechaFinInput} handleChange={handleChange} handleSubmit={handleSubmit} />
        {/* Item 2 */}
        <Grid item xs={10} sm={10} md={10} lg={12}>
          {buscar_justificaciones_generales.length > 0 ?
          <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
            <Table aria-label="simple table">
              <TableHead key={"head"}>
                <TableRow key={"0"} sx={{bgcolor:"primary.light"}}>
                  <TableCell key={"day"} align="center">Día inicio</TableCell>
                  <TableCell key={"date"} align="center">Fecha inicio</TableCell>
                  <TableCell key={"dayEnd"} align="center">Día fin</TableCell>
                  <TableCell key={"endDate"} align='center'>Fecha fin</TableCell>
                  <TableCell key={"days"} align='center'>Total días</TableCell>                  
                  <TableCell key={"justify"} align="center">Justificación</TableCell>
                  <TableCell key={"dependency"} align="center">Dependencia</TableCell>
                  <TableCell key={"office"} align="center">Oficina</TableCell>
                  <TableCell key={"createdby"} align="center">Creado por</TableCell>
                </TableRow>
              </TableHead>
              {
                <TableBody>
                  {
                    buscar_justificaciones_generales && buscar_justificaciones_generales.map(justificacion => {
                      const dia = obtenerDia(justificacion.fecha_inicio);
                      const diaE = justificacion.fecha_inicio === justificacion.fecha_fin ? '-' : obtenerDia(justificacion.fecha_fin);
                      
                      return (
                        <TableRow
                          key={justificacion.justificacion_id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="center">{dia}</TableCell>
                          <TableCell align="center">{obtenerFecha(justificacion.fecha_inicio).fecha}</TableCell>
                          <TableCell align="center">{diaE}</TableCell>
                          <TableCell align='center'>{justificacion.fecha_inicio === justificacion.fecha_fin ? '-' : obtenerFecha(justificacion.fecha_fin).fecha}</TableCell>
                          <TableCell key={"cantidad"} align="right">{justificacion.fecha_fin ? calcularDiasEntreFechas(justificacion.fecha_inicio, justificacion.fecha_fin): 1}</TableCell>
                          <TableCell align="center"
                            sx={{
                              '&:hover': {
                                cursor: 'pointer',
                                borderRadius: '.5em',
                                boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                              }
                            }}
                            onClick={() => handleClickJustificacion(justificacion.dni_creador, justificacion.justificacion_id)}
                            // corregir por que me tira como que son justificaciones genereales
                          >
                            {justificacion.titulo}
                          </TableCell>
                          <TableCell align="center"
                              sx={{
                                '&:hover': {
                                  cursor: 'pointer',
                                  borderRadius: '.5em',
                                  boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                }
                              }}
                              onClick={() => handleClickDependencia(justificacion.cabecera?.id)}>
                            {justificacion.cabecera?.name}
                          </TableCell>

                          {justificacion.cabecera?.id !== justificacion.id_dependencia ?
                          <TableCell align="center"
                              sx={{
                                '&:hover': {
                                  cursor: 'pointer',
                                  borderRadius: '.5em',
                                  boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                }
                              }}
                              onClick={() => handleClickDependencia(justificacion.id_dependencia)}>
                            {justificacion.nombre_dependencia}
                          </TableCell>
                          :
                          <TableCell align='center'>-</TableCell>}
                          <TableCell align="center" 
                            sx={{
                              '&:hover': {
                                cursor: 'pointer',
                                borderRadius: '.5em',
                                boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                              }
                            }}
                            onClick={() => handleClickCreador(justificacion.dni_creador)}
                          >
                            {justificacion.creador}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              }
            </Table>
          </TableContainer>
          :
          <Grid item xs={10} sm={10} md={10} lg={12}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", ml: "-3px"}}> 
              <Typography component={"h3"} variant={"h6"} fontSize={"1em"} ml={"2px"}>
                No se encontraron justificaciones generales para esas fechas...
              </Typography>
            </Box> 
          </Grid> 
          }
        </Grid>

        {verificarPermiso(permisos, 'super_usuario') && generales_audit.length > 0 ?
        <Grid item container xs={12} sm={12} md={12} lg={12} mt={"3em"}
        sx={{
          display: "flex",
          justifyContent: "center", 
        }}>
          <Grid item xs={10} sm={10} md={9.6} lg={12} mb={"2em"}>
          <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", ml: "-3px"}}> 
            <Delete />
            <Typography component={"h3"} variant={"h6"} fontSize={"1.4em"} ml={"2px"}>
              {`Justificaciones eliminadas`}
            </Typography>
            </Box>  
          </Grid>
          <Grid item xs={10} sm={10} md={9.6} lg={12}>
            <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
              <Table aria-label="simple table"> 
                <TableHead key={"head"}>
                  <TableRow key={"0"} sx={{bgcolor:"auditory.main"}}>
                    <TableCell key={"date_init"} align="center">Fecha inicio</TableCell>
                    <TableCell key={"date_end"} align="center">Fecha fin</TableCell>                  
                    <TableCell key={"justify"} align="center">Justificación</TableCell>
                    <TableCell key={"dependency"} align="center">Dependencia</TableCell>
                    <TableCell key={"office"} align="center">Oficina</TableCell>
                    <TableCell key={"createdat"} align="center">Fecha creacion</TableCell>
                    <TableCell key={"deletedby"} align="center">Eliminado por</TableCell>
                    <TableCell key={"deletedat"} align="center">Fecha eliminacion</TableCell>
                  </TableRow>
                </TableHead>
                {
                  <TableBody>
                    {
                      generales_audit && generales_audit.map(justificacion => {
                        return (
                          <TableRow
                            key={`${justificacion.fecha_inicio}${justificacion.titulo}`}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align="center">{obtenerFecha(justificacion.fecha_inicio).fecha}</TableCell>
                            <TableCell align="center">{justificacion.fecha_inicio === justificacion.fecha_fin ? '-' : obtenerFecha(justificacion.fecha_fin).fecha}</TableCell>
                            <TableCell align="center"
                              sx={{
                                '&:hover': {
                                  cursor: 'pointer',
                                  borderRadius: '.5em',
                                  boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                }
                              }}
                              onClick={() => handleClickEliminada(justificacion.dni_creador, justificacion.justificacion_id)}
                            >
                              {justificacion.titulo}
                            </TableCell>
                            <TableCell align="center"
                              sx={{
                                '&:hover': {
                                  cursor: 'pointer',
                                  borderRadius: '.5em',
                                  boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                }
                              }}
                              onClick={() => handleClickDependencia(justificacion.cabecera?.id)}>
                            {justificacion.cabecera?.name}
                          </TableCell>

                          {justificacion.cabecera?.id !== justificacion.id_dependencia ?
                          <TableCell align="center"
                              sx={{
                                '&:hover': {
                                  cursor: 'pointer',
                                  borderRadius: '.5em',
                                  boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                }
                              }}
                              onClick={() => handleClickDependencia(justificacion.id_dependencia)}>
                            {justificacion.nombre_dependencia}
                          </TableCell>
                          :
                          <TableCell align='center'>-</TableCell>}
                            <TableCell align="center">{obtenerFecha(justificacion.fecha_creacion).fecha}</TableCell>
                            <TableCell align="center" 
                              sx={{
                                '&:hover': {
                                  cursor: 'pointer',
                                  borderRadius: '.5em',
                                  boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                }
                              }}
                              onClick={() => handleClickCreador(justificacion.dni_creador)}
                            >
                              {justificacion.creador}
                            </TableCell>
                            <TableCell align="center"                              
                            >{obtenerFecha(justificacion.fecha_eliminada).fecha}</TableCell>
                            
                          </TableRow>
                        );
                      })
                    }
                  </TableBody>
                }
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        :
        null
        }
      </Grid>
      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        <Typography id="modal-modal-title" component="h2" sx={{ fontSize: '1.3em' }}>
          Ingrese una fecha de inicio y una de fin para el reporte.
        </Typography>
      </ModalComponent>
    </Grid>
  );
};

export default ListadoJustificaciones;
