import { createSlice } from '@reduxjs/toolkit';
// import CryptoJS from 'crypto-js';

// Decode
import jwt_decode from 'jwt-decode';

const initialState = {
  dni: null,
  isAuth: false,
  recordar: false,
  mensaje: '',
  token: '',
};

export const accesoSlice = createSlice({
  name: 'accesoReducer',
  initialState,
  reducers: {
    acceder: (state, { payload }) => {
      const KEY = process.env.REACT_APP_SECRET_KEY;

      if(payload.data.token) {
        const data = jwt_decode(payload.data.token, KEY);
        if(data.isAuth) {
          window.localStorage.setItem("dni", data.dni);
          window.localStorage.setItem("isAuth", data.isAuth);
          window.localStorage.setItem("recordar", data.recordar);
          window.localStorage.setItem("expiracion", data.expiracion);
          window.localStorage.setItem("token", payload.data.token);
          const roles = []
          const permisos = []
          data.rol.forEach(instancia => {
            roles.push(instancia.role.nombre);
            instancia.role.permissions.forEach(permiso => {
              permisos.push(permiso.name);
            })
          });

          window.localStorage.setItem("rol", Array.from(new Set(roles)));
          window.localStorage.setItem("permisos", Array.from(new Set(permisos)));

          // Encriptar los roles y permisos
          // const encryptedRoles = CryptoJS.AES.encrypt(JSON.stringify(roles), KEY).toString();
          // const encryptedPermisos = CryptoJS.AES.encrypt(JSON.stringify(permisos), KEY).toString();

          // Las guardo
          // window.localStorage.setItem('rol', encryptedRoles);
          // window.localStorage.setItem('permisos', encryptedPermisos);
        };

        return {
          ...state,
          dni: data.dni,
          isAuth: data.isAuth,
          recordar: data.recordar,
          mensaje: payload.data.mensaje,
          token: payload.data.token,
        };
      };

      return {
        ...state,
        mensaje: payload.data.mensaje,
      };
    },
    reacceder: (state, { payload }) => {
      return {
        ...state,
        dni: payload.dni,
        isAuth: payload.isAuth === 'true',
        recordar: payload.recordar,
      };
    },
    cerrar: (state, { payload }) => {
      window.localStorage.removeItem("dni");
      window.localStorage.removeItem("isAuth");
      window.localStorage.removeItem("recordar");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("dependency_roles");
      window.localStorage.removeItem("permisos");
      window.localStorage.removeItem("expiracion");
      window.localStorage.removeItem("rol");
      return {
        ...state,
        dni: null,
        isAuth: false,
        recordar: false,
        token: '',
        mensaje: 'Sesión Cerrada!',
      };
    },
    reset: (state) => {
      return {
        ...state,
        dni: null,
        isAuth: false,
        mensaje: 'Se te envió un mail para recuperar la contraseña.',
      };
    },
    limpiar: (state) => {
      return {
        ...state,
        mensaje: '',
      }
    }
  },
});

export const { acceder, cerrar, reacceder, reset, limpiar } = accesoSlice.actions;

export default accesoSlice.reducer;
