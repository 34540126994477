import React from 'react';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import Pie from '../Pie/Pie';

// MUI componentes
import { 
  Typography, 
  Box, 
  Grid,
  Avatar,
} from '@mui/material';

// MUI iconos
import ErrorIcon from '@mui/icons-material/Error';

const Error404 = () => {
  return (
    <>
      <Rotulo titulo={''} />
      {/* Componente de Error */}
      <Grid component="main" maxWidth="100%">
        <Box
          sx={{
            marginTop: "20vh",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <ErrorIcon />
          </Avatar>
          <Typography component="h1" variant="h4" fontSize={"2em"}>
            Error 404: No Encontrada!
          </Typography>
          <Typography component="h2" variant="h5" fontSize={"1.5em"}>
            Esta Página no Existe...
          </Typography>
        </Box>
      </Grid>
      {/* Componente de Pie de Pag. */}
      <Pie />
    </>
  );
};

export default Error404;