import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
// import { useDecrypt } from '../../hooks/useDecrypt';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import Pie from '../Pie/Pie';
import ListadoVigentes from './ListadoVigentes';
import ListadoHistoricas from './ListadoHistoricas';
import CrearDesignacion from './CrearDesignacion';
import ListadoEliminadas from './ListadoEliminadas';

// Actions
import { listar_designaciones, listar_designaciones_actual } from '../../redux/action-types/designacionActions';
import { buscar_por_dni, buscar_por_dni_actual } from '../../redux/action-types/usuarioActions';
import { designacionesEliminadas } from '../../redux/action-types/auditoriaActions';

// MUI componentes
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

// MUI iconos
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import AddIcon from '@mui/icons-material/Add';

// Utils
import { verificarPermiso } from '../../utils/verificarPermiso';

const Designaciones = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dni } = useParams();

  const { usuario } = useSelector(state => state.usuario);
  const dniActual  = useSelector(state => state.acceso.dni);
  const permisos = window.localStorage.getItem("permisos");
  // const permisos = useDecrypt('permisos');

  const [datosObtenidos, setDatosObtenidos] = useState(false);

  useEffect(() => { 
    dispatch(dni === dniActual ? buscar_por_dni_actual(dni) : buscar_por_dni(dni));
    dispatch(dni === dniActual ? listar_designaciones_actual(dni) : listar_designaciones(dni));
    if(verificarPermiso(permisos, 'super_usuario')){
      dispatch(designacionesEliminadas({usuarioDni:dni}));
    };
    setTimeout(() => {
        setDatosObtenidos(true);
      }, 500);

  }, [dispatch, dniActual, dni, permisos]);

  const handleAtras = () => {
    navigate(`/perfil/${dni}`);
  };

  if(!datosObtenidos) {
    return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={`Designaciones de ${usuario?.nombre} ${usuario?.apellido}`} />
        <Grid container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "3vh",
            }}
          > 
            <Grid item xs={10} sm={8} md={7} lg={8}>
              <Button 
                variant="contained"
                sx={{
                  alignSelf: "start",
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleAtras}
              >
                Atrás
              </Button>
              <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
            </Grid>
            {/* Contenedor Grid de los Items */}
            <Grid item container xs={10} sm={8} md={7} lg={8} mt={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* Item 1 */}
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          </Grid>
          {/* Pie de Pág. */}
        <Pie />
      </>
    );
  };

  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={`Designaciones de ${usuario?.nombre} ${usuario?.apellido}`} />
      <Grid container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3vh",
        }}
      > 
        <Grid item xs={10} sm={8} md={7} lg={8}>
          <Button 
            variant="contained"
            sx={{
              alignSelf: "start",
              fontSize: ".8em",
              fontWeight: "bold",
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'background.paper',
                transform: 'scale(1.01)',
              }
            }}
            onClick={handleAtras}
          >
            Atrás
          </Button>
          <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
        </Grid>
        {/* Contenedor Grid de los Items */}
        <Grid item container xs={12} sm={10} md={8} lg={8}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {
            verificarPermiso(permisos, 'usuario_administrador') ?
              <>
                {/* Item 1 */}
                <Grid item container  xs={10} sm={11} md={12} lg={12} mb={"3em"}>
                  <Box sx={{display: 'flex', justifyContent: 'start'}}>
                    <AddIcon fontSize="large" />
                    <Typography component={"h3"} variant={"h6"} fontSize={"1.4em"} ml={"2px"}>
                      Agregar nueva designacion
                    </Typography>
                  </Box>
                </Grid>
                {/*Formulario crear designacion*/}
                <CrearDesignacion />
              </>
            :
              null
          }
          {/* Item 2 */}
          <Grid item container xs={11} sm={11} md={12} lg={12} mb={"3em"}>
            <Box sx={{display: 'flex', justifyContent: 'start',  marginBottom:'8px'}}>
              <FormatListNumberedIcon />
              <Typography component={"h6"} variant={"h6"} fontSize={"1.2em"} ml={"2px"}>
                Designaciones vigentes
              </Typography>
            </Box>

            {/*Listado designaciones vigentes*/}
            <Grid item xs={11} sm={11} md={12} lg={12}>
              <ListadoVigentes />
            </Grid>
          </Grid>

          {/* Item 3 */}
          <Grid item xs={11} sm={11} md={12} lg={12}>
            <Box sx={{display: 'flex', justifyContent: 'start', marginBottom:'8px'}}>
              <FormatListNumberedIcon />
              <Typography component={"h6"} variant={"h6"} fontSize={"1.2em"} ml={"2px"}>
                Designaciones historicas
              </Typography>
            </Box>
            {/*Listado de designaciones historicas */}
            <Grid item xs={11} sm={11} md={12} lg={12}>
              <ListadoHistoricas />
            </Grid>
          </Grid>


          {/* Item 4 */}
          {verificarPermiso(permisos, 'super_usuario') ?            
            <Grid item xs={11} sm={11} md={12} lg={12} sx={{marginTop:"3em"}}>
              <ListadoEliminadas />
            </Grid>
          :
          null}


        </Grid>
      </Grid>
      {/* Pie de Pág. */}
      <Pie />
    </>
  );
};

export default Designaciones;
