import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
// import { useDecrypt } from '../../hooks/useDecrypt';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import AcercaDesignacion from './AcercaDesignacion';
import Pie from '../Pie/Pie';

// Actions
import { buscar_por_id } from '../../redux/action-types/designacionActions';
import { historialDesignaciones } from '../../redux/action-types/auditoriaActions';

// MUI componentes
import {
  Box, 
  Grid,
  CircularProgress,
  Typography,
} from '@mui/material';

// Utils
import { verificarPermiso } from '../../utils/verificarPermiso';

//MUI ICONS
import HistoryIcon from '@mui/icons-material/History';
import ListadoAuditoria from './ListadoAuditoria';

const DetalleDesignacion = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  
  const { designacion } = useSelector(state => state.designaciones);
  const { historial_versiones } = useSelector(state => state.auditoria);

  const permisos = window.localStorage.getItem("permisos");
  // const permisos = useDecrypt('permisos');

  useEffect(() => {
    dispatch(buscar_por_id(id));
    if (verificarPermiso(permisos, 'super_usuario')){
      dispatch(historialDesignaciones(id));
    }
  }, [dispatch, id, permisos]);


  if(!designacion) {
    return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={"Detalle Justificación"} />
        <Grid container>
          <CircularProgress 
            sx={{
              width: "auto",
              height: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />  
        </Grid>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  };

  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={"Detalle e historial designación"} />
      <Grid container spacing={6} 
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1vh",
        }}
        >
        <Grid item container xs={12} sm={10} md={8} lg={8} ml={2}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* Datos de la Justificacion */}
          <AcercaDesignacion 
            id={designacion.id} 
            dni={designacion.usuario_dni}
            usuario={designacion.usuario}
            inicio={designacion.fecha_inicio} 
            fin={designacion.fecha_fin}
            cerrador={designacion.cerrador}
            closed_by={designacion.closed_by}
            fecha_cierre={designacion.closed_at} 
            cargo={designacion.cargo}
            creador={designacion.creador}
            created_by={designacion.created_by}
            fecha_creacion={designacion.created_at}
            dependencia={designacion.dependency}
            cabecera={designacion.cabecera}
            eliminada={designacion.eliminada}
            fecha_eliminacion={designacion.updated_at}
            dni_modificador = {designacion.updated_by}
            modificador = {designacion.modificador}
          />
          </Grid>
          
          
          {verificarPermiso(permisos, 'super_usuario') ?
          <Grid item container xs={12} sm={12} md={12} lg={10}
          sx={{
            display: "flex",
            justifyContent: "center", 
          }}>
          <Grid item xs={10} sm={10} md={9.6} lg={11.6} mb={"2em"}>
          <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", ml: "-3px"}}> 
            <HistoryIcon />
            <Typography component={"h3"} variant={"h6"} fontSize={"1.4em"} ml={"2px"}>
              {`Historial de versiones`}
            </Typography>
            </Box>  
        </Grid>
            {historial_versiones && historial_versiones.length > 0 ?
        
          <ListadoAuditoria />
          :
          <Grid item xs={10} sm={10} md={9.6} lg={11.6} mb={"2em"}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", ml: "-3px"}}> 
              <Typography component={"h6"} variant={"h6"} fontSize={"1em"} ml={"2px"}>
                {`Esta designación nunca fue modificada`}
              </Typography>
            </Box>  
          </Grid>
          }
          </Grid>
          :
          null
          }  
          
        
      </Grid>
      
      
      <Pie /> 
    </>
  );
};

export default DetalleDesignacion;
