import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import SearchInput from '../SearchInput/SearchInput';
import Cargo from './Cargo';
import Pie from '../Pie/Pie';

// Actions
import { listar_cargos, buscar_cargo } from '../../redux/action-types/cargoActions'; 

// MUI componentes
import { 
  Button,
  Box,
  CircularProgress,
  Divider,
  Grid,
  useMediaQuery,
} from '@mui/material';

const AdministrarCargos = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const matches = useMediaQuery('(min-width:720px)');
  const matches2 = useMediaQuery('(min-width:1050px)');

  const { cargos } = useSelector(state => state.cargos);
  
  const [ cargo, setCargo ] = useState('');

  const handleClickCrear = () => {
    navigate('/crear-cargo');
  };

  const handleAtras = () => {
    navigate('/dashboard');
  };

  const handleClickSearch = () => {
    if(cargo.length > 0) {
      dispatch(buscar_cargo(cargo));
    } else dispatch(listar_cargos());
  };

  const chunkArray = (arr, size) => {
    return Array.from({ length: Math.ceil(arr.length / size) }, (_, index) =>
      arr.slice(index * size, index * size + size)
    );
  };

  useEffect(() => {
    dispatch(listar_cargos());
  }, [dispatch]);

  const handleChange = (event) => {
    setCargo(event.target.value);
  };

  if(!cargos) {
    return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={'Administrar Cargos'} />
        <Grid container spacing={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "0.5vh",
          }}
        >
          <Grid item container xs={12} sm={12} md={12} lg={12} ml={4}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >      
          <Grid container xs={11} sm={10} md={10} lg={6}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}>
            <Box>
              <Button variant="contained"
                sx={{
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleAtras}
              >
                Dashboard
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around"
                }}>
              <Button variant="contained"
                sx={{
                  marginBottom: '1em',
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleClickCrear}
              >
                Crear Cargo
              </Button>
            </Box>
            <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
          </Grid>
          {/* Search Input */}
          <Grid xs={12} sm={12} md={12} lg={12}
            sx={{
              marginBottom: "2vh",
              marginTop: "-3vh"
            }} 
          >
            <SearchInput 
              handleChange={handleChange}
              handleClick={handleClickSearch} 
              placeholder={"Ingrese el código"}
            />
          </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} pb={6}>
              <CircularProgress 
                sx={{
                  width: "auto",
                  height: "auto",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                }}
              />  
            </Grid>
          </Grid>
        </Grid>
        {/* Componente de Pie de Pag. */}
        <Pie />
      </>
    );
  };

  if (!matches) {
    return (
      <> 
        {/* Rótulo de Página */}
        <Rotulo titulo={'Administrar Cargos'} />
        {/* Componente */}
        <Grid container spacing={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "0.5vh",
            }}
          >
            <Grid item container xs={12} sm={12} md={12} lg={12} ml={4}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >      
            <Grid container xs={11} sm={10} md={10} lg={6}
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}>
              <Box>
                <Button variant="contained"
                  sx={{
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                  onClick={handleAtras}
                >
                  Dashboard
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around"
                  }}>
                <Button variant="contained"
                  sx={{
                    marginBottom: '1em',
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                  onClick={handleClickCrear}
                >
                  Crear Cargo
                </Button>
              </Box>
              <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
            </Grid>
            {/* Search Input */}
            <Grid xs={12} sm={12} md={12} lg={12}
              sx={{
                marginBottom: "2vh",
                marginTop: "-3vh"
              }} 
            >
              <SearchInput 
                handleChange={handleChange}
                handleClick={handleClickSearch} 
                placeholder={"Ingrese el código"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} pb={6}>
              {
                cargos.map(cargo => {
                  return (
                    <Cargo 
                      key={cargo.codigo}
                      codigo={cargo.codigo}
                      descripcion={cargo.descripcion}
                    />
                  )
                })
              }
            </Grid>
          </Grid>
        </Grid>
        {/* Componente de Pie de Pag. */}
        <Pie />
      </>
    );
  };

  if (!matches2) {
    const gruposDeDos = chunkArray(cargos, 2);

    return (
      <> 
        {/* Rótulo de Página */}
        <Rotulo titulo={'Administrar Cargos'} />
        {/* Componente */}
        <Grid container spacing={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "0.5vh",
            }}
          >
            <Grid item container xs={12} sm={12} md={12} lg={12} ml={4}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >      
            <Grid container xs={11} sm={10} md={10} lg={6}
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}>
              <Box>
                <Button variant="contained"
                  sx={{
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                  onClick={handleAtras}
                >
                  Dashboard
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around"
                  }}>
                <Button variant="contained"
                  sx={{
                    marginBottom: '1em',
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                  onClick={handleClickCrear}
                >
                  Crear Cargo
                </Button>
              </Box>
              <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
            </Grid>
            {/* Search Input */}
            <Grid xs={12} sm={12} md={12} lg={12}
              sx={{
                marginBottom: "2vh",
                marginTop: "-3vh"
              }} 
            >
              <SearchInput 
                handleChange={handleChange}
                handleClick={handleClickSearch} 
                placeholder={"Ingrese el código"}
              />
            </Grid>
            <Grid container
              sx={{
                justifyContent: "center",
              }}
            >
              {
                gruposDeDos.map((grupo, index) => (
                  <Grid key={index} item xs={12} sm={12} md={9} lg={7} pb={6}
                    sx={{
                      display:"flex",
                      justifyContent: "space-around",
                      marginBottom: "-4vh"
                    }}
                  >
                    {
                      grupo.map(cargo => (
                        <Cargo 
                          key={cargo.codigo}
                          codigo={cargo.codigo}
                          descripcion={cargo.descripcion}
                        />
                      ))
                    }
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
        </Grid>
        {/* Componente de Pie de Pag. */}
        <Pie />
      </>
    );
  };

  const gruposDeTres = chunkArray(cargos, 3);

  return (
    <> 
      {/* Rótulo de Página */}
      <Rotulo titulo={'Administrar Cargos'} />
      {/* Componente */}
      <Grid container spacing={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "0.5vh",
          }}
        >
          <Grid item container xs={12} sm={12} md={12} lg={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >      
          <Grid container xs={11} sm={10} md={10} lg={6}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}>
            <Box>
              <Button variant="contained"
                sx={{
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleAtras}
              >
                Dashboard
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around"
                }}>
              <Button variant="contained"
                sx={{
                  marginBottom: '1em',
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleClickCrear}
              >
                Crear Cargo
              </Button>
            </Box>
            <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
          </Grid>
          {/* Search Input */}
          <Grid xs={12} sm={12} md={12} lg={12}
            sx={{
              marginBottom: "2vh",
              marginTop: "-3vh"
            }} 
          >
            <SearchInput 
              handleChange={handleChange}
              handleClick={handleClickSearch} 
              placeholder={"Ingrese el código"}
            />
          </Grid>
          <Grid item container
            sx={{
              justifyContent: "center",
            }}
          >
            {
              gruposDeTres.map((grupo, index) => (
                <Grid key={index} item xs={12} sm={12} md={10} lg={8} pb={6}
                  sx={{
                    display:"flex",
                    justifyContent: "space-around",
                    marginBottom: "-4vh",
                  }}
                >
                  {
                    grupo.map(cargo => (
                      <Cargo 
                        key={cargo.codigo}
                        codigo={cargo.codigo}
                        descripcion={cargo.descripcion}
                      />
                    ))
                  }
                </Grid>
              ))
            }
          </Grid>
        </Grid>
      </Grid>
      {/* Componente de Pie de Pag. */}
      <Pie />
    </>
  );
};

export default AdministrarCargos;