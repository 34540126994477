import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from '../../hooks/useModal';
import { useNavigate, useParams } from 'react-router-dom';
// import { useDecrypt } from '../../hooks/useDecrypt';

//Componentes
import ModalComponent from '../ModalComponent/ModalComponent';

//Actions
import { listar_designaciones, listar_designaciones_actual, buscar_por_id, revivir_designacion, restaurar_mensajes } from '../../redux/action-types/designacionActions';

// Funciones de ayuda
import { obtenerFecha } from '../../utils/obtenerFecha';

// MUI componentes
import {
  Button,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

// Icons
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import HistoryIcon from '@mui/icons-material/History';

// Utils
import { verificarPermiso } from '../../utils/verificarPermiso';

const initialState = {
  fecha_inicio : '',
  id: '',
  revive: false,
};

const ListadoHistoricas = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dni } = useParams();

  const { historicas, status, mensaje } = useSelector(state => state.designaciones);
  const permisos = window.localStorage.getItem("permisos");
  // const permisos = useDecrypt('permisos');

  const [ state ] = useState(initialState);
  const [isOpenModal, openModal, closeModal] = useModal();
  const [datosObtenidos, setDatosObtenidos] = useState(false);

  const dniActual  = useSelector(state => state.acceso.dni);

  const handleClickCreador = creador => {
    navigate(`/perfil/${creador}`);
  };

  const handleClickDependencia = (id) => {
    window.sessionStorage.setItem('urlAnterior', `/designaciones/${dni}`);
    navigate(`/dependencia/${id}`);
  };

  const handleEdit = (id) => {
    window.sessionStorage.setItem('urlAnterior', `/designaciones/${dni}`);
    dispatch(buscar_por_id(id));
    navigate(`/historica/${id}`);
  };

  const handleClickVersions = (id) => {
    window.sessionStorage.setItem('urlAnterior', `/designaciones/${dni}`);
    navigate(`/historial-designacion/${id}`);
  };

  const handleClickRevive = (id) => {
    state.revive= true;
    state.id = id;
    dispatch(restaurar_mensajes());
    openModal();
  };

  const handleRevive = () => {
    const data = {...state,
      revivido_por: dniActual};
    dispatch(revivir_designacion(data));
    setTimeout(() => {
      closeModal();
      dispatch(dni === dniActual ? listar_designaciones_actual(dni) : listar_designaciones(dni));
    }, 3000);
    dispatch(restaurar_mensajes());
  };

  useEffect(() => {
    dispatch(dni === dniActual ? listar_designaciones_actual(dni) : listar_designaciones(dni));
    setTimeout(() => {
      setDatosObtenidos(true);
    }, 1000);

  }, [dispatch, dniActual, dni]);

  if(!datosObtenidos) {
    return (
        <>
        <Box>
          <Typography component={"h6"} variant={"h6"} fontSize={"1em"}>
            ...
          </Typography>
        </Box>
    </>
    );
  };

  return(
    <>
      {historicas && historicas.length>0 ?
            <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
              <Table aria-label="simple table">
                <TableHead key={"head"}>
                  <TableRow key={"0"} sx={{bgcolor:'primary.light'}}>
                    <TableCell key={"date_init"} align="center">Fecha inicio</TableCell>
                    <TableCell key={"date_end"} align="center">Fecha fin</TableCell>
                    <TableCell key={"codigo_cargo"} align='center'>Codigo cargo</TableCell>
                    <TableCell key={"tipo_cargo"} align='center'>Tipo cargo</TableCell>
                    <TableCell key={"descripcion_cargo"} align='center'>Descripcion cargo</TableCell>
                    <TableCell key={"dependencia"} align='center'>Dependencia</TableCell>
                    <TableCell key={"oficina"} align='center'>Oficina</TableCell>
                    {
                      verificarPermiso(permisos, 'super_usuario') ?
                    <TableCell key={"createdby"} align="center">Creado por</TableCell>
                    :
                    null}
                    {
                      verificarPermiso(permisos, 'usuario_administrador') ?
                        <>
                        <TableCell key={"edit"} align="center">Editar</TableCell>
			<TableCell key={"revive"} align="center">Revivir</TableCell>
                        </>
                      :
                        null
                    }
                    {
                      verificarPermiso(permisos, 'super_usuario') ?
                      <>
                        <TableCell key={"history"} align='center'>Versiones anteriores</TableCell>
                        </>
                      :
                        null
                    }
                  </TableRow>
                </TableHead>
                {
                  <TableBody>
                    {
                      historicas && historicas.map(designacion => {
                        return (
                          <TableRow
                            key={designacion.fecha_inicio}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                              <TableCell key={"fecha_inicio"} align="center">{obtenerFecha(designacion.fecha_inicio).fecha}</TableCell>
                              <TableCell key={"fecha_fin"} align="center">{obtenerFecha(designacion.fecha_fin).fecha}</TableCell>
                              <TableCell key={"codigo_cargo"} align='center'>{designacion.cargo?.codigo}</TableCell>
                              <TableCell key={"tipo_cargo"} align='center'>{designacion.cargo?.tipo}</TableCell>
                              <TableCell key={"descripcion_cargo"} align='center'>{designacion.cargo?.descripcion}</TableCell>
                              <TableCell key={"dependency"} align='center'
                            sx={{
                                '&:hover': {
                                  cursor: 'pointer',
                                  borderRadius: '.5em',
                                  boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                }
                              }}
                              onClick={() => handleClickDependencia(designacion.cabecera?.id)}
                          >
                            {designacion.cabecera?.name}
                            </TableCell>
                            {designacion.cabecera?.id !== designacion.dependency_id ?
                            <TableCell key={"office"} align='center'
                            sx={{
                                '&:hover': {
                                  cursor: 'pointer',
                                  borderRadius: '.5em',
                                  boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                }
                              }}
                              onClick={() => handleClickDependencia(designacion.dependency_id)}
                          >
                            {designacion.dependency.name}
                            </TableCell>
                            :
                            <TableCell key={"office"} align='center'>-</TableCell>
                            }
                              {verificarPermiso(permisos, 'super_usuario') ?
                                <TableCell key={"creadopor"} align="center"
                                  sx={{
                                  '&:hover': {
                                      cursor: 'pointer',
                                      borderRadius: '.5em',
                                      boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                  }
                                  }}
                                  onClick={() => handleClickCreador(designacion.created_by)}
                              >
                                  {designacion.creador}
                              </TableCell>
                              :
                              null}
                              {
                                verificarPermiso(permisos, 'usuario_administrador') ?
                                <>
                                  <TableCell key={"editIcon"} align='center'
                                      sx={{
                                          '&:hover': {
                                              cursor: 'pointer',
                                              borderRadius: '.5em',
                                              boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                          }
                                      }}
                                      onClick={() => handleEdit(designacion.id)}
                                  >
                                      <ModeEditIcon aria-label="Editar" color="primary" fontSize='large'></ModeEditIcon>
                                  </TableCell>
				                          <TableCell key={"reviveIcon"} align='center'
                                      sx={{
                                          '&:hover': {
                                              cursor: 'pointer',
                                              borderRadius: '.5em',
                                              boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                          }
                                      }}
                                      onClick={() => handleClickRevive(designacion.id)}
                                  >
                                      <RestartAltIcon aria-label="Revivir" color="primary" fontSize='large'></RestartAltIcon>
                                  </TableCell>
                                  </>
                                :
                                  null
                              }
                              {
                                verificarPermiso(permisos, 'super_usuario') ?
                                <>
                                    <TableCell key={"historyIcon"} align='center'
                                        sx={{
                                            '&:hover': {
                                                cursor: 'pointer',
                                                borderRadius: '.5em',
                                                boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                            }
                                        }}
                                        onClick={() => handleClickVersions(designacion.id)}
                                      >
                                        <HistoryIcon aria_label="History" color='primary' fontSize='large'></HistoryIcon>
                                      </TableCell>
                                </>
                                :
                                  null
                              }
                          </TableRow>
                        );
                      })
                    }
                  </TableBody>
                }
              </Table>
            </TableContainer>
            :
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "left", ml: "-3px"}}>
                <Typography component={"h6"} variant={"h6"} fontSize={"1em"}>
                  No se encontraron designaciones historicas...
                </Typography>
            </Box>
          }
          {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
          <>
          {
          /*Si hay mensaje y status, se muestra eso*/
          status
          ?
          <>
            <Typography id="modal-modal-title" component="h2"sx={{ fontSize: '1.3em' }} >
              {status}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {mensaje}
            </Typography>
          </>
          :
          <>
            <Typography id="modal-modal-title" component="h2" sx={{ fontSize: '1.3em' }}>
              Esta por revivir la designacion historica. ¿Desea continuar?
            </Typography>
            <Button
              type="submit"
              variant="contained"
              onClick={closeModal}
              sx={{
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                marginRight: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={handleRevive}
              sx={{
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'primary.light',
                  color: 'text.secondary',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Revivir
            </Button>
          </>
        }
        </>
      </ModalComponent>
      </>
  );
}

export default ListadoHistoricas;
