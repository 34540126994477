import axios from "axios";

import { dependencias, cabeceras, añadir, borrar, asignar, eliminar, restaurar, restaurar_mensaje, obtener_todos } from "../reducers/rolReducer";

import { setAuthorizationInterceptor } from "../../utils/tokenAuthorization";

setAuthorizationInterceptor();

export const obtener_cabeceras = (id) => {
  return function (dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/rol/cabeceras`)
      .then((response) => {
        dispatch(cabeceras({"cabeceras": response.data.cabeceras }));
      })
      .catch((error) => console.log("/rol/cabeceras", error));
  };
};

export const obtener_dependencias = (id) => {
  return function (dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/rol/dependencias-permitidas/${id}`)
      .then((response) => {
        dispatch(dependencias({"rol_id": response.data.rol_id, "asignadas":response.data.asignadas }));
      })
      .catch((error) => console.log("/rol/dependencias-permitidas", error));
  };
};

export const añadir_dependencia = (data) => {
  return function (dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/rol/dependencia/añadir`, data)
      .then((response) => {
        dispatch(añadir({ "id": response.data.id, "data": response.data.rol, "mensaje": response.data.mensaje }));
      })
      .catch((error) => console.log("/rol/dependencia/añadir", error));
  };
};

export const borrar_dependencia = (dni, rol, id, data) => {
  return function (dispatch) {
    return axios.delete(`${process.env.REACT_APP_BASE_URL}/rol/eliminar/permiso/${dni}/${rol}/${id}`, {
      headers: {
        'X-Data': JSON.stringify(data)
      },
    })
      .then((response) => {
        dispatch(borrar({ "id": response.data.id, "data": response.data.rol, "mensaje": response.data.mensaje }));
      })
      .catch((error) => console.log("/rol/dependencia/borrar", error));
  };
};

export const asignar_rol = (data) => {
  return function (dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/rol/asignar/${data.dni}/${data.rolInput}`, data)
      .then((response) => {
        dispatch(asignar(response));
      })
      .catch((error) => console.log("/rol/modificar", error));
  };
};

export const obtener_todos_roles = () => {
  return function (dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/rol/obtener-todos`)
      .then((response) => {
        dispatch(obtener_todos({consultas: response.data.consultas, admins: response.data.admins, privilegeds: response.data.privileged, supers: response.data.supers}));
      })
      .catch((error) => console.log("/rol/obtener-todos", error));
  };
};

export const eliminar_role = (rol_id, modificado_por) => {
  return function (dispatch) {
    return axios.delete(`${process.env.REACT_APP_BASE_URL}/rol/eliminar/${rol_id}`, {
      headers: {
        'X-Data': JSON.stringify(modificado_por)
      },
    })
      .then((response) => {
        dispatch(eliminar(response));
      })
      .catch((error) => console.log("/rol/eliminar", error));
  };
};

export const restaurar_mensajes = () => {
  return function (dispatch) {
    dispatch(restaurar_mensaje());
  };
};

export const restaurar_estado = () => {
  return function (dispatch) {
    dispatch(restaurar());
  };
};