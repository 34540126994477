import React from 'react';
import { useNavigate } from 'react-router-dom';

// MUI componentes
import { 
  Box,
  Typography,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

// MUI iconos
import {
  AccountCircle,
} from '@mui/icons-material';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(15),
    border: '1px solid #dadde9',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.secondary.main,
  }
}));

const Usuario = ({ nombre, apellido, dni, imagen, handleClick = null, historico = true }) => {
  const navigate = useNavigate();

  const handlePerfil = () => {
    navigate(`/perfil/${dni}`);
  };

  if (!historico) {
    return (
      <HtmlTooltip title={"Histórico"} arrow placement="left" enterDelay={600} leaveDelay={100}>
        <Box maxWidth="full" component="div"
          sx={{
            width: '18em',
            minHeight: '5em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '.5em',
            marginBottom: '1.5em',
            borderRadius: '.5em',
            padding: ".5em",
            boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)',
            bgcolor: 'background.paper',
            "&:hover": {
              cursor: 'pointer',
              transform: 'scale(1.01)',
            }
          }}
          onClick={handleClick ? handleClick : handlePerfil}
        >
          {
            imagen ? imagen : <AccountCircle sx={{ fontSize:"3em", color:"secondary.main", }} />
          }
          <Box component="div" 
            sx={{
              width: "100%",
              display: "flex",
              flexDirection:"column",
              alignItems: "center"
            }}
          >
            <Box component="div" sx={{ textAlign: 'center' }}>
              <Typography variant="p" fontSize="1.1em">
                {nombre}
              </Typography>
              <Typography variant="p" fontSize="1.1em" marginLeft=".1em">
                {apellido}
              </Typography>
            </Box>
            <Typography variant="p" sx={{ fontSize:".95em", color: "primary.disabled", }}>
              {dni}
            </Typography>
          </Box>
        </Box>
      </HtmlTooltip>
    );
  } else {
    return (
      <Box maxWidth="full" component="div"
        sx={{
          width: '18em',
          minHeight: '5em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '.5em',
          marginBottom: '1.5em',
          borderRadius: '.5em',
          padding: ".5em",
          boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)',
          bgcolor: 'background.paper',
          "&:hover": {
            cursor: 'pointer',
            transform: 'scale(1.01)',
          }
        }}
        onClick={handleClick ? handleClick : handlePerfil}
      >
        {
          imagen ? imagen : <AccountCircle sx={{ fontSize:"3em", color:"primary.light", }} />
        }
        <Box component="div" 
          sx={{
            width: "100%",
            display: "flex",
            flexDirection:"column",
            alignItems: "center"
          }}
        >
          <Box component="div" sx={{ textAlign: 'center' }}>
            <Typography variant="p" fontSize="1.1em">
              {nombre}
            </Typography>
            <Typography variant="p" fontSize="1.1em" marginLeft=".1em">
              {apellido}
            </Typography>
          </Box>
          <Typography variant="p" sx={{ fontSize:".95em", color: "primary.disabled", }}>
            {dni}
          </Typography>
        </Box>
      </Box>
    );
  };
};

export default Usuario;