import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router';

// Componentes
import SearchInput from '../SearchInput/SearchInput';
import Usuario from '../Usuario/Usuario';
import Rotulo from '../Rotulo/Rotulo';
import Pie from '../Pie/Pie';

// Acciones

import { buscarUsuario, restaurarEstado, setPeriodo } from '../../redux/action-types/auditoriaActions';

// MUI Componentes
import { 
  Box,
  Button,
  Divider,
  Container,  
  Grid,
} from '@mui/material';


const BuscarUsuarioAuditoria = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { usuarios } = useSelector(state => state.auditoria);

  const [ usuario, setUsuario ] = useState('');

  const handleChange = (event) => {
    setUsuario(event.target.value);
  };
  
  const handleClick = () => {
    if(usuario.length > 0) dispatch(buscarUsuario({usuario}));
  };

  const handleNavigate = (dni) => {
    dispatch(setPeriodo(7));
    navigate(`/auditoria/usuario/${dni}`);
  };

  const handleAtras = () => {
    navigate('/dashboard');
  };


  useEffect(() => {
    return () => {
      dispatch(restaurarEstado());
    };
  }, [dispatch]);

  return (
    <>
      <Rotulo titulo={"Buscar Usuarios a Auditar"} />
      {/* Contenedor del componente */}
      <Container maxWidth="full" 
        sx={{ 
          width: '100vw',
          height: '90%', 
          display:"flex",
          justifyContent: 'center',
          position: 'absolute',
          top: '10vh',
          marginBottom: '5em',
        }}
      >
        <Grid component="main" 
          sx={{
            width: '50%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '2em',
            marginTop: '2.5em'
          }}
        >
            <Button 
              variant="contained"
              sx={{
                alignSelf: "start",
                fontSize: ".8em",
                fontWeight: "bold",
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
              onClick={handleAtras}
            >
              Dashboard
            </Button>
            <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
          <Box
            sx={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingBottom: '2em',
            }}
          >
            {/* Search Input */}
            <SearchInput handleChange={handleChange} handleClick={handleClick} placeholder={"Ingrese el nombre o dni"} />
            {/* Renderizado de resultados */}
            {
              usuarios && usuarios.map(usuario => {
                return (
                  <Usuario 
                    key={usuario.dni}
                    nombre={usuario.nombre} 
                    apellido={usuario.apellido} 
                    dni={usuario.dni}
                    handleClick={() => handleNavigate(usuario.dni)}
                  />
                )
              })
            }
          </Box>
        </Grid>
      </Container>
      {/* Pie de Pág. */}
      <Pie />
    </>
  );
};

export default BuscarUsuarioAuditoria;