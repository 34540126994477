import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Componentes
import SearchInput from '../SearchInput/SearchInput';
import Rotulo from '../Rotulo/Rotulo';
import Justificacion from './Justificacion';
import Pie from '../Pie/Pie';

// Actions
import { buscar_tipos, buscar_tipos_nombre } from '../../redux/action-types/justificacionesActions'; 

// MUI componentes
import { 
  Button,
  Box,
  CircularProgress,
  Divider,
  Grid,
  useMediaQuery
} from '@mui/material';

const AdministrarJustificaciones = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const matches = useMediaQuery('(min-width:720px)');
  const matches2 = useMediaQuery('(min-width:1050px)');

  const { tipos } = useSelector(state => state.justificaciones);
  const [ justif, setJustif ] = useState('');

  const handleChange = (event) => {
    setJustif(event.target.value);
  };
  
  const handleClick = () => {
    if(justif.length > 0) dispatch(buscar_tipos_nombre({nombre: justif}))
    else dispatch(buscar_tipos());
  };

  const handleCrear = () => {
    navigate('/crear-justificacion');
  };

  const handleEliminadas = () => {
    navigate('/tipos-eliminados');
  };

  const chunkArray = (arr, size) => {
    return Array.from({ length: Math.ceil(arr.length / size) }, (_, index) =>
      arr.slice(index * size, index * size + size)
    );
  };

  useEffect(() => {
    dispatch(buscar_tipos());
  }, [dispatch]);

  if(!tipos) {
    return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={'Administrar Justificaciones'} />
        <CircularProgress 
          sx={{
            width: "auto",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />  
        {/* Componente de Pie de Pag. */}
        <Pie />
      </>
    );
  };

  if (!matches) {
    return (
      <> 
        {/* Rótulo de Página */}
        <Rotulo titulo={'Administrar Justificaciones'} />
        <Grid container spacing={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "0.5vh",
          }}
        >
          <Grid item container xs={12} sm={12} md={12} lg={12} ml={4}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >      
          <Grid container xs={11} sm={10} md={10} lg={6}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around"
                }}>
              <Button variant="contained"
                sx={{
                  marginBottom: '1em',
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleCrear}
              >
                Crear Justificación
              </Button>
              <Button variant="contained"
                sx={{
                  marginBottom: '1em',
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleEliminadas}
              >
                Justificaciones eliminadas
              </Button>
            </Box>
            <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
          </Grid>
          {/* Search Input */}
          <Grid xs={12} sm={12} md={12} lg={12}
          sx={{
            marginBottom: "2vh",
            marginTop: "-3vh"
          }} >
            <SearchInput 
              handleChange={handleChange}
              handleClick={handleClick} 
              placeholder={"Ingrese el codigo o el nombre"}
              />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} pb={6}>
            {
              tipos.map(tipo => {
                return (
                  <Justificacion 
                    key={tipo.id}
                    id={tipo.id}
                    codigo={tipo.codigo}
                    titulo={tipo.titulo}
                    activa={tipo.activo}
                  />
                )
              })
            }
          </Grid>
        </Grid>
        </Grid>
        {/* Componente de Pie de Pag. */}
        <Pie />
      </>
    );
  }
  if (!matches2) {
    const gruposDeDos = chunkArray(tipos, 2);
    return (
      <> 
        {/* Rótulo de Página */}
        <Rotulo titulo={'Administrar Justificaciones'} />
        <Grid container spacing={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "0.5vh",
          }}
        >
          <Grid item container xs={12} sm={12} md={12} lg={12} ml={4}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >      
          <Grid container xs={11} sm={10} md={10} lg={6}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around"
                }}>
              <Button variant="contained"
                sx={{
                  marginBottom: '1em',
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleCrear}
              >
                Crear Justificación
              </Button>
              <Button variant="contained"
                sx={{
                  marginBottom: '1em',
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleEliminadas}
              >
                Justificaciones eliminadas
              </Button>
            </Box>
            <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
          </Grid>
          {/* Search Input */}
          <Grid xs={12} sm={12} md={12} lg={12}
          sx={{
            marginBottom: "2vh",
            marginTop: "-3vh"
          }} >
            <SearchInput 
              handleChange={handleChange}
              handleClick={handleClick} 
              placeholder={"Ingrese el codigo o el nombre"}
              />
          </Grid>
          <Grid container
            sx={{
              justifyContent: "center",
            }}>
          {gruposDeDos.map((grupo, index) => (
            <Grid key={index} item xs={12} sm={12} md={9} lg={7} pb={6}
            sx={{
              display:"flex",
              justifyContent: "space-around",
              marginBottom: "-4vh"
            }}>
              {grupo.map(tipo => (
                <Justificacion
                  key={tipo.id}
                  id={tipo.id}
                  codigo={tipo.codigo}
                  titulo={tipo.titulo}
                  activa={tipo.activo}
                />
              ))}
            </Grid>
          ))}
        </Grid>
        </Grid>
        </Grid>
        {/* Componente de Pie de Pag. */}
        <Pie />
      </>
    );
  }
  const gruposDeTres = chunkArray(tipos, 3);
  return (
    <> 
      {/* Rótulo de Página */}
      <Rotulo titulo={'Administrar Justificaciones'} />
      <Grid container spacing={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: '3vh'
        }}
      >
        <Grid container xs={12} sm={12} md={12} lg={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >      
        <Grid container xs={10} sm={8} md={9} lg={6.8}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around"
              }}>
            <Button variant="contained"
              sx={{
                marginBottom: '1em',
                fontSize: ".8em",
                fontWeight: "bold",
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
              onClick={handleCrear}
            >
              Crear Justificación
            </Button>
            <Button variant="contained"
              sx={{
                marginBottom: '1em',
                fontSize: ".8em",
                fontWeight: "bold",
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
              onClick={handleEliminadas}
            >
              Justificaciones eliminadas
            </Button>
          </Box>
          <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
        </Grid>
        {/* Search Input */}
        <Grid xs={12} sm={12} md={12} lg={12}
          sx={{
            marginBottom: "2vh",
            marginTop: "-3vh"
          }} 
        >
          <SearchInput 
            handleChange={handleChange}
            handleClick={handleClick} 
            placeholder={"Ingrese el codigo o el nombre"}
            />
        </Grid>
        <Grid container
        sx={{
          justifyContent: "center",
        }}>
          {gruposDeTres.map((grupo, index) => (
            <Grid key={index} item xs={12} sm={12} md={9} lg={7} pb={6}
            sx={{
              display:"flex",
              justifyContent: "space-around",
              marginBottom: "-4vh"
            }}>
              {grupo.map(tipo => (
                <Justificacion
                  key={tipo.id}
                  id={tipo.id}
                  codigo={tipo.codigo}
                  titulo={tipo.titulo}
                  activa={tipo.activo}
                />
              ))}
            </Grid>
          ))}
        </Grid>
      </Grid>
      </Grid>
      {/* Componente de Pie de Pag. */}
      <Pie />
    </>
  );
};

export default AdministrarJustificaciones;