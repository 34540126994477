
import { Routes, Route, useNavigate } from "react-router-dom";

// Componentes
import Home from "./components/Home/Home";
import Acceso from "./components/Acceso/Acceso";
import Dashboard from "./components/Dashboard/Dashboard";
import DashboardRoles from "./components/Dashboard/Roles/DashboardRoles";
import BuscarUsuarioAuditoria from "./components/Auditoria/BuscarUsuarioAuditoria";
import RolDetalle from "./components/Dashboard/Roles/RolDetalle";
import AsignarRol from "./components/Dashboard/Roles/AsignarRol";
import EditarDependencias from "./components/Dashboard/Roles/EditarDependencias";
import BuscarUsuario from "./components/BuscarUsuario/BuscarUsuario";
import LinearStepper from "./components/CrearUsuario/Stepper";
import EditarUsuario from "./components/Editar Usario/EditarUsuario";
import Marcaciones from "./components/Marcaciones/Marcaciones";
import ListadoRelojes from "./components/Relojes/ListadoRelojes";
import CrearReloj from "./components/Relojes/CrearReloj";
import RelojDetalle from "./components/Relojes/RelojDetalle";
import EditarReloj from "./components/Relojes/EditarReloj";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import CrearDependencia from "./components/Dependencias/CrearDependencia";
import BuscarDependencia from "./components/Dependencias/BuscarDependencias";
import DetalleDependencia from "./components/Dependencias/DetalleDependencia";
import EditarDependencia from "./components/Dependencias/EditarDependencia";
import EditarHorario from "./components/CrearUsuario/Components/EditarHorario";
import CrearTipoJustificacion from "./components/Justificaciones/CrearTipoJustificacion";
import DetalleTipoJustificacion from "./components/Justificaciones/DetalleTipoJustificacion";
import DetalleCargo from "./components/Cargos/DetalleCargo.jsx";
import EditarTipoJustificacion from "./components/Justificaciones/EditarTipoJustificacion";
import UsuariosDependencia from "./components/Dependencias/UsuariosDependencia";
import BuscarMarcaciones from "./components/Marcaciones/BuscarMarcaciones";
import AdministrarJustificaciones from "./components/Justificaciones/AdministrarJustificaciones";
import AdministrarCargos from "./components/Cargos/AdministrarCargos.jsx";
import JustificacionesPersonales from "./components/Justificaciones/JustificacionesPersonales";
import CrearCargo from "./components/Cargos/CrearCargo.jsx";
import CrearHorarioNuevo from "./components/CrearUsuario/Components/CrearHorarioNuevo.jsx";
import DetalleJustificacion from "./components/Justificaciones/DetalleJustificacion";
import EditarCargo from "./components/Cargos/EditarCargo.jsx";
import EditarJustificacionPersonal from "./components/Justificaciones/EditarJustificacionPersonal";
import EditarJustificacionGeneral from "./components/Justificaciones/EditarJustificacionGeneral";
import CrearJustificacionPersonal from "./components/Justificaciones/CrearJustificacionPersonal";
import JustificacionesGenerales from "./components/Justificaciones/JustificacionesGenerales";
import PerfilUsuario from "./components/PerfilUsuario/PerfilUsuario";
import Error404 from "./components/Error404/Error404";
import Designaciones from "./components/Designaciones/Designaciones";
import EditarDesignacion from "./components/Designaciones/EditarDesignacion";
import EditarHistorica from "./components/Designaciones/EditarHistorica";
import ReporteGeneral from "./components/MarcacionesGenerales/ReporteGeneral";
import BuscarDependenciaReporte from "./components/MarcacionesGenerales/BuscarDependencias";
import CambiarContraseña from "./components/Contraseña/cambiarContraseña.jsx";
import TiposEliminados from "./components/Justificaciones/TiposEliminados.jsx";
import TablaAuditado from "./components/Auditoria/TablaAuditado.jsx";
import AuditoriaUsuarios from "./components/Auditoria/AuditoriaUsuarios.jsx";
import AuditoriaHorarios from "./components/Auditoria/AuditoriaHorarios.jsx";
import AuditoriaDesignaciones from "./components/Auditoria/AuditoriaDesignaciones.jsx";
import AuditoriaJustificaciones from "./components/Auditoria/AuditoriaJustificaciones.jsx";
import AuditoriaJustificacionesGenerales from "./components/Auditoria/AuditoriaJustificacionesGenerales.jsx";
import DetalleDesignacion from "./components/Designaciones/DetalleDesignacion.jsx";
import AuditoriaUsuario from "./components/PerfilUsuario/AuditoriaUsuario.jsx";
import AuditoriaHorario from "./components/PerfilUsuario/Horarios/AuditoriaHorario.jsx";
import RolesSistema from "./components/Dashboard/Roles/RolesSistema.jsx";
import BuscarUsuarioVacacion from "./components/Vacaciones/BuscarUsuarioVacacion.jsx";

// MUI Styles Baseline
import CssBaseline from '@mui/material/CssBaseline';

import globalRouter from "./globalRouter.ts";

// MUI Componentes
import {
  Grid,
} from '@mui/material';
import VacacionesUsuario from "./components/Vacaciones/VacacionesUsuario.jsx";
import VacacionesHistoricasUsuario from "./components/Vacaciones/VacacionesHistoricasUsuario.jsx";
import UsuariosSinVacaciones from "./components/Vacaciones/UsuariosSinVacaciones.jsx";

function App() {
  const navigate = useNavigate();
  globalRouter.navigate = navigate;

  return (
    <>
      <CssBaseline />
      <Grid >
        <Routes>
          <Route exact path="/" element={<PrivateRoute> <Home /> </PrivateRoute>} />
          <Route exact path='/acceso' element={<Acceso />} />
          <Route exact path="/buscar-usuario-auditoria" element={<PrivateRoute permission={'super_usuario'}> <BuscarUsuarioAuditoria /> </PrivateRoute>} />
          <Route exact path='/dashboard' element={<PrivateRoute permission={'super_usuario'}> <Dashboard /> </PrivateRoute>} />
          <Route exact path='/roles' element={<PrivateRoute permission={'super_usuario'}> <DashboardRoles /> </PrivateRoute>} />
          <Route exact path='/asignar-rol/:dni' element={<PrivateRoute permission={'super_usuario'}> <AsignarRol /> </PrivateRoute>} />
          <Route exact path='/rol/usuario/:dni' element={<PrivateRoute permission={'super_usuario'}> <RolDetalle /> </PrivateRoute>} />
          <Route exact path='/auditoria/usuario/:dni' element={<PrivateRoute permission={'super_usuario'}> <TablaAuditado /> </PrivateRoute>} />
          <Route exact path='/auditoria/usuario/:dni/usuarios-creados' element={<PrivateRoute permission={'super_usuario'}> <AuditoriaUsuarios /> </PrivateRoute>} />
          <Route exact path='/auditoria/usuario/:dni/usuarios-editados' element={<PrivateRoute permission={'super_usuario'}> <AuditoriaUsuarios /> </PrivateRoute>} />
          <Route exact path='/auditoria/usuario/:dni/usuarios-eliminados' element={<PrivateRoute permission={'super_usuario'}> <AuditoriaUsuarios /> </PrivateRoute>} />
          <Route exact path='/auditoria/usuario/:dni/horarios-creados' element={<PrivateRoute permission={'super_usuario'}> <AuditoriaHorarios /> </PrivateRoute>} />
          <Route exact path='/auditoria/usuario/:dni/horarios-editados' element={<PrivateRoute permission={'super_usuario'}> <AuditoriaHorarios /> </PrivateRoute>} />
          <Route exact path='/auditoria/usuario/:dni/designaciones-creadas' element={<PrivateRoute permission={'super_usuario'}> <AuditoriaDesignaciones /> </PrivateRoute>} />
          <Route exact path='/auditoria/usuario/:dni/designaciones-editadas' element={<PrivateRoute permission={'super_usuario'}> <AuditoriaDesignaciones /> </PrivateRoute>} />
          <Route exact path='/auditoria/usuario/:dni/designaciones-eliminadas' element={<PrivateRoute permission={'super_usuario'}> <AuditoriaDesignaciones /> </PrivateRoute>} />
          <Route exact path='/auditoria/usuario/:dni/justificaciones-creadas' element={<PrivateRoute permission={'super_usuario'}> <AuditoriaJustificaciones /> </PrivateRoute>} />
          <Route exact path='/auditoria/usuario/:dni/justificaciones-editadas' element={<PrivateRoute permission={'super_usuario'}> <AuditoriaJustificaciones /> </PrivateRoute>} />
          <Route exact path='/auditoria/usuario/:dni/justificaciones-eliminadas' element={<PrivateRoute permission={'super_usuario'}> <AuditoriaJustificaciones /> </PrivateRoute>} />
          <Route exact path='/auditoria/usuario/:dni/justificaciones-generales-creadas' element={<PrivateRoute permission={'super_usuario'}> <AuditoriaJustificacionesGenerales /> </PrivateRoute>} />
          <Route exact path='/auditoria/usuario/:dni/justificaciones-generales-editadas' element={<PrivateRoute permission={'super_usuario'}> <AuditoriaJustificacionesGenerales /> </PrivateRoute>} />
          <Route exact path='/auditoria/usuario/:dni/justificaciones-generales-eliminadas' element={<PrivateRoute permission={'super_usuario'}> <AuditoriaJustificacionesGenerales /> </PrivateRoute>} />
          <Route exact path='/editar-dependencias/:dni/:id' element={<PrivateRoute permission={'super_usuario'}> <EditarDependencias /> </PrivateRoute>} />
          <Route exact path='/detalle-perfil/:dni' element={<PrivateRoute permission={'super_usuario'}> <AuditoriaUsuario /> </PrivateRoute>} />
          <Route exact path='/detalle-horario/:dni' element={<PrivateRoute permission={'super_usuario'}> <AuditoriaHorario /> </PrivateRoute>} />
          <Route exact path='/perfil/:dni' element={<PrivateRoute> <PerfilUsuario /> </PrivateRoute>} />
          <Route exact path='/crear-usuario' element={<PrivateRoute permission={'usuario_administrador'}> <LinearStepper /> </PrivateRoute>} />
          <Route exact path='/editar-perfil/:dni' element={<PrivateRoute permission={'usuario_administrador'}> <EditarUsuario /> </PrivateRoute>} />
          <Route exact path='/editar-horario/:dni' element={<PrivateRoute permission={'usuario_administrador'}> <EditarHorario /> </PrivateRoute>} />
          <Route exact path='/crear-horario/:dni' element={<PrivateRoute permission={'usuario_administrador'}> <CrearHorarioNuevo /> </PrivateRoute>} />
          <Route exact path="/buscar-usuario" element={<PrivateRoute permission={'usuario_consulta'}> <BuscarUsuario /> </PrivateRoute>} />
          <Route exact path="/buscar-lugar" element={<PrivateRoute permission={'usuario_consulta'}> <BuscarDependencia /> </PrivateRoute>} />
          <Route exact path='/reportes' element={<PrivateRoute permission={'usuario_consulta'}> <BuscarMarcaciones /> </PrivateRoute>} />
          <Route exact path='/marcaciones/:dni' element={<PrivateRoute> <Marcaciones /> </PrivateRoute>} />
          <Route exact path='/administrar-justificaciones' element={<PrivateRoute permission={'usuario_administrador_privilegiado'}> <AdministrarJustificaciones /> </PrivateRoute>} />
          <Route exact path='/administrar-cargos' element={<PrivateRoute permission={'super_usuario'}> <AdministrarCargos /> </PrivateRoute>} />
          <Route exact path='/editar-cargo/:codigo' element={<PrivateRoute permission={'super_usuario'}> <EditarCargo /> </PrivateRoute>} />
          <Route exact path='/crear-justificacion' element={<PrivateRoute permission={'usuario_administrador_privilegiado'}> <CrearTipoJustificacion /> </PrivateRoute>} />
          <Route exact path='/administrar-justificaciones/tipo/:id' element={<PrivateRoute permission={'usuario_administrador_privilegiado'}> <DetalleTipoJustificacion /> </PrivateRoute>} />
          <Route exact path='/administrar-cargos/codigo/:codigo' element={<PrivateRoute permission={'super_usuario'}> <DetalleCargo /> </PrivateRoute>} />
          <Route exact path='/administrar-justificaciones/editar/:id' element={<PrivateRoute permission={'usuario_administrador_privilegiado'}> <EditarTipoJustificacion /> </PrivateRoute>} />
          <Route exact path='/justificaciones-personales' element={<PrivateRoute permission={'usuario_consulta'}> <JustificacionesPersonales /> </PrivateRoute>} />
          <Route exact path='/justificaciones-personales/:dni' element={<PrivateRoute permission={'usuario_consulta'}> <CrearJustificacionPersonal /> </PrivateRoute>} />
          <Route exact path='/justificaciones-personales/:dni/:dia' element={<PrivateRoute permission={'usuario_administrador'}> <CrearJustificacionPersonal /> </PrivateRoute>} />
          <Route exact path='/detalle-justificacion/:dni/:id' element={<PrivateRoute permission={'usuario_consulta'}> <DetalleJustificacion /> </PrivateRoute>} />
          <Route exact path='/editar-justificacion-personal/:dni/:dia' element={<PrivateRoute permission={'usuario_administrador'}> <EditarJustificacionPersonal /> </PrivateRoute>} />
          <Route exact path='/editar-justificacion-general/:dia' element={<PrivateRoute permission={'usuario_administrador'}> <EditarJustificacionGeneral /> </PrivateRoute>} />
          <Route exact path='/justificaciones-generales' element={<PrivateRoute permission={'usuario_consulta'}> <JustificacionesGenerales /> </PrivateRoute>} />
          <Route exact path='/dependencia/:id' element={<PrivateRoute permission={'usuario_consulta'}> <DetalleDependencia /> </PrivateRoute>} />
          <Route exact path='/crear-lugar' element={<PrivateRoute permission={'super_usuario'}> <CrearDependencia /> </PrivateRoute>} />
          <Route exact path='/crear-cargo' element={<PrivateRoute permission={'super_usuario'}> <CrearCargo /> </PrivateRoute>} />
          <Route exact path='/editar-dependencia/:id' element={<PrivateRoute permission={'super_usuario'}> <EditarDependencia /> </PrivateRoute>} />
          <Route exact path='/usuarios-dependencia/:id' element={<PrivateRoute permission={'usuario_consulta'}> <UsuariosDependencia /> </PrivateRoute>} />
          <Route exact path='/relojes' element={<PrivateRoute permission={'super_usuario'}> <ListadoRelojes /> </PrivateRoute>} />
          <Route exact path='/crear-reloj' element={<PrivateRoute permission={'super_usuario'}> <CrearReloj /> </PrivateRoute>} />
          <Route exact path='/relojes/reloj/:id' element={<PrivateRoute permission={'super_usuario'}> <RelojDetalle /> </PrivateRoute>} />
          <Route exact path='/relojes/reloj/:id/editar' element={<PrivateRoute permission={'super_usuario'}> <EditarReloj /> </PrivateRoute>} />
          <Route exact path='/designaciones/:dni' element={<PrivateRoute permission={'usuario_consulta'}> <Designaciones /> </PrivateRoute>} />
          <Route exact path='/designacion/:id' element={<PrivateRoute permission={'usuario_administrador'}><EditarDesignacion /></PrivateRoute>} />
          <Route exact path='/historial-designacion/:id' element={<PrivateRoute permission={'super_usuario'}><DetalleDesignacion /></PrivateRoute>} />
          <Route exact path='/historica/:id' element={<PrivateRoute permission={'usuario_administrador'}><EditarHistorica /></PrivateRoute>}/>
          <Route exact path='/reportes-generales' element={<PrivateRoute permission={'usuario_consulta'}><BuscarDependenciaReporte /></PrivateRoute>}/>
          <Route exact path='/reportes-generales/:id/:modo' element={<PrivateRoute permission={'usuario_consulta'}><ReporteGeneral /></PrivateRoute>}/>
          <Route exact path='/cambiar-contraseña' element={<PrivateRoute> <CambiarContraseña /> </PrivateRoute>} />
          <Route exact path='/tipos-eliminados' element={<PrivateRoute permission={'usuario_administrador_privilegiado'}> <TiposEliminados /> </PrivateRoute>} />
          <Route exact path='/roles-sistema' element={<PrivateRoute permission={'super_usuario'}><RolesSistema /></PrivateRoute>} />
          <Route exact path="/vacaciones-usuario" element={<PrivateRoute permission={'usuario_administrador'}> <BuscarUsuarioVacacion/> </PrivateRoute>} />
          <Route exact path='/vacaciones/:dni' element={<PrivateRoute permission={'usuario_administrador'}><VacacionesUsuario /></PrivateRoute>}/>
          <Route exact path='/vacaciones-historicas/:dni' element={<PrivateRoute permission={'usuario_administrador'}><VacacionesHistoricasUsuario /></PrivateRoute>}/>
          <Route exact path='/usuarios-sin-vacaciones' element={<PrivateRoute permission={'super_usuario'}><UsuariosSinVacaciones /></PrivateRoute>} />
          <Route path='*' element={<Error404 />} />
        </Routes>
      </Grid>
    </>
  );
};

export default App;
