import React from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';


// MUI componentes
import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
} from '@mui/material';

// Utils
import { obtenerFecha } from '../../../utils/obtenerFecha';



const ListadoAuditoria = () => {

    const navigate = useNavigate();

    const handleClickCreador = (dni, creador) => {
        window.sessionStorage.setItem('urlAnterior', `/detalle-perfil/${dni}`);
        navigate(`/perfil/${creador}`);
        };


  const { historial_versiones } = useSelector(state => state.auditoria);

  return (
    <>       
    <Grid item xs={10} sm={10} md={12} lg={12}>
    <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
        <Table aria-label="simple table"> 
        <TableHead key={"head"}>
            <TableRow key={"0"} sx={{bgcolor:"auditory.main"}}>
            <TableCell key={"day"} align="center">Dia</TableCell>
            <TableCell key={"enter"} align="center">Entrada</TableCell>
            <TableCell key={"exit"} align='center'>Salida</TableCell>
            <TableCell key={"hours"} align='center'>Horas</TableCell>
            <TableCell key={"modified_by"} align="center">Modificado por</TableCell>
            <TableCell key={"modified_at"} align="center">Fecha modificacion</TableCell>
            <TableCell key={"modified_at_hour"} align="center">Hora modificacion</TableCell>
            </TableRow>
        </TableHead>
        {
            <TableBody>
            {
                historial_versiones && historial_versiones.horarios.map(horario => {
                
                return (
                    <TableRow
                    key={`$${horario.updated_at}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell key={"dia"} align="left">{horario.dia}</TableCell>
                    <TableCell key={"entrada"} align="left">{horario.entrada}</TableCell>
                    <TableCell key={"salida"} align='center'>{horario.salida}</TableCell>
                    <TableCell key={"horas"} align='center'>{horario.horas}</TableCell>        
                    <TableCell key={"modificadopor"} align="center" 
                        sx={{
                        '&:hover': {
                            cursor: 'pointer',
                            borderRadius: '.5em',
                            boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                        }
                        }}
                        onClick={() => handleClickCreador(horario.usuario_dni, horario.updated_by)}
                    >
                        {horario.modificador}
                    </TableCell>
                    <TableCell key={"fecha_modificado"} align="center">{obtenerFecha(horario.created_at).fecha}</TableCell>
                    <TableCell key={"hora_modificado"} align="center">{obtenerFecha(horario.created_at).horario}</TableCell>
                    
                    </TableRow>
                );
                })
            }
            </TableBody>
            }
            </Table>
        </TableContainer>
        </Grid>        
    </>
  );
};

export default ListadoAuditoria;
