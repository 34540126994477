import React from 'react';

import { Box, Grid } from '@mui/material';

import Derechos from './Derechos/Derechos.jsx';

import palette from '../../theme/lightPalette.js';

const Pie = ({margen = true}) => {
  return (
    <> 
      <Box sx={{ marginTop: margen ? '10vh':'2vh'}}></Box>
      <Box 
        container="true"
        width='100%'
        height='3em'
        position='fixed'
        bottom={0}
        left={0}
        bgcolor="primary.light"
        zIndex={1}
      >
        <Grid 
          container
          width='100%'
          height='100%'
          direction='column'
          justifyContent='center'
          alignItems='center'
          color={palette.text.secondary}
        >
          <Derechos />
        </Grid>
      </Box>
    </>
  );
};

export default Pie;