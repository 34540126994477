import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';

// Componentes
import ModalComponent from '../ModalComponent/ModalComponent';
import Pie from '../Pie/Pie';

// Acciones
import { acceso, restaurarMensajes } from '../../redux/action-types/accesoActions';

// MUI componentes
import { 
  Avatar,
  Grid, 
  Box, 
  Button, 
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography, 
} from '@mui/material';

// Imagen
import logounlp from "../../images/logounlp.png";
import fondo from "../../images/fondo.png"

// MUi iconos
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const NUMBER = /^[0-9]+$/;

const validate = (state) => {
  let errors = {};

  if(state.dniInput === '') {
    errors.dniInput = '';
  } else if(state.dniInput.length < 8) {
    errors.dniInput = 'El usuario es inválido...';
  } else if(!NUMBER.test(state.dniInput)) {
    errors.dniInput = 'Solo se aceptan numeros...';
  };

  if(state.claveInput === '') {
    errors.claveInput = '';
  } else if(state.claveInput.length < 4) {
    errors.claveInput = 'Contraseña inválida...';
  };

  return errors;
};

const initialState = {
  dniInput: '',
  claveInput: '',
  recordarInput: false,
};

const Acceso = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mensaje } = useSelector(state => state.acceso);

  const [ showPassword, setShowPassword ] = useState(false);

  const [ state, setState ] = useState(initialState);
  const [ errors, setErrors ] = useState(initialState);
  const [ isProcessing, setIsProcessing ] = useState(false);
  
  const [ isOpenModal, openModal, closeModal ] = useModal();
  
  let logeado = window.localStorage.getItem("isAuth");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setErrors(validate({
      ...state,
      [name]: value
    }));
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsProcessing(true);
    openModal();
    dispatch(acceso(state))
    .then(() => {
      setIsProcessing(false);
      setState(initialState);
      setErrors(initialState);
    });
    dispatch(restaurarMensajes());
  };

  useEffect(() => {
    if(!!logeado) {
      setTimeout(() => {
        navigate('/');
      }, 2000);
    };
  }, [dispatch, logeado, navigate, state]);

  return (
    <>   
      <Grid maxWidth='full'
        sx={{
          height: '98vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',          
          backgroundImage: `url(${fondo})`,
          width: '100%', 
          backgroundSize: 'cover', 
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center', // Ajusta la posición vertical aquí
          backgroundPositionY: '-10px',
          overflow: "hidden" // Si necesitas ocultar el desbordamiento
        }}
      >
        <Grid item container xs={9} sm={7} md={5} lg={3}
          sx={{
            display: 'flex',
            width: '100%',
            maxHeight: '550px',
            justifyContent: 'center',
            margin: '1em',
            bgcolor: 'background.paper',
            borderRadius: '.3em',
            boxShadow: '1px 1px 5px 5px rgba(40,40,40,0.4)',
          }}
        >
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", mt: '1em' }}>
            <Avatar sx={{ m: 1, bgcolor: 'primary.dark' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Iniciar Sesión
            </Typography>
          </Box>

          <Box component="form" noValidate 
            sx={{ 
              display: 'flex',
              width: '80%',
              flexDirection: 'column',
              m: 3,
            }}
            onSubmit={handleSubmit} 
          >
              <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
                <Typography fontWeight={"bold"}>
                  Usuario:
                </Typography>
                <TextField 
                  id='dniInput' 
                  name="dniInput"
                  type='text'
                  value={state.dniInput}
                  placeholder="Ingresa tu usuario..." 
                  error={!!errors.dniInput}
                  onChange={handleChange}
                  inputlabelprops={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                />
                {
                  errors.dniInput && 
                  <FormHelperText
                    sx={{
                      color: "red",
                    }}
                  >
                    {errors.dniInput}
                  </FormHelperText>
                }
              </Box>

              <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
                <Typography fontWeight={"bold"}>
                  Contraseña:
                </Typography>
                <FormControl variant="outlined">
                  <OutlinedInput 
                    id='claveInput' 
                    name="claveInput"
                    type={showPassword ? 'text' : 'password'}
                    value={state.claveInput}
                    placeholder='Ingresa tu contraseña...'
                    error={!!errors.claveInput}
                    onChange={handleChange}
                    inputlabelprops={{
                      sx: {
                        color: 'rgb(150, 150, 150)',
                      }
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    />
                </FormControl>
                {
                  errors.claveInput && 
                  <FormHelperText
                    sx={{
                      color: "red",
                    }}
                  >
                    {errors.claveInput}
                  </FormHelperText>
                }
              </Box>

              <Box sx={{display: "flex", alignItems: 'center', alignSelf: 'center', marginBottom:"1em"}} absolute="true">
                <Checkbox 
                  name="recordarInput"
                  label="Recordar Contraseña" 
                  size="medium"  
                  onChange={() => setState({...state, recordarInput: !state.recordarInput})}
                  sx={{
                    color:"primary.main"
                  }}
                />
                <Typography variant="p"
                  sx={{
                    position: "relative",
                    top: "2px",
                  }}
                >
                  Recordar Contraseña
                </Typography>
              </Box>

              <Box marginTop={2} sx={{alignSelf: 'center',}}>
                {
                  ((!errors.dniInput && !errors.claveInput)
                  &&
                  (errors.dniInput !== '' && errors.claveInput !== ''))
                  ?
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        fontSize: ".8em",
                        fontWeight: "bold",
                        bgcolor: 'background.paper',
                        '&:hover': {
                          bgcolor: 'background.paper',
                          transform: 'scale(1.01)',
                        }
                      }}
                    >
                      Acceder
                    </Button>
                  :
                    <Button
                      type="submit"
                      disabled
                      variant="contained"
                      sx={{
                        fontSize: ".8em",
                        fontWeight: "bold",
                        bgcolor: 'background.paper',
                        '&:hover': {
                          bgcolor: 'background.paper',
                          transform: 'scale(1.01)',
                        }
                      }}
                    >
                      Acceder
                    </Button>
                }
              </Box>
          </Box>
          
          <Box
            component="img"
            sx={{
              height: 'auto',
              width: { xs: 150, sm: 180, md: 180, lg: 180 },
              marginBottom: 3
            }}
            alt="Logo UNLP."
            src={logounlp}
          />
        </Grid>
      </Grid>
      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {mensaje}
        </Typography>
        {
          logeado ?
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Redireccionando...
            </Typography>
          :
            isProcessing ? 
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress size={30}/>
              </Box>
          :
            null
        }
      </ModalComponent>
      {/* Pie de Pág. */}
      <Pie margen={false}/>
    </>  
  );
};

export default Acceso;