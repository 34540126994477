import { createSlice } from '@reduxjs/toolkit';

import { ordenarPorNombre } from '../../utils/ordenarPorNombre'; 

const initialState = {
  dependencias: [],
};

export const dependenciaSlice = createSlice({
  name: 'dependenciaReducer',
  initialState,
  reducers: {
    crear: (state, { payload }) => {
      return {
        ...state,
        status: payload.status,
        text: payload.text,
      };
    },
    buscar: (state, { payload }) => {
      return {
        ...state,
        dependencias: payload.dependencias ? ordenarPorNombre(payload.dependencias, 'name') : payload.dependencias,
        mensaje: payload.mensaje,
      };
    },
    buscarCabeceras: (state, { payload }) => {
      return {
        ...state,
        cabeceras: payload.cabeceras ? ordenarPorNombre(payload.cabeceras, 'name') : payload.cabeceras,
        mensaje: payload.mensaje,
      };
    },
    buscar_hijas: (state, { payload }) => {
      return {
        ...state,
        padre: payload.padre,
        hijas: payload.dependencias ? ordenarPorNombre(payload.dependencias, 'name') : null,
      };
    },
    buscar_solo_hijas: (state, { payload }) => {
      return {
        ...state,
        hijas: payload.dependencias ? ordenarPorNombre(payload.dependencias, 'name') : null,
      };
    },
    buscar_id: (state, { payload }) => {
      return {
        ...state,
        dependencia: payload.data,
        mensaje: payload.mensaje,
      };
    },
    padre: (state, { payload }) => {
      return {
        ...state,
        padre: payload.data,
      };
    },
    editar: (state, { payload }) => {
      return {
        ...state,
        status: payload.status,
        text: payload.text,
      };
    },
    restaurar: (state) => {
      return {
        ...state,
        dependencias: [],
        hijas: [],
        padre: ''
      };
    },
    restaurar_hijas: (state) => {
      return {
        ...state,
        hijas: [],
        padre: ''
      };
    },
    restaurar_mensajes: (state) => {
      return {
        ...state,
        status: '',
        text: '',
      };
    },
  },
});

export const { crear, buscar, buscarCabeceras,buscar_hijas, buscar_solo_hijas, buscar_id, editar, padre, restaurar, restaurar_hijas, restaurar_mensajes } = dependenciaSlice.actions;

export default dependenciaSlice.reducer;
