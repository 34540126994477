import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

// Componentes
import SearchInput from '../SearchInput/SearchInput';
import Dependencia from './Dependencia';
import Rotulo from '../Rotulo/Rotulo';
import Pie from '../Pie/Pie';

// Acciones
import { buscarDependencia, restaurarEstado } from '../../redux/action-types/dependenciaActions';

// MUI Componentes
import { 
  Box,
  Container,  
  Grid,
} from '@mui/material';

const BuscarDependencia = () => {
  const dispatch = useDispatch();

  const { dependencias } = useSelector(state => state.dependencia);

  const [ dependencia, setDependencia ] = useState('');

  const handleChange = (event) => {
    setDependencia(event.target.value);
  };
  
  const handleClick = () => {
    if(dependencia.length > 0) dispatch(buscarDependencia({name: dependencia}));
  };
  
  useEffect(() => {
    window.sessionStorage.setItem('urlAnterior', `/buscar-lugar`);

    return () => {
      dispatch(restaurarEstado());
    };
  }, [dispatch]);
  
  return (
    <>
      <Rotulo titulo={"Buscar Dependencias"} />
      {/* Contenedor del componente */}
      <Container maxWidth="full" 
        sx={{ 
          width: '100vw',
          height: '90%', 
          display:"flex",
          justifyContent: 'center',
          position: 'absolute',
          top: '10vh',
          marginBottom: '5em',
        }}
      >
        <Grid component="main" 
          sx={{
            width: '50%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '2em',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingBottom: '2em',
            }}
          >
            {/* Search Input */}
            <SearchInput handleChange={handleChange} handleClick={handleClick} placeholder={"Ingrese el lugar"} />
            {/* Renderizado de resultados */}
            {
              dependencias && dependencias.map(dependencia => {
                return (
                  <Dependencia 
                    key={dependencia.id}
                    id={dependencia.id}
                    nombre={dependencia.name} 
                  />
                )
              })
            }
          </Box>
        </Grid>
      </Container>
      {/* Pie de Pág. */}
      <Pie />
    </>
  );
};

export default BuscarDependencia;
