import React from 'react';
import { useNavigate } from 'react-router-dom';

// MUI componentes
import { 
  Box,
  Container,
  Typography,
} from '@mui/material';

// MUI iconos
import AssignmentIcon from '@mui/icons-material/Assignment';

const Cargo = ({ descripcion, codigo, imagen }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/administrar-cargos/codigo/${codigo}`);
  };

  return (
    <Container component="container"
      sx={{
        width: '18vw',
        minWidth: '280px',
        height: '5em',
        display:"flex",
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '.5em',
        boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)',
        bgcolor: 'background.paper',
        borderRadius: '.5em',
        marginBottom: '1em',
        '&:hover': {
          cursor: 'pointer',
          transform: 'scale(1.01)',
        }
      }}
      onClick={handleClick}
    >
      <Box 
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {
          imagen ? 
          <img src={imagen} alt={`Imagen`} /> 
          : 
          <AssignmentIcon sx={{ fontSize:"2em", color:"primary.light", mr: ".5em"}} />
        }
      </Box>
      <Box 
        sx={{
          width: '75%',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="p" fontSize="1.1em">
            {codigo} 
          </Typography>
          <Typography variant="p" sx={{ fontSize:".95em", color: "primary.disabled", }}>
            {descripcion}
          </Typography>
        </Box>
      </Box>  
    </Container>
  );
};

export default Cargo;