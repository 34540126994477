import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  usuario_vacacion: [],
  mensaje: ''
};

export const vacacionesSlice = createSlice({
  name: 'relojReducer',
  initialState,
  reducers: {
    vacacion_por_dni: (state, { payload }) => {
      return {
        ...state,
        usuario_vacacion: payload.vacacion,
        dias_tomados: payload.dias_tomados,
        viejas_tomadas: payload.viejas_tomadas
      };
    },
    historial_por_dni: (state, { payload }) => {
      return {
        ...state,
        historial: payload.data,
      };
    },
    historial_completo_por_dni: (state, { payload }) => {
      return {
        ...state,
        historial_completo: payload.data,
      };
    },
    usuarios_vacaciones: (state, { payload }) => {
      return {
        ...state,
        usuarios: payload.usuarios,
      };
    },
    mensaje_vacaciones: (state, { payload }) => {
        return {
          ...state,
          mensaje: payload.mensaje,
        };
      },
    mensaje_status: (state, { payload }) => {
      return {
        ...state,
        status: payload.status,
        mensaje: payload.mensaje,
      };
    },
    editar: (state, { payload }) => {
      return {
        ...state,
        status: payload.status,
        mensaje: payload.mensaje,
      };
    },
    restaurar: (state, { payload }) => {
    return {
        ...state,
        mensaje: '',
        status: ''
    };
    },
  },
});

export const { vacacion_por_dni, mensaje_vacaciones, mensaje_status,historial_por_dni, historial_completo_por_dni, usuarios_vacaciones, editar,restaurar } = vacacionesSlice.actions;

export default vacacionesSlice.reducer;