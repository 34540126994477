import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useModal } from '../../hooks/useModal';
import dayjs from 'dayjs';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import ModalComponent from '../ModalComponent/ModalComponent';
import Pie from '../Pie/Pie';

//Actions
import { informacionCreacion } from '../../redux/action-types/usuarioActions';
import { buscar_por_id, editar_designacion, restaurar_mensajes } from '../../redux/action-types/designacionActions';
import { cargo_por_tipo } from '../../redux/action-types/cargoActions';
import { buscar_dependencias_hijas, buscar_cabecera_padre, restaurarEstado } from '../../redux/action-types/dependenciaActions';

// MUI componentes
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers';
import { inputLabelClasses } from "@mui/material/InputLabel";

//Icons
import InfoIcon from '@mui/icons-material/Info';
import SchemaIcon from '@mui/icons-material/Schema';

const initialState = {
  fecha_inicioInput: '',
  cargo_Input: '',
  dependencia_Input: '',
  tipo_contratoInput: '',
  hijo_Input: ''
};

const EditarDesignacion = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id } = useParams();

    const { designacion, status, mensaje } = useSelector(state => state.designaciones);
    const { dependencias, tipo_cargos } = useSelector(state => state.usuario);
    const { cargos } = useSelector(state => state.cargos)
    const { hijas, padre } = useSelector(state => state.dependencia);

    const dniActual  = useSelector(state => state.acceso.dni);

    const [ state, setState ] = useState(initialState);

    const [isOpenModal, openModal, closeModal] = useModal();

    const [datosObtenidos, setDatosObtenidos] = useState(false);

    const handleChange = event => {
      const { name, value } = event.target;
      setState({
        ...state,
        [name]: value,
      });
    };

    const handleChangePadre = event => {
      const { name, value } = event.target;
      setState({
        ...state,
        [name]: value,
        hijo_Input: ''
      });
      dispatch(buscar_dependencias_hijas(value));
    };

    const handleChangeTipo = event => {
      const { name, value } = event.target;
      setState({
        ...state,
        [name]: value,
        cargo_Input: ''
      });
      dispatch(cargo_por_tipo(value))
    };

    const handleClick = () => {
      navigate(window.sessionStorage.getItem('urlAnterior'));
      dispatch(restaurar_mensajes());
      dispatch(restaurarEstado());
    };

    const handleSubmit = event => {
      event.preventDefault();
      const data = {...state, designacion_id: id, modificado_por: dniActual, titular_designacion: designacion.usuario_dni};
      dispatch(editar_designacion(data));
      openModal();
      setTimeout(() => {
        dispatch(restaurar_mensajes());
        navigate(window.sessionStorage.getItem('urlAnterior'));
        //navigate(`/detalle-justificacion/${justificacion.creado_por_dni}/${id}`);
      }, 2000);
      setState(initialState);
    };

    useEffect(() => {
      if(padre){
        setState((state) => ({
          ...state,
          dependencia_Input: padre.id
        }));
        dispatch(buscar_dependencias_hijas(padre.id))
      };
    }, [dispatch, padre, designacion])

    useEffect(() => {
      dispatch(buscar_por_id(id));
      dispatch(informacionCreacion());
    }, [dispatch, id]);

    useEffect(() => {        
        if (designacion){
          setState((state) => ({
            ...state,
            fecha_inicioInput: designacion.fecha_inicio,
            cargo_Input: designacion.cargo ? designacion.cargo.codigo : '',
            tipo_contratoInput: designacion.cargo ? designacion.cargo.tipo : '',
          }));
          if(designacion.dependency.cabecera){
            setState((state) => ({
              ...state, 
              dependencia_Input: designacion.dependency_id
            }));
            dispatch(buscar_dependencias_hijas(designacion.dependency_id))
          }
          else{            
            setState((state) => ({
              ...state,
              hijo_Input: designacion.dependency_id
            }));
            dispatch(buscar_cabecera_padre(designacion.dependency_id))
          }
          if(designacion.cargo){dispatch(cargo_por_tipo(designacion.cargo.tipo))};
          setDatosObtenidos(true);
        };

    }, [dispatch, id, designacion]);

    if(!datosObtenidos || cargos.lenght <1) {
        return (
          <>
            {/* Rótulo de Página */}
            <Rotulo titulo={'Editar designacion'} />
            <CircularProgress
              sx={{
                width: "auto",
                height: "auto",
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            />
            {/* Copmponente de Pie de Pag. */}
            <Pie />
        </>
        );
    };

    return (
        <>
          {/* Rótulo de Página */}
          <Rotulo titulo={'Editar Designacion'} />
          {/* Aca arranca el componente Editar designacion */}
          <Grid item container xs={12} sm={12} md={12} lg={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid id={"editarDesignacion"}  container xs={10} sm={10} md={8} lg={8} component="form" noValidate onSubmit={handleSubmit} spacing={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "3vh",
            }}
          >
            {/* Item 1 */}
            <Grid item xs={10} sm={11} md={12} lg={12}>

              <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "0.5em"}}>
              <InfoIcon />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                  Informacion de la designacion
                </Typography>
              </Box>
              <Grid container xs={12} sm={12} md={12} lg={12} spacing={6}>

              <Grid item xs={12} sm={12} md={12} lg={6}>
              <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
                  <Typography mr={"1em"} fontWeight={"bold"}>
                    Tipo Cargo*:
                  </Typography>
                  <TextField
                      labelid="tipo_contratoInput"
                      id="tipo_contratoInput"
                      name="tipo_contratoInput"
                      value={state.tipo_contratoInput}
                      fullWidth
                      onChange={handleChangeTipo}
                      select
                      InputLabelProps={{
                        sx: {
                          color: 'rgb(150, 150, 150)',
                        }
                      }}
                    >
                      {
                        tipo_cargos && tipo_cargos.map(tipo => {
                          return (
                            <MenuItem key={tipo}
                              value={tipo}
                            >
                              {tipo}
                            </MenuItem>
                          )
                        })
                      }
                  </TextField>
                </Box>

                <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
                  <Typography mr={"1em"} fontWeight={"bold"}>
                        Cargo*:
                  </Typography>
                  <TextField
                      labelid="cargo_Input"
                      id="cargo_Input"
                      name="cargo_Input"
                      value={state.cargo_Input}
                      fullWidth
                      onChange={handleChange}
                      select
                      InputLabelProps={{
                        sx: {
                          color: 'rgb(150, 150, 150)',
                        }
                      }}
                    >
                      {
                        cargos && cargos.map(tipo => {
                          if(tipo.codigo === 'CO') {
                            return null;
                          };
                          return (
                            <MenuItem key={tipo.codigo}
                              value={tipo.codigo}
                            >
                              {tipo.codigo} - {tipo.descripcion}
                            </MenuItem>
                          )
                        })
                      }
                  </TextField>
                </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
                    <Typography mr={"1em"} fontWeight={"bold"}>
                      Fecha Inicio*:
                    </Typography>
                    <DatePicker
                      id={'fecha_inicioInput'}
                      name="fecha_inicioInput"
                      views={['year', 'month', 'day']}
                      value={dayjs(state.fecha_inicioInput)}
                      onChange={date => handleChange({ target: { value: date, name: 'fecha_inicioInput' } })}
                      slotProps={{
                        textField: { InputLabelProps: { sx: {color: 'transparent',
                                                        [`&.${inputLabelClasses.shrink}`]: {
                                                          color: "black"}}
                                                        }},
                        toolbar: { toolbarFormat: 'dddd D MMM', hidden: false },
                      }}>
                    </DatePicker>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10} sm={11} md={12} lg={12}>
            {/* Item 3*/}
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "0.5em"}}>
              <SchemaIcon />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                Lugar de trabajo
              </Typography>
            </Box>

            <Grid container xs={12} sm={12} md={12} lg={12} spacing={6}>

              <Grid item xs={12} sm={12} md={12} lg={6}>
              <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
                  <Typography mr={"1em"} fontWeight={"bold"}>
                    Dependencia*:
                  </Typography>
                  <TextField
                      labelid="dependencia_Input"
                      id="dependencia_Input"
                      name="dependencia_Input"
                      value={state.dependencia_Input}
                      fullWidth
                      onChange={handleChangePadre}
                      select
                      InputLabelProps={{
                        sx: {
                          color: 'rgb(150, 150, 150)',
                        }
                      }}
                    >
                      {
                        dependencias && dependencias.map(dependencia => {
                          if(dependencia.name === 'UNLP') {
                            return null;
                          };
                          return (
                            <MenuItem key={dependencia.id}
                              value={dependencia.id}
                            >
                              {dependencia.name}
                            </MenuItem>
                          )
                        })
                      }
                  </TextField>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6}>
                {state.dependencia_Input && hijas ?
                  <Box sx={{display: "flex", flexDirection: "column", marginBottom:"0.5em"}}>
                    <Typography mr={"1em"} fontWeight={"bold"}>
                      Oficina:
                    </Typography>
                    <TextField
                      id='hijo_Input'
                      name="hijo_Input"
                      value={state.hijo_Input || ''}
                      onChange={handleChange}
                      select
                      disabled={!state.dependencia_Input || !hijas}
                      inputProps={{
                        autoComplete: 'off'
                      }}
                      InputLabelProps={{
                        sx: {
                          color: 'rgb(150, 150, 150)',
                        }
                      }}

                    >
                      {
                        hijas && hijas.map(dependencia => {
                          return (
                            <MenuItem key={dependencia.id} value={dependencia.id}>
                              {dependencia.name}
                            </MenuItem>
                          )
                        })
                      }
                    </TextField>

                  </Box>
                  :
                  <></>
                }
              </Grid>
            </Grid>
        </Grid>
            
        {/* Item 5 */}
        <Grid item container xs={10} sm={10} md={8} lg={12}>
          <Button
            type="submit"
            variant="contained"
            sx={{
              mb: 2,
              mr: 1,
              fontSize: ".8em",
              fontWeight: "bold",
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'background.paper',
                transform: 'scale(1.01)',
              }
            }}
            onClick={handleClick}
          >
            Cancelar
          </Button>
          
              <Button
                type="submit"
                variant="contained"
                disabled={
                  (state.fecha_inicioInput === designacion.fecha_inicio &&
                  state.cargo_Input === designacion.cargo?.codigo &&
                  state.tipo_contratoInput === designacion.cargo?.tipo) &&
                  ((state.dependencia_Input === designacion.dependency_id && state.hijo_Input === '') || (state.hijo_Input===designacion.dependency_id))
                }
                sx={{
                  mb: 2,
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
              >
                Guardar
              </Button>
          </Grid>
          </Grid>
          </Grid>
          {/* Componente de Notificaciones */}
          <ModalComponent open={isOpenModal} handleClose={closeModal} >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {status}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {mensaje}
            </Typography>
          </ ModalComponent>
          {/* Pie de Pág. */}
          <Pie />
        </>
      );
};

export default EditarDesignacion;