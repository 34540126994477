import { createSlice } from '@reduxjs/toolkit';

import { ordenarPorNombre } from '../../utils/ordenarPorNombre'; 

const initialState = {
  justificacion: {},
  justificaciones_personales: [],
  justificaciones_generales: [],
};

export const justificacionesSlice = createSlice({
  name: 'justificacionesReducer',
  initialState,
  reducers: {
    justificaciones_por_dni: (state, { payload }) => {
      return {
        ...state,
        justificaciones: payload.data ? payload.data : "",
        status: payload.status,
        mensaje: payload.text,
      };
    },
    justificaciones_personales: (state, { payload }) => {
      return {
        ...state,
        justificaciones_personales: payload.data ? payload.data : "",
      };
    },
    justificaciones_generales: (state, { payload }) => {
      return {
        ...state,
        justificaciones_generales: payload.data ? payload.data : "",
        status: payload.status,
        mensaje: payload.text,
      };
    },
    crear_justificacion: (state, { payload }) => {
      return {
        ...state,
        status: payload.status,
        mensaje: payload.text,
      };
    },
    editar_justificacion: (state, { payload }) => {
      return {
        ...state,
        status: payload.status,
        mensaje: payload.text,
      };
    },
    editar_justificacion_general: (state, { payload }) => {
      return {
        ...state,
        status: payload.status,
        mensaje: payload.text,
      };
    },
    editar_tipo_justificacion: (state, { payload }) => {
      return {
        ...state,
        status: payload.status,
        mensaje: payload.text,
      };
    },
    crear_justificacion_general: (state, { payload }) => {
      return {
        ...state,
        status: payload.status,
        mensaje: payload.text,
      };
    },
    tipos: (state, { payload }) => {
      return {
        ...state,
        tipos: ordenarPorNombre(payload.data, 'titulo'),
        // status: payload.status,
        // mensaje: payload.text,
      };
    },
    tipo_por_id: (state, { payload }) => {
      return {
        ...state,
        tipo_id: payload.data,
        // status: payload.status,
        // mensaje: payload.text,
      };
    },
    crear_tipo: (state, { payload }) => {
      return {
        ...state,
        tipo_id: payload.data,
        status: payload.status,
        mensaje: payload.text,
      };
    },
    buscar_id: (state, { payload }) => {
      return {
        ...state,
        justificacion: payload.justificacion,
        creador: payload.creador,
        status: payload.status,
        mensaje: payload.mensaje,
      };
    },
    eliminar: (state, { payload }) => {
      return {
        ...state,
        status: payload.status,
        mensaje: payload.mensaje,
      };
    },
    eliminar_general: (state, { payload }) => {
      return {
        ...state,
        status: payload.status,
        mensaje: payload.mensaje,
      };
    },
    eliminar_tipo: (state, { payload }) => {
      return {
        ...state,
        status: payload.status,
        mensaje: payload.mensaje,
      };
    },
    restaurar_mensajes: (state) => {
      return {
        ...state,
        status: '',
        mensaje: '',
      };
    },
    restaurar_tipos: (state) => {
      return {
        ...state,
        tipos: '',
      };
    },
  },
});

export const { 
  justificaciones_por_dni, crear_justificacion, justificaciones_generales, 
  tipos, buscar_id, eliminar, restaurar_mensajes, editar_justificacion,
  crear_justificacion_general, eliminar_general, justificaciones_personales,
  editar_justificacion_general, tipo_por_id, crear_tipo, eliminar_tipo,
  editar_tipo_justificacion, restaurar_tipos
} = justificacionesSlice.actions;

export default justificacionesSlice.reducer;
