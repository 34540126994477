import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useModal } from '../../hooks/useModal';
import dayjs from 'dayjs';


// Componentes
import Rotulo from '../Rotulo/Rotulo';
import ModalComponent from '../ModalComponent/ModalComponent';
import Pie from '../Pie/Pie';

// Actions
import { buscar_tipos_generales, justificacion_editar, restaurarMensajes } from '../../redux/action-types/justificacionesActions'; 
import { buscar_dependencias } from '../../redux/action-types/dependenciaActions';  

// Funciones de ayuda
import { obtenerFecha } from '../../utils/obtenerFecha';

// MUI componentes
import { 
  Box, 
  Button, 
  CircularProgress,
  FormHelperText,
  Grid, 
  MenuItem,
  TextField,
  Typography, 
} from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers';
import { inputLabelClasses } from "@mui/material/InputLabel";

// MUI iconos
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const initialState = {
  fecha_inicioInput: '',
  fecha_finInput: '',
  tipo_justificacionInput: '',
  dependenciaInput: ''
};

const validate = (state) => {
  let errors = {};

  if(state.fecha_inicioInput !== '' && state.fecha_finInput !== '') {
    let inicio = new Date(state.fecha_inicioInput)
    let fin = new Date(state.fecha_finInput)
    if (inicio > fin){
      errors.fecha_finInput = 'La fecha de fin debe ser posterior a la fecha de inicio'
    } else {
      errors.fecha_finInput = ''
    }
  }
  
  return errors;
};

const EditarJustificacionGeneral = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const { justificacion, status, mensaje } = useSelector(state => state.justificaciones);
  const { tipos } = useSelector (state => state.justificaciones)
  const { dependencias } = useSelector(state => state.dependencia);
  const dniActual  = useSelector(state => state.acceso.dni);

  const [ state, setState ] = useState(initialState);
  const [ errors, setErrors ] = useState(initialState);

  const [isOpenModal, openModal, closeModal] = useModal();

  const handleChange = event => {
    const { name, value } = event.target;
    setErrors(validate({
      ...state,
      [name]: value
    }));
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleClick = () => {
    navigate(`/justificaciones-generales`);
  };

  const handleSubmit = event => {
    event.preventDefault();
    const data = {...state, justificacion_id: justificacion.id, modificado_por_dni: dniActual};
    dispatch(justificacion_editar(data));
    openModal();
    setTimeout(() => {
      closeModal();
      dispatch(restaurarMensajes());
      navigate(`/justificaciones-generales`);
      //navigate(`/detalle-justificacion/${justificacion.creado_por_dni}/${id}`);
    }, 2000);
    setState(initialState);
  };

  useEffect(() => {
    dispatch(buscar_dependencias())
    dispatch(buscar_tipos_generales());
    if(justificacion.fecha_inicio) {
      setState((state) => ({
        ...state,
        fecha_inicioInput: obtenerFecha(justificacion.fecha_inicio).fecha2,
        tipo_justificacionInput: justificacion.tipo_justificacion_id,
        dependenciaInput: justificacion.id_dependencia
      }));
    }
    if(justificacion.fecha_fin){
      setState((state) => ({
        ...state,
        fecha_finInput: obtenerFecha(justificacion.fecha_fin).fecha2,
      }));
    };
    
    return () => dispatch(restaurarMensajes());
  }, [dispatch, justificacion, id]);

  if(!tipos && !dependencias) {
    return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={'Editar Justificación'} />
        <CircularProgress 
          sx={{
            width: "auto",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />  
        {/* Copmponente de Pie de Pag. */}
        <Pie />
      </>
    );
  };

  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={'Editar Justificación'} />
      {/* Aca arranca el componente Crear Usuario */}
      <Grid id={"editarGeneral"}  container component="form" noValidate onSubmit={handleSubmit} spacing={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3vh",
        }}
      >
        {/* Contenedor Grid de los Items   */}
        <Grid item container xs={12} sm={10} md={8} lg={6} spacing={6}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >   
          {/* Item 1 */}
          <Grid item xs={10} sm={10} md={8} lg={6}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}> 
              <CalendarTodayIcon />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                Justificación General
              </Typography>
            </Box>   

            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Fecha Inicio*:
              </Typography>
              <DatePicker 
                id={'fecha_inicioInput'} 
                name="fecha_inicioInput"
                views={['year', 'month', 'day']}
                value={dayjs(justificacion.fecha_inicio)}
                onChange={date => handleChange({ target: { value: date, name: 'fecha_inicioInput' } })}
                slotProps={{
                  textField: { InputLabelProps: { sx: {color: 'transparent',
                                                  [`&.${inputLabelClasses.shrink}`]: {
                                                    color: "black"}}
                                                  }},
                  toolbar: { toolbarFormat: 'dddd D MMM', hidden: false },
                }}></DatePicker>
              
            </Box>
            
            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Fecha Fin:
              </Typography>
              <DatePicker 
                id={'fecha_finInput'} 
                name="fecha_finInput"
                views={['year', 'month', 'day']}
                value={dayjs(justificacion.fecha_fin)}
                onChange={date => handleChange({ target: { value: date, name: 'fecha_finInput' } })}
                slotProps={{
                  textField: { InputLabelProps: { sx: {color: 'transparent',
                                                  [`&.${inputLabelClasses.shrink}`]: {
                                                    color: "black"}}
                                                  }},
                  toolbar: { toolbarFormat: 'dddd D MMM', hidden: false },
                }}>          
              </DatePicker>
              {
                errors.fecha_finInput && 
                <FormHelperText
                  sx={{
                    color: "red",
                  }}
                >
                  {errors.fecha_finInput}
                </FormHelperText>
              }
            </Box>
          </Grid>  

          {/* Item 2 */}
          <Grid item xs={10} sm={10} md={8} lg={6}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}> 
              <TaskAltIcon />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                Justificaciones
              </Typography>
            </Box>  

            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Tipo Justificacion*:
              </Typography>
              <TextField
                  labelid="tipo_justificacionInput"
                  id="tipo_justificacionInput"
                  name="tipo_justificacionInput"
                  value={state.tipo_justificacionInput}
                  fullWidth
                  onChange={handleChange}
                  select
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                >  
                  {
                    tipos && tipos.map(tipo => {
                      return (
                        <MenuItem key={tipo.id}
                          value={tipo.id}
                        >
                          {tipo.titulo}
                        </MenuItem>
                      )
                    })
                  }
              </TextField>
            </Box>

            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Dependencia*:
              </Typography>
              <TextField
                  labelid="dependenciaInput"
                  id="dependenciaInput"
                  name="dependenciaInput"
                  value={state.dependenciaInput}
                  fullWidth
                  onChange={handleChange}
                  select
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                >  
                  {
                    dependencias && dependencias.map(dependencia => {
                      return (
                        <MenuItem key={dependencia.id}
                          value={dependencia.id}
                        >
                          {dependencia.name}
                        </MenuItem>
                      )
                    })
                  }
              </TextField>
            </Box>
          </Grid>  

          {/* Item 3 */}
          <Grid item container xs={10} sm={10} md={8} lg={12}>
            <Button 
              type="submit"
              variant="contained"
              sx={{
                mb: 2,
                mr: 1,
                fontSize: ".8em",
                fontWeight: "bold",
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
              onClick={handleClick}
            >
              Cancelar
            </Button>
            {
              (state.fecha_inicioInput === '' && state.fecha_finInput === '' && state.tipo_justificacionInput === '')
              ?
                <Button
                  type="submit"
                  variant="contained"
                  disabled
                  sx={{
                    mb: 2,
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Guardar
                </Button>
              :
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mb: 2,
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Guardar
                </Button>
            }
          </Grid>
        </Grid>
      </Grid>
      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {status}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {mensaje}
        </Typography>
      </ ModalComponent>
      {/* Pie de Pág. */}
      <Pie />
    </>
  );
};

export default EditarJustificacionGeneral;