import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';
// import { useDecrypt } from '../../hooks/useDecrypt';
import dayjs from 'dayjs';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import ModalComponent from '../ModalComponent/ModalComponent';
import Pie from '../Pie/Pie';

// Actions
import { editarUsuario, editarUsuarioActual, buscar_por_dni, buscar_por_dni_actual } from '../../redux/action-types/usuarioActions';

// MUI componentes
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers';
import { inputLabelClasses } from "@mui/material/InputLabel";

// MUI iconos
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const ALPHA = /^[\p{L}\s]+$/u;
const DIRE = /^(\w+\.)?unlp\.edu\.ar$/;

const validate = (state) => {
  let errors = {};

  if(state.nombreInput === '') {
    errors.nombreInput = '';
  } else if(state.nombreInput.length < 3) {
    errors.nombreInput = 'El nombre debe tener al menos 3 caracteres...';
  } else if(!ALPHA.test(state.nombreInput)) {
    errors.nombreInput = 'Solo se aceptan letras y espacios...';
  };

  if(state.apellidoInput === '') {
    errors.apellidoInput = '';
  } else if(state.apellidoInput.length < 3) {
    errors.apellidoInput = 'El apellido debe tener al menos 3 caracteres...';
  } else if(!ALPHA.test(state.apellidoInput)) {
    errors.apellidoInput = 'Solo se aceptan letras y espacios...';
  };


  if(state.mailInput === '') {
    errors.mailInput = '';
  } else if(!state.mailInput.split('@')[0]) {
    errors.mailInput = 'Ingresa el usuario...';
  } else if(!DIRE.test(state.mailInput.split('@')[1])) {
    errors.mailInput = 'La dirección debe terminar con el formato "@dependencia.unlp.edu.ar"';
  };

  return errors;
};

const initialState = {
  nombreInput: '',
  apellidoInput: '',
  mailInput: '',
  nacimientoInput: '',
  generoInput: '',
  paisInput: '',
  ciudadInput: '',
  direccionInput: '',
  telFijoInput: '',
  celularInput: '',
};

const EditarUsuario = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dni } = useParams();

  const { status, text, usuario, flag } = useSelector(state => state.usuario);
  const dniActual  = useSelector(state => state.acceso.dni);
  const tipo_rol = window.localStorage.getItem("rol");
  // const tipo_rol = useDecrypt('rol');

  const [ state, setState ] = useState(initialState);
  const [ errors, setErrors ] = useState(initialState);
  const [ timeExceeded, setTimeExceeded ] = useState(false);

  const [ isOpenModal, openModal, closeModal ] = useModal();

  const handleChange = event => {
    const { name, value } = event.target;
    setErrors(validate({
      ...state,
      [name]: value
    }));
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleClick = (event) => {
    navigate(`/perfil/${dni}`);
  };

  const handleSubmit = event => {
    event.preventDefault();
    const data = {...state, dniInput: usuario.dni, usuarioActualInput: dniActual};
    dispatch(tipo_rol === ('UserRol' || 'UserConsulta') ? editarUsuarioActual(data) : editarUsuario(data));
    openModal();
    setTimeout(() => {
      navigate(`/perfil/${dni}`);
    }, 2000);
    setState(initialState);
    setErrors(initialState);
  };

  useEffect(() => {
    dispatch(dni === dniActual ? buscar_por_dni_actual(dniActual) : buscar_por_dni(dni ? dni : dniActual));

  }, [dispatch, dni, dniActual]);

  useEffect(() => {
    setState({
      nombreInput: usuario.nombre,
      apellidoInput: usuario.apellido,
      mailInput: usuario.email,
      nacimientoInput: usuario.nacimiento,
      generoInput: usuario.genero,
      paisInput: usuario.pais,
      ciudadInput: usuario.ciudad,
      direccionInput: usuario.direccion,
      telFijoInput: usuario.telefono,
      celularInput: usuario.celular,
    })
  }, [dispatch, usuario]);

  useEffect(() => {
    // Configura el tiempo límite
    const timeoutId = setTimeout(() => {
      setTimeExceeded(true);
      if(!usuario) {
        setTimeout(() => {
          openModal();
          setTimeout(() => {
            navigate("/buscar-usuario");
          }, 3000);
        }, 0);
      };
    }, 5000);
    // Limpia el temporizador
    return () => clearTimeout(timeoutId);
  }, [usuario, openModal, navigate]);

  // Si no hay datos del usuario
  if(!usuario && !timeExceeded) {
    return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={'Editar usuario.'} />
        <CircularProgress
          sx={{
            width: "auto",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />
        {/* Copmponente de Pie de Pag. */}
        <Pie />
      </>
    );
  };
  // Si el usuario no puede acceder a esa informacion
  if(!usuario && timeExceeded && !flag) {
    return (
      <>
        {/* Navegación */}
        <Rotulo titulo={"Editar usuario."} />
        {/* Componente de Notificaciones */}
        <ModalComponent open={isOpenModal} handleClose={closeModal} >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Redireccionando...
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {text}
          </Typography>
        </ ModalComponent>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  };
  // Si tiene autorizacion
  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={'Editar Usuario'} />
      {/* Aca arranca el componente Crear Usuario */}
      <Grid container component="form" noValidate onSubmit={handleSubmit} spacing={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3vh",
        }}
      >
        {/* Contenedor Grid de los Items   */}
        <Grid item container xs={12} sm={10} md={8} lg={6} spacing={6}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* Item 1 */}
          <Grid item xs={10} sm={10} md={8} lg={6}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}>
              <ManageAccountsIcon />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                Información Personal
              </Typography>
            </Box>

            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Nombre:
              </Typography>
              <TextField
                  id='nombreInput'
                  name="nombreInput"
                  type='text'
                  value={state.nombreInput}
                  placeholder={usuario.nombre}
                  error={!!errors.nombreInput}
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      // set the color of the label when not shrinked
                      color: 'rgb(150, 150, 150)',
                      [`&.${inputLabelClasses.shrink}`]: {
                        // set the color of the label when shrinked (usually when the TextField is focused)
                        // color: "orange"
                      }
                    }
                  }}
              />
              {
                errors.nombreInput &&
                <FormHelperText
                  sx={{
                    color: "red",
                  }}
                >
                  {errors.nombreInput}
                </FormHelperText>
              }
            </Box>

            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Apellido:
              </Typography>
              <TextField
                  id='apellidoInput'
                  name="apellidoInput"
                  type='text'
                  value={state.apellidoInput}
                  placeholder={usuario.apellido}
                  error={!!errors.apellidoInput}
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
              />
              {
                errors.apellidoInput &&
                <FormHelperText
                  sx={{
                    color: "red",
                  }}
                >
                  {errors.apellidoInput}
                </FormHelperText>
              }
            </Box>


            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                E-mail:
              </Typography>
              <TextField
                  id='mailInput'
                  name="mailInput"
                  type='mail'
                  value={state.mailInput}
                  placeholder={usuario.email}
                  error={!!errors.mailInput}
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
              />
              {
                errors.mailInput &&
                <FormHelperText
                  sx={{
                    color: "red",
                  }}
                >
                  {errors.mailInput}
                </FormHelperText>
              }
            </Box>

            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Fecha de Nacimiento:
              </Typography>
              <DatePicker
                id={'nacimientoInput'}
                name="nacimientoInput"
                views={['year', 'month', 'day']}
                value={dayjs(usuario.nacimiento)}
                onChange={date => handleChange({ target: { value: date, name: 'nacimientoInput' } })}
                slotProps={{
                  textField: { InputLabelProps: { sx: {color: 'transparent',
                                                  [`&.${inputLabelClasses.shrink}`]: {
                                                    color: "black"}}
                                                  }},
                  toolbar: { toolbarFormat: 'dddd D MMM', hidden: false },
                }}>
              </DatePicker>
            </Box>

            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Género:
              </Typography>
              <TextField
                  labelid="generoInputLabel"
                  id="generoInput"
                  name="generoInput"
                  value={state.generoInput}
                  onChange={handleChange}
                  select
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
              >
                {/* Chequear la Ley para ver que opciones hay que poner */}
                <MenuItem value={'Femenino'}>Femenino</MenuItem>
                <MenuItem value={'Masculino'}>Masculino</MenuItem>
                <MenuItem value={'No Binario'}>No Binario</MenuItem>
                <MenuItem value={'No Especificado'}>No Especificado</MenuItem>
              </TextField>
            </Box>

            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                País:
              </Typography>
              <TextField
                  id='paisInput'
                  name="paisInput"
                  type='text'
                  value={state.paisInput}
                  placeholder={usuario.pais}
                  error={!!errors.paisInput}
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
              />
            </Box>

            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Cuidad:
              </Typography>
              <TextField
                  id='ciudadInput'
                  name="ciudadInput"
                  type='text'
                  value={state.ciudadInput}
                  placeholder={usuario.ciudad}
                  error={!!errors.ciudadInput}
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
              />
            </Box>

            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Dirección:
              </Typography>
              <TextField
                  id='direccionInput'
                  name="direccionInput"
                  type='text'
                  value={state.direccionInput}
                  placeholder={usuario.direccion}
                  error={!!errors.direccionInput}
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
              />
            </Box>

            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Teléfono Fijo:
              </Typography>
              <TextField
                  id='telFijoInput'
                  name="telFijoInput"
                  type='text'
                  value={state.telFijoInput}
                  placeholder={usuario.telefono}
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
              />
            </Box>

            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Celular:
              </Typography>
              <TextField
                  id='celularInput'
                  name="celularInput"
                  type='text'
                  value={state.celularInput}
                  placeholder={usuario.celular}
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
              />
            </Box>
          </Grid>
          {/* Item 3.1 */}
          <Grid item container xs={10} sm={10} md={8} lg={6.001}>
            <Button
                type="submit"
                variant="contained"
                sx={{
                  mb: 2,
                  mr: 1,
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleClick}
              >
              Cancelar
            </Button>
            {
              (
              (errors.nombreInput?.length > 0 || errors.apellidoInput?.length > 0 || errors.dniInput?.length > 0 ||
              errors.mailInput?.length > 0)
              ||
              ((state.nombreInput ===  usuario.nombre && state.apellidoInput === usuario.apellido  && state.mailInput ===  usuario.email
              && state.generoInput === usuario.genero && state.paisInput === usuario.pais && state.ciudadInput === usuario.ciudad && state.direccionInput === usuario.direccion
              && state.nacimientoInput === usuario.nacimiento && state.telFijoInput === usuario.telefono && state.celularInput === usuario.celular)
              ||
              (state.nombreInput === '' || state.apellidoInput === '' || state.mailInput === ''
              || state.generoInput === '')
            )
              )
              ?
                <Button
                  type="submit"
                  variant="contained"
                  disabled
                  sx={{
                    mb: 2,
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Guardar
                </Button>
              :
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mb: 2,
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Guardar
                </Button>
            }
          </Grid>
        </Grid>
      </Grid>
      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {status}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {text}
        </Typography>
      </ ModalComponent>
      {/* Copmponente de Pie de Pag. */}
      <Pie />
    </>
  );
};

export default EditarUsuario;
