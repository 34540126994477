import axios from "axios";

import { vacacion_por_dni, mensaje_vacaciones, historial_por_dni,restaurar, editar, historial_completo_por_dni, usuarios_vacaciones, mensaje_status } from '../reducers/vacacionesReducer';

export const vacaciones_por_dni = (dni) => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/vacaciones/${dni}`)
      .then(response => {
        dispatch(vacacion_por_dni({vacacion:response.data.vacacion,
                                  dias_tomados: response.data.dias_tomados,
                                  viejas_tomadas: response.data.viejas_tomadas
        }));
      })
      .catch(error => console.log(error));
  };
};

export const editar_vacacion = (data) => {
  return (dispatch) => {
    return axios.put(`${process.env.REACT_APP_BASE_URL}/editar-vacacion/${data.dniInput}`, data)
      .then(response => {
        dispatch(editar({status: response.data.status, mensaje: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
};

export const historial_actual_dni = (dni) => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/vacaciones-periodo-actual/${dni}`)
      .then(response => {
        dispatch(historial_por_dni({data:response.data}));
      })
      .catch(error => console.log(error));
  };
};

export const historial_completo_dni = (dni) => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/vacaciones-completas/${dni}`)
      .then(response => {
        dispatch(historial_completo_por_dni({data:response.data}));
      })
      .catch(error => console.log(error));
  };
};

export const actualizar_vacacion_por_dni = (dni) => {
    return function(dispatch) {
      return axios.get(`${process.env.REACT_APP_BASE_URL}/vacaciones/actualizar/${dni}`)
        .then(response => {
          dispatch(mensaje_vacaciones({mensaje: response.data.mensaje}));
        })
        .catch(error => console.log(error));
    };
  }; 

  export const crear_vacacion = (data) => {
    return function(dispatch) {
      return axios.put(`${process.env.REACT_APP_BASE_URL}/vacaciones/crear/${data.dni}`, data)
        .then(response => {
          dispatch(mensaje_status({status: response.data.status, mensaje: response.data.mensaje}));
        })
        .catch(error => console.log(error));
    };
  }; 

export const actualizacion_anual = () => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/vacaciones/actualizacion-anual`)
      .then(response => {
        dispatch(mensaje_vacaciones({mensaje: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
};

export const usuarios_sin_vacaciones = () => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/vacaciones/usuarios-sin-vacaciones`)
      .then(response => {
        dispatch(usuarios_vacaciones({usuarios: response.data.usuarios}));
      })
      .catch(error => console.log(error));
  };
};

  export const restaurar_mensajes = () => {
    return function(dispatch) {
      dispatch(restaurar());
    }
  }
