import React from 'react';
import dayjs from 'dayjs';

// MUI Componentes
import {
  Button,
  Grid,
} from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers';
import { inputLabelClasses } from "@mui/material/InputLabel";

const InputJustificaciones = ({ fechaInicioInput, fechaFinInput, handleChange, handleSubmit }) => {

  return (
    <Grid id={"inputJustificaciones"}  container mb={"1em"} component="form" noValidate onSubmit={handleSubmit} 
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        width: "100%",
        alignItems: "center",
      }}
    >           
        <Grid item xs={3} sm={3} md={3} lg={3}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}>
          <DatePicker 
            id={'fechaInicioInput'} 
              name="fechaInicioInput"
              label='Fecha Inicio*'
              value={dayjs(fechaInicioInput)}                    
              onChange={date => handleChange({ target: { value: date, name: 'fechaInicioInput' } })}
              views={['year', 'month', 'day']}
              slotProps={{                
                textField: { InputLabelProps: { sx: {color: 'transparent',
                                [`&.${inputLabelClasses.shrink}`]: {
                                  color: "black"}}
                }},
              }}>
          </DatePicker>
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}>
          <DatePicker 
            id={'fechaFinInput'} 
            name="fechaFinInput"
            label='Fecha Fin*'
            value={dayjs(fechaFinInput)}
            views={['year', 'month', 'day']}
            onChange={date => handleChange({ target: { value: date, name: 'fechaFinInput' } })}
            slotProps={{
              textField: { InputLabelProps: { sx: {color: 'transparent',
                                              [`&.${inputLabelClasses.shrink}`]: {
                                                color: "black"}}
                                              }},
            }}>          
            </DatePicker>
          </Grid>

      <Grid item xs={2} sm={2} md={2} lg={2}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}>
        <Button
          type="submit"
          variant="contained"
          sx={{ 
            fontSize: ".8em",
            fontWeight: "bold",
            bgcolor: 'background.paper',
            '&:hover': {
              bgcolor: 'background.paper',
              transform: 'scale(1.01)',
            }
          }}
          >
          Buscar
        </Button> 
        </Grid>
    </Grid>
  );
};

export default InputJustificaciones;