import React from 'react';

import { 
  Grid,
  InputBase,
  Button,
} from '@mui/material';

import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';

import palette from '../../theme/lightPalette';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '.5em',
  boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)',
  backgroundColor: palette.background.paper,
  '&:hover': {
    backgroundColor: palette.text.secondary,
  },
  marginBottom: '1em',
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const SearchInput = ({handleChange, handleClick, placeholder}) => {
  
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleClick();
    };
  };

  return (
    <Grid container
      sx={{ 
        display:"flex",
        justifyContent: "center",
        marginTop: '3em'
      }}
    > 
      <Search sx={{width:"20em"}} id={"searchUser"} >
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase id={"buscarUsuario"} 
          sx={{
            width:"15em",
          }}
          placeholder={placeholder}
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleChange}
          onKeyUp={handleKeyPress}
        />
      </Search>
      <Button 
        variant="contained"
        onClick={handleClick}
        sx={{
          mb: 2,
          fontSize: ".8em",
          fontWeight: "bold",
          bgcolor: 'background.paper',
          '&:hover': {
            bgcolor: 'background.paper',
            transform: 'scale(1.01)',
          }
        }}
      >
        Buscar
      </Button>
    </Grid>
  );
};

export default SearchInput;