// Obtiene el horario que corresponde segun el dia de la semana

export const obtenerHorario = (dia, horario) => {
  if (horario) {
    for(let i = 0; i < horario.length; i++) {
      if(horario[i].dia === dia) {
        const entrada = horario[i].entrada.slice(0, -3) === '00:00' ? '' : `${horario[i].entrada.slice(0, -3)} - `;
        const salida = horario[i].salida.slice(0, -3) === '00:00' ? '' : horario[i].salida.slice(0, -3);
        return `${entrada} ${salida}`;
      };
    };
  };
  return null;
};
