import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    designaciones: [],
  };

export const designacionSlice = createSlice({
    name: 'designacionReducer',
    initialState,
    reducers: {
        listar: (state, { payload })=> {
            return{
                ...state,
                vigentes: payload.vigentes,
                historicas: payload.historicas,
                status: payload.status,
                text: payload.text
            };
        },
        buscar_id: (state, { payload }) => {
            return {
              ...state,
              designacion: payload.data,
              status: payload.status,
              text: payload.text
            };
          },
          usuarios_dependencia: (state, { payload }) => {
            return {
              ...state,
              vigentes: payload.data.vigentes,
              historicos: payload.data.historicos,
              status: payload.status,
              mensaje: payload.text
            };
          },
        crear: (state, { payload }) => {
          return {
            ...state,
            status: payload.status,
            mensaje: payload.text,
          };
        },
        editar: (state, { payload }) => {
          return {
            ...state,
            status: payload.status,
            mensaje: payload.text,
          };
        },
        editar_historica: (state, { payload }) => {
          return {
            ...state,
            status: payload.status,
            mensaje: payload.text,
          };
        },

        cerrar: (state, { payload }) => {
          return {
            ...state,
            status: payload.status,
            mensaje: payload.text,
          };
        },
        eliminar: (state, { payload }) => {
          return {
            ...state,
            status: payload.status,
            mensaje: payload.text,
          };
        },
        revivir: (state, { payload }) => {
          return {
            ...state,
            status: payload.status,
            mensaje: payload.text,
          };
        },
        restore_messages: (state) => {
          return {
            ...state,
            designacion: '',
            tipo_cargos: '',
            status: '',
            mensaje: '',
          };
        },

    },
});

export const { listar, buscar_id, usuarios_dependencia, crear, editar, editar_historica, cerrar, eliminar, revivir, restore_messages } = designacionSlice.actions;

export default designacionSlice.reducer;
