import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';
// import { useDecrypt } from '../../hooks/useDecrypt';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import ModalComponent from '../ModalComponent/ModalComponent';
import Pie from '../Pie/Pie';

// Actions
import { buscar_por_dni } from '../../redux/action-types/usuarioActions';
import { auditarJustificacionesPersonales, setPeriodo } from '../../redux/action-types/auditoriaActions';

// MUI componentes
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

// MUI iconos
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import InfoIcon from '@mui/icons-material/Info';
import { obtenerFecha } from '../../utils/obtenerFecha';

const AuditoriaJustificaciones = ({ titulo }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { dni } = useParams();
  
  // Obtengo la parte final de la URL
  const urlParams = location.pathname.split('/').pop().split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1));

  const dniActual = window.localStorage.getItem("dni");
  const tipo_rol = window.localStorage.getItem("rol");
  // const tipoRol = useDecrypt('rol');

  const { usuario, flag, text } = useSelector(state => state.usuario);
  const { personales_audit, periodo } = useSelector(state => state.auditoria);

  const [timeExceeded, setTimeExceeded] = useState(false);
  const [ datos, setDatos ]  = useState('');

  const [isOpenModal, openModal, closeModal] = useModal();

  const handleAtras = () => {
    navigate(`/auditoria/usuario/${dni}`);
  };

  const handleDetalle = (dni, id) => {
    window.sessionStorage.setItem('urlAnterior', location.pathname);    
    navigate(`/detalle-justificacion/${dni}/${id}`);
    
    
  };

  const handleClickUsuario = (dni) => {
    window.sessionStorage.setItem('urlAnterior', location.pathname);
    navigate(`/perfil/${dni}`);
  };

  useEffect(() => {
    dispatch(buscar_por_dni(dni));
    if(!periodo){
      dispatch(setPeriodo(7));
    };          
  }, [dispatch, dni, dniActual, tipo_rol, periodo]);

  useEffect(() => {
    if(periodo){
      dispatch(auditarJustificacionesPersonales({dni, periodo}));
    };    
  }, [dispatch, dni, periodo])

  useEffect(() => {
    // Configura el tiempo límite
    const timeoutId = setTimeout(() => {
      setTimeExceeded(true);
      if(!usuario) {
        setTimeout(() => {
          openModal();
          setTimeout(() => {
            navigate("/buscar-usuario-auditoria");
          }, 3000);
        }, 0);
      };
    }, 5000);
    // Limpia el temporizador
    return () => clearTimeout(timeoutId);
  }, [usuario, openModal, navigate]);

  useEffect(() => {
    if(urlParams[1].slice(0, -1) === "Creada"){
      setDatos(personales_audit.creadas);
    }else if(urlParams[1].slice(0, -1) === "Editada"){
      setDatos(personales_audit.modificadas);
    }else{
      setDatos(personales_audit.eliminadas);
    }
  }, [personales_audit, urlParams])

  // Si no hay datos del usuario
  if(!usuario && !timeExceeded) {
    return (
      <>
        {/* Navegación */}
        <Rotulo titulo={urlParams.length === 2 ? `Auditoría ${urlParams[0]} ${urlParams[1]}` : `Auditoría ${urlParams[0]} ${urlParams[1]} ${urlParams[2]}`} />
        <Grid container>
          <CircularProgress
            sx={{
              width: "auto",
              height: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </Grid>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  };

  // Si el usuario no puede acceder a esa informacion
  if(!usuario && timeExceeded && !flag) {
    return (
      <>
        {/* Navegación */}
        <Rotulo titulo={urlParams.length === 2 ? `Auditoría ${urlParams[0]} ${urlParams[1]}` : `Auditoría ${urlParams[0]} ${urlParams[1]} ${urlParams[2]}`} />
        {/* Componente de Notificaciones */}
        <ModalComponent open={isOpenModal} handleClose={closeModal} >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Redireccionando...
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {text}
          </Typography>
        </ ModalComponent>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  };

  return (
    <>
      <Rotulo titulo={urlParams.length === 2 ? `Auditoría ${urlParams[0]} ${urlParams[1]}` : `Auditoría ${urlParams[0]} ${urlParams[1]} ${urlParams[2]}`} />
      <Grid container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={11} sm={8.5} md={7} lg={8} mt={6} mb={2}>
          <Button
            variant="contained"
            sx={{
              alignSelf: "start",
              fontSize: ".8em",
              fontWeight: "bold",
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'background.paper',
                transform: 'scale(1.01)',
              }
            }}
            onClick={handleAtras}
          >
            Atrás
          </Button>
          <Divider sx={{ width: "100%", height: "1em"}}/>
        </Grid>
        <Grid item container xs={11} sm={8.5} md={7} lg={8} mt={1} mb={3}>
          <VerifiedUserIcon sx={{ mt: "4px" }}/>
          <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
            Justificaciones personales {urlParams[1]} por {usuario.nombre} {usuario.apellido} {periodo === 10000 ? "desde siempre": `en los últimos ${periodo} días`}.
          </Typography>
        </Grid>

        {/* Inicio Tabla */}
        {datos && datos.length > 0 ?        
        <Grid item container xs={12} sm={11} md={10} lg={8}>
          <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow key={"0"} sx={{bgcolor:"primary.light"}}>
                  <TableCell key={"dni"} align="center">Usuario</TableCell>
                  <TableCell key={"date_init"} align="center">Fecha inicio</TableCell>
                  <TableCell key={"date_end"} align="center">Fecha fin</TableCell>                  
                  <TableCell key={"justify"} align="center">Justificación</TableCell>
                  <TableCell key={"fecha"} align="center">
                    {urlParams.length === 2 ? `Fecha ${urlParams[1].slice(0, -1)}` : `Fecha ${urlParams[2].slice(0, -1)}`}
                  </TableCell>
                  <TableCell key={"fecha"} align="center">
                    {urlParams.length === 2 ? `Hora ${urlParams[1].slice(0, -1)}` : `Hora ${urlParams[2].slice(0, -1)}`}
                  </TableCell>
                  
                  <TableCell key={"detalle"} align="center">Detalle</TableCell>
                </TableRow>
              </TableHead>
              {
                <TableBody>
                  {
                    datos && datos.map(data => {
                      return (
                        <TableRow
                          key={`${data.dni}, ${data.fecha}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell key={"nombre"} align="center" sx={{
                                '&:hover': {
                                  cursor: 'pointer',
                                  borderRadius: '.5em',
                                  boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                }
                              }}
                              onClick={() => handleClickUsuario(data.usuario_dni)}>
                                {data.usuario ? `${data.usuario.nombre} ${data.usuario.apellido}` : data.usuario_dni}
                            </TableCell>
                          <TableCell key={"fecha_inicio"} align="center">{obtenerFecha(data.fecha_inicio).fecha}</TableCell>
                          <TableCell key={"fecha_fin"} align="center">{data.fecha_inicio === data.fecha_fin ? '-' : obtenerFecha(data.fecha_fin).fecha}</TableCell>
                          <TableCell key={"inicio"} align="center" width={'20%'}>{data.tipo_justificacion}</TableCell>
                          <TableCell key={"fecha_creado"} align="center">{urlParams[1].slice(0, -1) === "Creada" ? obtenerFecha(data.created_at).fecha : obtenerFecha(data.updated_at).fecha}</TableCell>
                          <TableCell key={"hora_creado"} align="center">{urlParams[1].slice(0, -1) === "Creada" ? obtenerFecha(data.created_at).horario : obtenerFecha(data.updated_at).horario}</TableCell>                          
                          <TableCell key={"detalle"} align="center" width={'20%'}>
                            <InfoIcon sx={{ color: "primary.light",cursor: 'pointer' }} onClick={() => handleDetalle(data.usuario_dni, urlParams[1].slice(0, -1) === "Editada" && data.justificacion_id ? data.justificacion_id : data.id)} />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              }
            </Table>
          </TableContainer>
        </Grid>
      :
      <Grid item xs={12} sm={11} md={10} lg={8} mb={"2em"}>
        <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", ml: "-3px"}}> 
          <Typography component={"h6"} variant={"h6"} fontSize={"1em"} ml={"2px"}>
            {`Este usuario no tiene justificaciones ${urlParams[1]} en el periodo seleccionado`}
          </Typography>
        </Box>  
      </Grid>
    }
      </Grid>
      {/* Pie de Pág */}
      <Pie />
    </>
  );
};

export default AuditoriaJustificaciones;
