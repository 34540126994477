import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useModal } from '../../../hooks/useModal';

// Componentes
import ModalComponent from '../../ModalComponent/ModalComponent';

// Actions
import { añadir_dependencia, borrar_dependencia } from '../../../redux/action-types/rolActions';

// MUI componentes
import { 
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material';

// MUI iconos
import ApartmentIcon from '@mui/icons-material/Apartment';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const DependenciaItem = ({ id, dni, nombre, imagen, add }) => {
  const dispatch = useDispatch();

  const { id_rol } = useSelector(state => state.rol);

  const titulo = nombre.length > 70 ? nombre.split(' ').splice(0, 5).join(' ') + '(...)' : nombre;
  
  const [isOpenModal, openModal, closeModal] = useModal();

  const handleClick = (event) => {
    event.preventDefault();
    openModal();
  };

  const handleClickAñadir = () => {
    const data = { dependencias: [id], dni_usuario: dni };
    dispatch(añadir_dependencia(data));
    closeModal();
  };

  const handleClickBorrar = () => {
    dispatch(borrar_dependencia(dni, id_rol, id));
    closeModal();
  };

  return (
    <>
      <Grid item xs={8} sm={8} md={5} lg={3.5} m={2}
        sx={{
          width: '26em',
          minHeight: '5em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '.5em',
          borderRadius: '.5em',
          padding: ".5em",
          boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)',
          bgcolor: 'background.paper',
        }}
      >
        {
          imagen ? imagen : <ApartmentIcon sx={{ fontSize:"2em", color:"text.default" }} />
        }
        <Box component="div" 
          sx={{
            width: "100%",
            display: "flex",
            flexDirection:"column",
            marginLeft: ".5em"
          }}
        >
          <Box component="div">
            <Typography variant="p" fontSize="1.1em" >
              {titulo}
            </Typography>
          </Box>
        </Box>
        <Box component="div"
          sx={{
            color:"text.default",
            '&:hover': {
              cursor: "pointer",
              transform: 'scale(1.2)',
              }
          }}
          onClick={handleClick}
        > 
          {
            add ?
              <AddIcon sx={{ fontSize:"1.8em", mt: 1, '&:hover': {color: "primary.main"} }} />
            :
              <DeleteIcon sx={{ fontSize:"1.8em", mt: 1, '&:hover': {color: "secondary.main"}}} />
          }
        </Box>
      </Grid>
      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        {
          add ? 
          <>
            <Typography id="modal-modal-title" component="h2" sx={{ fontSize: '1.3em' }}>
              Esta por añadir una dependencia. ¿Desea continuar?
            </Typography>
            <Button
              type="submit"
              variant="contained"
              onClick={closeModal}
              sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                marginRight: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={handleClickAñadir}
              sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'text.confirm',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Añadir
            </Button>
          </>
          :
          <>
            <Typography id="modal-modal-title" component="h2" sx={{ fontSize: '1.3em' }}>
              Esta por eliminar una dependencia. ¿Desea continuar?
            </Typography>
            <Button
              type="submit"
              variant="contained"
              onClick={closeModal}
              sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                marginRight: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={handleClickBorrar}
              sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'secondary.light',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Eliminar
            </Button>
          </>
        }
      </ModalComponent>
    </>
  );
};

export default DependenciaItem;
