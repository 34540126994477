
import { agregar, limpiar_mensajes } from "../reducers/messageReducer";

export const agregarMensaje = (mensaje) => {
  return function(dispatch) {
        dispatch(agregar({mensaje: mensaje}));
      }
};

export const restaurarMensajes = () => {
    return function(dispatch) {
          dispatch(limpiar_mensajes());
        }
};
