import React from 'react';
import { useNavigate } from 'react-router-dom';


// Funciones de ayuda
import { obtenerFecha } from '../../../utils/obtenerFecha';

// MUI componentes
import { 
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';


const TablaRoles = ({datos, nombre, icono}) => {
    const navigate = useNavigate();

    const handleClickCreador = creador => {
        window.sessionStorage.setItem('urlAnterior', `/roles-sistema`);
        navigate(`/perfil/${creador}`);
    };

    const handleClickDependencia = (id) => {
        window.sessionStorage.setItem('urlAnterior', `/roles-sistema`);
        navigate(`/dependencia/${id}`);
    };

    return (
        <>
        <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em", color:"primary.dark"}}>
        {icono}
        <Typography key={"nombre"} component={"h6"} variant={"h6"} fontSize={"1.2em"}>Roles {nombre}</Typography>
        </Box>
        {
            datos && datos.length > 0 ?
            <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
                <Table aria-label="simple table"> 
                <TableHead key={"head"}>
                    <TableRow key={"0"} sx={{bgcolor:'primary.light'}}>
                    <TableCell key={"user"} align='center'>Usuario</TableCell>                  
                    <TableCell key={"dni_user"} align='center'>DNI usuario</TableCell>
                    <TableCell key={"dependencia"} align='center'>Dependencia</TableCell>
                    <TableCell key={"createdby"} align="center">Asignado por</TableCell>
                    <TableCell key={"createdat"} align="center">Fecha asignacion</TableCell>
                    <TableCell key={"createdhour"} align="center">Hora asignacion</TableCell>                  
                    </TableRow>
                </TableHead>
                {
                    <TableBody>
                    {
                        datos && datos.map(user_rol => (                          
                            !user_rol.dependencies.some(dependencia => dependencia.name === "UNLP") ?                                                       
                                user_rol.dependencies.map((dependencia) => (                                                               
                                        <TableRow                                
                                        key={`${user_rol.id}-${dependencia.id}`}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >                    
                                            
                                            <TableCell align='center'
                                            sx={{
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                    borderRadius: '.5em',
                                                    boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                                }
                                                }}
                                                onClick={() => handleClickCreador(user_rol.user.dni)}
                                            >{user_rol.user.nombre} {user_rol.user.apellido}</TableCell>
                                            <TableCell align='center'>{user_rol.user.dni}</TableCell>
                                            <TableCell align='center'
                                            sx={{
                                                '&:hover': {
                                                cursor: 'pointer',
                                                borderRadius: '.5em',
                                                boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                                }
                                            }}
                                            onClick={() => handleClickDependencia(dependencia.id)}
                                        >
                                            {dependencia.name}
                                            </TableCell>                                        
                                            <TableCell align="center" 
                                                sx={{
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                    borderRadius: '.5em',
                                                    boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                                }
                                                }}
                                                onClick={() => handleClickCreador(user_rol.created_by)}
                                            >
                                                {user_rol.creador}
                                            </TableCell>
                                            <TableCell align='center'>{obtenerFecha(user_rol.created_at).fecha}</TableCell>
                                            <TableCell align='center'>{obtenerFecha(user_rol.created_at).horario}</TableCell>                                         
                                        </TableRow>
                                    ))                        
                            :                         
                            (<TableRow                                
                                key={user_rol.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >                    
                                <TableCell key={user_rol.user.nombre} align='center'
                                sx={{
                                    '&:hover': {
                                        cursor: 'pointer',
                                        borderRadius: '.5em',
                                        boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                    }
                                    }}
                                    onClick={() => handleClickCreador(user_rol.user.dni)}>
                                        {user_rol.user.nombre} {user_rol.user.apellido}
                                </TableCell>
                                <TableCell key={user_rol.user.dni} align='center'>{user_rol.user.dni}</TableCell>
                                <TableCell key={"UNLP"} align='center'>
                                UNLP
                                </TableCell>                                        
                                <TableCell key={"creadopor"} align="center" 
                                    sx={{
                                    '&:hover': {
                                        cursor: 'pointer',
                                        borderRadius: '.5em',
                                        boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                    }
                                    }}
                                    onClick={() => handleClickCreador(user_rol.created_by)}
                                >
                                    {user_rol.creador}
                                </TableCell>
                                <TableCell key={"createdat"} align='center'>{obtenerFecha(user_rol.created_at).fecha}</TableCell>
                                <TableCell key={"createdathour"} align='center'>{obtenerFecha(user_rol.created_at).horario}</TableCell>                                         
                            </TableRow>)
                             
                        ))
                    }
                    </TableBody>
                }
                </Table>
            </TableContainer> 
            :            
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "left", ml: "-3px", marginRight: '25px'}}> 
                <Typography component={"h3"} variant={"h6"} fontSize={"1em"}>
                    Actualmente no hay roles {nombre} en el sistema
                </Typography>
            </Box>  
            }
        </>
    );
};

export default TablaRoles;