import React from 'react';
import { useNavigate } from 'react-router-dom';

// MUI componentes
import { 
  Box,
  Container,
  Typography,
} from '@mui/material';

// MUI iconos
import {
  Storage,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

const Justificacion = ({ id, titulo, codigo, activa }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/administrar-justificaciones/tipo/${id}`);
  };
  

  return (
    <Container component="container"
      sx={{
        width: '18vw',
        minWidth: '280px',
        height: '5em',
        display:"flex",
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '.5em',
        boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)',
        bgcolor: 'background.paper',
        borderRadius: '.5em',
        marginBottom: '1em',
        '&:hover': {
          cursor: 'pointer',
          transform: 'scale(1.01)',
        }
      }}
      onClick={handleClick}
    >
      <Box 
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {
          activa ? 
          <Storage sx={{ fontSize:"2em", color:"primary.light", mr: ".5em"}} />
          : 
          <CloseIcon sx={{ fontSize:"2em", color:"primary.light", mr: ".5em"}} />
        }
      </Box>
      <Box 
        sx={{
          width: '75%',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            overflow: 'hidden', // Evita que el contenido se desborde
          }}
        >
          <Typography variant="p" fontSize="1.0em"
          sx={{
            overflowWrap: 'break-word', // Permite el salto de línea
            wordWrap: 'break-word', // Alternativa para compatibilidad
            textOverflow: 'ellipsis', // Corta el texto con puntos suspensivos
            overflow: 'hidden', // Oculta el texto que desborda
            maxHeight: '3em', // Altura máxima antes de cortar el texto
          }}>
            {titulo} 
          </Typography>
          <Typography variant="p" sx={{ fontSize:".95em", color: "primary.disabled", }}>
            {codigo}
          </Typography>
        </Box>
      </Box>  
    </Container>
  );
};

export default Justificacion;