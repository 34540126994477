import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useModal } from '../../hooks/useModal';
import dayjs from 'dayjs';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import ModalComponent from '../ModalComponent/ModalComponent';
import Pie from '../Pie/Pie';

// Funciones de ayuda
import { obtenerFecha } from '../../utils/obtenerFecha';

//Actions
import { buscar_por_id, editar_designacion_historica, restaurar_mensajes } from '../../redux/action-types/designacionActions';

// MUI componentes
import { 
  Box, 
  Button, 
  CircularProgress,
  Grid, 
  Typography, 
} from '@mui/material';
  
import { DatePicker } from '@mui/x-date-pickers';
import { inputLabelClasses } from "@mui/material/InputLabel";

// MUI iconos
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const initialState = {
    fecha_finInput: '',
};

const EditarHistorica = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const { designacion, status, mensaje } = useSelector(state => state.designaciones);
  const dniActual  = useSelector(state => state.acceso.dni);

  const [ state, setState ] = useState(initialState);

  const [isOpenModal, openModal, closeModal] = useModal();

  const [datosObtenidos, setDatosObtenidos] = useState(false);

  const handleChange = event => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleClick = () => {
    navigate(window.sessionStorage.getItem('urlAnterior'));
  };

  const handleSubmit = event => {
    event.preventDefault();
    const data = {...state, designacion_id: id, modificado_por: dniActual, titular_designacion: designacion.usuario_dni};
    dispatch(editar_designacion_historica(data));
    openModal();
    setTimeout(() => {
      navigate(window.sessionStorage.getItem('urlAnterior'));
      closeModal();
    }, 3000);
    dispatch(restaurar_mensajes());
    setState(initialState);
  };

  useEffect(() => {
    dispatch(buscar_por_id(id));
  }, [dispatch, id])
  
  useEffect(() => {
    setTimeout(() => {
      if (designacion){
        setDatosObtenidos(true);
        setState((state) => ({
          ...state,
          fecha_finInput: designacion?.fecha_fin,
      }))
      };
    }, 500);
            
    }, [dispatch, id, designacion]);

  if(!datosObtenidos) {
      return (
        <>
          {/* Rótulo de Página */}
          <Rotulo titulo={'Editar designacion'} />
          <CircularProgress 
            sx={{
              width: "auto",
              height: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />  
          {/* Copmponente de Pie de Pag. */}
          <Pie />
      </>
      );
  };

  return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={'Editar Designacion Historica'} />
        {/* Aca arranca el componente Editar designacion */}
        <Grid id={"editarHistorica"}  container component="form" noValidate onSubmit={handleSubmit} spacing={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "3vh",
          }}
        >
          {/* Contenedor Grid de los Items   */}
          <Grid item container xs={12} sm={10} md={8} lg={6} spacing={6}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >   
            {/* Item 1 */}
            <Grid item xs={10} sm={10} md={8} lg={6}>
              <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}> 
                <CalendarTodayIcon />
                <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                  Designacion
                </Typography>
              </Box>   
            <Box sx={{display:'flex'}}>
              <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Fecha Inicio:
                </Typography>
                <Typography>
                  {obtenerFecha(designacion.fecha_inicio).fecha}
                </Typography>               
              </Box>

              <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
                  <Typography mr={"1em"} fontWeight={"bold"}>
                      Fecha Fin:
                  </Typography>
                  <DatePicker 
                    id={'fecha_finInput'} 
                    name="fecha_finInput"
                    views={['year', 'month', 'day']}
                    value={dayjs(state.fecha_finInput)}
                    onChange={date => handleChange({ target: { value: date, name: 'fecha_finInput' } })}
                    slotProps={{
                      textField: { InputLabelProps: { sx: {color: 'transparent',
                                                      [`&.${inputLabelClasses.shrink}`]: {
                                                        color: "black"}}
                                                      }},
                      toolbar: { toolbarFormat: 'dddd D MMM', hidden: false },
                    }}>
                      </DatePicker>     
                  
              </Box>
              </Box>
                              
            </Grid>  
  
            {/* Item 2 */}
            <Grid item xs={10} sm={10} md={8} lg={6}>
              <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}> 
                <TaskAltIcon />
                <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                  Cargo
                </Typography>
              </Box>  
  
              <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
                <Typography mr={"1em"} fontWeight={"bold"}>
                      Cargo:
                </Typography>
                  <Typography>
                      {designacion.cargo?.codigo} - {designacion.cargo?.descripcion}
                  </Typography>
              </Box>
            </Grid>

            {/* Item 3*/}
            <Grid item xs={10} sm={10} md={8} lg={6}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}> 
                <TaskAltIcon />
                <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                  Dependencia
                </Typography>
              </Box> 

              <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Dependencia/Oficina:
                </Typography>
                <Typography>
                  {designacion.dependency?.name}
                </Typography>
              </Box>
            </Grid>

            {/* Item 4*/}
            <Grid item xs={10} sm={10} md={8} lg={6}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}> 
                <TaskAltIcon />
                <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                  Tipo Cargo
                </Typography>
              </Box> 

              <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Tipo Cargo:
                </Typography>
                <Typography>{designacion.cargo?.tipo}</Typography>                               
              </Box>
            </Grid>
  
            {/* Item 5 */}
            <Grid item container xs={10} sm={10} md={8} lg={12}>
              <Button 
                type="submit"
                variant="contained"
                sx={{
                  mb: 2,
                  mr: 1,
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleClick}
              >
                Cancelar
              </Button>
              {
                (state.fecha_finInput === designacion?.fecha_fin)
                ?
                  <Button
                    type="submit"
                    variant="contained"
                    disabled
                    sx={{
                      mb: 2,
                      fontSize: ".8em",
                      fontWeight: "bold",
                      bgcolor: 'background.paper',
                      '&:hover': {
                        bgcolor: 'background.paper',
                        transform: 'scale(1.01)',
                      }
                    }}
                  >
                    Guardar
                  </Button>
                :
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      mb: 2,
                      fontSize: ".8em",
                      fontWeight: "bold",
                      bgcolor: 'background.paper',
                      '&:hover': {
                        bgcolor: 'background.paper',
                        transform: 'scale(1.01)',
                      }
                    }}
                  >
                    Guardar
                  </Button>
              }
            </Grid>
          </Grid>
        </Grid>
        {/* Componente de Notificaciones */}
        <ModalComponent open={isOpenModal} handleClose={closeModal} >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {status}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {mensaje}
          </Typography>
        </ ModalComponent>
        {/* Pie de Pág. */}
        <Pie />
      </>
    );
};

export default EditarHistorica;