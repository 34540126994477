import React from 'react';
import { useNavigate } from 'react-router';

// Funciones de ayuda
import { obtenerFecha } from '../../utils/obtenerFecha';

// MUI componentes
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

// MUI iconos
import ApartmentIcon from '@mui/icons-material/Apartment';
// Utils
import { verificarPermiso } from '../../utils/verificarPermiso';


const AcercaDesignacion = ({ id, dni, usuario, inicio, fin, cerrador, modificador, dni_modificador,closed_by,fecha_cierre, cargo, dependencia, creador, created_by, fecha_creacion, cabecera, eliminada, fecha_eliminacion }) => {
  const navigate = useNavigate();

  const permisos = window.localStorage.getItem("permisos");
  const handleAtras = () => {
    const URL = window.sessionStorage.getItem('urlAnterior');
    navigate(URL);
  };
  const lastIndex = usuario.lastIndexOf(" ")
  const usu = usuario.substring(0, lastIndex)


  return (
    <Grid item xs={10} sm={10} md={8} lg={7}>
      <Grid item container xs={11} sm={11} md={12} lg={12}>
        <Button
          variant="contained"
          sx={{
            mr: 2,
            mb: 2,
            fontSize: ".8em",
            fontWeight: "bold",
            bgcolor: 'background.paper',
            '&:hover': {
              bgcolor: 'background.paper',
              transform: 'scale(1.01)',
            }
          }}
          onClick={handleAtras}
        >
          Atrás
        </Button>
        <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
      </Grid>

      <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}>
        <ApartmentIcon />
        <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
          {eliminada ? `Auditoria de designación eliminada de ${usu}` :`Detalle de designación de ${usu}`}
        </Typography>
      </Box>

      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"0.5em"}}>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Inicio:
          </Typography>
          <Typography>
            {inicio ? obtenerFecha(inicio).fecha : "Sin Detalle"}
          </Typography>
        </Box>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Fin:
          </Typography>
          <Typography>
            {fin !== inicio ? obtenerFecha(fin).fecha : "Sin Detalle"}
          </Typography>
        </Box>
      </Box>

      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"0.5em"}}>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
          Dependencia:
          </Typography>
          <Typography>
          {cabecera?.name}
          </Typography>
        </Box>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
          {cabecera?.id !== dependencia?.id ? "Oficina:" : null}
          </Typography>
          <Typography>
          {cabecera?.id !== dependencia?.id ? dependencia.name : null}
          </Typography>
        </Box>
      </Box>

      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"0.5em"}}>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Cargo:
          </Typography>
          <Typography>
            {cargo.codigo} - {cargo.descripcion}
          </Typography>
        </Box>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Tipo de cargo:
          </Typography>
          <Typography>
            {cargo.tipo}
          </Typography>
        </Box>
      </Box>

      <Box sx={{display: "flex", flexDirection: "column", marginBottom:"0.5em"}}>
          <Typography mr={"1em"} fontWeight={"bold"}>
            Estado:
          </Typography>
          <Typography>
            {fecha_cierre ? "Historica" : "Vigente"}
          </Typography>
        </Box>

      {cerrador ?
      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"0.5em"}}>
        <Box sx={{display: "flex", flexDirection: "column"}}>
          <Typography mr={"1em"} fontWeight={"bold"}>
            Cerrada por:
          </Typography>
          <Typography>
            {cerrador}
          </Typography>
        </Box>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            DNI cerrador:
          </Typography>
          <Typography>
            {closed_by}
          </Typography>
        </Box>
      </Box>
      :
      null}

      {cerrador ?
       <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"0.5em"}}>
       <Box sx={{display: "flex", flexDirection: "column"}}>
         <Typography mr={"1em"} fontWeight={"bold"}>
           Fecha cierre:
         </Typography>
         <Typography>
           {obtenerFecha(fecha_cierre).fecha}
         </Typography>
       </Box>
       <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
         <Typography mr={"1em"} fontWeight={"bold"}>
         Hora cierre:
         </Typography>
         <Typography>
           {obtenerFecha(fecha_cierre).horario}
         </Typography>
       </Box>
     </Box>
        :
        null}


      {verificarPermiso(permisos, 'super_usuario') ?
      <Typography component={"h3"} variant={"h6"} mt={"1em"} mb={"1em"} fontSize={"1.2em"} sx={{ textDecoration: 'underline' }}>Datos Auditoría</Typography>
      :null}

      {verificarPermiso(permisos, 'super_usuario') ?
      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"0.5em"}}>
        <Box sx={{display: "flex", flexDirection: "column"}}>
          <Typography mr={"1em"} fontWeight={"bold"}>
            Creada por:
          </Typography>
          <Typography>
            {creador}
          </Typography>
        </Box>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            DNI creador:
          </Typography>
          <Typography>
            {created_by}
          </Typography>
        </Box>
      </Box>
      :null}

    {verificarPermiso(permisos, 'super_usuario') ?
    <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"0.5em"}}>
      <Box sx={{display: "flex", flexDirection: "column"}}>
        <Typography mr={"1em"} fontWeight={"bold"}>
          Fecha creación:
        </Typography>
        <Typography>
          {obtenerFecha(fecha_creacion).fecha}
        </Typography>
      </Box>
      <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
        <Typography mr={"1em"} fontWeight={"bold"}>
        Hora creación:
        </Typography>
        <Typography>
          {obtenerFecha(fecha_creacion).horario}
        </Typography>
      </Box>
    </Box>
    :null}





    {modificador ?
    <>
    <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"0.5em"}}>
      <Box sx={{display: "flex", flexDirection: "column"}}>
        <Typography mr={"1em"} fontWeight={"bold"}>
          {eliminada ? "Eliminada por:" : "Ult. vez modificada por:"}
        </Typography>
        <Typography>
          {modificador}
        </Typography>
      </Box>
      <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
        <Typography mr={"1em"} fontWeight={"bold"}>
          DNI:
        </Typography>
        <Typography>
          {dni_modificador}
        </Typography>
      </Box>
    </Box>
      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"0.5em"}}>
      <Box sx={{display: "flex", flexDirection: "column"}}>
        <Typography mr={"1em"} fontWeight={"bold"}>
          {eliminada ? "Fecha eliminacion:" : "Fecha ult. modificación:"}
        </Typography>
        <Typography>
          {obtenerFecha(fecha_eliminacion).fecha}
        </Typography>
      </Box>
      <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
        <Typography mr={"1em"} fontWeight={"bold"}>
        {eliminada ? "Hora eliminacion:" : "Hora ult. modificación:"}
        </Typography>
        <Typography>
          {obtenerFecha(fecha_eliminacion).horario}
        </Typography>
      </Box>
    </Box>
    </>
    :
    null}








    </Grid>
  );
};

export default AcercaDesignacion;
