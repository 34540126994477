import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from '../../hooks/useModal';
import { useNavigate, useParams } from 'react-router-dom';
// import { useDecrypt } from '../../hooks/useDecrypt';

//Componentes
import ModalComponent from '../ModalComponent/ModalComponent';

// Funciones de ayuda
import { obtenerFecha } from '../../utils/obtenerFecha';

//Actions
import { listar_designaciones, listar_designaciones_actual, buscar_por_id, cerrar_designacion, eliminar_designacion, restaurar_mensajes } from '../../redux/action-types/designacionActions';

// MUI componentes
import { 
  Box,
  Button, 
  Grid, 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers';
import { inputLabelClasses } from "@mui/material/InputLabel";

// Icons
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Delete } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import HistoryIcon from '@mui/icons-material/History';

// Utils
import { verificarPermiso } from '../../utils/verificarPermiso';

const initialState = {
  fecha_fin: null,
  fecha_inicio : '',
  id: '',
  delete: false,
};

const ListadoVigentes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dni } = useParams();

  const { vigentes, status, mensaje } = useSelector(state => state.designaciones);
  const permisos = window.localStorage.getItem("permisos");
  // const permisos = useDecrypt('permisos');

  const [isOpenModal, openModal, closeModal] = useModal();

  const [datosObtenidos, setDatosObtenidos] = useState(false);
  const [ state, setState  ] = useState(initialState);

  const dniActual  = useSelector(state => state.acceso.dni);

  const handleClickCreador = creador => {
    navigate(`/perfil/${creador}`);
  };

  const handleClickDependencia = (id) => {
    window.sessionStorage.setItem('urlAnterior', `/designaciones/${dni}`);
    navigate(`/dependencia/${id}`);
  };

  const handleClickVersions = (id) => {
    window.sessionStorage.setItem('urlAnterior', `/designaciones/${dni}`);
    navigate(`/historial-designacion/${id}`);
  };

  const handleEdit = (id) => {
    window.sessionStorage.setItem('urlAnterior', `/designaciones/${dni}`);
    dispatch(buscar_por_id(id)); 
    navigate(`/designacion/${id}`);
  };

  const handleDelete = () => {
    const data = {...state,
      eliminado_por: dniActual};
    dispatch(eliminar_designacion(data));
    setTimeout(() => {        
      closeModal();
      dispatch(dni === dniActual ? listar_designaciones_actual(dni) : listar_designaciones(dni));
    }, 3000);
    dispatch(restaurar_mensajes());
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleClickDelete = (id) => {
    state.delete= true;
    state.id = id;
    dispatch(restaurar_mensajes());
    openModal();
  };

  const handleClick = (id) => {
    state.delete= false;
    state.id = id;
    dispatch(restaurar_mensajes());
    openModal();
  };

  const handleClose = () => {
    const data = {...state, cerrado_por: dniActual}
    dispatch(cerrar_designacion(data));
    setTimeout(() => {        
      closeModal();
      dispatch(dni === dniActual ? listar_designaciones_actual(dni) : listar_designaciones(dni));
    }, 3000);
    dispatch(restaurar_mensajes());
  };

  useEffect(() => {
      dispatch(dni === dniActual ? listar_designaciones_actual(dni) : listar_designaciones(dni));
      setTimeout(() => {
          dispatch(restaurar_mensajes());
          setDatosObtenidos(true);
        }, 1000); 
  }, [dispatch, dniActual, dni])
  
  if(!datosObtenidos) {
    return (
      <>  
        <Box> 
          <Typography component={"h6"} variant={"h6"} fontSize={"1em"}>
            ...
          </Typography>
        </Box>
      </>
    );
  };

  return (
    <>
      {
        vigentes && vigentes.length > 0 ?
          <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
            <Table aria-label="simple table"> 
              <TableHead key={"head"}>
                <TableRow key={"0"} sx={{bgcolor:'primary.light'}}>
                  <TableCell key={"date_init"} align="center">Fecha inicio</TableCell>
                  <TableCell key={"codigo_cargo"} align='center'>Codigo cargo</TableCell>
                  <TableCell key={"tipo_cargo"} align='center'>Tipo cargo</TableCell>
                  <TableCell key={"descripcion_cargo"} align='center'>Descripcion cargo</TableCell>
                  <TableCell key={"dependencia"} align='center'>Dependencia</TableCell>
                  <TableCell key={"oficina"} align='center'>Oficina</TableCell>
                  <TableCell key={"createdby"} align="center">Creado por</TableCell>
                  {
                    verificarPermiso(permisos, 'usuario_administrador') ?
                      <>
                        <TableCell key={"edit"} align="center">Editar</TableCell>
                        <TableCell key={"close"} align='center'>Pasar a historico</TableCell>
                      </>
                    :
                    null
                  }
                  {
                    verificarPermiso(permisos, 'super_usuario') ?
                    <>
                      <TableCell key={"history"} align='center'>Versiones anteriores</TableCell>
                      <TableCell key={"delete"} align='center'>Eliminar</TableCell>
                    </>
                    :
                      null
                  }
                </TableRow>
              </TableHead>
              {
                <TableBody>
                  {
                    vigentes && vigentes.map(designacion => {
                      return (
                        <TableRow                                
                          key={designacion.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >                    
                            <TableCell key={"fecha_inicio"} align="center">{obtenerFecha(designacion.fecha_inicio).fecha}</TableCell>
                            <TableCell key={"codigo_cargo"} align='center'>{designacion.cargo?.codigo}</TableCell>
                            <TableCell key={"tipo_cargo"} align='center'>{designacion.cargo?.tipo}</TableCell>
                            <TableCell key={"descripcion_cargo"} align='center'>{designacion.cargo?.descripcion}</TableCell>
                            <TableCell key={"dependency"} align='center'
                            sx={{
                                '&:hover': {
                                  cursor: 'pointer',
                                  borderRadius: '.5em',
                                  boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                }
                              }}
                              onClick={() => handleClickDependencia(designacion.cabecera?.id)}
                          >
                            {designacion.cabecera?.name}
                            </TableCell>
                            {designacion.cabecera?.id !== designacion.dependency_id ?
                            <TableCell key={"office"} align='center'
                            sx={{
                                '&:hover': {
                                  cursor: 'pointer',
                                  borderRadius: '.5em',
                                  boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                }
                              }}
                              onClick={() => handleClickDependencia(designacion.dependency_id)}
                          >
                            {designacion.dependency.name}
                            </TableCell>
                            :
                            <TableCell key={"office"} align='center'>-</TableCell>
                            }
                            <TableCell key={"creadopor"} align="center" 
                                sx={{
                                '&:hover': {
                                    cursor: 'pointer',
                                    borderRadius: '.5em',
                                    boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                }
                                }}
                                onClick={() => handleClickCreador(designacion.created_by)}
                            >
                                {designacion.creador}
                            </TableCell>   
                            {
                              verificarPermiso(permisos, 'usuario_administrador') ?
                                <>
                                  <TableCell key={"editIcon"} align='center'
                                  sx={{
                                      '&:hover': {
                                          cursor: 'pointer',
                                          borderRadius: '.5em',
                                          boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                      }
                                  }}
                                  onClick={() => handleEdit(designacion.id)}
                                    >
                                        <ModeEditIcon aria-label="Editar" color='primary' fontSize='large'></ModeEditIcon>
                                    </TableCell>
                                  <TableCell key={"closeIcon"} align='center'
                                        sx={{
                                            '&:hover': {
                                                cursor: 'pointer',
                                                borderRadius: '.5em',
                                                boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                            }
                                        }}
                                        onClick={() => handleClick(designacion.id)}
                                    >
                                      <CloseIcon aria_label="close" color='primary' fontSize='large'></CloseIcon>
                                    </TableCell>
                                  {
                                    verificarPermiso(permisos, 'super_usuario') ?
                                    <> 
                                    <TableCell key={"historyIcon"} align='center'
                                        sx={{
                                            '&:hover': {
                                                cursor: 'pointer',
                                                borderRadius: '.5em',
                                                boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                            }
                                        }}
                                        onClick={() => handleClickVersions(designacion.id)}
                                      >
                                        <HistoryIcon aria_label="History" color='primary' fontSize='large'></HistoryIcon>
                                      </TableCell> 
                                      <TableCell key={"deleteIcon"} align='center'
                                        sx={{
                                            '&:hover': {
                                                cursor: 'pointer',
                                                borderRadius: '.5em',
                                                boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                            }
                                        }}
                                        onClick={() => handleClickDelete(designacion.id)}
                                      >
                                        <Delete aria_label="Delete" color='primary' fontSize='large'></Delete>
                                      </TableCell>
                                      </>
                                    : 
                                      null
                                  }          
                                </>
                              :
                                null
                            }                          
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              }
            </Table>
          </TableContainer> 
          :
            
          <Box sx={{display: "flex", justifyContent: "start", alignItems: "left", ml: "-3px", marginRight: '25px'}}> 
            <Typography component={"h3"} variant={"h6"} fontSize={"1em"}>
              No se encontraron designaciones vigentes... 
            </Typography>
          </Box>  
        }
      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        {
          /*Si hay mensaje y status, se muestra eso*/
          status
          ?
          <>
            <Typography id="modal-modal-title" component="h2"sx={{ fontSize: '1.3em' }} >
              {status}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {mensaje}
            </Typography>
          </>
          :
          /*Si no hay mensaje y el flag de delete esta en true, se muestra el componente para eliminar */
          state.delete ?
          <>
            <Typography id="modal-modal-title" component="h2" sx={{ fontSize: '1.3em' }}>
              Esta por eliminar la designacion. ¿Desea continuar?
            </Typography>
            <Button
              type="submit"
              variant="contained"
              onClick={closeModal}
              sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                marginRight: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={handleDelete}
              sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'secondary.light',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Eliminar
            </Button>
          </>
        /*FIN DE MODAL DE ELIMINACION */
          :
        /*SI EL FLAG ES FALSE, MUESTRA EL COMPONENTE DE CIERRE */  
          <>
            <Typography id="modal-modal-title" component="h2" sx={{ fontSize: '1.3em', marginBottom:"0.5em" }}>
                Para cerrar esta designacion debe ingresar una fecha de fin:
            </Typography>
            <Grid item xs={10} sm={10} md={10} lg={6}>               
                <Box sx={{display: "flex", marginBottom:"1.5em"}}>
                  <CalendarTodayIcon />
                  <Typography mr={"1em"} fontWeight={"bold"}>
                    Fecha Fin*:
                  </Typography>
                </Box>
                  <DatePicker 
                  id={'fecha_fin'} 
                  name="fecha_fin"
                  views={['year', 'month', 'day']}
                  timezone='America/Argentina/Buenos_Aires'
                  fullWidth
                  onChange={date => handleChange({ target: { value: date, name: 'fecha_fin' } })}
                  slotProps={{
                    textField: { InputLabelProps: { sx: {color: 'transparent',
                                                    [`&.${inputLabelClasses.shrink}`]: {
                                                      color: "black"}}
                                                    }},
                    toolbar: { toolbarFormat: 'dddd D MMM', hidden: true },
                  }}>          
                </DatePicker>
                                                              
            </Grid>
            <Button
              type="submit"
              variant="contained"
              onClick={closeModal}
              sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                marginRight: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Cancelar
            </Button>
            {state.fecha_fin === initialState.fecha_fin ? 
            <Button
              type="submit"
              variant="contained"
              disabled
              sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'primary.light',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Confirmar
            </Button>
            :
            <Button
              type="submit"
              variant="contained"
              onClick={handleClose}
              sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'primary.light',
                  color: 'text.secondary',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Confirmar
            </Button>
            }
          </>
          
        }
      </ModalComponent>
    </>
  );
};

export default ListadoVigentes;