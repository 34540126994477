import axios from 'axios';
import globalRouter from '../globalRouter.ts';
import { agregarMensaje } from '../redux/action-types/messageActions.js';

// Función de utilidad para agregar el token a las solicitudes
export const setAuthorizationToken = (token, config) => {
  if(token) {
    // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  } else {
    delete axios.defaults.headers.common["Authorization"];
  };
};

// Agregar el interceptor para todas las solicitudes salientes
export const setAuthorizationInterceptor = () => {
  axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token"); // Obtengo el token del localStorage
      setAuthorizationToken(token, config); // Agrego el token al encabezado de autorización
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
};

const interceptor = (store) => {
  axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401 && globalRouter.navigate) {
            // Use router.push() to navigate to the login screen
            store.dispatch(agregarMensaje("Lo sentimos. Debe estar logueado para acceder"))
            globalRouter.navigate("/acceso")
            // Throw an exception to stop further execution
            return Promise.reject('Unauthorized');
        }
        else if(error.response.status === 403 && globalRouter.navigate){
            store.dispatch(agregarMensaje("Lo sentimos. No tiene los permisos necesarios para acceder"))
            globalRouter.navigate("/")
            // Throw an exception to stop further execution
            return Promise.reject('Forbidden');
        }
        else if(error.response.status === 404 && globalRouter.navigate){
          store.dispatch(agregarMensaje("Lo sentimos. El elemento que esta buscando no existe"))
          globalRouter.navigate("/")
          // Throw an exception to stop further execution
          return Promise.reject('Not found');
        }
        // Handle other errors here
        return Promise.reject(error);
    }
  );
}
const for_export = {
  interceptor
}
export default for_export;


