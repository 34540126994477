import React from 'react';

// MUI componentes
import { 
  Typography, 
  useMediaQuery,
} from '@mui/material';

const Derechos = props => {
  const matches = useMediaQuery('(min-width:600px)');

  if(matches) {
    return (
      <Typography variant="body2" align="center" {...props}>
        

          SiCAP {new Date().getFullYear()} | Direccion General de Personal | Presidencia | UNLP 
      </Typography>
    );
  };
  
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      
        SiCAP {new Date().getFullYear()} | Presidencia | UNLP '
    </Typography>
  );
};

export default Derechos;