import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';
// import { useDecrypt } from '../../hooks/useDecrypt';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import ModalComponent from '../ModalComponent/ModalComponent';
import Pie from '../Pie/Pie';

// Actions
import { buscar_por_dni } from '../../redux/action-types/usuarioActions';
import { auditarUsuarios, auditarHorarios, auditarDesignaciones, auditarJustificacionesGenerales, auditarJustificacionesPersonales, setPeriodo } from '../../redux/action-types/auditoriaActions';

// MUI componentes
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

// MUI iconos
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Person2Icon from '@mui/icons-material/Person2';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import ApartmentIcon from '@mui/icons-material/Apartment';

const AuditoriaUsuarios = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { dni } = useParams();

  const dniActual = window.localStorage.getItem("dni");
  const tipo_rol = window.localStorage.getItem("rol");
  // const tipo_rol = useDecrypt('rol');

  const { usuario, flag, text } = useSelector(state => state.usuario);
  const { usuarios_audit, personales_audit, generales_audit, designaciones_audit, horarios_audit, periodo } = useSelector(state => state.auditoria)

  const [timeExceeded, setTimeExceeded] = useState(false);
  const [datosObtenidos, setDatosObtenidos] = useState(false);

  const [isOpenModal, openModal, closeModal] = useModal();

  const handleChange = (event) => {
    dispatch(setPeriodo(event.target.value));
  };

  const handleAtras = () => {
    navigate(`/buscar-usuario-auditoria`);
  };

  const handleNavigate = (url) => {
    window.sessionStorage.setItem('urlAnterior', location.pathname);
    navigate(url);
  };

  useEffect(() => {
    dispatch(buscar_por_dni(dni));
    setDatosObtenidos(false);
    if (!periodo){
      dispatch(setPeriodo(7));
    };
  }, [dispatch, dni, dniActual, tipo_rol, periodo]);

  useEffect(() => {
    if(periodo){
      dispatch(auditarUsuarios({dni, periodo}));
      dispatch(auditarHorarios({dni, periodo}));
      dispatch(auditarJustificacionesGenerales({dni, periodo}));
      dispatch(auditarJustificacionesPersonales({dni, periodo}));
      dispatch(auditarDesignaciones({dni, periodo}));
    }
    
  },[dispatch, dni, periodo]);

  useEffect(() => {
    if(usuarios_audit.length !== 0){
      setDatosObtenidos(true);
    }
  }, [usuarios_audit])

  useEffect(() => {
    // Configura el tiempo límite
    const timeoutId = setTimeout(() => {
      setTimeExceeded(true);
      if(!usuario) {
        setTimeout(() => {
          openModal();
          setTimeout(() => {
            navigate("/buscar-usuario-auditoria");
          }, 3000);
        }, 0);
      };
    }, 5000);
    // Limpia el temporizador
    return () => clearTimeout(timeoutId);
  }, [usuario, openModal, navigate]);

  // Si no hay datos del usuario
  if(!usuario && !timeExceeded) {
    return (
      <>
        {/* Navegación */}
        <Rotulo titulo={"Auditoría"} />
        <Grid container>
          <CircularProgress
            sx={{
              width: "auto",
              height: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </Grid>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  };

  // Si el usuario no puede acceder a esa informacion
  if(!usuario && timeExceeded && !flag) {
    return (
      <>
        {/* Navegación */}
        <Rotulo titulo={"Auditoría"} />
        {/* Componente de Notificaciones */}
        <ModalComponent open={isOpenModal} handleClose={closeModal} >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Redireccionando...
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {text}
          </Typography>
        </ ModalComponent>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  };
  if(!datosObtenidos){
    return (
      <>
        {/* Navegación */}
        <Rotulo titulo={"Auditoría"} />
        <Grid container>
          <CircularProgress
            sx={{
              width: "auto",
              height: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </Grid>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  }

  return (
    <>
      <Rotulo titulo={"Auditoría"} />
      <Grid container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column"
        }}
      >
        <Grid container xs={11} sm={8.5} md={7} lg={6} mt={6} mb={2}>
          <Box sx={{width: "100%", display: "flex", justifyContent: "space-between"}}>
          <Box sx={{width: "50%"}}>
          <Button
            variant="contained"
            sx={{
              alignSelf: "start",
              fontSize: ".8em",
              fontWeight: "bold",
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'background.paper',
                transform: 'scale(1.01)',
              }
            }}
            onClick={handleAtras}
          >
            Atrás
          </Button>
          </Box>
          <Box>
          <FormControl fullWidth>
            <InputLabel sx={{
              color: "black"
            }}>Periodo</InputLabel>            
            <Select              
              labelId="selector-tiempo"
              id="selector-tiempo"
              value={periodo}
              label="Periodo"
              autoWidth
              onChange={handleChange}
            >
              <MenuItem value={7}>Última semana</MenuItem>
              <MenuItem value={30}>Último mes</MenuItem>
              <MenuItem value={365}>Último año</MenuItem>
              <MenuItem value={10000}>Siempre</MenuItem>
            </Select>
          </FormControl>
          </Box>
          </Box>
          <Divider sx={{ width: "100%", height: "1em"}}/>
        </Grid>
        
        <Grid container xs={11} sm={8.5} md={7} lg={6} mt={1} mb={3}>
          <VerifiedUserIcon sx={{ mt: "4px" }}/>
          <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
            Auditoría a {usuario.apellido}, {usuario.nombre}. 
          </Typography>
        </Grid>

        <Grid item container xs={12} sm={11} md={10} lg={6}>
          <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow key={"0"} sx={{bgcolor:"primary.light"}}>
                  <TableCell key={"dni"} align="center" width={"auto"}>Entidad</TableCell>
                  <TableCell key={"date_init"} align="center" width={"15%"}>Creados</TableCell>
                  <TableCell key={"date_end"} align="center" width={"15%"}>Editados</TableCell>                  
                  <TableCell key={"justify"} align="center" width={"15%"}>Eliminados</TableCell>                  
                </TableRow>
              </TableHead>
              {
                <TableBody>                 
                        <TableRow
                          key={"usuarios"}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell key={"nombre"} align="center" sx={{display:"flex", alignItems: "center", justifyContent:"center"}}>
                          <Person2Icon />
                          <Typography sx={{marginLeft: "1em"}}>Usuarios</Typography>               
                          </TableCell>
                          <TableCell key={"usuarios_creados"} align="center" width={'20%'}
                          sx={{
                            '&:hover': {
                              cursor: 'pointer',
                              borderRadius: '.5em',
                              boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                            }
                          }}
                          onClick={() => handleNavigate(`/auditoria/usuario/${dni}/usuarios-creados`)}>
                            <Typography>
                            {usuarios_audit.cantidad_creados}
                            </Typography>
                            </TableCell>
                          <TableCell key={"usuarios_modificados"} align="center" width={'20%'}
                          sx={{
                            '&:hover': {
                              cursor: 'pointer',
                              borderRadius: '.5em',
                              boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                            }
                          }}
                          onClick={() => handleNavigate(`/auditoria/usuario/${dni}/usuarios-editados`)}>
                            <Typography>{usuarios_audit.cantidad_modificados}</Typography>
                          </TableCell>
                          <TableCell key={"usuarios_eliminados"} align="center" width={'20%'}>
                            <Typography>X</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow
                          key={"horarios"}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell key={"nombre"} align="center" sx={{display:"flex", alignItems: "center", justifyContent:"center"}}>
                          <AccessTimeFilledIcon />
                          <Typography sx={{marginLeft: "1em"}}>Horarios</Typography>                 
                          </TableCell>
                          <TableCell key={"horarios_creados"} align="center" width={'20%'}><Typography>X</Typography></TableCell>
                          <TableCell key={"horarios_modificados"} align="center" width={'20%'}
                          sx={{
                            '&:hover': {
                              cursor: 'pointer',
                              borderRadius: '.5em',
                              boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                            }
                          }}
                          onClick={() => handleNavigate(`/auditoria/usuario/${dni}/horarios-editados`)}>
                            <Typography>{horarios_audit.cantidad_modificados}</Typography>
                          </TableCell>
                          <TableCell key={"horarios_eliminados"} align="center" width={'20%'}><Typography>X</Typography></TableCell>
                        </TableRow>
                        <TableRow
                          key={"designaciones"}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell key={"nombre"} align="center"sx={{display:"flex", alignItems: "center", justifyContent:"center"}}>
                          <ApartmentIcon />
                          <Typography sx={{marginLeft: "1em"}}>Designaciones</Typography>                 
                          </TableCell>
                          <TableCell key={"designaciones_creados"} align="center" width={'20%'} 
                          sx={{
                            '&:hover': {
                              cursor: 'pointer',
                              borderRadius: '.5em',
                              boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                            }
                          }}
                          onClick={() => handleNavigate(`/auditoria/usuario/${dni}/designaciones-creadas`)}>
                            <Typography>{designaciones_audit.cantidad_creadas}</Typography>
                          </TableCell>
                          <TableCell key={"designaciones_modificados"} align="center" width={'20%'}
                          sx={{
                            '&:hover': {
                              cursor: 'pointer',
                              borderRadius: '.5em',
                              boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                            }
                          }}
                          onClick={() => handleNavigate(`/auditoria/usuario/${dni}/designaciones-editadas`)}>
                            <Typography>{designaciones_audit.cantidad_modificadas}</Typography>
                            </TableCell>
                          <TableCell key={"designaciones_eliminados"} align="center" width={'20%'}
                          sx={{
                            '&:hover': {
                              cursor: 'pointer',
                              borderRadius: '.5em',
                              boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                            }
                          }}
                          onClick={() => handleNavigate(`/auditoria/usuario/${dni}/designaciones-eliminadas`)}>
                            <Typography>{designaciones_audit.cantidad_eliminadas}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow
                          key={"justificaciones_p"}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell key={"nombre"} align="center" sx={{display:"flex", alignItems: "center", justifyContent:"center"}}>
                          <CheckIcon />
                            <Typography sx={{marginLeft: "1em"}}>Justificaciones personales</Typography>               
                          </TableCell>
                          <TableCell key={"justificaciones_p_creados"} align="center" width={'20%'}
                          sx={{
                            '&:hover': {
                              cursor: 'pointer',
                              borderRadius: '.5em',
                              boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                            }
                          }}
                          onClick={() => handleNavigate(`/auditoria/usuario/${dni}/justificaciones-creadas`)}>
                            <Typography>{personales_audit.cantidad_creadas}</Typography>
                            </TableCell>
                          <TableCell key={"justificaciones_p_modificados"} align="center" width={'20%'}
                          sx={{
                            '&:hover': {
                              cursor: 'pointer',
                              borderRadius: '.5em',
                              boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                            }
                          }}
                          onClick={() => handleNavigate(`/auditoria/usuario/${dni}/justificaciones-editadas`)}>
                            <Typography>{personales_audit.cantidad_modificadas}</Typography>
                            </TableCell>
                          <TableCell key={"justificaciones_p_eliminados"} align="center" width={'20%'}
                          sx={{
                            '&:hover': {
                              cursor: 'pointer',
                              borderRadius: '.5em',
                              boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                            }
                          }}
                          onClick={() => handleNavigate(`/auditoria/usuario/${dni}/justificaciones-eliminadas`)}>
                            <Typography>{personales_audit.cantidad_eliminadas}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow
                          key={"justificaciones_g"}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell key={"nombre"} align="center" sx={{display:"flex", alignItems: "center", justifyContent:"center"}}>
                          <DoneAllIcon />
                          <Typography sx={{marginLeft: "1em"}}>Justificaciones generales</Typography>                 
                          </TableCell>
                          <TableCell key={"justificaciones_g_creados"} align="center" width={'20%'}
                          sx={{
                            '&:hover': {
                              cursor: 'pointer',
                              borderRadius: '.5em',
                              boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                            }
                          }}
                          onClick={() => handleNavigate(`/auditoria/usuario/${dni}/justificaciones-generales-creadas`)}>
                            <Typography>{generales_audit.cantidad_creadas}</Typography>
                            </TableCell>
                          <TableCell key={"justificaciones_g_modificados"} align="center" width={'20%'}
                          sx={{
                            '&:hover': {
                              cursor: 'pointer',
                              borderRadius: '.5em',
                              boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                            }
                          }}
                          onClick={() => handleNavigate(`/auditoria/usuario/${dni}/justificaciones-generales-editadas`)}>
                            <Typography>{generales_audit.cantidad_modificadas}</Typography>
                            </TableCell>
                          <TableCell key={"justificaciones_g_eliminados"} align="center" width={'20%'}
                          sx={{
                            '&:hover': {
                              cursor: 'pointer',
                              borderRadius: '.5em',
                              boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                            }
                          }}
                          onClick={() => handleNavigate(`/auditoria/usuario/${dni}/justificaciones-generales-eliminadas`)}>
                            <Typography>{generales_audit.cantidad_eliminadas}</Typography>
                            </TableCell>
                        </TableRow>
                
                </TableBody>
              }
            </Table>
          </TableContainer>
        </Grid>
        {/* Usuarios */}
        {/* <Grid item container xs={12} sm={10} md={10} lg={8}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Resumen
            icono={<Person2Icon />}
            titulo={'Usuarios Creados'}
            cantidad={usuarios_audit.cantidad_creados}
            url={`/auditoria/usuario/${dni}/usuarios-creados`}
          />
          <Resumen
            icono={<Person2Icon />}
            titulo={'Usuarios Editados'}
            cantidad={usuarios_audit.cantidad_modificados}
            url={`/auditoria/usuario/${dni}/usuarios-editados`}
          />
          <Resumen
            icono={<AccessTimeFilledIcon/>}
            titulo={'Horarios Editados'}
            cantidad={horarios_audit.cantidad_modificados}
            url={`/auditoria/usuario/${dni}/horarios-editados`}
          />
        </Grid> */}


        {/* Designacion */}
        {/* <Grid item container xs={12} sm={10} md={10} lg={8}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Resumen
            icono={<ApartmentIcon/>}
            titulo={'Designaciones Creadas'}
            cantidad={designaciones_audit.cantidad_creadas}
            url={`/auditoria/usuario/${dni}/designaciones-creadas`}
          />
          <Resumen
            icono={<ApartmentIcon/>}
            titulo={'Designaciones Editadas'}
            cantidad={designaciones_audit.cantidad_modificadas}
            url={`/auditoria/usuario/${dni}/designaciones-editadas`}
          />
          <Resumen
            icono={<ApartmentIcon/>}
            titulo={'Designaciones Eliminadas'}
            cantidad={designaciones_audit.cantidad_eliminadas}
            url={`/auditoria/usuario/${dni}/designaciones-eliminadas`}
          />
        </Grid> */}

        {/* Justificaciones Personales   */}
        {/* <Grid item container xs={12} sm={10} md={10} lg={8}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Resumen
            icono={<CheckIcon/>}
            titulo={'Justificaciones Creadas'}
            cantidad={personales_audit.cantidad_creadas}
            url={`/auditoria/usuario/${dni}/justificaciones-creadas`}
          />
          <Resumen
            icono={<CheckIcon/>}
            titulo={'Justificaciones Editadas'}
            cantidad={personales_audit.cantidad_modificadas}
            url={`/auditoria/usuario/${dni}/justificaciones-editadas`}
          />
          <Resumen
            icono={<CheckIcon/>}
            titulo={'Justificaciones Eliminadas'}
            cantidad={personales_audit.cantidad_eliminadas}
            url={`/auditoria/usuario/${dni}/justificaciones-eliminadas`}
          />
        </Grid> */}

        {/* Justificaciones Personales */}
        {/* <Grid item container xs={12} sm={10} md={10} lg={8}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Resumen
            icono={<DoneAllIcon/>}
            titulo={'Justificaciones Generales Creadas'}
            cantidad={generales_audit.cantidad_creadas}
            url={`/auditoria/usuario/${dni}/justificaciones-generales-creadas`}
          />
          <Resumen
            icono={<DoneAllIcon/>}
            titulo={'Justificaciones Generales Editadas'}
            cantidad={generales_audit.cantidad_modificadas}
            url={`/auditoria/usuario/${dni}/justificaciones-generales-editadas`}
          />
          <Resumen
            icono={<DoneAllIcon/>}
            titulo={'Justificaciones Generales Eliminadas'}
            cantidad={generales_audit.cantidad_eliminadas}
            url={`/auditoria/usuario/${dni}/justificaciones-generales-eliminadas`}
          />
        </Grid>*/}
      </Grid>
      {/* Pie de Pág */}
      <Pie />
    </>
  );
};

export default AuditoriaUsuarios;
