import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

// Componentes
import SearchInput from '../SearchInput/SearchInput';
import Usuario from '../Usuario/Usuario';
import Rotulo from '../Rotulo/Rotulo';
import Pie from '../Pie/Pie';

// Acciones
import { buscarUsuario, restaurarEstado } from '../../redux/action-types/usuarioActions';

// MUI Componentes
import { 
  Box,
  Container,  
  Grid,
} from '@mui/material';

const BuscarMarcaciones = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { usuarios } = useSelector(state => state.usuario);

  const [ usuario, setUsuario ] = useState('');

  const handleChange = (event) => {
    setUsuario(event.target.value);
  };
  
  const handleMarcaciones = (dni) => {
    navigate(`/marcaciones/${dni}`);
  };

  const handleClick = () => {
    if(usuario.length > 0) dispatch(buscarUsuario({usuario}));
  };

  useEffect(() => {
    return () => {
      dispatch(restaurarEstado());
    };
  }, [dispatch]);

  return (
    <>
      <Rotulo titulo={"Buscar Usuarios"} />
      {/* Contenedor del componente */}
      <Container maxWidth="full" 
        sx={{ 
          width: '100vw',
          height: '90%', 
          display:"flex",
          justifyContent: 'center',
          position: 'absolute',
          top: '10vh',
          marginBottom: '5em',
        }}
      >
        <Grid component="main" 
          sx={{
            width: '50%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '2em',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingBottom: '2em',
            }}
          >
            {/* Search Input */}
            <SearchInput handleChange={handleChange} handleClick={handleClick} placeholder={"Ingrese el nombre o dni"} />
            {/* Renderizado de resultados */}
            {
              usuarios && usuarios.map(usuario => {
                return (
                  <Usuario 
                    key={usuario.dni ? usuario.dni : usuario.usuario.dni}
                    nombre={usuario.nombre ? usuario.nombre : usuario.usuario.nombre} 
                    apellido={usuario.apellido ? usuario.apellido : usuario.usuario.apellido} 
                    dni={usuario.dni ? usuario.dni : usuario.usuario.dni} 
                    historico={usuario.designaciones}
                    handleClick={() => handleMarcaciones(usuario.dni ? usuario.dni : usuario.usuario.dni)}
                  />
                )
              })
            }
          </Box>
        </Grid>
      </Container>
      {/* Pie de Pág. */}
      <Pie />
    </>
  );
};

export default BuscarMarcaciones;