import React from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';


// MUI componentes
import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
} from '@mui/material';

// Utils
import { obtenerFecha } from '../../utils/obtenerFecha';



const ListadoAuditoria = () => {

    const navigate = useNavigate();

    const handleClickCreador = (dni, creador) => {
        window.sessionStorage.setItem('urlAnterior', `/detalle-perfil/${dni}`);
        navigate(`/perfil/${creador}`);
        };

  const { usuario } = useSelector(state => state.usuario);
  const { historial_versiones } = useSelector(state => state.auditoria);

  return (
    <>       
    <Grid item xs={10} sm={10} md={8} lg={10}>
    <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
        <Table aria-label="simple table"> 
        <TableHead key={"head"}>
            <TableRow key={"0"} sx={{bgcolor:"auditory.main"}}>
            <TableCell key={"name"} align="center">Nombre</TableCell>
            <TableCell key={"lastname"} align="center">Apellido</TableCell>
            <TableCell key={"dni"} align='center'>DNI</TableCell>
            <TableCell key={"gender"} align='center'>Genero</TableCell>
            <TableCell key={"born"} align='center'>Nacimiento</TableCell>
            <TableCell key={"city"} align='center'>Ciudad</TableCell>
            <TableCell key={"country"} align="center">Pais</TableCell>
            <TableCell key={"addres"} align="center">Direccion</TableCell>
            <TableCell key={"email"} align="center">E-mail</TableCell>
            <TableCell key={"phone"} align="center">Telefono</TableCell>
            <TableCell key={"cellphone"} align="center">Celular</TableCell>
            <TableCell key={"modified_by"} align="center">Modificado por</TableCell>
            <TableCell key={"modified_at"} align="center">Modificado fecha</TableCell>
            <TableCell key={"modified_hour"} align="center">Modificado hora</TableCell>
            </TableRow>
        </TableHead>
        {
            <TableBody>
            {
                historial_versiones && historial_versiones.map((usuario_h, index) => {
                    const esPrimero = index === 0;
                    const cambioAnterior = esPrimero ? usuario : historial_versiones[index -1];

                    const colorFondoNombre = usuario_h.nombre !== cambioAnterior.nombre ? "auditory.third" : null;
                    const colorFondoApellido = usuario_h.apellido !== cambioAnterior.apellido ? "auditory.third" : null;
                    const colorFondoGenero = usuario_h.genero !== cambioAnterior.genero ? "auditory.third" : null;
                    const colorFondoNacimiento = usuario_h.nacimiento !== cambioAnterior.nacimiento ? "auditory.third" : null;
                    const colorFondoCiudad = usuario_h.ciudad !== cambioAnterior.ciudad ? "auditory.third" : null;
                    const colorFondoPais = usuario_h.pais !== cambioAnterior.pais ? "auditory.third" : null;
                    const colorFondoDireccion = usuario_h.direccion !== cambioAnterior.direccion ? "auditory.third" : null;
                    const colorFondoEmail = usuario_h.email !== cambioAnterior.email ? "auditory.third" : null;
                    const colorFondoCelular = usuario_h.celular !== cambioAnterior.celular ? "auditory.third" : null;
                    const colorFondoTelefono = usuario_h.telefono !== cambioAnterior.telefono ? "auditory.third" : null;
                
                return (
                    <TableRow
                    key={`${usuario_h.dni}${usuario_h.updated_at}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell key={"nombre"} align="left"
                    sx={{bgcolor: colorFondoNombre}}>{usuario_h.nombre}</TableCell>
                    <TableCell key={"apellido"} align="left"
                    sx={{bgcolor: colorFondoApellido}}>{usuario_h.apellido}</TableCell>
                    <TableCell key={"dni"} align='center'
                    >{usuario_h.dni}</TableCell>
                    <TableCell key={"genero"} align='center'
                    sx={{bgcolor: colorFondoGenero}}>{usuario_h.genero}</TableCell>
                    <TableCell key={"nacimiento"} align='center'
                    sx={{bgcolor: colorFondoNacimiento}}>{obtenerFecha(usuario_h.nacimiento).fecha}</TableCell>
                    <TableCell key={"ciudad"} align="left"
                    sx={{bgcolor: colorFondoCiudad}}>{usuario_h.ciudad}</TableCell>
                    <TableCell key={"pais"} align="left"
                    sx={{bgcolor: colorFondoPais}}>{usuario_h.pais}</TableCell>
                    <TableCell key={"direccion"} align='center'
                    sx={{bgcolor: colorFondoDireccion}}>{usuario_h.direccion}</TableCell>
                    <TableCell key={"email"} align='center'
                    sx={{bgcolor: colorFondoEmail}}>{usuario_h.email}</TableCell>
                    <TableCell key={"celular"} align='center'
                    sx={{bgcolor: colorFondoCelular}}>{usuario_h.celular}</TableCell>
                    <TableCell key={"telefono"} align='center'
                    sx={{bgcolor: colorFondoTelefono}}>{usuario_h.telefono}</TableCell>                    
                    <TableCell key={"modificadopor"} align="center" 
                        sx={{
                        '&:hover': {
                            cursor: 'pointer',
                            borderRadius: '.5em',
                            boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                        }
                        }}
                        onClick={() => handleClickCreador(usuario_h.dni, usuario_h.updated_by)}
                    >
                        {usuario_h.modificador}
                    </TableCell>
                    <TableCell align="center">{obtenerFecha(usuario_h.updated_at).fecha}</TableCell>
                    <TableCell align="center">{obtenerFecha(usuario_h.updated_at).horario}</TableCell>
                    
                    </TableRow>
                );
                })
            }
            </TableBody>
            }
            </Table>
        </TableContainer>
        </Grid>        
    </>
  );
};

export default ListadoAuditoria;
