import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useModal } from '../../hooks/useModal';

//Actions
import { informacionCreacion } from '../../redux/action-types/usuarioActions';
import { listar_designaciones, listar_designaciones_actual, crear_designacion, restaurar_mensajes } from '../../redux/action-types/designacionActions';
import { buscar_dependencias_hijas } from '../../redux/action-types/dependenciaActions';
import { cargo_por_tipo } from '../../redux/action-types/cargoActions';


//Componentes
import ModalComponent from '../ModalComponent/ModalComponent';

// MUI componentes
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers';
import { inputLabelClasses } from "@mui/material/InputLabel";

//Icons
import InfoIcon from '@mui/icons-material/Info';
import SchemaIcon from '@mui/icons-material/Schema';

const initialState = {
    fecha_inicioInput: '',
    cargo_Input: null,
    objcargo_Input: null,
    dependencia_Input: '',
    hijo_Input: '',
    objhijo_Input: null,
    tipo_contratoInput: '',
};

const CrearDesignacion = () => {
    const dispatch = useDispatch();

    const { dependencias, tipo_cargos } = useSelector(state => state.usuario);
    const dniActual  = useSelector(state => state.acceso.dni);
    const { status, mensaje } = useSelector(state => state.designaciones);
    const { cargos } = useSelector(state => state.cargos);


    const [ state, setState ] = useState(initialState);

    const [ isOpenModal, openModal, closeModal ] = useModal();

    const { dni } = useParams();
    const { hijas } = useSelector(state => state.dependencia);

    const handleChangePadre = event => {
      const { name, value } = event.target;
      setState({
        ...state,
        [name]: value,
      });
      dispatch(buscar_dependencias_hijas(value))
    };

    const handleChangeTipo = event => {
      const { name, value } = event.target;
      setState({
        ...state,
        [name]: value,
        cargo_Input: null,
        objcargo_Input: null,
      });
      dispatch(cargo_por_tipo(value))
    };

    const handleChangeCargo = (event, newValue) => {
      const selectedOption = newValue; // El objeto completo seleccionado
      const name = "cargo_Input";
      const objeto = 'obj'+name;

      setState({
        ...state,
        [name]: selectedOption ? selectedOption.codigo : null, // Guarda el ID del objeto o null si no hay selección
        [objeto]: selectedOption ? selectedOption : null,
      });
    };

    const handleChangeHijo = (event, newValue) => {
      const selectedOption = newValue; // El objeto completo seleccionado
      const name = "hijo_Input";
      const objeto = 'obj'+name;

      setState({
        ...state,
        [name]: selectedOption ? selectedOption.id : null, // Guarda el ID del objeto o null si no hay selección
        [objeto]: selectedOption ? selectedOption : null,
      });
    };

    const handleChange = event => {
      const { name, value } = event.target;
      setState({
        ...state,
        [name]: value,
      });
    };

    const handleCancel = (event) => {
      event.preventDefault();
      setState(initialState);
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      const data = {...state, creado_por: dniActual, titular_designacion: dni};
      dispatch(crear_designacion(data));
      openModal();
      setTimeout(() => {
          closeModal();
          dispatch(dni === dniActual ? listar_designaciones_actual(dni) : listar_designaciones(dni));
      }, 3000);
      setState(initialState);
      dispatch(restaurar_mensajes());
    };


    useEffect(() => {
        dispatch(informacionCreacion());
    }, [dispatch, dni]);

    return (
      <>
      {/* Aca arranca el componente Crear Justificacion */}
      <Grid id={"crearDesignacion"}  item container component="form" noValidate onSubmit={handleSubmit} spacing={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
       
          {/* Item 1 */}
          <Grid item xs={10} sm={11} md={12} lg={12}>

              <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "0.5em"}}>
              <InfoIcon />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                  Informacion de la designacion
                </Typography>
              </Box>
              <Grid container xs={12} sm={12} md={12} lg={12} spacing={6}>

              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Box sx={{display: "flex", flexDirection: "column", marginBottom:"0.5em"}}>

                <Typography mr={"1em"} fontWeight={"bold"}>
                  Tipo cargo*:
                </Typography>
                <TextField
                    labelid="tipo_contratoInput"
                    id="tipo_contratoInput"
                    name="tipo_contratoInput"
                    value={state.tipo_contratoInput}
                    fullWidth
                    onChange={handleChangeTipo}
                    select
                    InputLabelProps={{
                      sx: {
                        color: 'rgb(150, 150, 150)',
                      }
                    }}
                  >
                    {
                      tipo_cargos && tipo_cargos.map(tipo => {
                        return (
                          <MenuItem key={tipo}
                            value={tipo}
                          >
                            {tipo}
                          </MenuItem>
                        )
                      })
                    }
                </TextField>
              </Box>

              {/* Item 2 */}
              <Box sx={{display: "flex", flexDirection: "column", marginBottom:"0.5em"}}>

                <Typography mr={"1em"} fontWeight={"bold"}>
                      Cargo*:
                </Typography>
                {cargos && (<Autocomplete
                      id="cargo_Input"
                      name="cargo_Input"
                      options={cargos.filter(option => option.codigo !== 'CO')}

                      value={state.objcargo_Input}

                      getOptionLabel={(option) => `${option.codigo} - ${option.descripcion}`}
                      onChange={handleChangeCargo}
                      noOptionsText={`No se encontraron cargos`}
                      renderOption={(props, option) => (
                        <Box key={option.codigo}
                            component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          
                          {option.codigo} - {option.descripcion}
                        </Box>
                      )}
                      renderInput={(params) => <TextField {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',}} />}
                    ></Autocomplete>)}

              </Box>
              </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Box sx={{display: "flex", flexDirection: "column", marginBottom:"0.5em"}}>

                    <Typography mr={"1em"} fontWeight={"bold"}>
                      Fecha Inicio*:
                    </Typography>
                    <DatePicker
                    id={'fecha_inicioInput'}
                    name="fecha_inicioInput"
                    views={['year', 'month', 'day']}
                    fullWidth
                    onChange={date => handleChange({ target: { value: date, name: 'fecha_inicioInput' } })}
                    slotProps={{
                      textField: { InputLabelProps: { sx: {color: 'transparent',
                                                      [`&.${inputLabelClasses.shrink}`]: {
                                                        color: "black"}}
                                                      }},
                      toolbar: { toolbarFormat: 'dddd D MMM', hidden: false },
                    }}>
                  </DatePicker>
                  </Box>
                </Grid>



              {/* Item 4*/}
              
            </Grid>
          </Grid>
          {/* Item 2 */}
          <Grid item xs={10} sm={11} md={12} lg={12}>
            {/* Item 3*/}
            
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "0.5em"}}>
              <SchemaIcon />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                Lugar de trabajo
              </Typography>
            </Box>

            <Grid container xs={12} sm={12} md={12} lg={12} spacing={6}>

              <Grid item xs={12} sm={12} md={12} lg={6}>
              <Box sx={{display: "flex", flexDirection: "column", marginBottom:"0.5em"}}>
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Dependencia*:
                </Typography>
                <TextField
                  labelid="dependencia_Input"
                  id="dependencia_Input"
                  name="dependencia_Input"
                  value={state.dependencia_Input}
                  fullWidth
                  onChange={handleChangePadre}
                  select
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                >
                  {
                    dependencias && dependencias.map(dependencia => {
                      if(dependencia.name === 'UNLP') {
                        return null;
                      };
                      return (
                        <MenuItem key={dependencia.id}
                          value={dependencia.id}
                        >
                          {dependencia.name}
                        </MenuItem>
                      )
                    })
                  }
              </TextField>

              </Box>
              </Grid>
            {/* Item 3*/}
            <Grid item xs={12} sm={12} md={12} lg={6}>
              {state.dependencia_Input && hijas ?

              <Box sx={{display: "flex", flexDirection: "column", marginBottom:"0.5em"}}>
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Oficina:
                </Typography>
                {hijas && (<Autocomplete
                      id="hijo_Input"
                      name="hijo_Input"
                      options={hijas}
                      value={state.objhijo_Input}
                      getOptionLabel={(option) => `${option.name}`}
                      disabled={!state.dependencia_Input || !hijas}
                      onChange={handleChangeHijo}
                      noOptionsText={`No se encontraron oficinas`}
                      renderOption={(props, option) => (
                        <Box key={option.id}
                            component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          
                          {option.name} 
                        </Box>
                      )}
                      renderInput={(params) => <TextField {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',}} />}
                    ></Autocomplete>)}

              </Box>
              :
              <></>
          
            }
            </Grid>
          </Grid>
        </Grid>

          {/* Item 5 */}
          <Grid item container xs={10} sm={11} md={12} lg={12}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  mb: 2,
                  mr: 1,
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              {
                (state.fecha_inicioInput === initialState.fecha_inicioInput ||
                  state.cargo_Input === initialState.cargo_Input ||
                  state.dependencia_Input === initialState.dependencia_Input ||
                  state.tipo_contratoInput === initialState.tipo_contratoInput)
                ?
                  <Button
                    type="submit"
                    variant="contained"
                    disabled
                    sx={{
                      mb: 2,
                      fontSize: ".8em",
                      fontWeight: "bold",
                      bgcolor: 'background.paper',
                      '&:hover': {
                        bgcolor: 'background.paper',
                        transform: 'scale(1.01)',
                      }
                    }}
                  >
                    Guardar
                  </Button>
                :
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      mb: 2,
                      fontSize: ".8em",
                      fontWeight: "bold",
                      bgcolor: 'background.paper',
                      '&:hover': {
                        bgcolor: 'background.paper',
                        transform: 'scale(1.01)',
                      }
                    }}
                  >
                    Guardar
                  </Button>
              }
              </Grid>
      </Grid>

      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
          <Typography id="modal-modal-title" variant="h6" component="h2">
          {status}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {mensaje}
          </Typography>
      </ ModalComponent>
    </>
  );
};

export default CrearDesignacion;
