import React from 'react';
import { useNavigate } from 'react-router-dom';

// MUI comoinentes
import { 
  Box,
  Typography,
} from '@mui/material';

// MUI iconos
import {
  AccountCircle,
} from '@mui/icons-material';

const UsuarioJustificaciones = ({ nombre, apellido, dni, imagen }) => {
  const navigate = useNavigate();

  const handlePerfil = () => {
    window.sessionStorage.setItem('urlAnterior', '/justificaciones-personales');  
    navigate(`/justificaciones-personales/${dni}`);
  };

  return (
    <Box maxWidth="full" component="div"
      sx={{
        width: '18em',
        minHeight: '5em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '.5em',
        borderRadius: '.5em',
        padding: ".5em",
        boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)',
        bgcolor: 'background.paper',
        "&:hover": {
          cursor: 'pointer',
          transform: 'scale(1.01)',
        }
      }}
      onClick={handlePerfil}
    >
      {
        imagen ? imagen : <AccountCircle sx={{ fontSize:"3em", color:"primary.light", }} />
      }
      <Box component="div" 
        sx={{
          width: "100%",
          display: "flex",
          flexDirection:"column",
          alignItems: "center"
        }}
      >
        <Box component="div">
          <Typography variant="p" fontSize="1.1em">
            {nombre} 
          </Typography>
          <Typography variant="p" fontSize="1.1em" marginLeft=".1em">
            {apellido}
          </Typography>
        </Box>
        <Typography variant="p" sx={{ fontSize:".95em", color: "primary.disabled", }}>
          {dni}
        </Typography>
      </Box>
    </Box>
  );
};

export default UsuarioJustificaciones;