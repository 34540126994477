import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// MUI componentes
import { 
  Box,
  Typography,
} from '@mui/material';

// MUI iconos
import ApartmentIcon from '@mui/icons-material/Apartment';

const Dependencia = ({ nombre, id, imagen }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const titulo = nombre.length > 70 ? nombre.split(' ').splice(0, 5).join(' ') + '(...)' : nombre;

  const handleDependencia = () => {
    if(location.pathname.split('/')[1] !== 'usuarios-dependencia') {
      window.sessionStorage.setItem('urlAnterior', location.pathname);
      navigate(`/dependencia/${id}`);
    };
  };

  return (
    <Box maxWidth="full" component="div"
      sx={{
        width: '26em',
        minHeight: '5em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '.5em',
        borderRadius: '.5em',
        padding: ".5em",
        boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)',
        bgcolor: 'background.paper',
        "&:hover": {
          cursor: 'pointer',
          transform: 'scale(1.01)',
        }
      }}
      onClick={handleDependencia}
    >
      {
        imagen ? imagen : <ApartmentIcon sx={{ fontSize:"2.5em", color:"primary.light" }} />
      }
      <Box component="div" 
        sx={{
          width: "100%",
          display: "flex",
          flexDirection:"column",
          marginLeft: ".5em"
        }}
      >
        <Box component="div">
          <Typography variant="p" fontSize="1.1em" >
            {titulo}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Dependencia;