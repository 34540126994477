import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    usuarios : [],
    usuarios_audit : [],
    personales_audit: [],
    generales_audit: [],
    designaciones_audit: [],
    horarios_audit: []
};

export const auditoriaSlice = createSlice({
  name: 'auditoriaReducer',
  initialState,
  reducers: {
    buscar: (state, { payload })=> {
        return {
            ...state,
            usuarios: payload.usuarios,
            mensaje: payload.text
        };
      },
    audit_user: (state, { payload })=> {
      return {
          ...state,
          usuarios_audit: payload.usuarios_auditoria,
          mensaje: payload.text
      };
    },
    audit_justif_person: (state, { payload })=> {
      return {
          ...state,
          personales_audit: payload.justificaciones_auditoria,
          mensaje: payload.text
      };
    },
    audit_justif_gene: (state, { payload })=> {
      return {
          ...state,
          generales_audit: payload.justificaciones_auditoria,
          mensaje: payload.text
      };
    },
    audit_desig: (state, { payload })=> {
      return {
          ...state,
          designaciones_audit: payload.designaciones_auditoria,
          mensaje: payload.text
      };
    },
    audit_hor: (state, { payload })=> {
      return {
          ...state,
          horarios_audit: payload.horarios_auditoria,
          mensaje: payload.text
      };
    },
    justificacion_por_id: (state, { payload })=> {
      return {
          ...state,
          justificacion_eliminada: payload.data,
      };
    },
    historial_versiones: (state, { payload })=> {
      return {
          ...state,
          historial_versiones: payload.historial_versiones,
      };
    },
    creador_eliminador: (state, { payload })=> {
      return {
          ...state,
          creador: payload.creador,
          eliminador: payload.eliminador,
      };
    },
    set_periodo: (state, { payload })=> {
      return {
          ...state,
          periodo: payload,
      };
    },
    restaurar: (state) => {
      return {
        ...state,
        usuarios : [],
        usuarios_audit : [],
        personales_audit: [],
        generales_audit: [],
        designaciones_audit: [],
        horarios_audit: []
      };
    },
  },
});

export const { buscar, set_periodo, restaurar, audit_user, audit_justif_gene, audit_justif_person, audit_desig, audit_hor, historial_versiones,justificacion_por_id, creador_eliminador } = auditoriaSlice.actions;

export default auditoriaSlice.reducer;
