const tablaLAO = {"79A": "LAO (< 5 años)", "79B": "LAO (Entre 5 y 10 años)", "79C": "LAO (Entre 10 y 15 años)", "79D": "LAO (Entre 15 y 20 años)", "79E":"LAO (> 20 años)"};

export const traducirDetalle = (detalle) => {
    if (detalle==="ALTA"){
        return "Renovación vacaciones anual";
    }
    else if (detalle==="ALTAR"){
        return "Paso de remanentes del periodo anterior";
    }
    else if(detalle.includes("79")){
        return tablaLAO[detalle];
    }
    else{
        return "Licencia periodos anteriores";
    }; 
    
  };
  