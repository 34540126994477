import React from 'react';
import { useNavigate } from 'react-router';
// import { useDecrypt } from '../../hooks/useDecrypt';

// MUI componentes
import { 
  Box,
  Button,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

// MUI iconos
import AssignmentIcon from '@mui/icons-material/Assignment';

// Utils
import { verificarPermiso } from '../../utils/verificarPermiso';

const AcercaCargo = ({ codigo, descripcion, tipo, handleClick }) => {
  const navigate = useNavigate();

  const permisos = window.localStorage.getItem("permisos");
  // const permisos = useDecrypt('permisos');

  const handleAtras = () => {
    navigate(`/administrar-cargos`);
  };

  const handleEditar = () => {
    navigate(`/editar-cargo/${codigo}`);
  };

  return (
    <Grid item xs={10} sm={10} md={8} lg={7}>
      <Grid item container xs={11} sm={11} md={12} lg={12}>
        <Button 
          variant="contained"
          sx={{
            mr: 2, 
            mb: 2,
            fontSize: ".8em",
            fontWeight: "bold",
            bgcolor: 'background.paper',
            '&:hover': {
              bgcolor: 'background.paper',
              transform: 'scale(1.01)',
            }
          }}
          onClick={handleAtras}
        >
          Atrás
        </Button>
        <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
      </Grid>

      <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}> 
        <AssignmentIcon />
        <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
          Información sobre el Cargo
        </Typography>
      </Box>    

      <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}} >
        <Typography mr={"1em"} fontWeight={"bold"}>
          Código:
        </Typography>
        <Typography>
          {codigo}
        </Typography>
      </Box>
      
      <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
        <Typography mr={"1em"} fontWeight={"bold"}>
          Descripción:
        </Typography>
        <Typography>
          {descripcion}
        </Typography>
      </Box>

      <Box sx={{display: "flex", flexDirection: "column"}}>
        <Typography mr={"1em"} fontWeight={"bold"}>
          Tipo:
        </Typography>
        <Typography>
          {tipo}
        </Typography>
      </Box>

      {
        verificarPermiso(permisos, 'super_usuario') ?
          <Typography
            sx={{
              maxWidth: "auto",
              display: "inline-block",
              mt: "1em",
              background: 'none',
              fontWeight: "bold",
              border: 'none',
              color: "primary.dark",
              "&:hover": {
                cursor: 'pointer',
              }
            }}
            onClick={handleEditar}
          >
            Modificar datos*
        </Typography>
        :
          null
      }

      {/* Item 3 */}
      <Grid item xs={10} sm={10} md={8} lg={12}>
        {
          verificarPermiso(permisos, 'super_usuario') ?
            <Button 
              variant="contained"
              sx={{
                mb: 2,
                marginTop: "1.5em",
                fontSize: ".8em",
                fontWeight: "bold",
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'secondary.light',
                  transform: 'scale(1.01)',
                }
              }}
              onClick={handleClick}
            >
              Eliminar
            </Button>
          :
            null
        }
      </Grid> 
    </Grid>
  );
};  

export default AcercaCargo;