import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useModal } from '../../hooks/useModal';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import ModalComponent from '../ModalComponent/ModalComponent';
import Pie from '../Pie/Pie';

// Actions
import { buscar_tipo_por_id, eliminar_tipo_justificacion, reactivar_tipo_justificacion, restaurarMensajes } from '../../redux/action-types/justificacionesActions'; 

// Funciones de ayuda
import { obtenerFecha } from '../../utils/obtenerFecha';

// MUI componentes
import { 
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';

// MUI Icons
import { 
  Feed,
} from '@mui/icons-material';

const DetalleTipoJustificacion = () => {
  const dispatch = useDispatch(); 
  const navigate = useNavigate();

  const { id } = useParams();

  const { tipo_id, status, mensaje } = useSelector(state => state.justificaciones);
  const dniActual  = useSelector(state => state.acceso.dni);

  const [isOpenModal, openModal, closeModal] = useModal();
  const [ tab, setTab] = useState("")

  const handleClickAtras = () => {
    navigate(`/administrar-justificaciones`);
  };

  const handleClickEditar = () => {
    navigate(`/administrar-justificaciones/editar/${id}`);
  };

  const handleDelete = () => {
    const data = {
      usuarioActualInput: dniActual,
      id: id,
    };
    dispatch(eliminar_tipo_justificacion(data));
    setTimeout(() => {
      closeModal();
      dispatch(restaurarMensajes());
      navigate(`/administrar-justificaciones`);
    }, 2000);
    
  };

  const handleRevive = () => {
    const data = {
      usuarioActualInput: dniActual,
      id: id,
    };
    dispatch(reactivar_tipo_justificacion(data));
    setTimeout(() => {
      closeModal();
      dispatch(restaurarMensajes());      
      navigate(`/administrar-justificaciones`);
    }, 2000);
    
  };

  const handleClick = () => {
    setTab("1");
    openModal();
  };

  const handleReClick = () => {
    setTab("2")
    openModal();
  };

  useEffect(() => {
    dispatch(buscar_tipo_por_id(id));
  }, [dispatch, id]);

  if(!tipo_id) {
    return(
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={'Detalles de la Justificación'} />
        <CircularProgress 
          sx={{
            width: "auto",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />  
        {/* Componente de Pie de Pag. */}
        <Pie />
      </>
    );
  };

  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={'Detalles de la Justificación'} />
      {/* Contenedor del componente */}
      <Grid container spacing={6} 
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "3vh",
        }}
      >
        <Grid item container xs={12} sm={10} md={8} lg={6} ml={2}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item xs={10} sm={10} md={8} lg={7}>
            {/* Item 1 */}
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}> 
              <Feed />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                Información sobre la Justificación:
              </Typography>
            </Box> 

            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}} >
              <Typography mr={"1em"} fontWeight={"bold"}>
                Título:
              </Typography>
              <Typography>
               {tipo_id.titulo ? tipo_id.titulo : "Sin Detalle"}  
              </Typography>
            </Box>

            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}} >
              <Typography mr={"1em"} fontWeight={"bold"}>
                Descripción:
              </Typography>
              <Typography>
                {tipo_id.descripcion ? tipo_id.descripcion : "Sin Detalle"}  
              </Typography>
            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"1em"}}>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Código:
                </Typography>
                <Typography>
                  {tipo_id.codigo ? tipo_id.codigo : "Sin Detalle"}
                </Typography>
              </Box>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Tipo:
                </Typography>
                <Typography>
                  {tipo_id.general === true ? "General" : "Personal"}
                </Typography>
              </Box>
            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"1em"}}>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Creador:
                </Typography>
                <Typography>
                  {tipo_id.created_by ? tipo_id.created_by : "Sin Detalle"}
                </Typography>
              </Box>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Fecha Creación:
                </Typography>
                <Typography>
                  {tipo_id.fecha_creacion ? obtenerFecha(tipo_id.fecha_creacion).fecha : "Sin Detalle"}
                </Typography>
              </Box>
            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"1em"}}>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Estado:
                </Typography>
                <Typography>
                  {tipo_id.activo ? "Activa" : "Inactiva"}
                </Typography>
              </Box>
            </Box>
            
            <Typography
              sx={{
                maxWidth: "auto",
                display: "inline-block",
                mt: "1em",
                background: 'none',
                border: 'none',
                color: "primary.dark",
                "&:hover": {
                  cursor: 'pointer',
                }
              }}
              onClick={handleClickEditar}
            >
              Modificar datos*
            </Typography>

            {/* Item 2 */}
            <Grid item xs={10} sm={10} md={8} lg={12}>
              <Button 
                variant="contained"
                sx={{
                  mr: 2, 
                  mb: 2,
                  marginTop: "1.5em",
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleClickAtras}
              >
                Atrás
              </Button>
              {tipo_id.activo ?
              <Button 
                variant="contained"
                sx={{
                  mb: 2,
                  marginTop: "1.5em",
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'secondary.light',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleClick}
              >
                Eliminar
              </Button>
              :
              <Button 
                variant="contained"
                sx={{
                  mb: 2,
                  marginTop: "1.5em",
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'primary.light',
                    transform: 'scale(1.01)',
                    color: "text.secondary"
                  }
                }}
                onClick={handleReClick}
              >
                Reactivar
              </Button>
              }
            </Grid> 

          </Grid>  
        </Grid>
      </Grid>

      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        {
          status
          ?
          <>
            <Typography id="modal-modal-title" component="h2"sx={{ fontSize: '1.3em' }} >
              {status}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {mensaje}
            </Typography>
          </>
          : tab === "1" ?
          <>
            <Typography id="modal-modal-title" component="h2" sx={{ fontSize: '1.3em' }}>
              Esta por eliminar el tipo de justificación con codigo {tipo_id.codigo}. ¿Desea continuar?
            </Typography>
            <Button
              type="submit"
              variant="contained"
              onClick={closeModal}
              sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                marginRight: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={handleDelete}
              sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'secondary.light',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Eliminar
            </Button>
          </>
          :
          <>
            <Typography id="modal-modal-title" component="h2" sx={{ fontSize: '1.3em' }}>
              Esta por reactiva el tipo de justificación con codigo {tipo_id.codigo}. ¿Desea continuar?
            </Typography>
            <Button
              type="submit"
              variant="contained"
              onClick={closeModal}
              sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                marginRight: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={handleRevive}
              sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'primary.light',
                  transform: 'scale(1.01)',
                  color: "text.secondary"
                }
              }}
            >
              Reactivar
            </Button>
          </>
        }
      </ModalComponent>
      {/* Pie de Pág */}
      <Pie />
    </>
  );
};

export default DetalleTipoJustificacion;