export const ordenarPorNombre = (data, campo) => {
  const lista = data.sort((a, b) => {
    // Determinar si estamos trabajando con el nuevo formato
    const esNuevoFormato = a.usuario && b.usuario;

    // Obtener los nombres a comparar
    const nombreA = esNuevoFormato
      ? (campo ? a.usuario[campo].toLowerCase() : a.usuario.nombre.toLowerCase())
      : (campo ? a[campo].toLowerCase() : a.toLowerCase());

    const nombreB = esNuevoFormato
      ? (campo ? b.usuario[campo].toLowerCase() : b.usuario.nombre.toLowerCase())
      : (campo ? b[campo].toLowerCase() : b.toLowerCase());

    if (nombreA < nombreB) {
      return -1;
    }
    if (nombreA > nombreB) {
      return 1;
    }
    return 0;
  });
  return lista;
};