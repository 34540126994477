import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { useModal } from '../../../hooks/useModal';

// Componentes
import Rotulo from '../../Rotulo/Rotulo';
import ModalComponent from '../../ModalComponent/ModalComponent';
import Pie from '../../Pie/Pie';

// Actions
import { buscar_por_dni } from '../../../redux/action-types/usuarioActions';
import { asignar_rol } from '../../../redux/action-types/rolActions';

// MUI componentes
import { 
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

// MUI iconos
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

const validate = (state) => {
  let errors = {};

  if(state.rolInput === '') {
    errors.rolInput = '';
  };
  
  return errors;
};

const initialState = {
  rolInput: '',
};

const AsignarRol = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dni } = useParams();

  const dniActual  = useSelector(state => state.acceso.dni);
  
  const { mensaje } = useSelector(state => state.rol);

  const [ state, setState ] = useState(initialState);
  const [ errors, setErrors ] = useState(initialState);

  const [isOpenModal, openModal, closeModal] = useModal();

  const handleChange = event => {
    const { name, value } = event.target;
    setErrors(validate({
      ...state,
      [name]: value
    }));
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleClickCancelar = () => {
    navigate(`/rol/usuario/${dni}`);
  };

  const handleAtras = () => {
    navigate(`/rol/usuario/${dni}`);
  };

  const handleSubmit = event => {
    event.preventDefault();
    const data = {...state, dni:dni,creado_por:dniActual };
    dispatch(asignar_rol(data));
    openModal();
    setState(initialState);
    setTimeout(() => {
      navigate(`/rol/usuario/${dni}`);
    }, 2000);
  };

  useEffect(() => {
    dispatch(buscar_por_dni(dni));
  }, [dispatch, dni]);

  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={"Asignar Rol"} />
      <Grid container component="form" noValidate onSubmit={handleSubmit} 
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3vh",
        }}
      > 
        {/* Contenedor Grid de los Items   */}
        <Grid item container xs={12} sm={10} md={10} lg={8} 
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        > 
          {/* Item 1 */}
          <Grid item xs={10} sm={10} md={10} lg={8}>
            <Button 
              variant="contained"
              sx={{
                fontSize: ".8em",
                fontWeight: "bold",
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
              onClick={handleAtras}
            >
              Atrás
            </Button>
            <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}> 
              <SupervisedUserCircleIcon />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                Roles
              </Typography>
            </Box> 
            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"2em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Nuevo Rol*:
              </Typography>
              <TextField 
                labelid="rolInputLabel"
                id='rolInput' 
                name="rolInput"
                value={state.rolInput}
                error={!!errors.rolInput}
                onChange={handleChange}
                select
                inputProps={{
                  autoComplete: 'off'
                }}
                InputLabelProps={{
                  sx: {
                    color: 'rgb(150, 150, 150)',
                  }
                }}
              >
                <MenuItem key={'Usuario'} value={'Usuario'}>Usuario Base</MenuItem>
                <MenuItem key={'Consulta'} value={'Consulta'}>Usuario Consulta</MenuItem>
                <MenuItem key={'Admin'} value={'Admin'}>Usuario Admin</MenuItem>
                <MenuItem key={'Privilegiado'} value={'Privilegiado'}>Usuario Admin Privilegiado</MenuItem>
                <MenuItem key={'Super'} value={'Super'}>Super Usuario</MenuItem>
              </TextField>
              {
                errors.rolInput && 
                <FormHelperText
                  sx={{
                    color: "red",
                  }}
                >
                  {errors.rolInput}
                </FormHelperText>
              }
            </Box>
            <Button 
                type="submit"
                variant="contained"
                sx={{
                  mr: 1,
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleClickCancelar}
              >
               Cancelar
            </Button>
            { 
              ((!errors.rolInput) 
                && 
              (errors.rolInput !== ''))
              ?
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Asignar
                </Button>
              :
                <Button
                  type="submit"
                  disabled
                  variant="contained"
                  sx={{
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Asignar
                </Button>
              }
          </Grid>
        </Grid>
      </Grid>
      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        <Typography id="modal-modal-description">
          {mensaje}
        </Typography>
      </ ModalComponent>
      {/* Pie de Pág. */}
      <Pie />
    </>
  );
};

export default AsignarRol;