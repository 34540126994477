import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from '../../hooks/useModal';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import ModalComponent from '../ModalComponent/ModalComponent';
import Pie from '../Pie/Pie';

// Actions
import { buscar_dependencias_hijas, crearDependencia, restaurarMensajes  } from '../../redux/action-types/dependenciaActions';
import { informacionCreacion } from '../../redux/action-types/usuarioActions'; 
import { obtener_cabeceras } from '../../redux/action-types/rolActions';

// MUI componentes
import { 
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

// MUI iconos
import ApartmentIcon from '@mui/icons-material/Apartment';
import SchemaIcon from '@mui/icons-material/Schema';

const ALPHA = /^[\p{L}\s]+$/u;

const validate = (state) => {
  let errors = {};

  if(state.nombreInput === '') {
    errors.nombreInput = '';
  } else if(state.nombreInput.length < 3) {
    errors.nombreInput = 'El nombre debe tener al menos 3 caracteres...';
  } else if(!ALPHA.test(state.nombreInput)) {
    errors.nombreInput = 'Solo se aceptan letras y espacios...';
  };

  if(state.padreInput === '') {
    errors.padreInput = '';
  };
  
  if(state.nombreInput.length > 2 && state.padreInput === '') {
    errors.padreInput = 'Determine la dependencia padre...';
  };

  if(state.emailInput === '') {
    errors.emailInput = '';
  } else if(!state.emailInput.split('@')[0]) {
    errors.emailInput = 'Ingresa el email...';
  } else if(!state.emailInput.endsWith('unlp.edu.ar')) {
    errors.emailInput = 'La dirección debe terminar con el formato "unlp.edu.ar"';
  };

  if(state.descripcionInput.length > 250) {
    errors.descripcionInput = 'La descripcion no puede superar los 250 caracteres'
  }

  return errors;
};

const initialState = {
  nombreInput: '',
  descripcionInput: '',
  telefonoInput: '',
  emailInput: '',
  padreInput: '',
  hijosInput: '',
  hijoInput: '',
};

const CrearDependencia = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { cabeceras } = useSelector(state => state.rol);
  const { hijas, status, text } = useSelector(state => state.dependencia);
  const dniActual  = useSelector(state => state.acceso.dni);

  const [ state, setState ] = useState(initialState);
  const [ errors, setErrors ] = useState(initialState);

  const [ isOpenModal, openModal, closeModal ] = useModal();

  const handleChangePadre = event => {
    const { name, value } = event.target;
    setErrors(validate({
      ...state,
      [name]: value
    }));
    setState({
      ...state,
      [name]: value,
    });
    dispatch(buscar_dependencias_hijas(value))
  };
  

  const handleChange = event => {
    const { name, value } = event.target;
    setErrors(validate({
      ...state,
      [name]: value
    }));
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleClickCancelar = () => {
    navigate('/buscar-lugar');
  };

  const handleSubmit = event => {
    event.preventDefault();
    const data = {...state, usuarioActualInput: dniActual};
    dispatch(crearDependencia(data));
    openModal();
    setTimeout(() => {
      closeModal();
     
    }, 3000);
    setState(initialState);
    dispatch(restaurarMensajes())
  };

  useEffect(() => {
    dispatch(informacionCreacion());
    dispatch(obtener_cabeceras());
  }, [dispatch]);

  if(!cabeceras) {
    return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={'Crear Dependencia.'} />
        <CircularProgress 
          sx={{
            width: "auto",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />  
        {/* Copmponente de Pie de Pag. */}
        <Pie />
      </>
    );
  };

  return (
    <>
      {/* Rotulo de la Pág.*/}
      <Rotulo titulo={"Crear Dependencia"} />
      <Grid id={"crearDependencia"} container component="form" noValidate onSubmit={handleSubmit} spacing={6} 
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3vh",
        }}
      > 
        {/* Contenedor Grid de los Items   */}
        <Grid item container xs={12} sm={10} md={8} lg={6} spacing={6}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        > 
          {/* Item 1 */}
          <Grid item xs={10} sm={10} md={8} lg={6}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}> 
              <ApartmentIcon />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                Información
              </Typography>
            </Box>   
             
            {/* Formulario para Crear */}
            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Nombre*:
              </Typography>
              <TextField 
                id='nombreInput' 
                name="nombreInput"
                type='text'
                value={state.nombreInput}
                placeholder={"Ingrese el nombre..."} 
                error={!!errors.nombreInput}
                onChange={handleChange}
                inputProps={{
                  autoComplete: 'off'
                }}
                InputLabelProps={{
                  sx: {
                    color: 'rgb(150, 150, 150)',
                  }
                }}
              />
              {
                errors.nombreInput && 
                <FormHelperText
                  sx={{
                    color: "red",
                  }}
                >
                  {errors.nombreInput}
                </FormHelperText>
              }
            </Box>

            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}} >
              <Typography mr={"1em"} fontWeight={"bold"}>
                  Descripción:
              </Typography>
              <TextField 
                id='descripcionInput' 
                name="descripcionInput"
                type='text'
                value={state.descripcionInput}
                error={!!errors.descripcionInput}
                placeholder={"Ingrese una descripcion (máximo 250 caracteres)"} 
                onChange={handleChange}
                inputProps={{
                  autoComplete: 'off'
                }}
                InputLabelProps={{
                  sx: {
                    color: 'rgb(150, 150, 150)',
                  }
                }}
              />
              {
                errors.descripcionInput && 
                <FormHelperText
                  sx={{
                    color: "red",
                  }}
                >
                  {errors.descripcionInput}
                </FormHelperText>
              }
            </Box>

            <Box sx={{with: "100%", display: "flex", justifyContent: "space-between"}}>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Teléfono:
                </Typography>
                <TextField 
                  id='telefonoInput' 
                  name="telefonoInput"
                  type='text'
                  value={state.telefonoInput}
                  placeholder={"Ingrese el teléfono..."} 
                  onChange={handleChange}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                />
              </Box>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  E-mail:
                </Typography>
                <TextField 
                  id='emailInput' 
                  name="emailInput"
                  type='mail'
                  value={state.emailInput}
                  placeholder={"Ingrese el email..."} 
                  error={!!errors.emailInput}
                  onChange={handleChange}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                />
                {
                  errors.emailInput && 
                  <FormHelperText
                    sx={{
                      color: "red",
                    }}
                  >
                    {errors.emailInput}
                  </FormHelperText>
                }
              </Box>
            </Box>
          </Grid>
          {/* Item 2 */}
          <Grid item xs={10} sm={10} md={8} lg={6}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}> 
              <SchemaIcon />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                Organigrama
              </Typography>
            </Box> 
            {/* Dependencias Padre */}
            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Dependencia Padre*:
              </Typography>
              <TextField 
                id='padreInput' 
                name="padreInput"
                value={state.padreInput}
                error={!!errors.padreInput}
                select
                onChange={handleChangePadre}
                inputProps={{
                  autoComplete: 'off'
                }}
                InputLabelProps={{
                  sx: {
                    color: 'rgb(150, 150, 150)',
                  }
                }}
              >
                {
                  cabeceras && cabeceras.map(dependencia => {
                    return (
                      <MenuItem key={dependencia.id} value={dependencia.id}>
                        {dependencia.name}
                      </MenuItem>
                    )
                  })
                }
              </TextField>
              {
                errors.padreInput && 
                <FormHelperText
                  sx={{
                    color: "red",
                  }}
                >
                  {errors.padreInput}
                </FormHelperText>
              }
            </Box>
            {/* Oficinas */}
            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Oficina Padre:
              </Typography>
              <TextField 
                id='hijoInput' 
                name="hijoInput"
                value={state.hijoInput}
                onChange={handleChange}
                select
                disabled={!state.padreInput || !hijas}
                inputProps={{
                  autoComplete: 'off'
                }}
                InputLabelProps={{
                  sx: {
                    color: 'rgb(150, 150, 150)',
                  }
                }}
              >
                {
                  hijas && hijas.map(dependencia => {
                    return (
                      <MenuItem key={dependencia.id} value={dependencia.id}>
                        {dependencia.name}
                      </MenuItem>
                    )
                  })
                }
              </TextField>
            </Box>
          </Grid>

          {/* Item 3 */}
          <Grid item container xs={10} sm={10} md={8} lg={12}>
            <Button 
                variant="contained"
                sx={{
                  mr: 1,
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleClickCancelar}
              >
               Cancelar
            </Button>
            { 
              ((!errors.nombreInput && !errors.padreInput && !errors.emailInput && !errors.descripcionInput) 
                && 
              (errors.nombreInput !== '' && errors.padreInput !== ''))
              ?
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Crear
                </Button>
              :
                <Button
                  type="submit"
                  disabled
                  variant="contained"
                  sx={{
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Crear
                </Button>
            }
          </Grid>
        </Grid>
      </Grid>
      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {status}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {text}
        </Typography>
      </ ModalComponent>
      {/* Pie de Pág. */}
      <Pie />
    </>
  );
};

export default CrearDependencia;