import axios from "axios";

import { crear, editar, eliminar, buscar, buscar_dni, buscar_dni_audit, creacion, cambiar_contraseña, restaurar, restaurar_mensajes } from '../reducers/usuarioReducer';

import { setAuthorizationInterceptor } from '../../utils/tokenAuthorization';

// Función de utilidad para agregar el token a las solicitudes
setAuthorizationInterceptor();

export const crearUsuario = (data) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/usuario/crear`, data)
      .then(response => {
        dispatch(crear({status: response.data.status, text: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
};

export const editarUsuario = (data) => {
  return (dispatch) => {
    return axios.put(`${process.env.REACT_APP_BASE_URL}/editar/usuario/${data.dniInput}`, data)
      .then(response => {
        dispatch(editar({status: response.data.status, text: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
};

export const eliminarUsuario = (data) => {
  return (dispatch) => {
    return axios.delete(`${process.env.REACT_APP_BASE_URL}/usuario/${data.dni}`, data)
      .then(response => {
        dispatch(eliminar({status: response.data.status, text: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
};

export const editarUsuarioActual = (data) => {
  return (dispatch) => {
    return axios.put(`${process.env.REACT_APP_BASE_URL}/editar/usuario/actual/${data.dniInput}`, data)
      .then(response => {
        dispatch(editar({status: response.data.status, text: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
};

export const informacionCreacion = () => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/formulario/formulario-creacion`)
      .then(response => {
        dispatch(creacion({data: response.data}))
      })
      .catch(error => console.log(error))
  };
};

export const buscarUsuario = (usuario) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/usuarios/buscar`, usuario)
      .then(response => {
        dispatch(buscar(response.data));
      })
      .catch(error => console.log(error));
  };
};

export const buscar_por_dni = (dni) => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/usuario/${dni}`)
      .then(response => {
        dispatch(buscar_dni({data: response.data}));
      })
      .catch(error => console.log('/buscar_por_dni', error));
  };
};

export const buscar_por_dni_audit = (dni) => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/usuario_audit/${dni}`)
      .then(response => {
        dispatch(buscar_dni_audit({data: response.data}));
      })
      .catch(error => console.log('/buscar_por_dni_audit', error));
  };
};

export const buscar_por_dni_actual = (dni) => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/usuario/actual/${dni}`)
      .then(response => {
        dispatch(buscar_dni({data: response.data, status: response.status, text: response.statusText}));
      })
      .catch(error => console.log('/buscar_por_dni_actual', error));
  };
};

export const cambiarContraseña = (data) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/cambiar-contraseña`, data)
      .then(response => {
        dispatch(cambiar_contraseña({status: response.data.status, text: response.data.mensaje}))
      })
      .catch(error => console.log("/cambiar_contraseña", error))
  }
}

export const restaurarMensajes = () => {
  return function(dispatch) {
    dispatch(restaurar_mensajes());
  }
}

export const restaurarEstado = () => {
  return function(dispatch) {
    dispatch(restaurar());
  };
};
