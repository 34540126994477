import React from 'react';

// MUI componentes
import {
  Divider, 
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';

const DetalleReporte = ({detalle}) => {

  const matches = useMediaQuery('(min-width:650px)');

  if(detalle) {
    return (
      <>
        {
          matches &&
            <Grid item container component={Paper} padding={'.8em'}
            key={"container"}
              style={{ 
                display: 'flex',
                minHeight: '10vh',
                justifyContent: 'space-between',
                boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)',
              }}
            >
              <Grid key={"Tabla1"} item lg={3.5} md={3.5} sm={3.5}>
                <Typography key={"Titulo1"} variant='subtitle2'>Detalle sobre faltas</Typography>
                <Divider style={{ marginTop: '.5em', marginBottom: '.5em' }}/>
                <Grid style={{display:'flex', justifyContent: 'space-between'}}>
                  <Typography key={"item1"}>Faltas Injustificadas:</Typography>
                  <Typography key={"dato1"}>{detalle.faltas_injustificadas}</Typography>
                </Grid>
                <Grid style={{display:'flex', justifyContent: 'space-between'}}>
                  <Typography key={"item2"}>Faltas Justificadas:</Typography>
                  <Typography key={"dato2"}>{detalle.faltas_justificadas}</Typography>
                </Grid>
                <Grid style={{display:'flex', justifyContent: 'space-between'}}>
                  <Typography key={"item3"}><strong>Faltas Totales:</strong></Typography>
                  <Typography key={"dato3"}><strong>{detalle.faltas_totales}</strong></Typography>
                </Grid>
              </Grid>
              <Grid key={"Tabla2"} item lg={3.5} md={3.5} sm={3.5}>
                <Typography key={"Titulo1"} variant='subtitle2'>Detalle sobre horas</Typography>
                <Divider style={{ marginTop: '.5em', marginBottom: '.5em' }}/>
                <Grid style={{display:'flex', justifyContent: 'space-between'}}>
                  <Typography key={"item5"}>Dias laborables transcurridos:</Typography>
                  <Typography key={"dato5"}> {detalle.dias_habiles}</Typography>
                </Grid>
                <Grid style={{display:'flex', justifyContent: 'space-between'}}>
                  <Typography key={"item5"}>Dias trabajados:</Typography>
                  <Typography key={"dato5"}> {detalle.dias_trabajados}</Typography>
                </Grid>
                <Grid style={{display:'flex', justifyContent: 'space-between'}}>
                  <Typography key={"item4"}>Horas Trabajadas:</Typography>
                  <Typography key={"dato4"}> {detalle.horas_trabajadas}</Typography>
                </Grid>
                
              </Grid>
              <Grid key={"Tabla3"} item lg={3.5} md={3.5} sm={3.5}>
                <Grid style={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography variant='subtitle2'>Justificaciones</Typography>
                <Typography variant='subtitle2'>{detalle.justificaciones ? Object.values(detalle.justificaciones).reduce((acumulador, actual) => acumulador + actual, 0) : ""}</Typography>
                </Grid>             
                <Divider style={{ marginTop: '.5em', marginBottom: '.5em' }}/>              
                {Object.keys(detalle.justificaciones).map(clave => {
                  return(
                    <Grid key={clave} style={{display:'flex', justifyContent: 'space-between'}}>
                      <Typography key={clave}>{clave}:</Typography>
                      <Typography key={detalle.justificaciones[clave]}> {detalle.justificaciones[clave]}</Typography>
                    </Grid>
                  )
                })}
                
              </Grid>
            </Grid>
        }
      </>
    )
  };
    
  return ( 
    <>
      {
        !matches && 
          <Grid item container xs={12} component={Paper} padding={'.5em'}
            style={{ 
              minHeight: '20vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)',
            }}
          >
            <Grid item lg={3.5} md={3.5} sm={3.5} xs={12}>
              <Typography>Detalle sobre Faltas </Typography>
              <Divider style={{ marginTop: '.5em', marginBottom: '.5em' }}/>
              <Typography>Faltas Injustificadas</Typography>
              <Typography>Faltas Totales</Typography>
            </Grid>
            <Grid lg={3.5} md={3.5} sm={3.5} xs={12}>
              <Typography>Detalle sobre Horas</Typography>
              <Divider style={{ marginTop: '.5em', marginBottom: '.5em' }}/>
              <Typography>Horas Trabajadas</Typography>
            </Grid>
            <Grid lg={3.5} md={3.5} sm={3.5} xs={12}>
              <Typography>Justificaciones</Typography>
              <Divider style={{ marginTop: '.5em', marginBottom: '.5em' }}/>
            </Grid>
          </Grid>
      }
    </>
  );
};

export default DetalleReporte;