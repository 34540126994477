import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useModal } from '../../hooks/useModal';
// import { useDecrypt } from '../../hooks/useDecrypt';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import ModalComponent from '../ModalComponent/ModalComponent';
import ListadoJustificaciones from './ListadoJustificaciones';
import Pie from '../Pie/Pie';

// Actions
import { buscar_tipos_generales, justificacion_general_crear, restaurarMensajes } from '../../redux/action-types/justificacionesActions';
import { buscar_dependencias_hijas } from '../../redux/action-types/dependenciaActions';
import { informacionCreacion } from '../../redux/action-types/usuarioActions';

// Funciones de ayuda
import { obtenerFecha } from '../../utils/obtenerFecha';

// MUI componentes
import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers';
import { inputLabelClasses } from "@mui/material/InputLabel";

// MUI iconos
import AddIcon from '@mui/icons-material/Add';
import SchemaIcon from '@mui/icons-material/Schema';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import DoneAllIcon from '@mui/icons-material/DoneAll';

// Utils
import { verificarPermiso } from '../../utils/verificarPermiso';
import { calcularDiasEntreFechas } from '../../utils/calcularDiasEntreFechas';

const validate = (state) => {
  let errors = {};

  if(state.fechaInicioInput !== null && state.fechaFinInput !== null) {
    let inicio = new Date(state.fechaInicioInput)
    let fin = new Date(state.fechaFinInput)
    if (inicio > fin){
      errors.fechaFinInput = 'La fecha de fin debe ser posterior a la fecha de inicio'
    } else {
      errors.fechaFinInput = ''
    };
  };

  return errors;
};

const initialState = {
  fechaInicioInput: null,
  fechaFinInput: null,
  tipoJustificacionInput: null,
  objetotipoJustificacionInput: null,
  dependenciaInput: null,
  hijo_Input: '',
  objhijo_Input: null,
};

const JustificacionesGenerales = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dia } = useParams();

  const { tipos, status, mensaje } = useSelector(state => state.justificaciones);
  const { dependencias } = useSelector(state => state.usuario);
  const { hijas } = useSelector(state => state.dependencia);
  const dniActual  = useSelector(state => state.acceso.dni);

  const permisos = window.localStorage.getItem("permisos");
  // const permisos = useDecrypt('permisos');

  const [ state, setState ] = useState(initialState);
  const [ errors, setErrors ] = useState(initialState);

  const [isOpenModal, openModal, closeModal] = useModal();

  const handleChangePadre = event => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
    dispatch(buscar_dependencias_hijas(value))
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setErrors(validate({
      ...state,
      [name]: value
    }));
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleChangeHijo = (event, newValue) => {
    const selectedOption = newValue; // El objeto completo seleccionado
    const name = "hijo_Input";
    const objeto = 'obj'+name;
    setState({
      ...state,
      [name]: selectedOption ? selectedOption.id : null, // Guarda el ID del objeto o null si no hay selección
      [objeto]: selectedOption ? selectedOption : null,
    });
  };

  const handleChangeAuto = (event, newValue) => {
    const selectedOption = newValue; // El objeto completo seleccionado
    const name = "tipoJustificacionInput";
    const objeto = 'objeto'+name;
    setErrors(validate({
      ...state,
      [name]: selectedOption ? selectedOption.id : null // Guarda el ID del objeto o null si no hay selección
    }));

    setState({
      ...state,
      [name]: selectedOption ? selectedOption.id : null, // Guarda el ID del objeto o null si no hay selección
      [objeto]: selectedOption ? selectedOption : null,
    });
  };

  const handleClick = (event) => {
    event.preventDefault();
    setState(initialState);
    setErrors(initialState);
  };

  const handleSubmit = event => {
    event.preventDefault();
    const data = {...state, usuarioActualInput: dniActual};
    dispatch(justificacion_general_crear(data));
    openModal();
    setTimeout(() => {
      closeModal();
      navigate(0);
    }, 2000);
    dispatch(restaurarMensajes());
    setState(initialState);
  };

  useEffect(() => {
    if(dia) {
      setState((state) => ({
        ...state,
        fechaInicioInput: obtenerFecha(dia).fecha2,
      }));
    };
    if (verificarPermiso(permisos, 'usuario_administrador')){
      dispatch(informacionCreacion());
      dispatch(buscar_tipos_generales());
    }
  }, [dispatch,permisos, dia]);

  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={'Justificaciones Generales'} />
      {/* Aca arranca el componente Crear Justificacion */}
      {
        verificarPermiso(permisos, 'usuario_administrador') ?
          <>
            <Grid container component="form" noValidate onSubmit={handleSubmit}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "3vh",
              }}
            >
              {/* Contenedor Grid de los Items */}
              <Grid item container xs={12} sm={10} md={8} lg={6} spacing={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* Item Crear */}
                <Grid item xs={10} sm={11} md={12} lg={12}>
                  <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", ml: "-3px", mb: ".5em"}}>
                    <AddIcon />
                    <Typography component={"h3"} variant={"h6"} fontSize={"1.6em"}>
                      Crear Justificación
                    </Typography>
                  </Box>
                </Grid>

                {/* Item 0 */}
                <Grid container item xs={10} sm={11} md={12} lg={12}>
                  <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}>
                    <EditCalendarIcon></EditCalendarIcon>
                    <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                      Fecha Justificación
                    </Typography>
                  </Box>

                  <Grid container item xs={12} sm={12} md={12} lg={12} spacing={6}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Box sx={{display: "flex", flexDirection: "column"}}>
                      <Typography mr={"1em"} fontWeight={"bold"}>
                        Fecha Inicio*:
                      </Typography>
                      <DatePicker
                        id={'fechaInicioInput'}
                        name="fechaInicioInput"
                        views={['year', 'month', 'day']}
                        value={state.fechaInicioInput}
                        onChange={date => handleChange({ target: { value: date, name: 'fechaInicioInput' } })}
                        slotProps={{
                          textField: { InputLabelProps: { sx: {color: 'transparent',
                                                          [`&.${inputLabelClasses.shrink}`]: {
                                                            color: "black"}}
                                                          }},
                          toolbar: { toolbarFormat: 'ddd DD MMMM', hidden: false },
                        }}>
                      </DatePicker>
                    </Box>
                    </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Box sx={{display: "flex", flexDirection: "column"}}>
                      <Typography mr={"1em"} fontWeight={"bold"}>
                        Fecha Fin:
                      </Typography>
                      <DatePicker
                        id={'fechaFinInput'}
                        name="fechaFinInput"
                        views={['year', 'month', 'day']}
                        value={state.fechaFinInput}
                        onChange={date => handleChange({ target: { value: date, name: 'fechaFinInput' } })}
                        slotProps={{
                          textField: { InputLabelProps: { sx: {color: 'transparent',
                                                          [`&.${inputLabelClasses.shrink}`]: {
                                                            color: "black"}}
                                                          }},
                          toolbar: { toolbarFormat: 'ddd DD MMMM', hidden: false },
                        }}>
                      </DatePicker>
                    {
                        errors.fechaFinInput &&
                        <FormHelperText
                          sx={{
                            color: "red",
                          }}
                        >
                          {errors.fechaFinInput}
                        </FormHelperText>
                      }
                    </Box>
                  </Grid>
                
                </Grid>
                {
                    state.fechaInicioInput && !errors.fechaFinInput ?
                    <Box >
                      <Typography>
                        Se asignará la justificación por {" "}
                        <span style={{fontWeight:"bold"}}>{state.fechaFinInput ? calcularDiasEntreFechas(state.fechaInicioInput, state.fechaFinInput) + " días": "1 día"}</span>
                      </Typography>
                    </Box>
                    :
                    null
                  }
              </Grid>

            {/* Item 3*/}
            <Grid container item xs={10} sm={11} md={12} lg={12}>
            
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "0.5em"}}>
              <SchemaIcon />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                Lugar de justificación
              </Typography>
            </Box>

            <Grid container item xs={12} sm={12} md={12} lg={12} spacing={6}>

              <Grid item xs={12} sm={12} md={12} lg={6}>
              <Box sx={{display: "flex", flexDirection: "column", marginBottom:"0.5em"}}>
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Dependencia*:
                </Typography>
                <TextField
                  labelid="dependenciaInput"
                  id="dependenciaInput"
                  name="dependenciaInput"
                  value={state.dependenciaInput}
                  fullWidth
                  onChange={handleChangePadre}
                  select
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                >
                  {
                    dependencias && dependencias.map(dependencia => {
                      if(dependencia.name === 'UNLP') {
                        return null;
                      };
                      return (
                        <MenuItem key={dependencia.id}
                          value={dependencia.id}
                        >
                          {dependencia.name}
                        </MenuItem>
                      )
                    })
                  }
              </TextField>

              </Box>
              </Grid>
            {/* Item 3*/}
            <Grid item xs={12} sm={12} md={12} lg={6}>
              {state.dependenciaInput && hijas ?

              <Box sx={{display: "flex", flexDirection: "column", marginBottom:"0.5em"}}>
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Oficina:
                </Typography>
                {hijas && (<Autocomplete
                      id="hijo_Input"
                      name="hijo_Input"
                      options={hijas}
                      value={state.objhijo_Input}
                      getOptionLabel={(option) => `${option.name}`}
                      disabled={!state.dependenciaInput || !hijas}
                      onChange={handleChangeHijo}
                      noOptionsText={`No se encontraron oficinas`}
                      renderOption={(props, option) => (
                        <Box key={option.id}
                            component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          
                          {option.name} 
                        </Box>
                      )}
                      renderInput={(params) => <TextField {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',}} />}
                    ></Autocomplete>)}

              </Box>
              :
              <></>
          
            }
            </Grid>
          </Grid>
        </Grid>

              {/* Item 2 */}
              <Grid item xs={10} sm={11} md={12} lg={12}>
                <Box sx={{display: "flex", justifyContent: "start", mb: "1em"}}>
                  <DoneAllIcon/>
                  <Typography component={"h3"} variant={"h6"} fontSize={"1.2em"}>
                    Justificación
                  </Typography>
                </Box>
                <Grid container item xs={12} sm={12} md={12} lg={12} spacing={6}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
                  <Typography mr={"1em"} fontWeight={"bold"}>
                    Tipo Justificación*:
                  </Typography>
                  {tipos && (<Autocomplete
                    id="tipoJustificacionInput"
                    name="tipoJustificacionInput"
                    options={tipos}
                    value={state.objetotipoJustificacionInput}
                    isOptionEqualToValue={(option, value) => option.id === value}
                    getOptionLabel={(option) => `${option.codigo} - ${option.titulo}`}
                    onChange={handleChangeAuto}
                    noOptionsText={`No se encontraron justificaciones`}
                    renderOption={(props, option) => (
                      <Box key={option.id}
                          component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.codigo} - {option.titulo}
                      </Box>
                    )}
                    renderInput={(params) => <TextField {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',}} />}
                  ></Autocomplete>)}
                  
                </Box>
                </Grid>
                </Grid>
              </Grid>

              {/* Item 3 */}
              <Grid item container xs={10} sm={10} md={10} lg={12}>
                <Button
                  variant="contained"
                  sx={{
                    mb: 2,
                    mr: 1,
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                  onClick={handleClick}
                >
                  Cancelar
                </Button>
                {
                  (state.fechaInicioInput === null ||
                  state.tipoJustificacionInput === '' ||
                  state.dependenciaInput === '' ||
                  (errors.fechaFinInput !== '' && state.fechaFinInput !== null))
                  ?
                    <Button
                      type="submit"
                      variant="contained"
                      disabled
                      sx={{
                        mb: 2,
                        fontSize: ".8em",
                        fontWeight: "bold",
                        bgcolor: 'background.paper',
                        '&:hover': {
                          bgcolor: 'background.paper',
                          transform: 'scale(1.01)',
                        }
                      }}
                    >
                      Crear
                    </Button>
                  :
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        mb: 2,
                        fontSize: ".8em",
                        fontWeight: "bold",
                        bgcolor: 'background.paper',
                        '&:hover': {
                          bgcolor: 'background.paper',
                          transform: 'scale(1.01)',
                        }
                      }}
                    >
                      Crear
                    </Button>
                  }
                </Grid>
              </Grid>
            </Grid>
          </>
        :
          null
      }
      <ListadoJustificaciones />
      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {status}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {mensaje}
        </Typography>
      </ ModalComponent>
      {/* Pie de Pág. */}
      <Pie />
    </>
  );
};

export default JustificacionesGenerales;
