import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import Reloj from './Reloj';
import Pie from '../Pie/Pie';

// Actions
import { buscarRelojes } from '../../redux/action-types/relojActions';

// MUI componentes
import {
  Button,
  CircularProgress,
  Grid,
} from '@mui/material';

const ListadoRelojes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { relojes } = useSelector(state => state.reloj);

  const handleClick = () => {
    navigate('/crear-reloj');
  };

  const handleAtras = () => {
    navigate('/dashboard');
  };

  useEffect(() => {
    dispatch(buscarRelojes());
    const interval = setInterval(() => {
      dispatch(buscarRelojes());
    }, 300000);
    return () => clearInterval(interval);
  }, [dispatch]);

  if(relojes.length < 1) {
    return (
      <>
        <Rotulo titulo={'Administrar Relojes'} />
        <CircularProgress
          sx={{
            width: "auto",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />
      </>
    );
  };

  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={'Administrar Relojes'} />
      {/* Componente */}
      <Grid container pt={6}
        sx={{
          display:"flex",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12} sm={6} md={4} pb={4}>
          {
            relojes.map(reloj => {
              return (
                <Reloj
                  key={reloj.id}
                  id={reloj.id}
                  img={reloj.img }
                  ip={reloj.ip}
                  ubicacion={reloj.ubicacion}
                  nombre={reloj.nombre}
                  activo={reloj.activo}
                  time = {reloj.time}
                  conexion={reloj.conexion}
                />
              )
            })
          }
        </Grid>
        <Grid item
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Button variant="contained"
            sx={{
              marginBottom: '1em',
              fontSize: ".8em",
              fontWeight: "bold",
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'background.paper',
                transform: 'scale(1.01)',
              }
            }}
            onClick={handleClick}
          >
            Agregar Reloj
          </Button>
          <Button variant="contained"
            sx={{
              fontSize: ".8em",
              fontWeight: "bold",
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'background.paper',
                transform: 'scale(1.01)',
              }
            }}
            onClick={handleAtras}
          >
            Dashboard
          </Button>
        </Grid>
      </Grid>
      {/* Componente de Pie de Pag. */}
      <Pie />
    </>
  );
};

export default ListadoRelojes;
