import React from 'react';
import { useNavigate } from 'react-router';
// import { useDecrypt } from '../../hooks/useDecrypt';

// MUI componentes
import { 
  Box,
  Button,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

// MUI iconos
import ApartmentIcon from '@mui/icons-material/Apartment';

// Utils
import { verificarPermiso } from '../../utils/verificarPermiso';

const AcercaDependencia = ({ id, nombre, email, descripcion, telefono, padre, handleClick }) => {
  const navigate = useNavigate();

  let permisos = window.localStorage.getItem("permisos");
  // const permisos = useDecrypt('permisos');

  const handleEditar = () => {
    navigate(`/editar-dependencia/${id}`);
  };

  const handleAtras = () => {
    navigate(window.sessionStorage.getItem("urlAnterior"));
  };

  return (
    <Grid item xs={10} sm={10} md={8} lg={7}>
      
      <Grid item container xs={11} sm={11} md={12} lg={12}>
        <Button 
          variant="contained"
          sx={{
            alignSelf: "start",
            fontSize: ".8em",
            fontWeight: "bold",
            bgcolor: 'background.paper',
            '&:hover': {
              bgcolor: 'background.paper',
              transform: 'scale(1.01)',
            }
          }}
          onClick={handleAtras}
        >
          Atrás
        </Button>
        <Button variant="contained"
          sx={{
            fontSize: ".8em",
            marginLeft: "1em",
            fontWeight: "bold",
            bgcolor: 'background.paper',
            '&:hover': {
              bgcolor: 'background.paper',
              transform: 'scale(1.01)',
            }
            }}
          onClick={handleClick}
        >
          Usuarios del lugar
        </Button>
        <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
      </Grid>

      <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}> 
        <ApartmentIcon />
        <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
          Información sobre la Dependencia
        </Typography>
      </Box>    

      <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
        <Typography mr={"1em"} fontWeight={"bold"}>
          Nombre:
        </Typography>
        <Typography>
          {nombre}
        </Typography>
      </Box>
      
      {
        verificarPermiso(permisos, 'super_usuario') ?
          <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
            <Typography mr={"1em"} fontWeight={"bold"}>
              Dependencia/Oficina Padre:
            </Typography>
            <Typography>
              {padre}
            </Typography>
          </Box>
        :
          null
      }

      <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}} >
        <Typography mr={"1em"} fontWeight={"bold"}>
            Descripción:
        </Typography>
        <Typography>
            {descripcion ? descripcion : "Sin detalle"}
        </Typography>
      </Box>

      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between"}}>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Teléfono:
          </Typography>
          <Typography>
            {telefono ? telefono : "Sin detalle"}
          </Typography>
        </Box>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            E-mail:
          </Typography>
          <Typography>
            {email ? email : "Sin detalle"}
          </Typography>
        </Box>
      </Box>

      {
        verificarPermiso(permisos, 'super_usuario') ?
          <Typography
            onClick={handleEditar}
            disableelevation="true"
            sx={{
              mt: "1em",
              background: 'none',
              border: 'none',
              color: "primary.dark",
              "&:hover": {
                cursor: 'pointer',
              }
            }}
            >
              Modificar datos*
          </Typography>
        :
          null
      }
    </Grid>
  );
};  

export default AcercaDependencia;
