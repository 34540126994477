import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';
// import { useDecrypt } from '../../hooks/useDecrypt';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
// import Clock from '../Clock/Clock';
import AcercaPerfil from './Componentes/AcercaPerfil';
import MarcacionesPerfil from './Componentes/MarcacionesPerfil';
import HorarioPerfil from './Componentes/HorariosPerfil';
import AcercaDesignacion from './Componentes/AcercaDesignacion';
import ModalComponent from '../ModalComponent/ModalComponent';
import Pie from '../Pie/Pie';

// Actions
import { buscar_por_dni, buscar_por_dni_actual, restaurarEstado } from '../../redux/action-types/usuarioActions';

// MUI componentes
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

// Estado inicial de busqueda
const initialState = {
  inicio: new Date(new Date().setDate(new Date().getDate() - 6)),
  fin: new Date(new Date().setDate(new Date().getDate())),
};

const PerfilUsuario = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dni } = useParams();

  const dniActual = window.localStorage.getItem("dni");

  const tipo_rol = window.localStorage.getItem("permisos");
  // const tipo_rol = useDecrypt('rol');

  const { usuario, flag, text } = useSelector(state => state.usuario);

  const [datosObtenidos, setDatosObtenidos] = useState(false);

  const [timeExceeded, setTimeExceeded] = useState(false);

  const [isOpenModal, openModal, closeModal] = useModal();

  const handleClick = () => {
    navigate(window.sessionStorage.getItem("urlAnterior"));
  };

  useEffect( () => {
    dispatch(restaurarEstado())
    dispatch(buscar_por_dni(dni));
    setDatosObtenidos(true)
    if (window.sessionStorage.getItem("urlAnterior") === `/perfil/${dni}`)
      window.sessionStorage.setItem("urlAnterior", "")
  }, [dispatch, dni, dniActual, tipo_rol, datosObtenidos]);

  useEffect(() => {
    // Configura el tiempo límite
    const timeoutId = setTimeout(() => {
      setTimeExceeded(true);
      if(!usuario) {
        setTimeout(() => {
          openModal();
          setTimeout(() => {
            navigate("/buscar-usuario");
          }, 3000);
        }, 0);
      };
    }, 5000);
    // Limpia el temporizador
    return () => clearTimeout(timeoutId);
  }, [usuario, openModal, navigate]);

  // Si no hay datos del usuario
  if(!usuario && !timeExceeded) {

    return (
      <>
        {/* Navegación */}
        <Rotulo titulo={"Perfil"} />
        <Grid container>
          <CircularProgress
            sx={{
              width: "auto",
              height: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </Grid>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  };
  // Si el usuario no puede acceder a esa informacion
  if(!usuario && timeExceeded && !flag) {
    return (
      <>
        {/* Navegación */}
        <Rotulo titulo={"Perfil"} />
        {/* Componente de Notificaciones */}
        <ModalComponent open={isOpenModal} handleClose={closeModal} >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Redireccionando...
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {text}
          </Typography>
        </ ModalComponent>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  };
  // Si tiene autorizacion
  // Filtro designaciones vigentes e historicas

  if (!datosObtenidos){
    return (
      <>

    {/* Navegación */}
    <Rotulo titulo={"Perfil"} />
        <Grid container>
          <CircularProgress
            sx={{
              width: "auto",
              height: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </Grid>
        {/* Pie de Pág */}
        <Pie />
    </>
    );
  }

  const desig = usuario.designacion?.filter((des) => !des.historica)[0] || usuario.designacion?.filter((des) => des.historica)[0]


  return (
    <>
      <Rotulo titulo={"Perfil"} />
      {/* Muestra el Reloj */}
      {/* Lo voy a dejar asi hasta que funcione bien */}
      {/* {
        dniActual === dni ?
          <Clock marcaciones={usuario.marcaciones} horario={usuario.horario}/>
        :
          <Grid sx={{ height: "9vh" }}></Grid>
      }  */}
      <Grid sx={{ height: "5vh" }}></Grid>
      <Grid container spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: '5em'
        }}
      >
        {window.sessionStorage.getItem("urlAnterior") ?
        <Grid item xs={12} sm={10} md={10} lg={8}>
          <Button
            variant="contained"
            sx={{
              alignSelf: "start",
              fontSize: ".8em",
              fontWeight: "bold",
              bgcolor: "background.paper",
              "&:hover": {
                bgcolor: "background.paper",
                transform: "scale(1.01)",
              },
            }}
            onClick={handleClick}
          >
            Atrás
          </Button>
          <Divider sx={{ width: "100%", height: "1em", mb: "1em" }} />
        </Grid>
        :
        <></>}
        <Grid item container xs={12} sm={10} md={10} lg={8} spacing={4} mb={4}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* Datos del Usuario */}
          <AcercaPerfil
            nombre={usuario.nombre}
            apellido={usuario.apellido}
            dni={usuario.dni}
            genero={usuario.genero}
            ciudad={usuario.ciudad}
            nacimiento={usuario.nacimiento}
            direccion={usuario.direccion}
            mail={usuario.email}
            telefono={usuario.telefono}
            celular={usuario.celular}
          />
          {/* Datos Designacion */}
          <AcercaDesignacion
            dependencia={desig.dependency }
            cargo={desig.cargo?.codigo}
            desc={desig.cargo?.descripcion}
            cargoTipo={desig.cargo?.tipo}
            inicio={desig.fecha_inicio}
            estado={desig.historica}
            fin={desig.fecha_fin}
            dni={usuario.dni}
          />
        </Grid>
        <Grid item container xs={12} sm={10} md={10} lg={8} spacing={4}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* Ultimas marcaciones */}
          <MarcacionesPerfil
            dni={usuario.dni}
            inicio={initialState.inicio}
            fin={initialState.fin}
          />
          {/* Horario establecido */}
          <HorarioPerfil usuario={usuario} />
        </Grid>
      </Grid>
      {/* Pie de Pág */}
      <Pie />
    </>
  );
};

export default PerfilUsuario;
