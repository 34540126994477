import axios from "axios";

import { audit_desig, audit_hor, audit_justif_gene, audit_justif_person, audit_user, buscar, restaurar, justificacion_por_id, creador_eliminador, historial_versiones, set_periodo } from "../reducers/auditoriaReducer";

export const buscarUsuario = (dni) => {
    return function(dispatch) {
      return axios.post(`${process.env.REACT_APP_BASE_URL}/auditoria/buscar-usuarios`, dni)
        .then(response => {
          dispatch(buscar({usuarios: response.data.usuarios,                            
                            text: response.data.mensaje}));
        })
        .catch(error => console.log(error));
    };
  };

  export const auditarUsuarios = (dni, periodo) => {
    return function(dispatch) {
      return axios.post(`${process.env.REACT_APP_BASE_URL}/auditoria/auditar-usuarios`, dni, periodo)
        .then(response => {
          dispatch(audit_user({usuarios_auditoria: response.data.usuarios,                            
                            text: response.data.mensaje}));
        })
        .catch(error => console.log(error));
    };
  };

  export const auditarJustificacionesPersonales = (dni, periodo) => {
    return function(dispatch) {
      return axios.post(`${process.env.REACT_APP_BASE_URL}/auditoria/auditar-justificaciones-personales`, dni, periodo)
        .then(response => {
          dispatch(audit_justif_person({justificaciones_auditoria: response.data.justificaciones,                            
                            text: response.data.mensaje}));
        })
        .catch(error => console.log(error));
    };
  };

  export const auditarJustificacionesGenerales = (dni, periodo) => {
    return function(dispatch) {
      return axios.post(`${process.env.REACT_APP_BASE_URL}/auditoria/auditar-justificaciones-generales`, dni, periodo)
        .then(response => {
          dispatch(audit_justif_gene({justificaciones_auditoria: response.data.justificaciones,                            
                            text: response.data.mensaje}));
        })
        .catch(error => console.log(error));
    };
  };

  export const auditarDesignaciones = (dni, periodo) => {
    return function(dispatch) {
      return axios.post(`${process.env.REACT_APP_BASE_URL}/auditoria/auditar-designaciones`, dni, periodo)
        .then(response => {
          dispatch(audit_desig({designaciones_auditoria: response.data.designaciones,                            
                            text: response.data.mensaje}));
        })
        .catch(error => console.log(error));
    };
  };

  export const auditarHorarios = (dni, periodo) => {
    return function(dispatch) {
      return axios.post(`${process.env.REACT_APP_BASE_URL}/auditoria/auditar-horarios`, dni, periodo)
        .then(response => {
          dispatch(audit_hor({horarios_auditoria: response.data.horarios,                            
                            text: response.data.mensaje}));
        })
        .catch(error => console.log(error));
    };
  };

  export const justificacionAuditPorId = (id) => {
    return function(dispatch) {
      return axios.get(`${process.env.REACT_APP_BASE_URL}/auditoria/justificacion-eliminada/${id}`)
        .then(response => {
          dispatch(justificacion_por_id({data: response.data}));
        })
        .catch(error => console.log(error));
    };
  };

  export const justificacionesPersonalesEnRango = (data) => {
    return function(dispatch) {
      return axios.post(`${process.env.REACT_APP_BASE_URL}/auditoria/justificaciones-personales-eliminadas`, data)
        .then(response => {
          dispatch(audit_justif_person({justificaciones_auditoria: response.data.justificaciones_eliminadas,                            
            text: response.mensaje}));
        })
        .catch(error => console.log(error));
    };
  };

  export const justificacionesGeneralesEnRango = (data) => {
    return function(dispatch) {
      return axios.post(`${process.env.REACT_APP_BASE_URL}/auditoria/justificaciones-generales-eliminadas`, data)
        .then(response => {
          dispatch(audit_justif_gene({justificaciones_auditoria: response.data.justificaciones_eliminadas,                            
            text: response.mensaje}));
        })
        .catch(error => console.log(error));
    };
  };

  export const designacionesEliminadas = (data) => {
    return function(dispatch) {
      return axios.post(`${process.env.REACT_APP_BASE_URL}/auditoria/designaciones-eliminadas`, data)
        .then(response => {
          dispatch(audit_desig({designaciones_auditoria: response.data.designaciones_eliminadas,                            
            text: response.mensaje}));
        })
        .catch(error => console.log(error));
    };
  };

  export const historialJustificaciones = (id) => {
    return function(dispatch) {
      return axios.get(`${process.env.REACT_APP_BASE_URL}/auditoria/justificaciones-modificadas/${id}`)
        .then(response => {
          dispatch(historial_versiones({historial_versiones: response.data.justificaciones_modificadas,                            
            text: response.mensaje}));
        })
        .catch(error => console.log(error));
    };
  };

  export const historialDesignaciones = (id) => {
    return function(dispatch) {
      return axios.get(`${process.env.REACT_APP_BASE_URL}/auditoria/designaciones-modificadas/${id}`)
        .then(response => {
          dispatch(historial_versiones({historial_versiones: response.data.designaciones_modificadas,                            
            text: response.mensaje}));
        })
        .catch(error => console.log(error));
    };
  };

  export const historialUsuario = (dni) => {
    return function(dispatch) {
      return axios.get(`${process.env.REACT_APP_BASE_URL}/auditoria/usuario-modificado/${dni}`)
        .then(response => {
          dispatch(historial_versiones({historial_versiones: response.data.usuarios_modificados,                            
            text: response.mensaje}));
        })
        .catch(error => console.log(error));
    };
  };

  export const historialHorarios = (dni) => {
    return function(dispatch) {
      return axios.get(`${process.env.REACT_APP_BASE_URL}/auditoria/horarios-modificados/${dni}`)
        .then(response => {
          dispatch(historial_versiones({historial_versiones: response.data.horarios_modificados,                            
            text: response.mensaje}));
        })
        .catch(error => console.log(error));
    };
  };

  export const historialVacaciones = (dni) => {
    return function(dispatch) {
      return axios.get(`${process.env.REACT_APP_BASE_URL}/auditoria/vacaciones-modificadas/${dni}`)
        .then(response => {
          dispatch(historial_versiones({historial_versiones: response.data.vacaciones_modificadas,                            
            text: response.mensaje}));
        })
        .catch(error => console.log(error));
    };
  };

  export const buscarCreadorYEliminador = (data) => {
    return function(dispatch) {
      return axios.post(`${process.env.REACT_APP_BASE_URL}/auditoria/creador-y-eliminador`, data)
        .then(response => {
          dispatch(creador_eliminador({creador: response.data.creador,                            
            eliminador:response.data.eliminador}));
        })
        .catch(error => console.log(error));
    };
  };

  export const setPeriodo = (periodo) => {
    return function(dispatch) {
      dispatch(set_periodo(periodo));
    };
  };

  export const restaurarEstado = () => {
    return function(dispatch) {
      dispatch(restaurar());
    };
  };