import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';
// import { useDecrypt } from '../../hooks/useDecrypt';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import AcercaJustificacion from './AcercaJustificacion';
import ModalComponent from '../ModalComponent/ModalComponent';
import Pie from '../Pie/Pie';

// Actions
import { justificacion_por_id, eliminar_justificacion, eliminar_justificacion_general, restaurarMensajes } from '../../redux/action-types/justificacionesActions'; 
import { historialJustificaciones, buscarCreadorYEliminador } from '../../redux/action-types/auditoriaActions';

// MUI componentes
import {
  Box, 
  Button,
  Grid,
  CircularProgress,
  Paper,
  Table,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  Typography,
} from '@mui/material';

// Utils
import { verificarPermiso } from '../../utils/verificarPermiso';
import { obtenerFecha } from '../../utils/obtenerFecha';

//MUI ICONS
import HistoryIcon from '@mui/icons-material/History';

const DetalleJustificacion = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dni, id } = useParams();
  
  const { justificacion, status, mensaje } = useSelector(state => state.justificaciones);
  const { creador, eliminador, historial_versiones } = useSelector(state => state.auditoria);

  const dniActual  = useSelector(state => state.acceso.dni);

  const permisos = window.localStorage.getItem("permisos");
  // const permisos = useDecrypt('permisos');

  const [isOpenModal, openModal, closeModal] = useModal();

  const handleClick = () => {
    openModal();
  };

  const handleDelete = (event) => {
    if(justificacion.general) { 
      const data = {
        usuarioActualInput: dniActual,
        id: id,
      };
      dispatch(eliminar_justificacion_general(data));
      openModal();
      setTimeout(() => {
        closeModal();
        navigate(`/justificaciones-generales`);
        dispatch(restaurarMensajes());
    }, 2000);
    } else { 
        const data = {
          usuarioActualInput: dniActual,
          id: id,
        };
        dispatch(eliminar_justificacion(data));
        openModal();
        setTimeout(() => {
          closeModal();
          navigate(`/marcaciones/${dni}`);
          dispatch(restaurarMensajes());
        }, 2000);
    };
  };

  const handleClickCreador = creador => {
    navigate(`/perfil/${creador}`);
  };

  useEffect(() => {
    dispatch(justificacion_por_id(id));
    dispatch(restaurarMensajes());
    if (verificarPermiso(permisos, 'super_usuario')){
      dispatch(historialJustificaciones(id));
    }
  }, [dispatch, id, permisos]);

  useEffect(() => {    
    if (justificacion && verificarPermiso(permisos, 'super_usuario')){ 
        const data = {dniCreador: justificacion.created_by, dniEliminador: justificacion.updated_by}
        dispatch(buscarCreadorYEliminador(data));
    }
  }, [dispatch, justificacion, permisos]);

  if(!justificacion) {
    return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={"Detalle Justificación"} />
        <Grid container>
          <CircularProgress 
            sx={{
              width: "auto",
              height: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />  
        </Grid>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  };

  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={"Detalle Justificación"} />
      <Grid container spacing={6} 
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1vh",
        }}
        >
        <Grid item container xs={12} sm={10} md={8} lg={8} ml={2}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* Datos de la Justificacion */}
          <AcercaJustificacion 
            id={justificacion.id} 
            dni={dni}
            nombre={`${justificacion.usuario?.nombre} ${justificacion.usuario?.apellido}`}
            inicio={justificacion.fecha_inicio} 
            fin={justificacion.fecha_fin} 
            justificacion={justificacion.tipo_justificacion}
            general={justificacion.general}
            creador={creador}
            dependencia={justificacion.dependencia}
            cabecera={justificacion.cabecera}
            eliminada={justificacion.eliminada}
            fecha_eliminacion={justificacion.updated_at}
            fecha_creacion={justificacion.created_at}
            eliminador = {eliminador}
            handleClick={handleClick}
          />
        {verificarPermiso(permisos, 'super_usuario')  ?
        <Grid item container xs={12} sm={12} md={12} lg={8} mt={"3em"}
        sx={{
          display: "flex",
          justifyContent: "center", 
        }}>
          <Grid item xs={10} sm={10} md={9.6} lg={12} mb={"2em"}>
          <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", ml: "-3px"}}> 
            <HistoryIcon />
            <Typography component={"h3"} variant={"h6"} fontSize={"1.4em"} ml={"2px"}>
              {`Historial de versiones`}
            </Typography>
            </Box>  
          </Grid>
          {historial_versiones && historial_versiones.length > 0 ?
          <Grid item xs={10} sm={10} md={9.6} lg={12}>
            <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
              <Table aria-label="simple table"> 
                <TableHead key={"head"}>
                  <TableRow key={"0"} sx={{bgcolor:"auditory.main"}}>
                    <TableCell key={"date_init"} align="center">Fecha inicio</TableCell>
                    <TableCell key={"date_end"} align="center">Fecha fin</TableCell>                  
                    <TableCell key={"justify"} align="center">Justificación</TableCell>
                    {justificacion.general ? <TableCell key={"dependency"} align="center">Dependencia</TableCell>:null}
                    {justificacion.general ? <TableCell key={"office"} align="center">Oficina</TableCell>:null}
                    <TableCell key={"deletedby"} align="center">Modificado por</TableCell>
                    <TableCell key={"deletedat"} align="center">Fecha modificacion</TableCell>
                    <TableCell key={"deletedathour"} align="center">Hora modificacion</TableCell>
                  </TableRow>
                </TableHead>
                {
                  <TableBody>
                    {
                      historial_versiones && historial_versiones.map((justificacion_h, index) => {
                        const esPrimeraJustificacion = index === 0;
                        const justificacionAnterior = esPrimeraJustificacion ? justificacion : historial_versiones[index - 1];

                        const colorFondoFechaInicio = justificacion_h.fecha_inicio !== justificacionAnterior.fecha_inicio ? "auditory.third" : null;
                        const colorFondoFechaFin = justificacion_h.fecha_fin !== justificacionAnterior.fecha_fin ? "auditory.third" : null;
                        const colorFondoTitulo = esPrimeraJustificacion ? justificacion_h.titulo !== justificacionAnterior.tipo_justificacion ? "auditory.third" : null : justificacion_h.titulo !== justificacionAnterior.titulo ? "auditory.third" : null;
                        const colorFondoDependencia = esPrimeraJustificacion? justificacion_h.nombre_dependencia !== justificacionAnterior.dependencia?.name ? "auditory.third" : null : justificacion_h.nombre_dependencia !== justificacionAnterior.nombre_dependencia ? "auditory.third" : null;
                        return (
                          <TableRow
                            key={`${justificacion_h.id}$`}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align="center"
                            sx={{
                              bgcolor: colorFondoFechaInicio 
                            }}>
                              {obtenerFecha(justificacion_h.fecha_inicio).fecha}</TableCell>
                            <TableCell align="center"
                            sx={{
                              bgcolor: colorFondoFechaFin 
                            }}
                            >{justificacion_h.fecha_inicio === justificacion_h.fecha_fin ? '-' : obtenerFecha(justificacion_h.fecha_fin).fecha}</TableCell>
                            <TableCell align="center"                             
                            sx={{
                              bgcolor: colorFondoTitulo 
                            }}>
                              {justificacion_h.titulo}
                            </TableCell>
                            {justificacion_h.general ? 
                            <TableCell align="center"                             
                            sx={{
                              bgcolor: colorFondoDependencia 
                            }}>
                              {justificacion_h.cabecera?.name}
                            </TableCell>
                            :
                            null}
                            {justificacion_h.general ? 
                            <TableCell align="center"                             
                            sx={{
                              bgcolor: colorFondoDependencia 
                            }}>
                              {justificacion_h.cabecera?.id !== justificacion_h.id_dependencia ? justificacion_h.nombre_dependencia : "-"}
                            </TableCell>
                            :
                            null}
                            <TableCell align="center" 
                              sx={{
                                '&:hover': {
                                  cursor: 'pointer',
                                  borderRadius: '.5em',
                                  boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                }
                              }}
                              onClick={() => handleClickCreador(justificacion_h.dni_creador)}
                            >
                              {justificacion_h.creador}
                            </TableCell>
                            <TableCell align="center">{obtenerFecha(justificacion_h.fecha_eliminada).fecha}</TableCell>
                            <TableCell align="center">{obtenerFecha(justificacion_h.fecha_eliminada).horario}</TableCell>
                          </TableRow>
                        );
                      })
                    }
                  </TableBody>
                }
              </Table>
            </TableContainer>
          </Grid>
          :
          <Grid item xs={10} sm={10} md={9.6} lg={11.6} mb={"2em"}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center"}}> 
              <Typography component={"h6"} variant={"h6"} fontSize={"1em"}>
                {`Esta justificación nunca fue modificada`}
              </Typography>
            </Box>  
          </Grid>
          }
        </Grid>
          :
            null
          }  
        </Grid>
      </Grid>
      
      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        {
          mensaje !== "OK"
          ?
          <>
            <Typography id="modal-modal-title" component="h2"sx={{ fontSize: '1.3em' }} >
              {status}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {mensaje}
            </Typography>
          </>
          :
          <>
            <Typography id="modal-modal-title" component="h2" sx={{ fontSize: '1.3em' }}>
              Esta por eliminar la justificación. ¿Desea continuar?
            </Typography>
            <Button
              type="submit"
              variant="contained"
              onClick={closeModal}
              sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                marginRight: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={handleDelete}
              sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'secondary.light',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Eliminar
            </Button>
          </>
        }
      </ModalComponent>
      {/* Pie de Pág */}
      <Pie /> 
    </>
  );
};

export default DetalleJustificacion;