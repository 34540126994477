import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';
// // import { useDecrypt } from '../../hooks/useDecrypt';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import Usuario from '../Usuario/Usuario';
import InputDate from './Components/InputDate';
import DetalleReporte from './Components/DetalleReporte';
import ModalComponent from '../ModalComponent/ModalComponent';
import Pie from '../Pie/Pie';

// Actions
import { buscar_por_dni, buscar_por_dni_actual } from '../../redux/action-types/usuarioActions';
import { marcaciones_dni, marcaciones_dni_actual, detalle_marcaciones, restaurarMensajes } from '../../redux/action-types/marcacionesActions';

// Funciones de ayuda
import { obtenerDia } from '../../utils/obtenerDia';
import { obtenerFecha } from '../../utils/obtenerFecha';
import { parsearFecha } from '../../utils/parsearFecha';
import { obtenerHorario } from '../../utils/obtenerHorario';

// MUI componentes
import {
  Box,
  Button,
  CircularProgress,
  Chip,
  Divider,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';

// MUI iconos
import AddCircleIcon from '@mui/icons-material/AddCircle';

// Utils
import { verificarPermiso } from '../../utils/verificarPermiso';
import { verificarDesignacion } from '../../utils/verificarDesignacion';
import { convertirAFecha } from '../../utils/stringAFecha';

// Estado inicial de busqueda
var fechaActual = new Date();
var semanaEnMilisegundos = 1000 * 60 * 60 * 24 * 7;
//var diaEnMilisegundos = 1000 * 60 * 60 * 24;
var suma = fechaActual.getTime(); //+ diaEnMilisegundos;
var resta = fechaActual.getTime() - semanaEnMilisegundos;
var fechaInicio = obtenerFecha(new Date(resta)).fecha2;
var fechaFin = obtenerFecha(new Date(suma)).fecha2;

const initialState = {
  inicio: fechaInicio,
  fin: fechaFin,
};

const initialDetail = {
  detalle: [],
};

const Marcaciones = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const matches = useMediaQuery('(min-width:650px)');

  const { dni } = useParams();
  const { usuario, text, flag } = useSelector(state => state.usuario);
  const { marcaciones_rango, sereno, mensaje, status } = useSelector(state => state.marcaciones);
  const { detalle } = useSelector(state => state.marcaciones)

  const permisos = window.localStorage.getItem("permisos");
  const tipo_rol = window.localStorage.getItem("rol");
  // const permisos = useDecrypt('permisos');
  // const tipo_rol = useDecrypt('rol');

  const dniActual = window.localStorage.getItem("dni");

  const [ isProcessing, setIsProcessing ] = useState(false);
  const [ fecha, setFecha ] = useState(initialState);
  const [ timeExceeded, setTimeExceeded ] = useState(false);

  const [ isOpenModal, openModal, closeModal ] = useModal();

  const [ detail, setDetail ] = useState(initialDetail)
  const [ tab, setTab ] = useState("1");
  const [ message, setMessage] = useState("");

  const handleTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setFecha({
      ...fecha,
      [name]: value,
    });
  };

  const handleAdd = (dia) => {
    window.sessionStorage.setItem('urlAnterior', `/marcaciones/${dni}`);
    navigate(`/justificaciones-personales/${dni}/${dia}`);
  };

  const handleDetail = (id) => {
    window.sessionStorage.setItem('urlAnterior', `/marcaciones/${dni}`);  
    navigate(`/detalle-justificacion/${dni}/${id}`);
  };

  const handleDetalle = (detalle) => {
    setDetail({ detalle: detalle });
    setTab("3");
  };

  const handleClick = () => {
    navigate(`/reportes`);
  };

  const handlePerfil = () => {
    window.sessionStorage.setItem("urlAnterior", `/marcaciones/${dni}`);
    navigate(`/perfil/${dni}`);
  };

  const handleSubmit = event => {
    event.preventDefault();
    dispatch(restaurarMensajes());
    
    if(convertirAFecha(fecha.inicio) > convertirAFecha(fecha.fin)){
      setMessage("La fecha de inicio del reporte no puede ser mayor a la de fin");
      openModal();
      setTimeout(() => {
        closeModal();
        setMessage("");
        setFecha(initialState);
      }, 4000);
    }else{
      const data = {dni, dniActual, inicio: fecha.inicio, fin: fecha.fin};
      dispatch(marcaciones_dni(data));
    }    
  };

  useEffect(() => {
    const data = {marcaciones: marcaciones_rango, dni_usuario: dni, sereno: sereno}
    dispatch(detalle_marcaciones(data));
    if (status === "Error") {       
      openModal();
      setTimeout(() => {
        navigate(`/reportes`);
        closeModal();
        dispatch(restaurarMensajes());
      }, 4000);
    };

    // eslint-disable-next-line
  }, [dispatch, marcaciones_rango, dni, navigate, status]);

  useEffect(() => {
    dispatch(restaurarMensajes())
    setFecha({inicio: initialState.inicio, fin: initialState.fin });
    dispatch(buscar_por_dni(dni));
    const data = {dni, dniActual, inicio: initialState.inicio, fin: initialState.fin};
    dispatch(marcaciones_dni(data));
  }, [dispatch, dni, tipo_rol, dniActual]);

  useEffect(() => {
    // Configura el tiempo límite
    const timeoutId = setTimeout(() => {
      setTimeExceeded(true);
      if(!usuario) {
        setTimeout(() => {
          openModal();
          setTimeout(() => {
            navigate("/reportes");
          }, 3000);
        }, 0);
      };
    }, 5000);
    // Limpia el temporizador
    return () => clearTimeout(timeoutId);
  }, [usuario, openModal, navigate]);

  // Si no hay datos
  if((!marcaciones_rango || !usuario) && !timeExceeded) {
    return (
      <>
        <CircularProgress
          sx={{
            width: "auto",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />
        {/* Pie de Pág. */}
        <Pie />
      </>
    );
  };

  // Si el usuario no puede acceder a esa informacion
  if(!usuario && timeExceeded && !flag) {
    return (
      <>
        {/* Navegación */}
        <Rotulo titulo={"Marcaciones"} />
        {/* Componente de Notificaciones */}
        <ModalComponent open={isOpenModal} handleClose={closeModal} >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Redireccionando...
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {text}
          </Typography>
        </ ModalComponent>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  };

  // Si tiene autorizacion
  return (
    <>
      <Rotulo titulo={"Marcaciones"} />
      <Grid container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3vh",
        }}
      >
        <Grid item xs={11} sm={11} md={10} lg={7}>
          <Button
            variant="contained"
            sx={{
              alignSelf: "start",
              fontSize: ".8em",
              fontWeight: "bold",
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'background.paper',
                transform: 'scale(1.01)',
              }
            }}
            onClick={handleClick}
          >
            Buscar
          </Button>
          <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
        </Grid>
        <Grid item container xs={12} sm={10} md={12} lg={7}
          sx={{
            display:"flex",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12} sm={10} md={8} lg={5}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Componente que muestra los datos del usuario */}
            <Usuario 
              nombre={usuario.nombre} 
              apellido={usuario.apellido} 
              dni={dni} 
              handleClick={handlePerfil} 
              historico={verificarDesignacion(usuario.designacion)}
            />
          </Grid>
          <Grid item xs={12} sm={7} md={6} lg={7}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Componente que toma la fecha para el rango de marcaciones */}
            <InputDate 
              inicio={fecha.inicio} 
              fin={fecha.fin} 
              handleChange={handleChange} 
              handleSubmit={handleSubmit} 
              dependencia_id={''} 
              dependencia_name={''} 
              dni_usuario={dni}
              isProcessing={isProcessing}
              setIsProcessing={setIsProcessing}
            />
          </Grid>
        </Grid>

        <Grid item mt={2} xs={12} sm={11} md={10} lg={7} marginLeft={".8em"} marginRight={".8em"}>
          <Divider />
        </Grid>
        {
          marcaciones_rango.length === 0 ? 
            <CircularProgress
              sx={{
                width: "auto",
                height: "auto",
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            />
          :
            //Componente con las pestañas
            <Grid item xs={12} sm={11} md={10} lg={7}
              sx={{
                display:"flex",
                justifyContent: "center",
                flexDirection: "column"
              }}
            >
              <Grid item container >
                <TabContext value={tab}>
                  <Grid item container
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider',
                    }}
                  >
                    <TabList onChange={handleTab} aria-label="tabs">
                      <Tab label="Reporte" value={"1"} sx={{ fontSize:".8em", fontWeight:"bold", color: "text.default" }} />
                      <Tab label="Resumen" value={"2"} sx={{ fontSize:".8em", fontWeight:"bold", color: "text.default" }} />
                      {tab !== "3" ?
                      <Tab label="Detalle" value={"3"} sx={{ visibility: "hidden", height: 0, width: 0 }} />
                      :
                      <Tab label="Detalle" value={"3"} sx={{ fontSize:".8em", fontWeight:"bold", color: "text.default" }} />}
                    </TabList>
                    </Grid>
                </TabContext>
              </Grid>
                {
                  tab === "1" ?
                    <>
                      {
                        matches && marcaciones_rango &&
                        <Grid item container >
                          <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow key={"0"} sx={{bgcolor:"primary.light"}}>
                                  <TableCell key={"day"} align="center">Día</TableCell>
                                  <TableCell key={"date"} align="center">Fecha</TableCell>
                                  <TableCell key={"schu"} align="center">Horario</TableCell>
                                  <TableCell key={"in"} align="center">Entrada</TableCell>
                                  <TableCell key={"out"} align="center">Salida</TableCell>
                                  <TableCell key={"hours"} align="center">Horas</TableCell>
                                  <TableCell key={"justify"} align="center">Justificación</TableCell>
                                </TableRow>
                              </TableHead>
                              {
                                <TableBody>
                                  {
                                    marcaciones_rango && marcaciones_rango.map(marcacion => {                                  
                                      const dia = obtenerDia(marcacion.fecha_marcacion);
                                      const hoy = obtenerFecha(fechaActual).fecha;
                                      return (
                                        <TableRow
                                          key={`${marcacion.horas_trabajadas}, ${marcacion.fecha_marcacion}`}
                                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                          style={marcacion.fecha_marcacion===hoy?{backgroundColor:'rgba(0, 95, 97, 0.1)'}:{} }
                                        >
                                          <TableCell align="center" >{dia}</TableCell>
                                          <TableCell align="center" >{marcacion.fecha_marcacion}</TableCell>
                                          <TableCell align="center" width={'15%'}>{obtenerHorario(dia, usuario.horario)}</TableCell>
                                          <TableCell align="center" >{!sereno && marcacion.entrada === '00:00' ? '' : sereno && marcacion.entrada === "15:00" ? '' : marcacion.entrada}</TableCell>
                                          <TableCell align="center" >{!sereno && marcacion.salida === '00:00' ? '' : sereno && marcacion.salida === "15:00" ? '' : marcacion.salida}</TableCell>
                                          {(!sereno && marcacion.entrada !== '00:00') || (sereno && marcacion.entrada !== '15:00') ?
                                          <TableCell align="center" 
                                          sx={{
                                            "&:hover": {
                                              cursor: "pointer",
                                              borderRadius: ".5em",
                                              boxShadow:
                                                "1px 1px 3px 3px rgba(40,40,40,0.4)",
                                            },
                                          }}
                                          onClick={() => handleDetalle(marcacion.detalle)}>{marcacion.horas_trabajadas === '00:00' ? '' : marcacion.horas_trabajadas}</TableCell>
                                        :
                                          <TableCell align="center" >{marcacion.horas_trabajadas === '00:00' ? '' : marcacion.horas_trabajadas}</TableCell>}
                                          <TableCell
                                            align='center'
                                            width={'45%'}
                                            sx={{ 
                                              alignItems: 'center', 
                                              justifyContent: 'center' 
                                          }}
                                          >
                                            <Box sx={{ 
                                                  display:' flex',                
                                                  alignItems: 'center', 
                                                  justifyContent: 'center' 
                                              }}>
                                              {
                                              marcacion.justificacion.map(justificacion => {
                                                return (
                                                <Box sx={{ 
                                                  display:' flex',
                                                  flexDirection: 'column',
                                                  alignItems: 'center', 
                                                  justifyContent: 'center' 
                                              }}>
                                                {justificacion.eliminada ?
                                                  verificarPermiso(permisos, 'super_usuario') ?
                                                    <Chip sx={{bgcolor: justificacion.general ? 'auditory.main' : "auditory.fourth", color:"white", margin: '3px'}}                                                  
                                                    key={justificacion.id} label={justificacion.tipo_justificacion}
                                                    onClick={() => handleDetail(justificacion.id)} />
                                                  :
                                                  null
                                                :                                                
                                                  <Chip sx={{bgcolor: justificacion.general ? 'primary.main' : "primary.chip", color:"white", margin: '3px'}}
                                                  key={justificacion.id} label={justificacion.tipo_justificacion}
                                                  onClick={() => handleDetail(justificacion.id)} />}
                                                </Box>
                                                )
                                              })                                       

                                              }
                                              {
                                                <Box>
                                              {verificarPermiso(permisos, 'usuario_administrador') ?
                                                <AddCircleIcon style={{ cursor: 'pointer', justifyContent: 'center' }} onClick={() => handleAdd(parsearFecha(marcacion.fecha_marcacion))} />
                                              :
                                                null}
                                                </Box>
                                              }
                                              </Box>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })
                                  }
                                </TableBody>
                              }
                            </Table>
                          </TableContainer>
                      </Grid>
                    }
                    {
                      !matches && marcaciones_rango &&
                        <Grid item xs={12} marginLeft={".8em"} marginRight={".8em"}>
                          <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell key={"date"} align="left">Fecha</TableCell>
                                  <TableCell key={"in"} align="left">Entrada</TableCell>
                                  <TableCell key={"out"} align="left">Salida</TableCell>
                                  <TableCell key={"hours"} align="left">Horas</TableCell>
                                </TableRow>
                              </TableHead>
                              {
                                <TableBody>
                                  {
                                      marcaciones_rango && marcaciones_rango.map(marcacion => {
                                      return (
                                        <TableRow
                                          key={marcacion.id}
                                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                          <TableCell key={"fecha"} align="left">{marcacion.fecha_marcacion}</TableCell>
                                          <TableCell key={"entrada"} align="left">{marcacion.entrada === '00:00' ? '' : marcacion.entrada}</TableCell>
                                          <TableCell key={"salida"} align="left">{marcacion.salida === '00:00' ? '' : marcacion.salida}</TableCell>
                                          <TableCell key={"horas"} align="left">{marcacion.horas_trabajadas === '00:00' ? '' : marcacion.horas_trabajadas}</TableCell>
                                        </TableRow>
                                      );
                                    })
                                  }
                                </TableBody>
                              }
                            </Table>
                        </TableContainer>
                      </Grid>
                    }
                  </>
                : tab === "2" ?
                <DetalleReporte detalle={detalle} />
                :
                <>
                    {
                      detail &&
                      <Grid item container >
                        <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow key={"0"} sx={{bgcolor:'primary.light'}}>
                                <TableCell key={"day"}>Día</TableCell>
                                <TableCell key={"date"}>Fecha</TableCell>
                                <TableCell key={"schu"} align="center">Marcacion</TableCell>
                                <TableCell key={"justify"} align="center">Reloj</TableCell>
                              </TableRow>
                            </TableHead>
                            {
                              <TableBody>
                                {
                                  detail && detail.detalle.map(marcacion => {
                                    const dia = obtenerDia(marcacion.fecha_marcacion);

                                    return (
                                      (!sereno && obtenerFecha(marcacion.fecha_marcacion).horario2 === "00:00:00") || (sereno && obtenerFecha(marcacion.fecha_marcacion).horario2 === "15:00:00") ?
                                      null
                                      :
                                      <TableRow
                                        key={`${marcacion.fecha_marcacion}`}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                      >
                                        <TableCell key={"dia"} align="left" width={'10%'}>{dia}</TableCell>
                                        <TableCell key={"fecha"} align="left" width={'10%'}>{obtenerFecha(marcacion.fecha_marcacion).fecha}</TableCell>
                                        <TableCell key={"horario"} align="center" width={'15%'}>{obtenerFecha(marcacion.fecha_marcacion).horario2}</TableCell>
                                        <TableCell key={"reloj"} align="center" width={'15%'}>{marcacion.nombre_reloj}</TableCell>
                                      </TableRow>
                                    );
                                  })
                                }
                              </TableBody>
                            }
                          </Table>
                        </TableContainer>
                      </Grid>
                    }
                  </>
              }
          </Grid>
        }

        {/* Componente de Notificaciones */}
        <ModalComponent open={isOpenModal} handleClose={closeModal} >
          {message
          ?
          <Typography id="modal-modal-title" component="h2" sx={{ fontSize: '1.3em' }}>
          {message}
          </Typography>
          :
          mensaje
          ?
          <>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Redireccionando...
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {mensaje}
          </Typography>
          </>
          :
          <Typography id="modal-modal-title" component="h2" sx={{ fontSize: '1.3em' }}>
          Ingrese una fecha de inicio y una de fin para el reporte.
          </Typography>}
        </ModalComponent>
      </Grid>
      {/* Componente de Pie Pag */}
      <Pie />
    </>
  );
};

export default Marcaciones;
