import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import AcercaDependencia from './AcercaDependencia';
import Pie from '../Pie/Pie';

// Actions
import { buscar_por_id, buscar_padre } from '../../redux/action-types/dependenciaActions';

// MUI componentes
import {
  Grid,
  CircularProgress,
} from '@mui/material';

const DetalleDependencia = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const { dependencia, padre } = useSelector(state => state.dependencia);

  const handleClick = () => {
    navigate(`/usuarios-dependencia/${id}`);
  };

  useEffect(() => {
    // window.sessionStorage.setItem('urlAnterior', `/dependencia/${id}`);
    dispatch(buscar_por_id(id));
    dispatch(buscar_padre(id));
  }, [dispatch, id]);

  if(!dependencia || !padre) {
    return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={"Dependencia"} />
        <Grid container>
          <CircularProgress
            sx={{
              width: "auto",
              height: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </Grid>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  };

  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={"Dependencia"} />
      <Grid container spacing={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "3vh",
        }}
      >
        <Grid item container xs={12} sm={10} md={8} lg={6} ml={2}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* Datos de la Dependencia */}
          <AcercaDependencia
            id={dependencia.id}
            nombre={dependencia.name}
            email={dependencia.email}
            descripcion={dependencia.descripcion}
            telefono={dependencia.telefono}
            padre={padre.name}
            handleClick={handleClick}
          />
        </Grid>
      </Grid>
      {/* Pie de Pág */}
      <Pie />
    </>
  );
};

export default DetalleDependencia;
