import { createTheme } from '@mui/material/styles';
//import { esES as dataGridEsES } from '@mui/x-data-grid';
import { esES as coreEsES } from '@mui/material/locale';
import { esES } from '@mui/x-date-pickers/locales';

import palette from "./lightPalette";
import typography from "./lightTypography";

// import palette from "./darkPalette";
// import typography from "./darkTypography";

export const lightTheme = createTheme({
  palette,
  typography,
  esES, // x-date-pickers translations
  coreEsES, // core translations
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        noOptions: {
          color: palette.text.primary,
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: palette.text.secondary,

          "&:hover": {
            color: palette.background.paper,
            transform: 'scale(1.02)',
          },
          "&:active": {
            color: palette.background.paper,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 'bold',
          color: 'white'
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: palette.text.primary
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        root: {          
          color: palette.primary.light,
          borderRadius: 6,
          borderWidth: 0,
          borderColor: '#2196f3',
          border: '0px solid',
          backgroundColor: palette.primary.light,

        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          borderWidth: 0,
          border: '0px solid',
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          color: palette.primary.light,
          borderRadius: 6,
          borderWidth: 0,
          borderColor: '#2196f3',
          border: '0px solid',
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        label: {
          color: palette.primary.light,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        labelContainer: {
          color: "black",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
          tooltip: {
              fontSize: '1em',
              backgroundColor: palette.primary.light
          },
          arrow: {
            color: palette.primary.light
          }
      },
    },
  },
});

export const darkTheme = createTheme({
  palette,
  typography,
});