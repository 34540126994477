import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from '../../hooks/useModal';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import ModalComponent from '../ModalComponent/ModalComponent';
import Pie from '../Pie/Pie';

// Actions
import { cambiarContraseña, restaurarMensajes } from '../../redux/action-types/usuarioActions'; 
 
// MUI componentes
import { 
  Box, 
  Button, 
  CircularProgress,
  OutlinedInput,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';


// MUI iconos
import KeyIcon from '@mui/icons-material/Key';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const validate = (state) => {
  let errors = {};

  if (state.contraseñaInput === '') {
    errors.contraseñaInput = '';
  }

  if(state.contraseñaNuevaRInput === '') {
    errors.contraseñaNuevaRInput = '';
  };

  if(state.contraseñaNuevaInput === '') {
    errors.contraseñaNuevaInput = '';
  } else if(state.contraseñaNuevaInput.length < 6) {
    errors.contraseñaNuevaInput = 'La contraseña debe tener al menos 6 caracteres...';
  } else if(state.contraseñaNuevaInput !== state.contraseñaNuevaRInput && state.contraseñaNuevaRInput !== ''){
    errors.contraseñaNuevaInput = 'Las contraseñas ingresadas no coinciden'
  } else if(state.contraseñaNuevaInput === state.contraseñaInput) {
    errors.contraseñaNuevaInput = 'La contraseña nueva no puede ser igual a la anterior'
  }


  
  return errors;
};

const initialState = {
  contraseñaInput: '',
  contraseñaNuevaInput: '',
  contraseñaNuevaRInput: '',
};

const CambiarContraseña = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { status, text } = useSelector(state => state.usuario);
  const dniActual  = useSelector(state => state.acceso.dni);

  const [ state, setState ] = useState(initialState);
  const [ errors, setErrors ] = useState(initialState);
  
  const [isOpenModal, openModal, closeModal] = useModal();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);


  const handleChange = event => {
    const { name, value } = event.target;
    setErrors(validate({
      ...state,
      [name]: value
    }));
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleClick = () => {
    navigate(`/`);
  };

  const handleSubmit = event => {
    event.preventDefault();
    dispatch(cambiarContraseña({...state, usuarioActualInput: dniActual}));
    openModal()
    setTimeout(() => {
        dispatch(restaurarMensajes())
        navigate(`/`);
    }, 3000);
    setState(initialState);
    setErrors(initialState);
  };


  if(!dniActual ) {
    return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={'Cambiar contraseña'} />
        <CircularProgress 
          sx={{
            width: "auto",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />  
        {/* Copmponente de Pie de Pag. */}
        <Pie />
      </>
    );
  };
 
  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={'Cambiar contraseña'} />
      {/* Aca arranca el componente Crear Usuario */}
      <Grid id={"cambiarContraseña"} container component="form" noValidate onSubmit={handleSubmit} spacing={6} 
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3vh",
          width: "100%"
        }}
      >
        {/* Contenedor Grid de los Items   */}
        <Grid item container xs={10} sm={10} md={8} lg={6} spacing={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%"
          }}
        >   
          {/* Item 1 */}
          <Grid item xs={10} sm={10} md={8} lg={10}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}> 
              <KeyIcon />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                Contraseña
              </Typography>
            </Box>   

            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
            <Typography mr={"1em"} fontWeight={"bold"}>
                Contraseña actual*:
              </Typography>
            <FormControl>
                <OutlinedInput
                    id="contraseñaInput"
                    name="contraseñaInput"
                    placeholder='Ingresa tu contraseña actual'
                    value={state.contraseñaInput}
                    type={showPassword ? 'text' : 'password'}
                    error={!!errors.contraseñaInput}
                    onChange={handleChange}
                    fullWidth                    
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                        >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }
                />
                </FormControl>
                {
                errors.contraseñaInput && 
                <FormHelperText
                  sx={{
                    color: "red",
                  }}
                >
                  {errors.contraseñaInput}
                </FormHelperText>
              }
            
            </Box>
            
            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Contraseña nueva*:
              </Typography>
              <FormControl>
                <OutlinedInput
                    id="contraseñaNuevaInput"
                    name="contraseñaNuevaInput"
                    placeholder='Ingresa la nueva contraseña'
                    value={state.contraseñaNuevaInput}
                    type={showPassword ? 'text' : 'password'}
                    error={!!errors.contraseñaNuevaInput}
                    onChange={handleChange}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                        >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }
                />
                </FormControl>
                {
                errors.contraseñaNuevaInput && 
                <FormHelperText
                  sx={{
                    color: "red",
                  }}
                >
                  {errors.contraseñaNuevaInput}
                </FormHelperText>
              }
              
            </Box>
            
            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Repetir contraseña nueva*:
              </Typography>
              <FormControl>
                <OutlinedInput
                    id="contraseñaNuevaRInput"
                    name="contraseñaNuevaRInput"
                    placeholder='Repite la contraseña nueva'
                    value={state.contraseñaNuevaRInput}
                    type={showPassword ? 'text' : 'password'}
                    error={!!errors.contraseñaNuevaRInput}
                    onChange={handleChange}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                        >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }
                />
                </FormControl>
                {
                errors.contraseñaNuevaRInput && 
                <FormHelperText
                  sx={{
                    color: "red",
                  }}
                >
                  {errors.contraseñaNuevaRInput}
                </FormHelperText>
              }
            </Box>

          </Grid> 

          {/* Item 3 */}
          <Grid item container xs={10} sm={10} md={8} lg={10}>
            <Button 
              variant="contained"
              sx={{
                mb: 2,
                mr: 1,
                fontSize: ".8em",
                fontWeight: "bold",
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
              onClick={handleClick}
            >
              Cancelar
            </Button>
            {
              (
              (errors.contraseñaNuevaRInput?.length > 0 || errors.contraseñaNuevaInput?.length > 0 || errors.contraseñaInput?.length > 0 )
              ||
              (state.contraseñaNuevaRInput === '' || state.contraseñaNuevaInput === '' || state.contraseñaInput === '' ))
              ?
                <Button
                  type="submit"
                  variant="contained"
                  disabled
                  sx={{
                    mb: 2,
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Confirmar
                </Button>
              :
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mb: 2,
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Confirmar
                </Button>
            }
          </Grid>
        </Grid>
      </Grid>
      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {status}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {text}
        </Typography>
      </ ModalComponent>
      {/* Copmponente de Pie de Pag. */}
      <Pie />
    </>
  );
};

export default CambiarContraseña;