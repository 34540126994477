import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
// import { useDecrypt } from '../../hooks/useDecrypt';

// Funciones de ayuda
import { obtenerFecha } from '../../utils/obtenerFecha';

//Actions
import { designacionesEliminadas } from '../../redux/action-types/auditoriaActions';

// MUI componentes
import { 
  Box,
  Grid, 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

// Icons
import { Delete } from '@mui/icons-material';

// Utils
import { verificarPermiso } from '../../utils/verificarPermiso';

const ListadoEliminadas = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dni } = useParams();

  const { designaciones_audit } = useSelector(state => state.auditoria);
  const permisos = window.localStorage.getItem("permisos");
  // const permisos = useDecrypt('permisos');

  const [datosObtenidos, setDatosObtenidos] = useState(false);

  const dniActual  = useSelector(state => state.acceso.dni);

  const handleClickCreador = creador => {
    window.sessionStorage.setItem('urlAnterior', `/designaciones/${dni}`);
    navigate(`/perfil/${creador}`);
  };

  const handleClickDependencia = (id) => {
    window.sessionStorage.setItem('urlAnterior', `/designaciones/${dni}`);
    navigate(`/dependencia/${id}`);
  };
  
  useEffect(() => {
    if(verificarPermiso(permisos, 'super_usuario')){
      dispatch(designacionesEliminadas({usuarioDni:dni}));
    };
      setTimeout(() => {
          setDatosObtenidos(true);
        }, 1000); 
  }, [dispatch, dniActual, dni, permisos])
  
  if(!datosObtenidos) {
    return (
      <>  
        <Box> 
          <Typography component={"h6"} variant={"h6"} fontSize={"1em"}>
            ...
          </Typography>
        </Box>
      </>
    );
  };

  return (
    <>
      {
        verificarPermiso(permisos, 'super_usuario') && designaciones_audit && designaciones_audit.length > 0 ?
        <Grid>
        <Box sx={{display: 'flex', justifyContent: 'start', marginBottom:'8px'}}>
              <Delete />
              <Typography component={"h6"} variant={"h6"} fontSize={"1.2em"} ml={"2px"}>
                Designaciones Eliminadas
              </Typography>
            </Box>

          <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
            <Table aria-label="simple table"> 
              <TableHead key={"head"}>
                <TableRow key={"0"} sx={{bgcolor:"auditory.main"}}>
                  <TableCell key={"date_init"} align="center">Fecha inicio</TableCell>
                  <TableCell key={"date_end"} align="center">Fecha fin</TableCell>
                  <TableCell key={"codigo_cargo"} align='center'>Codigo cargo</TableCell>
                  <TableCell key={"tipo_cargo"} align='center'>Tipo cargo</TableCell>
                  <TableCell key={"descripcion_cargo"} align='center'>Descripcion cargo</TableCell>
                  <TableCell key={"dependencia"} align='center'>Dependencia</TableCell>
                  <TableCell key={"oficina"} align='center'>Oficina</TableCell>
                  <TableCell key={"createdby"} align="center">Creado por</TableCell>
                  <TableCell key={"createdat"} align="center">Creado en</TableCell>
                  <TableCell key={"deletedby"} align="center">Eliminado por</TableCell>
                  <TableCell key={"deletedat"} align="center">Eliminado en</TableCell>
                </TableRow>
              </TableHead>
              {
                <TableBody>
                  {
                    designaciones_audit && designaciones_audit.map(designacion => {
                      return (
                        <TableRow                                
                          key={designacion.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >                    
                            <TableCell key={"fecha_inicio"} align="center">{obtenerFecha(designacion.fecha_inicio).fecha}</TableCell>
                            <TableCell key={"fecha_fin"} align="center">{obtenerFecha(designacion.fecha_fin).fecha}</TableCell>
                            <TableCell key={"codigo_cargo"} align='center'>{designacion.cargo?.codigo}</TableCell>
                            <TableCell key={"tipo_cargo"} align='center'>{designacion.cargo?.tipo}</TableCell>
                            <TableCell key={"descripcion_cargo"} align='center'>{designacion.cargo?.descripcion}</TableCell>
                            <TableCell key={"dependency"} align='center'
                            sx={{
                                '&:hover': {
                                  cursor: 'pointer',
                                  borderRadius: '.5em',
                                  boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                }
                              }}
                              onClick={() => handleClickDependencia(designacion.cabecera?.id)}
                          >
                            {designacion.cabecera?.name}
                            </TableCell>
                            {designacion.cabecera?.id !== designacion.dependency_id ?
                            <TableCell key={"office"} align='center'
                            sx={{
                                '&:hover': {
                                  cursor: 'pointer',
                                  borderRadius: '.5em',
                                  boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                }
                              }}
                              onClick={() => handleClickDependencia(designacion.dependency_id)}
                          >
                            {designacion.dependency.name}
                            </TableCell>
                            :
                            <TableCell key={"office"} align='center'>-</TableCell>
                            }
                            <TableCell key={"creadopor"} align="center" 
                                sx={{
                                '&:hover': {
                                    cursor: 'pointer',
                                    borderRadius: '.5em',
                                    boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                }
                                }}
                                onClick={() => handleClickCreador(designacion.created_by)}
                            >
                                {designacion.creador}
                            </TableCell>   
                            <TableCell key={"fecha_creado"} align="center">{obtenerFecha(designacion.created_at).fecha}</TableCell>
                            <TableCell key={"eliminadopor"} align="center" 
                                sx={{
                                '&:hover': {
                                    cursor: 'pointer',
                                    borderRadius: '.5em',
                                    boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                }
                                }}
                                onClick={() => handleClickCreador(designacion.updated_by)}
                            >
                                {designacion.eliminador}
                            </TableCell>   
                            <TableCell key={"fecha_eliminado"} align="center">{obtenerFecha(designacion.updated_at).fecha}</TableCell>          
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              }
            </Table>
          </TableContainer>
          </Grid> 
          :
            
          null
        }
     
    </>
  );
};

export default ListadoEliminadas;