// export function verificarPermiso(permisos, parametro) {
//   if (Array.isArray(permisos)) {
//     // Verificar si el parámetro está en el array de permisos
//     return permisos.includes(parametro);
//   } else {
//     return false;
//   };
// };

export function verificarPermiso(permisos, parametro) {
  if (permisos) {
    // Dividido el string en un arreglo  
    const arregloPermisos = permisos ? permisos.split(',') : [];
    
    // Verifico si el parámetro coincide
    return arregloPermisos.includes(parametro);
  } else {
    return []
  };
};