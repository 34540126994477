import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useModal } from '../../hooks/useModal';
// import { useDecrypt } from '../../hooks/useDecrypt';
import dayjs from 'dayjs';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import ModalComponent from '../ModalComponent/ModalComponent';
import ListadoJustificacionesPersonales from './ListadoJustificacionesPersonales';
import Pie from '../Pie/Pie';

// Actions
import { buscar_tipos, justificacion_crear, restaurarMensajes } from '../../redux/action-types/justificacionesActions';
import { buscar_por_dni } from '../../redux/action-types/usuarioActions';

// Funciones de ayuda
import { obtenerFecha } from '../../utils/obtenerFecha';

// MUI componentes
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers';
import { inputLabelClasses } from "@mui/material/InputLabel";

// MUI iconos
import AddIcon from '@mui/icons-material/Add';
import ReportIcon from '@mui/icons-material/Report';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// Utils
import { verificarPermiso } from '../../utils/verificarPermiso';
import { calcularDiasEntreFechas } from '../../utils/calcularDiasEntreFechas';

const validate = (state) => {
  let errors = {};

  if(state.fecha_inicioInput !== null && state.fecha_finInput !== null) {
    let inicio = new Date(state.fecha_inicioInput)
    let fin = new Date(state.fecha_finInput)
    if (inicio > fin){
      errors.fecha_finInput = 'La fecha de fin debe ser posterior a la fecha de inicio'
    } else {
      errors.fecha_finInput = ''
    };
  };

  return errors;
};

const initialState = {
  fecha_inicioInput: null,
  fecha_finInput: null,
  tipo_justificacionInput: null,
  objetoSeleccionado: null,
};

const CrearJustificacionPersonal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dni, dia } = useParams();

  const { text, usuario, flag } = useSelector(state => state.usuario);
  const { tipos, status, mensaje } = useSelector(state => state.justificaciones);
  const dniActual  = useSelector(state => state.acceso.dni);

  const permisos = window.localStorage.getItem("permisos");
  // const permisos = useDecrypt('permisos');

  const [ state, setState ] = useState(initialState);
  const [ errors, setErrors ] = useState(initialState);
  const [ timeExceeded, setTimeExceeded ] = useState(false);

  const [isOpenModal, openModal, closeModal] = useModal();

  const handleChange = event => {
    const { name, value } = event.target;
    setErrors(validate({
      ...state,
      [name]: value
    }));
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleChangeAuto = (event, newValue) => {
    const selectedOption = newValue; // El objeto completo seleccionado
    const tipo_justificacionInput = "tipo_justificacionInput"
    const objetoSeleccionado = "objetoSeleccionado"
    setErrors(validate({
      ...state,
      [tipo_justificacionInput]: selectedOption ? selectedOption.id : null // Guarda el ID del objeto o null si no hay selección
    }));

    setState({
      ...state,
      [tipo_justificacionInput]: selectedOption ? selectedOption.id : null, // Guarda el ID del objeto o null si no hay selección
      [objetoSeleccionado]: selectedOption ? selectedOption : null,
    });
  };

  const handleClick = (event) => {
     event.preventDefault();
     setState(initialState);
     setErrors(errors.fechaFinInput= '',);
  };

  const handleAtras = () => {
    const url = window.sessionStorage.getItem('urlAnterior');  
    if(url){
      navigate(url);
    }
    else{
      navigate(`/justificaciones-personales`);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    const data = {...state, usuario_dniInput: dni, creado_por_dniInput: dniActual};
    dispatch(justificacion_crear(data));
    openModal();
    setTimeout(() => {         
      dispatch(restaurarMensajes());
      closeModal();   
    }, 3000);
    
    setState(initialState);
  };

  useEffect(() => {
    if(dia) {
      setState((state) => ({
        ...state,
        fecha_inicioInput: obtenerFecha(dia).fecha2,
      }));
    };
    if (verificarPermiso(permisos, 'usuario_administrador')){
      dispatch(buscar_tipos());
    }
  }, [dispatch, dia, permisos]);

  useEffect(() => {
    dispatch(buscar_por_dni(dni));
  }, [dispatch, dni]);

  useEffect(() => {
    // Configura el tiempo límite
    const timeoutId = setTimeout(() => {
      setTimeExceeded(true);
      if(!usuario) {
        setTimeout(() => {
          openModal();
          setTimeout(() => {
            navigate("/justificaciones-personales");
          }, 1000);
        }, 0);
      };
    }, 1000);
    // Limpia el temporizador
    return () => clearTimeout(timeoutId);
  }, [usuario, openModal, navigate]);

  // Si no hay datos
  if((!tipos || !usuario) && !timeExceeded) {
    return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={'Justificaciones'} />
        <CircularProgress
          sx={{
            width: "auto",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />
        {/* Copmponente de Pie de Pag. */}
        <Pie />
      </>
    );
  };
  // Si el usuario no puede acceder a esa informacion
  if(!usuario && timeExceeded && !flag) {
    return (
      <>
        {/* Navegación */}
        <Rotulo titulo={"Justificaciones"} />
        {/* Componente de Notificaciones */}
        <ModalComponent open={isOpenModal} handleClose={closeModal} >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Redireccionando...
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {text}
          </Typography>
        </ ModalComponent>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  };
  // Si tiene autorizacion
  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={'Justificaciones'} /> {/* Podria poner el nombre del usuario: Justificaciones de Pepe Pepin */}
      {
        verificarPermiso(permisos, 'usuario_administrador') ?
          <>
            {/* Aca arranca el componente Crear Usuario */}
            <Grid id={"crearJustificacion"} container component="form" noValidate onSubmit={handleSubmit} spacing={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1vh",
              }}
            >
              <Grid item xs={11} sm={8.5} md={7} lg={6.5}>
                <Button
                  variant="contained"
                  sx={{
                    alignSelf: "start",
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                  onClick={handleAtras}
                >
                  Atras
                </Button>
                <Divider sx={{ width: "100%", height: "1em"}}/>
              </Grid>
              {/* Contenedor Grid de los Items   */}
              <Grid item container xs={12} sm={10} md={8} lg={6.5} spacing={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* Item Crear */}
                <Grid item xs={10} sm={10} md={10} lg={12}>
                  <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", ml: "-3px", mb: ".5em"}}>
                    <AddIcon />
                    <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                      Crear Justificación
                    </Typography>
                  </Box>
                </Grid>

                {/* Item 0 */}
                <Grid item xs={10} sm={10} md={10} lg={6}>
                  <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}>
                    <CalendarMonthIcon />
                    <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.2em"}>
                      Fecha Justificación
                    </Typography>
                  </Box>

                  <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
                    <Typography mr={"1em"} fontWeight={"bold"}>
                      Fecha Inicio*:
                    </Typography>
                    <DatePicker
                      id={'fecha_inicioInput'}
                      name="fecha_inicioInput"
                      views={['year', 'month', 'day']}
                      value={dia ? dayjs(dia) : state.fecha_inicioInput}
                      onChange={date => handleChange({ target: { value: date, name: 'fecha_inicioInput' } })}
                      slotProps={{
                        textField: { InputLabelProps: { sx: {color: 'transparent',
                                                        [`&.${inputLabelClasses.shrink}`]: {
                                                          color: "black"}}
                                                        }},
                        toolbar: { toolbarFormat: 'dddd D MMM', hidden: false },
                      }}>
                    </DatePicker>
                  </Box>

                  <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
                    <Typography mr={"1em"} fontWeight={"bold"}>
                      Fecha Fin:
                    </Typography>
                    <DatePicker
                      id={'fecha_finInput'}
                      name="fecha_finInput"
                      views={['year', 'month', 'day']}
                      value={state.fecha_finInput}
                      onChange={date => handleChange({ target: { value: date, name: 'fecha_finInput' } })}
                      slotProps={{
                        textField: { InputLabelProps: { sx: {color: 'transparent',
                                                        [`&.${inputLabelClasses.shrink}`]: {
                                                          color: "black"}}
                                                        }},
                        toolbar: { toolbarFormat: 'ddd DD MMMM', hidden: false },
                      }}>
                    </DatePicker>
                    {
                      errors.fecha_finInput &&
                      <FormHelperText
                        sx={{
                          color: "red",
                        }}
                      >
                        {errors.fecha_finInput}
                      </FormHelperText>
                    }
                  </Box>
                  {
                    state.fecha_inicioInput && !errors.fecha_finInput ?
                    <Box >
                      <Typography>
                        Se asignará la justificación por {" "}
                        <span style={{fontWeight:"bold"}}>{state.fecha_finInput ? calcularDiasEntreFechas(state.fecha_inicioInput, state.fecha_finInput) + " días": "1 día"}</span>
                      </Typography>
                    </Box>
                    :
                    null
                  }
                </Grid>

                {/* Item 2 */}
                <Grid item xs={10} sm={10} md={10} lg={6}>
                  <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}>
                    <ReportIcon />
                    <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.2em"} >
                      Justificación
                    </Typography>
                  </Box>

                  <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
                    <Typography mr={"1em"} fontWeight={"bold"}>
                      Tipo Justificacion*:
                    </Typography>
                    <Autocomplete
                    options={tipos}
                    getOptionLabel={(option) => `${option.codigo} - ${option.titulo}`}
                    value={state.objetoSeleccionado}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={handleChangeAuto}
                    noOptionsText={`No se encontraron justificaciones`}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.codigo} - {option.titulo}
                      </Box>
                    )}
                    renderInput={(params) => <TextField {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',}} />}
                    ></Autocomplete>                    
                  </Box>

                  
                </Grid>

                {/* Item 3 */}
                <Grid item container xs={10} sm={10} md={10} lg={12}>
                  <Button
                    variant="contained"
                    sx={{
                      mb: 2,
                      mr: 1,
                      fontSize: ".8em",
                      fontWeight: "bold",
                      bgcolor: 'background.paper',
                      '&:hover': {
                        bgcolor: 'background.paper',
                        transform: 'scale(1.01)',
                      }
                    }}
                    onClick={handleClick}
                  >
                    Cancelar
                  </Button>
                  {

                    (state.fecha_inicioInput === null ||
                    state.tipo_justificacionInput === null ||
                    (errors.fecha_finInput !== '' && state.fecha_finInput !== null))
                    ?
                      <Button
                        type="submit"
                        variant="contained"
                        disabled
                        sx={{
                          mb: 2,
                          fontSize: ".8em",
                          fontWeight: "bold",
                          bgcolor: 'background.paper',
                          '&:hover': {
                            bgcolor: 'background.paper',
                            transform: 'scale(1.01)',
                          }
                        }}
                      >
                        Crear
                      </Button>
                    :
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          mb: 2,
                          fontSize: ".8em",
                          fontWeight: "bold",
                          bgcolor: 'background.paper',
                          '&:hover': {
                            bgcolor: 'background.paper',
                            transform: 'scale(1.01)',
                          }
                        }}
                      >
                        Crear
                      </Button>
                  }
                </Grid>
              </Grid>
            </Grid>
          </>
        :
          null
      }
      {/* Listado */}
      <ListadoJustificacionesPersonales />
      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {status}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {mensaje}
        </Typography>
      </ ModalComponent>
      {/* Pie de Pág. */}
      <Pie />
    </>
  );
};

export default CrearJustificacionPersonal;
