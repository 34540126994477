import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

// Actions
import { informacionCreacion } from '../../../redux/action-types/usuarioActions';

// MUI componentes
import {
  Box,
  CircularProgress,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'; 
import { DatePicker } from '@mui/x-date-pickers';
import { inputLabelClasses } from "@mui/material/InputLabel";

// MUI iconos
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: 'white',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid white',
  },

}));

const CrearUsuario = ({ state, setState, errors, setErrors, validate }) => {
  const dispatch = useDispatch();

  const { dependencias, tipo_cargos } = useSelector(state => state.usuario);

  const handleChange = event => {
    const { name, value } = event.target;
    setErrors(validate({
      ...state,
      [name]: value
    }));
    setState({
      ...state,
      [name]: value,
    });
  };

  useEffect(() => {
    dispatch(informacionCreacion());
  }, [dispatch]);

  if(!dependencias || !tipo_cargos ) {
    return (
      <>
        <CircularProgress
          sx={{
            width: "auto",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />
      </>
    );
  };

  return (
    <>
      {/* Contenedor Grid de los Items   */}
      <Grid item container xs={12} sm={10} md={8} lg={8}
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: "1em",
        }}
      >
        {/* Item 1 */}
        <Grid item xs={10} sm={10} md={8} lg={6}>
          <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}>
            <PersonAddAlt1Icon />
            <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
              Información Personal
            </Typography>
          </Box>

          <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
            <Typography mr={"1em"} fontWeight={"bold"}>
              Nombre*:
            </Typography>
            <TextField
                id='nombreInput'
                name="nombreInput"
                type='text'
                value={state.nombreInput}
                placeholder="Ingresa el nombre..."
                error={!!errors.nombreInput}
                onChange={handleChange}
                InputLabelProps={{
                  sx: {
                    // set the color of the label when not shrinked
                    color: 'rgb(150, 150, 150)',
                    [`&.${inputLabelClasses.shrink}`]: {
                      // set the color of the label when shrinked (usually when the TextField is focused)
                      // color: "orange"
                    }
                  }
                }}
            />
            {
              errors.nombreInput &&
              <FormHelperText
                sx={{
                  color: "red",
                }}
              >
                {errors.nombreInput}
              </FormHelperText>
            }
          </Box>

          <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
            <Typography mr={"1em"} fontWeight={"bold"}>
              Apellido*:
            </Typography>
            <TextField
                id='apellidoInput'
                name="apellidoInput"
                type='text'
                value={state.apellidoInput}
                placeholder='Ingresa el apellido...'
                error={!!errors.apellidoInput}
                fullWidth
                onChange={handleChange}
                InputLabelProps={{
                  sx: {
                    color: 'rgb(150, 150, 150)',
                  }
                }}
            />
            {
              errors.apellidoInput &&
              <FormHelperText
                sx={{
                  color: "red",
                }}
              >
                {errors.apellidoInput}
              </FormHelperText>
            }
          </Box>

          <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
            <Typography mr={"1em"} fontWeight={"bold"}>
              DNI*:
            </Typography>
            <HtmlTooltip placement="left" arrow leaveDelay={2000} title={
              <React.Fragment>
                <Typography>
                Por favor compruebe que el <b>DNI</b> ingresado sea correcto
                </Typography>
              {"El "}<b>{"DNI"}</b>{" es un campo"} <b>{"fundamental"}</b> {" para la sincronizacion y no podrá ser cambiado"}
                
              </React.Fragment>}
              >
            <TextField
                id='dniInput'
                name="dniInput"
                value={state.dniInput}
                placeholder="Ingresa el DNI..."
                error={!!errors.dniInput}
                fullWidth
                onChange={handleChange}
                InputLabelProps={{
                  sx: {
                    color: 'rgb(150, 150, 150)',
                  }
                }}
            />
            {
              errors.dniInput &&
              <FormHelperText
                sx={{
                  color: "red",
                }}
              >
                {errors.dniInput}
              </FormHelperText>
            }
            </HtmlTooltip>
          </Box>

          <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
            <Typography mr={"1em"} fontWeight={"bold"}>
              E-mail*:
            </Typography>
            <TextField
                id='mailInput'
                name="mailInput"
                type='mail'
                value={state.mailInput}
                placeholder="Ingresa el email..."
                error={!!errors.mailInput}
                fullWidth
                onChange={handleChange}
                InputLabelProps={{
                  sx: {
                    color: 'rgb(150, 150, 150)',
                  }
                }}
            />
            {
              errors.mailInput &&
              <FormHelperText
                sx={{
                  color: "red",
                }}
              >
                {errors.mailInput}
              </FormHelperText>
            }
          </Box>

          <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
            <Typography mr={"1em"} fontWeight={"bold"}>
              Fecha de Nacimiento*:
            </Typography>
            <DatePicker
              id={'nacimientoInput'}
              name="nacimientoInput"
              views={['year', 'month', 'day']}
              onChange={date => handleChange({ target: { value: date, name: 'nacimientoInput' } })}
              slotProps={{
                textField: { InputLabelProps: { sx: {color: 'transparent',
                                                [`&.${inputLabelClasses.shrink}`]: {
                                                  color: "black"}}
                                                }},
                toolbar: { toolbarFormat: 'dddd D MMM', hidden: false },
              }}>
            </DatePicker>
            {
              errors.nacimientoInput &&
              <FormHelperText
                sx={{
                  color: "red",
                }}
              >
                {errors.nacimientoInput}
              </FormHelperText>
            }
          </Box>

          <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
            <Typography mr={"1em"} fontWeight={"bold"}>
              Género*:
            </Typography>
            <TextField
                labelid="generoInputLabel"
                id="generoInput"
                name="generoInput"
                value={state.generoInput}
                fullWidth
                onChange={handleChange}
                select
                InputLabelProps={{
                  sx: {
                    color: 'rgb(150, 150, 150)',
                  }
                }}
            >
              {/* Chequear la Ley para ver que opciones hay que poner */}
              <MenuItem key={'Femenino'} value={'Femenino'}>Femenino</MenuItem>
              <MenuItem key={'Masculino'} value={'Masculino'}>Masculino</MenuItem>
              <MenuItem key={'Binario'} value={'No Binario'}>No Binario</MenuItem>
              <MenuItem key={'Indefinido'} value={'No Especificado'}>No Especificado</MenuItem>
            </TextField>
          </Box>

          <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
            <Typography mr={"1em"} fontWeight={"bold"}>
              País*:
            </Typography>
            <TextField
                id='paisInput'
                name="paisInput"
                type='text'
                value={state.paisInput}
                placeholder="Ingresa el país..."
                error={!!errors.paisInput}
                fullWidth
                onChange={handleChange}
                InputLabelProps={{
                  sx: {
                    color: 'rgb(150, 150, 150)',
                  }
                }}
            />
            {
              errors.paisInput &&
              <FormHelperText
                sx={{
                  color: "red",
                }}
              >
                {errors.paisInput}
              </FormHelperText>
            }
          </Box>

          <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
            <Typography mr={"1em"} fontWeight={"bold"}>
              Ciudad*:
            </Typography>
            <TextField
                id='ciudadInput'
                name="ciudadInput"
                type='text'
                value={state.ciudadInput}
                placeholder="Ingresa la ciudad..."
                error={!!errors.ciudadInput}
                fullWidth
                onChange={handleChange}
                InputLabelProps={{
                  sx: {
                    color: 'rgb(150, 150, 150)',
                  }
                }}
            />
            {
              errors.ciudadInput &&
              <FormHelperText
                sx={{
                  color: "red",
                }}
              >
                {errors.ciudadInput}
              </FormHelperText>
            }
          </Box>

          <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
            <Typography mr={"1em"} fontWeight={"bold"}>
              Dirección*:
            </Typography>
            <TextField
                id='direccionInput'
                name="direccionInput"
                type='text'
                value={state.direccionInput}
                placeholder="Ejemplo: Calle 7 Nº 776 (47 y 48)"
                error={!!errors.direccionInput}
                fullWidth
                onChange={handleChange}
                InputLabelProps={{
                  sx: {
                    color: 'rgb(150, 150, 150)',
                  }
                }}
            />
            {
              errors.direccionInput &&
              <FormHelperText
                sx={{
                  color: "red",
                }}
              >
                {errors.direccionInput}
              </FormHelperText>
            }
          </Box>

          <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
            <Typography mr={"1em"} fontWeight={"bold"}>
              Teléfono Fijo:
            </Typography>
            <TextField
                id='telFijoInput'
                name="telFijoInput"
                type='text'
                value={state.telFijoInput}
                placeholder="Ingresa el teléfono fijo..."
                error={!!errors.telFijoInput}
                fullWidth
                onChange={handleChange}
                InputLabelProps={{
                  sx: {
                    color: 'rgb(150, 150, 150)',
                  }
                }}
            />
            {
              errors.telFijoInput &&
              <FormHelperText
                sx={{
                  color: "red",
                }}
              >
                {errors.telFijoInput}
              </FormHelperText>
            }
          </Box>

          <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
            <Typography mr={"1em"} fontWeight={"bold"}>
              Celular:
            </Typography>
            <TextField
                id='celularInput'
                name="celularInput"
                type='text'
                value={state.celularInput}
                placeholder="Ingresa el celular..."
                error={!!errors.celularInput}
                fullWidth
                onChange={handleChange}
                InputLabelProps={{
                  sx: {
                    color: 'rgb(150, 150, 150)',
                  }
                }}
            />
            {
              errors.celularInput &&
              <FormHelperText
                sx={{
                  color: "red",
                }}
              >
                {errors.celularInput}
              </FormHelperText>
            }
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CrearUsuario;
