import { createSlice } from '@reduxjs/toolkit';

import { ordenarPorNombre } from '../../utils/ordenarPorNombre'; 

const initialState = {
  relojes: [],
  reloj: {},
  mensaje: '',
};

export const relojSlice = createSlice({
  name: 'relojReducer',
  initialState,
  reducers: {
    crear: (state, { payload }) => {
      return {
        ...state,
        status: payload.status,
        text: payload.text,
      };
    },
    buscar_todos: (state, { payload }) => {
      return {
        ...state,
        relojes: ordenarPorNombre(payload, 'ubicacion'),
      };
    },
    buscar_reloj: (state, { payload }) => {
      return {
        ...state,
        reloj: payload,
      };
    },
    editar: (state, { payload }) => {
      return {
        ...state,
        status: payload.status,
        text: payload.text,
      };
    },
  },
});

export const { crear, buscar_reloj, buscar_todos, editar } = relojSlice.actions;

export default relojSlice.reducer;