import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useModal } from "../../hooks/useModal";
import { useNavigate } from "react-router-dom";

// Funciones de ayuda
import { obtenerFecha } from "../../utils/obtenerFecha";

// Action
import {
  crear_vacacion,
  restaurar_mensajes,
} from "../../redux/action-types/vacacionesActions";

import PropTypes from "prop-types";

import ModalComponent from "../ModalComponent/ModalComponent";

// MUI componentes
import {
  Button,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TablePagination,
  TableRow,
  TextField,
  FormHelperText,
  Typography,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers";
import { inputLabelClasses } from "@mui/material/InputLabel";

//ICONS
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import DownhillSkiingIcon from "@mui/icons-material/DownhillSkiing";

function descendingComparator(a, b, orderBy) {

  if (orderBy === "oficina") {
    if (b.designacion[0].dependency.name < a.designacion[0].dependency.name) {
      return -1;
    }
    if (b.designacion[0].dependency.name > a.designacion[0].dependency.name) {
      return 1;
    }
  }
  if (orderBy === "fecha") {
    if (b.designacion[0].fecha_inicio < a.designacion[0].fecha_inicio) {
      return -1;
    }
    if (b.designacion[0].fecha_inicio > a.designacion[0].fecha_inicio) {

      return 1;
    }
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCellsVigentes = [
  {
    id: "dni",
    numeric: false,
    disablePadding: false,
    label: "DNI",
  },
  {
    id: "apellido",
    numeric: false,
    disablePadding: false,
    label: "Apellido",
  },
  {
    id: "nombre",
    numeric: false,
    disablePadding: true,
    label: "Nombre",
  },
  {
    id: "cargo",
    numeric: false,
    disablePadding: true,
    label: "Cargo",
  },
  {
    id: "fecha",
    numeric: false,
    disablePadding: true,
    label: "Fecha de Designación",
  },
  {
    id: "oficina",
    numeric: false,
    disablePadding: true,
    label: "Oficina",
  },
  {
    id: "vacacion",
    numeric: false,
    disablePadding: false,
    label: "Crear Vacación",
  },
];

function EnhancedTableHeadV(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ bgcolor: "primary.light" }}>
        {headCellsVigentes.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {" "}
            {headCell.id !== "cargo" && headCell.id !== "vacacion" ? (
              <>

                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  sx={{
                    color:
                      orderBy === headCell.id
                        ? "palette.background.paper"
                        : "palette.text.secondary,",
                    "&:hover": {
                      color: "palette.background.paper",
                      transform: "scale(1.02)",
                    },
                  }}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span">
                      {/* {order === "desc" ? "sorted descending" : "sorted ascending"} */}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </>
            ) : (
              <>{headCell.label}</>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHeadV.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const validate = (state) => {
  let errors = {};

  if (state.meses_externos === "") {
    errors.meses_externos = "En caso de no tener, indicar 0";
  } else if (!/^\d+$/.test(state.meses_externos)) {
    errors.meses_externos = "Ingresar solo números...";
  }

  return errors;
};

const initialState = {
  fecha_ingreso: null,
  meses_externos: 0,
  dni: "",
};
const initialStateErrors = {
  fecha_ingreso: null,
  meses_externos: "",
  dni: "",
};

const TablaUsuarios = ({ datos, nombre, icono, dniActual }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickCreador = (creador) => {
    window.sessionStorage.setItem("urlAnterior", `/usuarios-sin-vacaciones`);
    navigate(`/perfil/${creador}`);
  };

  const handleClick = (dni) => {
    state.dni = dni;
    dispatch(restaurar_mensajes());
    openModal();
  };

  const handleCancel = () => {
    setState(initialState);
    closeModal();

  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setErrors(
      validate({
        ...state,
        [name]: value,
      })
    );
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleClose = () => {
    const data = { ...state, creado_por: dniActual };
    dispatch(crear_vacacion(data));
    setTimeout(() => {
      closeModal();
      dispatch(restaurar_mensajes());
    }, 3000);
  };

  const esInvierno = obtenerFecha(new Date()).esInvierno;

  const { status, mensaje } = useSelector((state) => state.vacaciones);

  const [isOpenModal, openModal, closeModal] = useModal();


  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("apellido");
  const [selected, setSelected] = useState([]);
  const [state, setState] = useState(initialState);
  const [errors, setErrors] = useState(initialStateErrors);
  const [page, setPage] = useState(0);
  const [dense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  let usuariosFiltrados = React.useMemo(() => {
    return [
      ...new Set(
        datos?.filter(
          (obj, index, self) =>
            index === self.findIndex((t) => t.dni === obj.dni)
        )
      ),
    ];
  }, [datos]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRowsUsuarios = React.useMemo(
    () =>
      stableSort(
        datos ? usuariosFiltrados : [],
        getComparator(order, orderBy)
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, datos, usuariosFiltrados]
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          mb: "1em",
          color: "primary.dark",
        }}
      >

        {icono}
        <Typography
          key={"nombre"}
          component={"h6"}
          variant={"h6"}
          fontSize={"1.2em"}
        >
          {nombre}
        </Typography>
      </Box>
      {usuariosFiltrados && usuariosFiltrados.length > 0 ? (
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer sx={{ overflow: "visible" }}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHeadV
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={datos ? usuariosFiltrados.length : 0}
                />
                <TableBody>
                  {visibleRowsUsuarios.map((row, index) => {
                    return (
                      <TableRow
                        key={row.dni}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          id={row.dni}
                          scope="row"
                          padding="normal"
                          sx={{
                            "&:hover": {
                              cursor: "pointer",
                              borderRadius: ".5em",
                              boxShadow: "1px 1px 3px 3px rgba(40,40,40,0.4)",
                            },
                          }}
                          onClick={() => handleClickCreador(row.dni)}
                        >
                          {row.dni}
                        </TableCell>
                        <TableCell align="left">{row.apellido}</TableCell>
                        <TableCell align="left">{row.nombre}</TableCell>
                        <TableCell align="left">
                          {row.designacion
                            .map((designacion) => designacion.cargo?.codigo)
                            .filter((cargo) => cargo && cargo.trim() !== "")
                            .join(" - ")}
                        </TableCell>
                        <TableCell align="left">
                          {row.designacion
                            .map(
                              (designacion) =>
                                obtenerFecha(designacion.fecha_inicio).fecha
                            )
                            .filter((cargo) => cargo && cargo.trim() !== "")
                            .join(" - ")}
                        </TableCell>
                        <TableCell align="left">
                          {row.designacion
                            .map((designacion) => designacion.dependency?.name)
                            .filter((cargo) => cargo && cargo.trim() !== "")
                            .join(" - ")}
                        </TableCell>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          padding="normal"
                          sx={{
                            "&:hover": {
                              cursor: "pointer",
                              borderRadius: ".5em",
                              boxShadow: "1px 1px 3px 3px rgba(40,40,40,0.4)",
                            },
                          }}
                          onClick={() => handleClick(row.dni)}
                        >
                          {esInvierno ? (
                            <DownhillSkiingIcon
                              sx={{ fontSize: "2.5em", color: "primary.light" }}
                            ></DownhillSkiingIcon>
                          ) : (
                            <BeachAccessIcon
                              sx={{ fontSize: "2em", color: "primary.light" }}
                            ></BeachAccessIcon>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, usuariosFiltrados.length]}
              component="div"
              count={datos ? usuariosFiltrados.length : 0}
              labelRowsPerPage={"Elementos por Pagina"}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "left",
            ml: "-3px",
            marginRight: "25px",
          }}
        >
          <Typography component={"h3"} variant={"h6"} fontSize={"1em"}>
            No se encontraron usuarios...
          </Typography>
        </Box>
      )}
      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal}>
        {
          /*Si hay mensaje y status, se muestra eso*/

          status ? (
            <>
              <Typography
                id="modal-modal-title"
                component="h2"
                sx={{ fontSize: "1.3em" }}
              >
                {status}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {mensaje}
              </Typography>
            </>
          ) : (
            /*SI EL FLAG ES FALSE, MUESTRA EL COMPONENTE DE CIERRE */
            <>
              <Typography
                id="modal-modal-title"
                component="h2"
                sx={{ fontSize: "1.3em", marginBottom: "0.5em" }}
              >
                Para crear una vacación debe ingresar la fecha de ingreso a la
                Universidad y, en caso de tener, meses externos trabajados:
              </Typography>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "1.5em",
                  marginTop: "1.5em",
                }}
              >
                <Box sx={{ width: "45%" }}>
                  <Box sx={{ display: "flex", marginBottom: "1.5em" }}>
                    <CalendarTodayIcon />
                    <Typography mr={"1em"} fontWeight={"bold"}>
                      Fecha Ingreso*:
                    </Typography>
                  </Box>
                  <DatePicker
                    id={"fecha_fin"}
                    name="fecha_fin"
                    views={["year", "month", "day"]}
                    timezone="America/Argentina/Buenos_Aires"
                    fullWidth
                    onChange={(date) =>
                      handleChange({
                        target: { value: date, name: "fecha_ingreso" },
                      })
                    }
                    slotProps={{
                      textField: {
                        InputLabelProps: {
                          sx: {
                            color: "transparent",
                            [`&.${inputLabelClasses.shrink}`]: {
                              color: "black",
                            },
                          },
                        },
                      },
                      toolbar: { toolbarFormat: "dddd D MMM", hidden: true },
                    }}
                  ></DatePicker>
                </Box>
                <Box sx={{ width: "45%" }}>
                  <Box sx={{ display: "flex", marginBottom: "1.5em" }}>

                    <CalendarTodayIcon />
                    <Typography mr={"1em"} fontWeight={"bold"}>
                      Meses externos:
                    </Typography>
                  </Box>
                  <TextField
                    id="meses_externos"
                    name="meses_externos"
                    type="text"
                    value={state.meses_externos}
                    error={!!errors.meses_externos}
                    fullWidth
                    onChange={handleChange}
                    InputLabelProps={{
                      sx: {
                        color: "rgb(150, 150, 150)",
                      },
                    }}
                  />
                  {errors.meses_externos && (
                    <FormHelperText
                      sx={{
                        color: "red",
                      }}
                    >
                      {errors.meses_externos}
                    </FormHelperText>
                  )}
                </Box>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                onClick={handleCancel}
                sx={{
                  fontSize: ".8em",
                  fontWeight: "bold",
                  marginTop: "1em",
                  marginRight: "1em",
                  bgcolor: "background.paper",
                  "&:hover": {
                    bgcolor: "background.paper",
                    transform: "scale(1.01)",
                  },
                }}
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                variant="contained"
                onClick={handleClose}
                disabled={state.fecha_ingreso === null || errors.meses_externos}
                sx={{
                  fontSize: ".8em",
                  fontWeight: "bold",
                  marginTop: "1em",
                  bgcolor: "background.paper",
                  "&:hover": {
                    bgcolor: "primary.light",
                    transform: "scale(1.01)",
                  },
                }}
              >
                Confirmar
              </Button>
            </>
          )
        }
      </ModalComponent>
    </>
  );
};

export default TablaUsuarios;
