import { createSlice } from '@reduxjs/toolkit';

// Utils
import { ordenarPorNombre } from '../../utils/ordenarPorNombre';

const initialState = {
  dependencias: '',
  mensaje: null,
};

export const rolSlice = createSlice({
  name: 'rolReducer',
  initialState,
  reducers: {
    dependencias: (state, { payload }) => {
      // console.log("Reducer", payload);
      return {
        ...state,
        id_rol: payload.rol_id,
        asignadas: payload.asignadas, 
      };
    },
    cabeceras: (state, { payload }) => {
      return {
        ...state,
        cabeceras: ordenarPorNombre(payload.cabeceras, "name"),
      }
    },
    añadir: (state, { payload }) => {
      return {
        ...state,
        id_rol: payload.id,
        dependencias: payload.data.data,
        mensaje: payload.mensaje, 
      };
    },
    borrar: (state, { payload }) => {
      return {
        ...state,
        id_rol: payload.id,
        dependencias: payload.data.data,
        mensaje: payload.mensaje, 
      };
    },
    asignar: (state, { payload }) => {
      return {
        ...state,
        mensaje: payload.data.mensaje, 
        status: payload.data.status, 
      };
    },
    eliminar: (state, { payload }) => {
      return {
        ...state,
        mensaje: payload.data.mensaje, 
        status: payload.data.status, 
      };
    },
    obtener_todos: (state, { payload }) => {
      return {
        ...state,
        consultas: payload.consultas, 
        admins: payload.admins,
        privilegeds: payload.privilegeds,
        supers: payload.supers 
      };
    },
    restaurar_mensaje: (state, { payload }) => {
      return {
        ...state,
        mensaje: '', 
        status: '', 
      };
    },
    restaurar: (state) => {
      return {
        ...state,
        dependencias: [],
      };
    },
  },
});

export const { dependencias, cabeceras, añadir, borrar, asignar, eliminar, obtener_todos,restaurar, restaurar_mensaje } = rolSlice.actions;

export default rolSlice.reducer;
