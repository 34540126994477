import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useModal } from '../../hooks/useModal';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import ModalComponent from '../ModalComponent/ModalComponent';
import Pie from '../Pie/Pie';

// Acciones
import { buscarReloj } from '../../redux/action-types/relojActions';

// Funciones de ayuda
import { obtenerFecha } from '../../utils/obtenerFecha';

// MUI componentes
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

// MUI Icons
import WatchLaterIcon from '@mui/icons-material/WatchLater';

const RelojDetalle = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const { reloj } = useSelector(state => state.reloj);

  const [isOpenModal, openModal, closeModal] = useModal();

  const handleClickAtras = () => {
    navigate(`/relojes`);
  };

  const handleClickEditar = () => {
    navigate(`/relojes/reloj/${id}/editar`);
  };

  const handleDelete = () => {
    console.log("No estoy implementado :D");
  };

  const handleClick = () => {
    openModal();
  };

  useEffect(() => {
    dispatch(buscarReloj(id));
  }, [dispatch, id]);

  if(!reloj.ip) {
    return(
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={'Detalles del Reloj'} />
        <CircularProgress
          sx={{
            width: "auto",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />
        {/* Copmponente de Pie de Pag. */}
        <Pie />
      </>
    );
  };

  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={'Detalle Reloj'} />
      {/* Contenedor del componente */}
      <Grid container spacing={3}
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1vh",
        }}
      >
        <Grid item xs={11} sm={10} md={7} lg={6.1}>
          <Button
            variant="contained"
            sx={{
              alignSelf: "start",
              fontSize: ".8em",
              fontWeight: "bold",
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'background.paper',
                transform: 'scale(1.01)',
              }
            }}
            onClick={handleClickAtras}
          >
            Atrás
          </Button>
          <Divider sx={{ width: "100%", height: "1em"}}/>
        </Grid>
        <Grid item container xs={12} sm={10} md={8} lg={6} ml={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column"
          }}
        >
          <Grid item xs={10} sm={10} md={8} lg={7}>
            {/* Item 1 */}
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}>
              <WatchLaterIcon />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                Datos sobre el Reloj
              </Typography>
            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"1em"}}>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Nombre:
                </Typography>
                <Typography>
                  {reloj.nombre ? reloj.nombre : "Sin Detalle"}
                </Typography>
              </Box>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Ubicación:
                </Typography>
                <Typography>
                  {reloj.ubicacion ? reloj.ubicacion : "Sin Detalle"}
                </Typography>
              </Box>

            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"1em"}}>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  IP:
                </Typography>
                <Typography>
                  {reloj.ip ? reloj.ip : "Sin Detalle"}
                </Typography>
              </Box>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Soap URL:
                </Typography>
                <Typography>
                  {reloj.soap ? reloj.soap : "Sin Detalle"}
                </Typography>
              </Box>

            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"1em"}}>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Máscara de Red:
                </Typography>
                <Typography>
                  {reloj.mascara ? reloj.mascara : "Sin Detalle"}
                </Typography>
              </Box>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Algoritmo:
                </Typography>
                <Typography>
                  {reloj.algoritmo === 'nuevo' ? "pyzkaccess" : "PYZK"}
                </Typography>
              </Box>

            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"1em"}}>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Router:
                </Typography>
                <Typography>
                  {reloj.router ? reloj.router : "Sin Detalle"}
                </Typography>
              </Box>
            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"1em"}}>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Modelo:
                </Typography>
                <Typography>
                  {reloj.modelo ? reloj.modelo : "Sin Detalle"}
                </Typography>
              </Box>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Fecha Instalación:
                </Typography>
                <Typography>
                  {reloj.fecha_instalacion ? obtenerFecha(reloj.fecha_instalacion).fecha : "Sin Detalle"}
                </Typography>
              </Box>
            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"1em"}}>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Marca:
                </Typography>
                <Typography>
                  {reloj.marca ? reloj.marca : "Sin Detalle"}
                </Typography>
              </Box>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Estado:
                </Typography>
                <Typography>
                  {reloj.activo === true ? "Activo" : "Inactivo"}
                </Typography>
              </Box>
            </Box>

            <Typography
              sx={{
                maxWidth: "auto",
                display: "inline-block",
                mt: "1em",
                background: 'none',
                border: 'none',
                color: "primary.dark",
                "&:hover": {
                  cursor: 'pointer',
                }
              }}
              onClick={handleClickEditar}
            >
              Modificar datos*
            </Typography>

            {/* Item 2 */}
            <Grid item xs={10} sm={10} md={8} lg={12}>
              <Button
                variant="contained"
                sx={{
                  mb: 2,
                  marginTop: "1.5em",
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'secondary.light',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleClick}
              >
                Eliminar
              </Button>
            </Grid>

          </Grid>
          <Grid item xs={10} sm={10} md={8} lg={7}>
                {/* Item 1 */}
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}>
              <WatchLaterIcon />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                Datos de Almacenamiento
              </Typography>
            </Box>
            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"1em"}}>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}}>
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Cantidad de usuarios:
                </Typography>
                <Typography>
                  {reloj.users ? reloj.users : "Sin Datos"}
                </Typography>
              </Box>
            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"1em"}}>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}}>
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Cantidad de huellas:
                </Typography>
                <Typography>
                  {reloj.fingers ? reloj.fingers : "Sin Datos"}
                </Typography>
              </Box>
            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"1em"}}>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}}>
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Cantidad de marcaciones:
                </Typography>
                <Typography>
                  {reloj.records ? reloj.records : "Sin Datos"}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        {
          <>
            <Typography id="modal-modal-title" component="h2" sx={{ fontSize: '1.3em' }}>
              Esta por eliminar el reloj. ¿Desea continuar?
            </Typography>
            <Button
              type="submit"
              variant="contained"
              onClick={closeModal}
              sx={{
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                marginRight: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={handleDelete}
              sx={{
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'secondary.light',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Eliminar
            </Button>
          </>
        }
      </ModalComponent>
      {/* Pie de Pág */}
      <Pie />
    </>
  );
};

export default RelojDetalle;
