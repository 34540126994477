import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';
// import { useDecrypt } from '../../hooks/useDecrypt';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import ModalComponent from '../ModalComponent/ModalComponent';
import Pie from '../Pie/Pie';

// Actions
import { buscar_por_dni } from '../../redux/action-types/usuarioActions';
import { auditarHorarios, setPeriodo } from '../../redux/action-types/auditoriaActions';

// MUI componentes
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

// MUI iconos
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { obtenerFecha } from '../../utils/obtenerFecha';

const AuditoriaHorarios = ({ titulo }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dni } = useParams();

  const dniActual = window.localStorage.getItem("dni");
  const tipo_rol = window.localStorage.getItem("rol");
  // const tipoRol = useDecrypt('rol');

  const { usuario, flag, text } = useSelector(state => state.usuario);
  const { horarios_audit, periodo } = useSelector(state => state.auditoria)

  const [timeExceeded, setTimeExceeded] = useState(false);

  const [isOpenModal, openModal, closeModal] = useModal();

  const handleAtras = () => {
    navigate(`/auditoria/usuario/${dni}`);
  };

  useEffect(() => {
    dispatch(buscar_por_dni(dni));             

    if(!periodo){
      dispatch(setPeriodo(7));
    }
  }, [dispatch, dni, periodo, dniActual, tipo_rol]);

  useEffect(() => {
    if(periodo){
      dispatch(auditarHorarios({dni, periodo}));
    }    
  }, [dispatch, dni, periodo])

  useEffect(() => {
    // Configura el tiempo límite
    const timeoutId = setTimeout(() => {
      setTimeExceeded(true);
      if(!usuario) {
        setTimeout(() => {
          openModal();
          setTimeout(() => {
            navigate("/buscar-usuario-auditoria");
          }, 3000);
        }, 0);
      };
    }, 5000);
    // Limpia el temporizador
    return () => clearTimeout(timeoutId);
  }, [usuario, openModal, navigate]);

  // Si no hay datos del usuario
  if(!usuario && !timeExceeded) {
    return (
      <>
        {/* Navegación */}
        <Rotulo titulo={"Auditoria horarios editados"} />
        <Grid container>
          <CircularProgress
            sx={{
              width: "auto",
              height: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </Grid>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  };

  // Si el usuario no puede acceder a esa informacion
  if(!usuario && timeExceeded && !flag) {
    return (
      <>
        {/* Navegación */}
        <Rotulo titulo={"Auditoria horarios editados"} />
        {/* Componente de Notificaciones */}
        <ModalComponent open={isOpenModal} handleClose={closeModal} >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Redireccionando...
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {text}
          </Typography>
        </ ModalComponent>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  };

  return (
    <>
      <Rotulo titulo={"Auditoria horarios editados"} />
      <Grid container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={11} sm={8.5} md={7} lg={8} mt={6} mb={2}>
          <Button
            variant="contained"
            sx={{
              alignSelf: "start",
              fontSize: ".8em",
              fontWeight: "bold",
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'background.paper',
                transform: 'scale(1.01)',
              }
            }}
            onClick={handleAtras}
          >
            Atrás
          </Button>
          <Divider sx={{ width: "100%", height: "1em"}}/>
        </Grid>
        <Grid item container xs={11} sm={8.5} md={7} lg={8} mt={1} mb={3}>
          <VerifiedUserIcon sx={{ mt: "4px" }}/>
          <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
            Horarios Editados por {usuario.nombre} {usuario.apellido} {periodo === 10000 ? "desde siempre": `en los últimos ${periodo} días`}.
          </Typography>
        </Grid>

        {/* Inicio Tabla */}
        {horarios_audit && horarios_audit.modificados?.length > 0 ?
        <Grid item container xs={12} sm={11} md={10} lg={8}>
          <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow key={"0"} sx={{bgcolor:"primary.light"}}>
                  <TableCell key={"dni"} align="center">DNI usuario</TableCell>
                  <TableCell key={"nombre"} align='center'>Usuario</TableCell>
                  <TableCell key={"dia"} align="center">Día</TableCell>
                  <TableCell key={"entrada"} align="center">Entrada</TableCell>
                  <TableCell key={"salida"} align="center">Salida</TableCell>
                  <TableCell key={"fecha"} align="center">
                    Fecha modificado
                  </TableCell>
                  <TableCell key={"Hora"} align="center">
                    Hora modificado
                  </TableCell>
                </TableRow>
              </TableHead>
              {
                <TableBody>
                  {
                    horarios_audit.modificados && horarios_audit.modificados.map(data => {
                      return (
                        <TableRow
                          key={`${data.dni}, ${data.fecha}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell key={"dni"} align="center" width={'20%'}>{data.usuario_dni}</TableCell>
                          <TableCell key={"dni"} align="center" width={'20%'}>{data.usuario_nombre}</TableCell>
                          <TableCell key={"dia"} align="center" width={'20%'}>{data.dia}</TableCell>
                          <TableCell key={"entrada"} align="center" width={'20%'}>{data.entrada}</TableCell>
                          <TableCell key={"salida"} align="center" width={'20%'}>{data.salida}</TableCell>
                          <TableCell key={"fecha"} align="center" width={'20%'}>{obtenerFecha(data.created_at).fecha}</TableCell>
                          <TableCell key={"fecha"} align="center" width={'20%'}>{obtenerFecha(data.created_at).horario}</TableCell>
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              }
            </Table>
          </TableContainer>
        </Grid>
        :
        <Grid item xs={12} sm={11} md={10} lg={8} mb={"2em"}>
          <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", ml: "-3px"}}> 
            <Typography component={"h6"} variant={"h6"} fontSize={"1em"} ml={"2px"}>
              {`Este usuario no tiene horarios modificados en el periodo seleccionado`}
            </Typography>
          </Box>  
        </Grid>
        }
      </Grid>
      {/* Pie de Pág */}
      <Pie />
    </>
  );
};

export default AuditoriaHorarios;
