import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';

// Componentes
import Rotulo from '../../Rotulo/Rotulo';
import AcercaRol from './AcercaRol';
import Pie from '../../Pie/Pie';

// Actions
import { buscar_por_dni } from '../../../redux/action-types/usuarioActions';

// MUI componentes
import { 
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

// MUI iconos
import PeopleIcon from '@mui/icons-material/People';

// Swiper React Components
import { Swiper, SwiperSlide } from "swiper/react";

// Required Modules
import { Navigation } from "swiper/modules";

// Swiper Styles
import "swiper/css";
import "swiper/css/navigation";

// CSS Slider
import "./swiper.css";

const RolDetalle = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dni } = useParams();

  // Obtener el estado del usuario
  const usuarioState = useSelector(state => state.usuario.usuario);

  // Verificar si usuarioState existe y tiene la propiedad 'dependency_roles'
  const dependency_roles = usuarioState ? usuarioState.dependency_roles : null;
  const { nombre, apellido } = usuarioState || {};

  const handleAsignarRol = () => {
    navigate(`/asignar-rol/${dni}`);
  };

  const handleAtras = () => {
    navigate('/roles');
  };
  
  const actualizarDependencias = async () => {
    // Realizar un nuevo dispatch para obtener dependency_roles actualizado
    await dispatch(buscar_por_dni(dni));
  };

  useEffect(() => {
    dispatch(buscar_por_dni(dni));
  }, [dispatch, dni]);

  if(!dependency_roles) {
    return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={"Rol"} />
        <CircularProgress 
          sx={{
            width: "auto",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />  
        {/* Pie de Pág. */}
        <Pie />
      </>
    );
  };

  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={"Rol"} />
      <Grid container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          marginTop: "2vh",
          padding: "1em",
        }}
      >
        <Grid item container xs={12} sm={10} md={10} lg={6} 
          sx={{
            display: "flex",
            flexDirection: "column", 
          }}
        >
          {/* Botonera */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button 
              variant="contained"
              sx={{
                fontSize: ".8em",
                fontWeight: "bold",
                mr: "1em",
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
              onClick={handleAtras}
            >
              Atrás
            </Button>
            <Button 
              variant="contained"
              sx={{
                fontSize: ".8em",
                fontWeight: "bold",
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
              onClick={handleAsignarRol}
            >
              Crear nuevo rol
            </Button>
            <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
          </Grid>
          {/* Titulo */}
          <Grid item container sx={{display: "flex", alignItems: "center"}}> 
            <PeopleIcon />
            <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
              Información sobre los roles de {`${nombre} ${apellido}`}
            </Typography>
          </Grid>  
          {/* Slider */}
          <Swiper navigation={true} modules={[Navigation]} className="swiper swiper-slide">
            {
              dependency_roles && dependency_roles.map((rol, index) => {
                return (
                  <SwiperSlide key={rol.id} className="mySwiper">
                    <AcercaRol
                      key={rol.id}
                      index={index}
                      id={rol?.id}
                      nombre={rol?.role.nombre} 
                      permisos={rol?.role.permissions}
                      dependencyRoles={dependency_roles}
                      actualizarDependencias={actualizarDependencias}
                    /> 
                  </SwiperSlide>
                )
              })
            }
          </Swiper> 
        </Grid>
      </Grid>
      {/* Pie de Pág */}
      <Pie /> 
    </>
  );
};

export default RolDetalle;