import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
// import { useDecrypt } from '../../hooks/useDecrypt';

// Componentes
import ListMenuItem from './ListMenuItem';

// Actions
import { openBar, closeBar, setSelected } from '../../redux/action-types/sideBarActions';
import { cerrarSesion } from '../../redux/action-types/accesoActions';

// Componentes de MUI
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';

// MUI iconos
import {
  AccountCircle,
  ChevronLeft as ChevronLeftIcon,
  Dashboard,
  Menu as MenuIcon,
  Home as HomeIcon,
  ExitToApp as Exit
} from '@mui/icons-material';
import KeyIcon from '@mui/icons-material/Key';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import DownhillSkiingIcon from '@mui/icons-material/DownhillSkiing';

// Utils
import { verificarPermiso } from '../../utils/verificarPermiso';

import * as listOfPages from "../../utils/listOfPages";

// Imagen del logo de Asistencia
import logo from "../../images/fingerprint.png";
import { obtenerFecha } from '../../utils/obtenerFecha';

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const esInvierno = obtenerFecha(new Date()).esInvierno

  const dniLocal = window.localStorage.getItem("dni");
  let permisos = window.localStorage.getItem("permisos");
  // const permisos = useDecrypt('permisos');

  const { open, selectedOption } = useSelector(state => state.sidebar);

  const [openMenuReportes, setOpenMenuReportes] = useState(false);
  const [openMenuJustificaciones, setOpenMenuJustificaciones] = useState(false);
  const [openMenuAdmin, setOpenMenuAdmin] = useState(false);

  const [hoveredItems, setHoveredItems] = useState(new Array(5).fill(false));

  const handleMouseEnter = (index) => {
    const updatedHoveredItems = Array(5).fill(false);
    updatedHoveredItems[index] = true;
    setHoveredItems(updatedHoveredItems);
  };

  const handleMouseLeave = (index) => {
    const updatedHoveredItems = [...hoveredItems];
    updatedHoveredItems[index] = false;
    setHoveredItems(updatedHoveredItems);
  };

  const handleDrawerOpen = () => {
    dispatch(openBar());
  };

  const handleDrawerClose = () => {
    dispatch(closeBar());
  };

  const handleInicio = () => {
    handleDrawerClose()
    navigate("/");
    dispatch(setSelected({principal:"Inicio"}));
  };

  const handlePerfil = () => {
    handleDrawerClose()
    window.sessionStorage.setItem('urlAnterior', '')
    navigate(`/perfil/${dniLocal}`);
    dispatch(setSelected({principal:"Mi Perfil"}));
  };

  const handleDash = () => {
    handleDrawerClose()
    navigate(`/dashboard`);
    dispatch(setSelected({principal:"Dashboard"}));
  };

  const handleVacation = () => {
    handleDrawerClose()
    navigate(`/vacaciones-usuario`);
    dispatch(setSelected({principal:"Vacaciones"}));
  };


  const handleReportes = () => {
    setOpenMenuReportes(!openMenuReportes);
  };

  const handleJustificaciones = () => {
    setOpenMenuJustificaciones(!openMenuJustificaciones);
  };

  const handleAdmin = () => {
    setOpenMenuAdmin(!openMenuAdmin);
  };

  const handleChangePassword = () => {
    handleDrawerClose();
    navigate("/cambiar-contraseña");
    dispatch(setSelected({principal:"Contraseña"}));
  }

  const handleCloseSesion = () => {
    dispatch(cerrarSesion());
    navigate("/acceso");
  };

  return (
    <Box sx={{heigth: "100vh"}}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleDrawerOpen}>
            <MenuIcon sx={{color: 'white'}} />
          </IconButton>
        </Toolbar>
        <Drawer
          variant="temporary"
          anchor="left"
          open={open}
          onClose={handleDrawerClose}
          sx={{heigth: "100%"}}
        >

          <div/>
          <div/>
          <div>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
        <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                height:"100%",
                justifyContent:"space-between"
                }}>
          <Box>
          <List>
            <ListItem sx={{ width: "16vw", minWidth: "300px" }}>
            <Avatar alt="Logo Asistencia" src={logo} />
              <Typography
                sx={{
                  ml: 1,
                  mr: 2,
                  mt: 2,
                  mb: 1,
                  fontWeight: 800,
                  fontSize: '1.3em',
                  letterSpacing: '.4rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
              SiCAP
              </Typography>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItemButton
            onMouseEnter={() => handleMouseEnter(0)}
            onMouseLeave={() => handleMouseLeave(0)}
            >
              <ListItemIcon>
                <HomeIcon sx={{
                  color: (selectedOption === "Inicio" && hoveredItems[0]) ? 'primary.dark'
                  : (selectedOption === "Inicio" || hoveredItems[0]) ? 'primary.light'
                  : null}} />
              </ListItemIcon>
              <ListItemText primary="Inicio" onClick={handleInicio} />
            </ListItemButton>
          </List>
          <Divider />
          <List>
            <ListItemButton
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={() => handleMouseLeave(1)}
            >
              <ListItemIcon>
                <AccountCircle sx={{
                  color: (selectedOption === "Mi Perfil" && hoveredItems[1]) ? 'primary.dark'
                  : (selectedOption === "Mi Perfil" || hoveredItems[1]) ? 'primary.light'
                  : null}}/>
              </ListItemIcon>
              <ListItemText primary="Mi Perfil" onClick={handlePerfil} />
            </ListItemButton>
          </List>
          <Divider />

          {
            verificarPermiso(permisos, 'super_usuario') ?
            <>
              <List>
                <ListItemButton
                onMouseEnter={() => handleMouseEnter(2)}
                onMouseLeave={() => handleMouseLeave(2)}
                >
                  <ListItemIcon>
                    <Dashboard sx={{
                      color: (selectedOption === "Dashboard" && hoveredItems[2]) ? 'primary.dark'
                      : (selectedOption === "Dashboard" || hoveredItems[2]) ? 'primary.light'
                      : null}}/>
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" onClick={handleDash} />
                </ListItemButton>
                </List>
              <Divider />
            </>
            :
            null
          }

          <List>

            {
              verificarPermiso(permisos, 'usuario_consulta') ?
                <>
                  <ListMenuItem
                    menu={listOfPages.reportesOpciones[0]}
                    opciones={listOfPages.reportesOpciones}
                    open={openMenuReportes}
                    handleClick={handleReportes}
                  />

                  {
                    verificarPermiso(permisos, 'usuario_administrador_privilegiado') ?
                      <ListMenuItem
                        menu={listOfPages.justificacionesOpcionesPriv[0]}
                        opciones={listOfPages.justificacionesOpcionesPriv}
                        open={openMenuJustificaciones}
                        handleClick={handleJustificaciones}
                      />
                    :
                      verificarPermiso(permisos, 'usuario_consulta') ?
                      <ListMenuItem
                        menu={listOfPages.justificacionesOpciones[0]}
                        opciones={listOfPages.justificacionesOpciones}
                        open={openMenuJustificaciones}
                        handleClick={handleJustificaciones}
                      />
                    :
                      null
                  }
                
                  {
                    verificarPermiso(permisos, 'super_usuario') ?
                      <ListMenuItem
                        menu={listOfPages.adminOpcionesSuper[0]}
                        opciones={listOfPages.adminOpcionesSuper}
                        open={openMenuAdmin}
                        handleClick={handleAdmin}
                      />
                    : verificarPermiso(permisos, 'usuario_administrador') ?
                    <>
                      <ListMenuItem
                        menu={listOfPages.adminOpciones[0]}
                        opciones={listOfPages.adminOpciones}
                        open={openMenuAdmin}
                        handleClick={handleAdmin}
                      />
                    </>
                    :
                    <ListMenuItem
                    menu={listOfPages.consultaOpciones[0]}
                    opciones={listOfPages.consultaOpciones}
                    open={openMenuAdmin}
                    handleClick={handleAdmin}
                    />
                  }
                  {
                    verificarPermiso(permisos, 'usuario_administrador') ?
                      <>
                          <ListItemButton
                          onMouseEnter={() => handleMouseEnter(5)}
                          onMouseLeave={() => handleMouseLeave(5)}
                          >
                            <ListItemIcon>
                            {esInvierno? <DownhillSkiingIcon sx={{
                                color: (selectedOption === "Vacaciones" && hoveredItems[5]) ? 'primary.dark'
                                : (selectedOption === "Vacaciones" || hoveredItems[5]) ? 'primary.light'
                                : null}}/> :
                              <BeachAccessIcon sx={{
                                color: (selectedOption === "Vacaciones" && hoveredItems[5]) ? 'primary.dark'
                                : (selectedOption === "Vacaciones" || hoveredItems[5]) ? 'primary.light'
                                : null}}/>
                              }
                            </ListItemIcon>
                            <ListItemText primary="Vacaciones" onClick={handleVacation} />
                          </ListItemButton>
                      </>
                    :
                      null
                  }
                </>
                :
                  null
            }
            </List>
          </Box>
          <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: "16vw", minWidth: "300px",
                }}>
              <Divider />
              <ListItemButton
                onClick={handleChangePassword}
                onMouseEnter={() => handleMouseEnter(3)}
                onMouseLeave={() => handleMouseLeave(3)}
              >
                <ListItemIcon>
                  <KeyIcon sx={{
              color: (selectedOption === "Contraseña" && hoveredItems[3]) ? 'secondary.dark'
              : (selectedOption === "Contraseña" || hoveredItems[3]) ? 'secondary.light'
              : null}}/>
                </ListItemIcon>
                <ListItemText primary="Cambiar contraseña" />
              </ListItemButton>
              <ListItemButton
                sx={{  width: "100%" }}
                onClick={handleCloseSesion}
                onMouseEnter={() => handleMouseEnter(4)}
                onMouseLeave={() => handleMouseLeave(4)}
              >
                <ListItemIcon>
                  <Exit sx={{
              color: hoveredItems[4] ? 'secondary.dark' : 'secondary.light'}}/>
                </ListItemIcon>
                <ListItemText primary="Cerrar Sesión" />
              </ListItemButton>
            </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
