import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useModal } from '../../hooks/useModal';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import ModalComponent from '../ModalComponent/ModalComponent';
import Pie from '../Pie/Pie';

// Actions
import { buscar_tipo_por_id, tipo_justificacion_editar, restaurarMensajes } from '../../redux/action-types/justificacionesActions';

// MUI componentes
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

// MUI icons
import {
  Storage,
} from '@mui/icons-material';


const validate = (state) => {
  let errors = {};

  if(state.tituloInput === '') {
    errors.tituloInput = '';
  } else if(state.tituloInput.length < 3) {
    errors.tituloInput = 'El título debe tener al menos 3 caracteres...';
  };

  if(state.codigoInput === '') {
    errors.codigoInput = '';
  } else if(state.codigoInput.length < 3) {
    errors.codigoInput = 'El código debe tener al menos 3 caracteres...';
  };

  if(state.generalInput) {
    errors.generalInput = '';
  };

  return errors;
};

const initialState = {
  tituloInput: '',
  codigoInput: '',
  descripcionInput: '',
  generalInput: '',
};

const EditarTipoJustificacion = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const { tipo_id, status, mensaje } = useSelector(state => state.justificaciones);
  const dniActual  = useSelector(state => state.acceso.dni);

  const [ state, setState ] = useState(initialState);
  const [ errors, setErrors ] = useState(initialState);

  const [isOpenModal, openModal, closeModal] = useModal();

  const handleChange = event => {
    const { name, value } = event.target;
    setErrors(validate({
      ...state,
      [name]: value
    }));
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleClickCancelar = () => {
    navigate(`/administrar-justificaciones/tipo/${id}`);
  };

  const handleClickGuardar = event => {
    event.preventDefault();
    const data = {...state, idInput: tipo_id.id, usuarioActualInput: dniActual};
    dispatch(tipo_justificacion_editar(data));
    openModal();
    setTimeout(() => {
      dispatch(restaurarMensajes());
      navigate(`/administrar-justificaciones`);
    }, 2000);
    setState(initialState);
  };

  useEffect(() => {
    if (tipo_id)
    setState({
      tituloInput: tipo_id.titulo,
      codigoInput: tipo_id.codigo,
      descripcionInput: tipo_id.descripcion,
      generalInput: tipo_id.general,
    })
  }, [dispatch, tipo_id])

	useEffect(() => {
    dispatch(buscar_tipo_por_id(id));
  }, [dispatch, id]);

	if(!tipo_id) {
    return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={'Editar Justificación'} />
        <CircularProgress
          sx={{
            width: "auto",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />
        {/* Copmponente de Pie de Pag. */}
        <Pie />
      </>
    );
  };

  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={'Editar Tipo Justificación'} />
      {/* Contenedor del componente: Paper */}
      <Grid id={"editarTipoJustificacion"}  container component="form" noValidate onSubmit={handleClickGuardar} spacing={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3vh",
        }}
      >
        {/* Contenedor Grid de los Items   */}
        <Grid item container xs={12} sm={10} md={8} lg={6} spacing={6}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* Item 1 */}
          <Grid item xs={10} sm={10} md={8} lg={8}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}>
              <Storage />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                Justificación
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb:"1em",
              }}
            >
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"} >
                  Título*:
                </Typography>
                <TextField
                  id='tituloInput'
                  name="tituloInput"
                  type='text'
                  value={state.tituloInput}
                  error={errors.tituloInput && "error"}
                  placeholder={tipo_id.titulo}
                  onChange={handleChange}
                />
                {
                 errors.tituloInput &&
                  <FormHelperText
                    sx={{
                      color: "red",
                    }}
                  >
                    {errors.tituloInput}
                  </FormHelperText>
                }
              </Box>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"} >
                  Código*:
                </Typography>
                <TextField
                  id='codigoInput'
                  name="codigoInput"
                  type='text'
                  value={state.codigoInput}
                  placeholder={tipo_id.codigo}
                  error={errors.codigoInput && "error"}
                  onChange={handleChange}

                />
                {
                 errors.codigoInput &&
                  <FormHelperText
                    sx={{
                      color: "red",
                    }}
                  >
                    {errors.codigoInput}
                  </FormHelperText>
                }
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb:"1em",
              }}
            >
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"}>
                  Descripción:
                </Typography>
                <TextField
                  id='descripcionInput'
                  name="descripcionInput"
                  type='text'
                  value={state.descripcionInput}
                  placeholder={tipo_id.descripcion ? tipo_id.descripcion : "Ingrese la descripción..."}
                  error={errors.descripcionInput && "error"}
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      // set the color of the label when not shrinked
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                />
                {
                  errors.descripcionInput &&
                  <FormHelperText
                    sx={{
                      color: "red",
                    }}
                  >
                    {errors.descripcionInput}
                  </FormHelperText>
                }
              </Box>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"}>
                  Tipo*:
                </Typography>
                <TextField
                  labelId="generalInputLabel"
                  id='generalInput'
                  name="generalInput"
                  value={state.generalInput}
                  error={errors.generalInput && "error"}
                  onChange={handleChange}
                  select
                  InputLabelProps={{
                    sx: {
                      // set the color of the label when not shrinked
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                >
                  <MenuItem key={"placeholder"} disabled  value="">
                    Selecciona el tipo
                  </MenuItem>
                  <MenuItem key={"general"}
                    value={true}
                  >
                    General
                  </MenuItem>
                  <MenuItem key={"personal"}
                    value={false}
                  >
                    Personal
                  </MenuItem>
                </TextField>
                {
                 errors.generalInput &&
                  <FormHelperText
                    sx={{
                      color: "red",
                    }}
                  >
                    {errors.generalInput}
                  </FormHelperText>
                }
              </Box>
            </Box>

          </Grid>

          {/* Item 2 */}
          <Grid item container xs={10} sm={10} md={8} lg={8}>
            <Button
                type="submit"
                variant="contained"
                sx={{
                  mr: 1,
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleClickCancelar}
              >
               Cancelar
            </Button>
            {
              ((errors.tituloInput?.length > 0 || errors.codigoInput?.length > 0 || errors.generalInput?.length > 0)
                ||
              ((state.tituloInput === '' && state.codigoInput === '' && state.generalInput === '' && state.descripcionInput === '')
              ||
              (state.tituloInput === tipo_id.titulo && state.codigoInput === tipo_id.codigo && state.generalInput === tipo_id.general && state.descripcionInput === tipo_id.descripcion)
            )
            )
              ?
                <Button
                  type="submit"
                  variant="contained"
                  disabled
                  sx={{
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Guardar
                </Button>
              :
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Guardar
                </Button>
            }
          </Grid>
        </Grid>
      </Grid>
      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {status}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {mensaje}
        </Typography>
      </ ModalComponent>
      {/* Pie de Pág */}
      <Pie />
    </>
  );
};

export default EditarTipoJustificacion;
