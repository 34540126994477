import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

// Actions
import { buscar_por_id } from "../../redux/action-types/dependenciaActions";

// Componentes
import Rotulo from "../Rotulo/Rotulo";
import Dependencia from "../Dependencias/Dependencia";
import Pie from "../Pie/Pie";

// MUI componentes
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import ListadoUsuarios from "./ListadoUsuarios";

const UsuariosDependencia = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const { dependencia } = useSelector((state) => state.dependencia);

  const handleAtras = () => {
    navigate(`/dependencia/${id}`);
  };

  useEffect(() => {
    dispatch(buscar_por_id(id));
  }, [dispatch, id]);

  if (!dependencia) {
    return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={"Dependencia"} />
        <Grid container>
          <CircularProgress
            sx={{
              width: "auto",
              height: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </Grid>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  };

  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={`Personas de ${dependencia.name}`} />
      <Grid container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3vh",
        }}
      >
        <Grid item xs={11} sm={11} md={10} lg={6}>
          <Button
            variant="contained"
            sx={{
              alignSelf: "start",
              fontSize: ".8em",
              fontWeight: "bold",
              bgcolor: "background.paper",
              "&:hover": {
                bgcolor: "background.paper",
                transform: "scale(1.01)",
              },
            }}
            onClick={handleAtras}
          >
            Atrás
          </Button>
          <Divider sx={{ width: "100%", height: "1em", mb: "1em" }} />
        </Grid>
        {/* Contenedor Grid de los Items */}
        <Grid item container xs={11} sm={11} md={10} lg={6} ml={2}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* Datos de la Dependencia */}
          <Dependencia
            key={dependencia.id}
            id={dependencia.id}
            nombre={dependencia.name}
          />
          {/* Item 2 */}
          <Grid />
            <Divider sx={{ width: "100%", height: "1em", mb: "1em" }} />
          <Grid item container xs={11} sm={11} md={10} lg={12} mb={"3em"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                marginBottom: "8px",
              }}
            >
              <FormatListNumberedIcon sx={{ mt: .3 }} />
              <Typography
                component={"h6"}
                variant={"h6"}
                fontSize={"1.2em"}
                ml={"2px"}
              >
                Personas
              </Typography>
            </Box>
            {/* Tabla de Listado */}
            <ListadoUsuarios />
          </Grid>
        </Grid>
      </Grid>
      {/* Pie de Pág */}
      <Pie />
    </>
  );
};

export default UsuariosDependencia;