import axios from "axios";

import { crear, editar, buscar } from '../reducers/usuarioReducer';

export const crearHorario = (data) => {
  return function(dispatch) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/horario/crear`, data)
      .then(response => {
        dispatch(crear({status: response.data.status, text: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
};

export const editarHorario = (data) => {
  return (dispatch) => {
    return axios.put(`${process.env.REACT_APP_BASE_URL}/horario/editar`, data)
      .then(response => {
        dispatch(editar({status: response.data.status, text: response.data.mensaje}));
      })
      .catch(error => console.log(error));
  };
};

export const buscarHorario = (dni) => {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/horario/${dni}`)
      .then(response => {
        dispatch(buscar({data: response.data, status: response.status, text: response.statusText}));
      })
      .catch(error => console.log(error));
  };
};
