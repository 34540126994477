import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  selectedOption: "Inicio",
  secondary: null,
};

const sideBarSlice = createSlice({
  name: 'sideBarReducer',
  initialState,
  reducers: {
    setOpen: (state) => {
      state.open = true;
    },
    setClose: (state) => {
      state.open = false;
    },
    setRoute: (state, action) => {
      return{
        ...state,
        selectedOption: action.payload.principal,
        secondary: action.payload.secondary
      }
    }
  },
});

export const { setOpen, setClose, setRoute } = sideBarSlice.actions;
export default sideBarSlice.reducer;