import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

// Actions
import { informacionCreacion } from '../../../redux/action-types/usuarioActions'; 
import { buscar_dependencias_hijas } from '../../../redux/action-types/dependenciaActions';
import { cargo_por_tipo } from '../../../redux/action-types/cargoActions';

// MUI componentes
import { 
  Autocomplete,
  Box, 
  CircularProgress,
  FormHelperText,
  Grid, 
  MenuItem,
  TextField,
  Typography, 
} from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers';
import { inputLabelClasses } from "@mui/material/InputLabel";

// MUI iconos
import ApartmentIcon from '@mui/icons-material/Apartment';

const CrearDesignacion = ({ designacion, setDesignacion, errorsDesigancion, setErrorsDesignacion, validate }) => {
  const dispatch = useDispatch();

  const { tipo_cargos, dependencias } = useSelector(designacion => designacion.usuario);
  const { hijas } = useSelector(state => state.dependencia);
  const { cargos } = useSelector(state => state.cargos)

  const handleChangePadre = event => {
    const { name, value } = event.target;
    setErrorsDesignacion(validate({
      ...designacion,
      [name]: value
    }));
    setDesignacion({
      ...designacion,
      [name]: value,
      hijoInput: '',
      objhijoInput: null,
    });
    dispatch(buscar_dependencias_hijas(value))
  };

  const handleChangeTipo = event => {
    const { name, value } = event.target;
    setErrorsDesignacion(validate({
      ...designacion,
      [name]: value
    }));
    setDesignacion({
      ...designacion,
      [name]: value,
      cargoInput: '',
      objcargoInput: null
    });
    dispatch(cargo_por_tipo(value))
  };

  const handleChangeCargo = (event, newValue) => {
    const selectedOption = newValue; // El objeto completo seleccionado
    const name = "cargoInput";
    const objeto = 'obj'+name;

    setDesignacion({
      ...designacion,
      [name]: selectedOption ? selectedOption.codigo : null, // Guarda el ID del objeto o null si no hay selección
      [objeto]: selectedOption ? selectedOption : null,
    });
  };

  const handleChangeOficina = (event, newValue) => {
    const selectedOption = newValue; // El objeto completo seleccionado
    const name = "hijoInput";
    const objeto = 'obj'+name;

    setDesignacion({
      ...designacion,
      [name]: selectedOption ? selectedOption.id : null, // Guarda el ID del objeto o null si no hay selección
      [objeto]: selectedOption ? selectedOption : null,
    });
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setErrorsDesignacion(validate({
      ...designacion,
      [name]: value
    }));
    setDesignacion({
      ...designacion,
      [name]: value,
    });
  };

  useEffect(() => {
    dispatch(informacionCreacion());
  }, [dispatch]);

  if(!dependencias || !tipo_cargos ) {
    return (
      <>
        <CircularProgress 
          sx={{
            width: "auto",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />  
      </>
    );
  }; 
 
  return (
    <>
      {/* Contenedor Grid de los Items   */}
      <Grid item container xs={12} sm={10} md={8} lg={8}
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: "1em",
        }}
      >   
        {/* Item 1 */}
        <Grid item xs={10} sm={10} md={8} lg={6}>
          <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}> 
            <ApartmentIcon />
            <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
              Designación
            </Typography>
          </Box>

          <Box sx={{display: "flex", flexDirection: "column", marginBottom:"0.5em"}}>

            <Typography mr={"1em"} fontWeight={"bold"}>
              Fecha Inicio*:
            </Typography>
            <DatePicker
            id={'fecha_inicioInput'}
            name="fecha_inicioInput"
            views={['year', 'month', 'day']}
            fullWidth
            onChange={date => handleChange({ target: { value: date, name: 'fecha_inicioInput' } })}
            slotProps={{
              textField: { InputLabelProps: { sx: {color: 'transparent',
                                              [`&.${inputLabelClasses.shrink}`]: {
                                                color: "black"}}
                                              }},
              toolbar: { toolbarFormat: 'dddd D MMM', hidden: false },
            }}>
          </DatePicker>
          </Box>  

          <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
            <Typography mr={"1em"} fontWeight={"bold"}>
              Dependencia*:
            </Typography>
            <TextField
                labelid="dependenciaInputLabel"
                id="dependenciaInput"
                name="dependenciaInput"
                value={designacion.dependenciaInput}
                fullWidth
                error={!!errorsDesigancion?.dependenciaInput}
                onChange={handleChangePadre}
                select
                InputLabelProps={{
                  sx: {
                    color: 'rgb(150, 150, 150)',
                  }
                }}
              >  
                {
                  dependencias && dependencias.map(dependencia => {
                    if(dependencia.name === 'UNLP') {
                      return null;
                    };

                    return (
                      <MenuItem key={dependencia.id}
                        value={dependencia.id}
                      >
                        {dependencia.name}
                      </MenuItem>
                    )
                  })
                }
            </TextField>
            {
              errorsDesigancion?.dependenciaInput && 
              <FormHelperText
                sx={{
                  color: "red",
                }}
              >
                {errorsDesigancion?.dependenciaInput}
              </FormHelperText>
            }
          </Box>

          {hijas ?
          <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
         <Typography mr={"1em"} fontWeight={"bold"}>
              Oficina:
            </Typography>
            <Autocomplete
                      id="hijoInputLabel"
                      name="hijoInput"
                      options={hijas}

                      value={designacion.objhijoInput}
                      disabled={!designacion.dependenciaInput || !hijas}
                      getOptionLabel={(option) => `${option.name} `}
                      onChange={handleChangeOficina}
                      noOptionsText={`No se encontraron oficinas`}
                      renderOption={(props, option) => (
                        <Box key={option.id}
                            component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.name} 
                        </Box>
                      )}
                      renderInput={(params) => <TextField {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',}} />}
                    ></Autocomplete>
                  
            
          </Box>
          :
          <></>}
          
          

          <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
            <Typography mr={"1em"} fontWeight={"bold"}>
              Tipo de cargo*:
            </Typography>
            <TextField
                labelid="tipoInputLabel"
                id="tipoInput"
                name="tipoInput"
                value={designacion.tipoInput}
                fullWidth
                error={!!errorsDesigancion?.tipoInput}
                onChange={handleChangeTipo}
                select
                InputLabelProps={{
                  sx: {
                    color: 'rgb(150, 150, 150)', 
                  }
                }}
              >  
                {
                  tipo_cargos && tipo_cargos.map(tipo => {
                    return (
                      <MenuItem key={tipo}
                        value={tipo}
                      >
                        {tipo}
                      </MenuItem>
                    )
                  })
                }
            </TextField>
            {
              errorsDesigancion?.tipoInput && 
              <FormHelperText
                sx={{
                  color: "red",
                }}
              >
                {errorsDesigancion?.tipoInput}
              </FormHelperText>
            }
          </Box>
          
          <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
            <Typography mr={"1em"} fontWeight={"bold"}>
              Cargo*:
            </Typography>
            {cargos && (<Autocomplete
                      id="cargoInputLabel"
                      name="cargoInput"
                      options={cargos}

                      value={designacion.objcargoInput}

                      getOptionLabel={(option) => `${option.codigo} - ${option.descripcion}`}
                      onChange={handleChangeCargo}
                      noOptionsText={`No se encontraron cargos`}
                      renderOption={(props, option) => (
                        <Box key={option.id}
                            component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.codigo} - {option.descripcion}
                        </Box>
                      )}
                      renderInput={(params) => <TextField {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',}} />}
                    ></Autocomplete>)}
                    {
              errorsDesigancion?.cargoInput && 
              <FormHelperText
                sx={{
                  color: "red",
                }}
              >
                {errorsDesigancion?.cargoInput}
              </FormHelperText>
            }
            
          </Box> 

        </Grid> 
      </Grid>
    </>
  );
};

export default CrearDesignacion;