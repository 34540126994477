import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from '../../hooks/useModal';
import dayjs from 'dayjs';

// Componentes
import Pie from '../Pie/Pie';
import Rotulo from '../Rotulo/Rotulo';
import ModalComponent from '../ModalComponent/ModalComponent';

// Acciones
import { crearReloj } from '../../redux/action-types/relojActions';

// MUI componentes
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

// MUI Date Picker
import { DatePicker } from '@mui/x-date-pickers';
// MUI icons
import MoreTimeIcon from '@mui/icons-material/MoreTime';

const ALPHA = /^[a-zA-Z\s]+$/;

const validate = (state) => {
  let errors = {};

  if(state.nombreInput === '') {
    errors.nombreInput = '';
  } else if(state.nombreInput.length < 3) {
    errors.nombreInput = 'El nombre debe tener al menos 3 caracteres...';
  } else if(!ALPHA.test(state.nombreInput)) {
    errors.nombreInput = 'Solo se aceptan letras y espacios...';
  };

  if(state.ubicacionInput === '') {
    errors.ubicacionInput = '';
  } else if(state.ubicacionInput.length < 3) {
    errors.ubicacionInput = 'La ubicacion debe tener al menos 3 caracteres...';
  };

  if(state.ipInput === '') {
    errors.ipInput = '';
  } else if(state.ipInput.length < 6) {
    errors.ipInput = 'Ingresa la IP...';
  };

  return errors;
};

const initialState = {
  nombreInput: '',
  ubicacionInput: '',
  ipInput: '',
  mascaraInput: '',
  routerInput: '',
  modeloInput: '',
  soapInput: '',
  algoritmoInput: '',
  zonaInput: '',
  fechaInput: '',
  marcaInput: 'ZKTeco',
  activoInput: false,
};

const CrearReloj = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dniActual  = useSelector(state => state.acceso.dni);
  const { status, text } = useSelector(state => state.reloj);

  const [ state, setState ] = useState(initialState);
  const [ errors, setErrors ] = useState(initialState);

  const [isOpenModal, openModal, closeModal] = useModal();

  const handleChange = event => {
    const { name, value } = event.target;
    setErrors(validate({
      ...state,
      [name]: value
    }));
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleClickCancelar = () => {
    navigate('/relojes');
  };

  const handleClickGuardar = event => {
    event.preventDefault();
    dispatch(crearReloj({...state, usuarioActualInput: dniActual}));
    setTimeout(() => {
      navigate(`/relojes`);
    }, 2000);
    openModal();
    setState(initialState);
  };

  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={'Crear Reloj'} />
      {/* Contenedor del componente: Paper */}
      <Grid id={"crearReloj"}  container component="form" noValidate onSubmit={handleClickGuardar} spacing={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3vh",
        }}
      >
        {/* Contenedor Grid de los Items   */}
        <Grid item container xs={12} sm={10} md={8} lg={6} spacing={6}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* Item 1 */}
          <Grid item xs={10} sm={10} md={8} lg={8}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}>
              <MoreTimeIcon />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                Información
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb:"1em",
              }}
            >
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"} >
                  Nombre*:
                </Typography>
                <TextField
                  id='nombreInput'
                  name="nombreInput"
                  type='text'
                  value={state.nombreInput}
                  error={errors.nombreInput && "error"}
                  placeholder='Nombre'
                  onChange={handleChange}
                />
                {
                 errors.nombreInput &&
                  <FormHelperText
                    sx={{
                      color: "red",
                    }}
                  >
                    {errors.nombreInput}
                  </FormHelperText>
                }
              </Box>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"} >
                  Ubicación*:
                </Typography>
                <TextField
                  id='ubicacionInput'
                  name="ubicacionInput"
                  type='text'
                  value={state.ubicacionInput}
                  placeholder='Ubicación'
                  error={errors.ubicacionInput && "error"}
                  onChange={handleChange}

                />
                {
                 errors.ubicacionInput &&
                  <FormHelperText
                    sx={{
                      color: "red",
                    }}
                  >
                    {errors.ubicacionInput}
                  </FormHelperText>
                }
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb:"1em",
              }}
            >
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"}>
                  IP*:
                </Typography>
                <TextField
                  id='ipInput'
                  name="ipInput"
                  type='text'
                  value={state.ipInput}
                  placeholder="Ingrese la IP..."
                  error={errors.ipInput && "error"}
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      // set the color of the label when not shrinked
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                />
                {
                  errors.ipInput &&
                  <FormHelperText
                    sx={{
                      color: "red",
                    }}
                  >
                    {errors.ipInput}
                  </FormHelperText>
                }
              </Box>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"}>
                  Soap URL:
                </Typography>
                <TextField
                  id='soapInput'
                  name="soapInput"
                  type='text'
                  value={state.soapInput}
                  placeholder="Ingrese la Soap URL..."
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      // set the color of the label when not shrinked
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb:"1em",
              }}
            >
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"}>
                  Máscara de Red:
                </Typography>
                <TextField
                  id='mascaraInput'
                  name="mascaraInput"
                  type='text'
                  value={state.mascaraInput}
                  placeholder="Ingrese la máscara de red..."
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      // set the color of the label when not shrinked
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                />
              </Box>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"}>
                  Algoritmo*:
                </Typography>
                <TextField
                  labelid="generoInputLabel"
                  id="algoritmoInput"
                  name="algoritmoInput"
                  value={state.algoritmoInput}
                  fullWidth
                  onChange={handleChange}
                  select
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                >
                  <MenuItem key={'viejo'} value={'viejo'}>PYZK</MenuItem>
                  <MenuItem key={'nuevo'} value={'nuevo'}>pyzkaccess (new)</MenuItem>
                </TextField>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb:"1em",
              }}
            >
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"}>
                  Router:
                </Typography>
                <TextField
                  id='routerInput'
                  name="routerInput"
                  type='text'
                  value={state.routerInput}
                  placeholder="Ingrese el router..."
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      // set the color of the label when not shrinked
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb:"1em",
              }}
            >
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"}>
                  Modelo:
                </Typography>
                <TextField
                  id='modeloInput'
                  name="modeloInput"
                  type='text'
                  value={state.modeloInput}
                  placeholder="Ingrese el modelo..."
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      // set the color of the label when not shrinked
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                />
              </Box>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"}>
                  Fecha Instalación:
                </Typography>
                <DatePicker
              id={'fechaInput'}
              name="fechaInput"
              value={dayjs(state.fechaInput)}
              views={['year', 'month', 'day']}
              onChange={date => handleChange({target: {value: date, name: 'fechaInput'}})}
              >
            </DatePicker>
                {/* <TextField
                  id='fechaInput'
                  name="fechaInput"
                  type='date'
                  value={state.fechaInput}
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      // set the color of the label when not shrinked
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                /> */}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb:"1em",
              }}
            >
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"}>
                  Marca:
                </Typography>
                <TextField
                  id='marcaInput'
                  name="marcaInput"
                  type='text'
                  value={state.marcaInput}
                  placeholder="Ingrese la marca..."
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      // set the color of the label when not shrinked
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                />
              </Box>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography fontWeight={"bold"}>
                  Estado:
                </Typography>
                <TextField
                  id='activoInput'
                  name="activoInput"
                  select
                  value={state.activoInput}
                  placeholder="Ingrese el estado..."
                  onChange={handleChange}
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                >
                  <MenuItem key={1} value={true}> Activo </MenuItem>
                  <MenuItem key={2} value={false}> Inactivo </MenuItem>
                </TextField>
              </Box>
            </Box>
          </Grid>

          {/* Item 2 */}
          <Grid item container xs={10} sm={10} md={8} lg={8}>
            <Button
                type="submit"
                variant="contained"
                sx={{
                  mr: 1,
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleClickCancelar}
              >
               Cancelar
            </Button>
            {
              ((errors.nombreInput?.length > 0 || errors.ubicacionInput?.length > 0 || errors.ipInput?.length > 0)
                ||
              (state.nombreInput === '' || state.ubicacionInput === '' || state.ipInput === ''|| state.algoritmoInput === ''))
              ?
                <Button
                  type="submit"
                  disabled
                  variant="contained"
                  sx={{
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Crear
                </Button>
              :
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Crear
                </Button>
            }
          </Grid>
        </Grid>
      </Grid>
      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {status}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {text}
        </Typography>
      </ ModalComponent>
      {/* Pie de Pág */}
      <Pie />
    </>
  );
};

export default CrearReloj;
