import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { closeBar } from '../../redux/action-types/sideBarActions';
import { useDispatch, useSelector } from 'react-redux';

import { setSelected } from '../../redux/action-types/sideBarActions';

import { 
  Collapse,
  List,
  ListItemButton,
  ListItemIcon, 
  ListItemText, 
} from '@mui/material';

// Iconos de MUI
import { 
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';

const ListMenuItem = ({menu, opciones, handleClick, open}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { selectedOption, secondary } = useSelector(state => state.sidebar);

  const [hovered, setHovered] = useState(false);
  const [childHovered, setChildHovered] = useState(Array(opciones.length - 1).fill(false));

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
    setChildHovered(Array(opciones.length - 1).fill(false));
  };

  const handleChildMouseEnter = (index) => {
    const updatedChildHovered = Array(opciones.length - 1).fill(false);
    updatedChildHovered[index] = true;
    setChildHovered(updatedChildHovered);
  };

  const handleChildMouseLeave = (index) => {
    const updatedChildHovered = [...childHovered];
    updatedChildHovered[index] = false;
    setChildHovered(updatedChildHovered);
  };

  const handleNavigate = (place, sidebar) => {
    let url = place.toLowerCase().replace(' ', '-');
    dispatch(closeBar());
    dispatch(setSelected({principal:sidebar, secondary:place}));
    navigate(`/${url}`);
  };

  return (
    <>
      <ListItemButton 
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
        <ListItemIcon>
        {menu.logo && React.cloneElement(menu.logo, {
            sx: {
              color: (selectedOption===menu.sidebar && hovered) ? "primary.dark":
              (hovered || open || selectedOption===menu.sidebar) ? 'primary.light' : null,
            },
          })}
        </ListItemIcon>
        <ListItemText primary={menu.name} />
        {open? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            opciones.slice(1).map((data, index) => (
              <ListItemButton 
              key={data.name} 
              sx={{ pl: 4 }} 
              onClick={() => handleNavigate(data.name, data.sidebar)}
              onMouseEnter={() => handleChildMouseEnter(index)}
              onMouseLeave={() => handleChildMouseLeave(index)}
            >
              <ListItemIcon>
                {data.logo && React.cloneElement(data.logo, {
                  sx: {
                    color: (secondary===data.name && childHovered[index]) ? "primary.dark":
                    (secondary===data.name || childHovered[index] ) ? 'primary.light' : null,
                  },
                })}
                </ListItemIcon>
                <ListItemText primary={data.name} />
              </ListItemButton>
            ))
          }  
        </List>
      </Collapse>
    </>
  );
};

export default ListMenuItem;