import React from 'react';

import {
    Box,
    Grid,
    Modal,
    useMediaQuery,
} from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderColor: 'primary.main',
  borderRadius: '1em',
  boxShadow: 24,
  p: 4,
};

const styleMobile = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderColor: 'primary.main',
  borderRadius: '1em',
  boxShadow: 24,
  p: 2,
};

const ModalComponent = ({open, handleClose, children}) => {
  const matches = useMediaQuery('(min-width:450px)');

  if(matches) {
    return (
      <Grid>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} fontSize={'1em'}>
            {children}
          </Box>
        </Modal>
      </Grid>
    );
  };

  return (
    <Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleMobile}>
          {children}
        </Box>
      </Modal>
    </Grid>
  );
};

export default ModalComponent;