import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import AcercaCargo from './AcercaCargo';
import ModalComponent from '../ModalComponent/ModalComponent';
import Pie from '../Pie/Pie';

// Actions
import { eliminar_cargo, restaurarMensajes } from '../../redux/action-types/cargoActions'; 
import { cargo_por_codigo } from '../../redux/action-types/cargoActions';

// MUI componentes
import { 
  Button,
  Grid,
  CircularProgress,
  Typography,
} from '@mui/material';

const DetalleCargo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { codigo } = useParams();
  
  const [ fueBorrado, setFueBorrado ] = useState(false);

  const { cargo, status, mensaje, borrado } = useSelector(state => state.cargos);
  const dniActual  = useSelector(state => state.acceso.dni);

  const [isOpenModal, openModal, closeModal] = useModal();

  const handleClick = () => {
    openModal();
  };

  const handleDelete = (event) => {
    const data = {
      usuarioActualInput: dniActual,
      codigo,
    };
    dispatch(eliminar_cargo(data));
    setFueBorrado(borrado);
    openModal();
    setTimeout(() => {
      closeModal();
      navigate(`/administrar-cargos`);
      dispatch(restaurarMensajes());
    }, 2000);
  };

  useEffect(() => {
    dispatch(cargo_por_codigo(codigo));
    dispatch(restaurarMensajes());
  }, [dispatch, codigo]);

  if(!cargo) {
    return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={"Detalle Cargo"} />
        <Grid container>
          <CircularProgress 
            sx={{
              width: "auto",
              height: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />  
        </Grid>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  };

  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={"Detalle Cargo"} />
      <Grid container spacing={6} 
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1vh",
        }}
        >
        <Grid item container xs={12} sm={10} md={8} lg={6} ml={2}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* Datos de la Justificacion */}
          <AcercaCargo 
            codigo={cargo.codigo} 
            descripcion={cargo.descripcion}
            tipo={cargo.tipo}
            handleClick={handleClick}
          />  
        </Grid>
      </Grid>
      
      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        {
          fueBorrado 
          ?
          <>
            <Typography id="modal-modal-title" component="h2"sx={{ fontSize: '1.3em' }} >
              {status}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {mensaje}
            </Typography>
          </>
          :
          <>
            <Typography id="modal-modal-title" component="h2" sx={{ fontSize: '1.3em' }}>
              Esta por eliminar el cargo. ¿Desea continuar?
            </Typography>
            <Button
              type="submit"
              variant="contained"
              onClick={closeModal}
              sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                marginRight: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={handleDelete}
              sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'secondary.light',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Eliminar
            </Button>
          </>
        }
      </ModalComponent>
      {/* Pie de Pág */}
      <Pie /> 
    </>
  );
};

export default DetalleCargo;