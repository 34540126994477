import React from 'react';

import Sidebar from '../SideBar/SideBar';

import logoUNLP from '../../images/membrete_Mesa de trabajo 1.png'

import { 
  Box,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

const Rotulo = ({titulo='Rótulo'}) => {
  return (
    <Grid container
      sx={{
        width: "100%",
        height: "auto",
        bgcolor: "primary.light",
        display: "flex",
        justifyContent: 'space-between'
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* Componente para la navegacion */}
        <Sidebar />
        {/* Titulo de la Pág. */}
        <Typography
          sx={{
            fontSize: "1.5em",
            marginLeft: "2vw",
            fontWeight: "bold",
            color: "white",
          }}
        >
          { titulo }
        </Typography>
        
      </Box>
      <Box 
          component="a"
          href='/'
          sx={{
            width: { xs: '56%', sm:'50%', md: '40%', lg:'23%' }, // Establece el ancho al 100% en dispositivos pequeños
            height: { xs: 'auto', md: 'auto' }, // Altura automática para mantener la relación de aspecto          
            maxWidth: { md: '40%' },
            maxHeight: { xs: '80vh', md: '25%' }, // Limita la altura para evitar que sea demasiado grande en dispositivos pequeños
            marginLeft: 'auto'
          }}>
        <Box
          component="img"
          sx={{width: '100%'}}
          src={logoUNLP}
          alt="Logo UNLP"
          >
        </Box>
      </Box>
      
      <Divider />
    </Grid>
  );
};

export default Rotulo;