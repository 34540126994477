const palette = {
  primary: {
    main: 'rgb(0, 95, 97)',//'rgb(95, 157, 247)',
    light: 'rgb(0, 95, 97)', //'rgb(134, 178, 240)'
    dark : 'rgb(0, 145, 147)',//'rgb(95, 157, 247)',
    chip : 'rgba(0, 95, 97, 0.7)',
    disabled: 'rgba(0, 0, 0, 0.38)',
  },
  secondary: {
    main: 'rgb(242, 76, 76)',
    light: 'rgb(250, 96, 96)',
    dark: 'rgb(232, 36, 46)',
  },
  auditory: {
    main: "rgba(257,30,40,0.8)",
    secondary: "rgba(257,30,40,1)",
    third: "rgba(257,30,40,0.3)",
    fourth: "rgba(257,30,40,0.5)"
  },
  error: {
    main: 'rgb(242, 76, 76)',
  }, 
  warning: {
    main: 'rgb(236, 155, 59)',
  },
  text: {
    default: 'rgb(128, 128, 128)',
    primary: 'rgb(0, 0, 0)',
    secondary: 'rgb(256, 256, 256)',
    confirm: 'rgb(14, 205, 66)',
  },
  background: {
    paper: 'rgb(255, 255, 240)',
    default: 'rgb(255, 255, 240)',//'rgb(255, 247, 233)',
  },
};

export default palette;
