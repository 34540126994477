import React from 'react';
import { useNavigate } from 'react-router-dom';

// MUI componentes
import { 
  Grid,
} from '@mui/material';

const Window = ({ titulo, route }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/${route}`);
  };

  return (
    <Grid item xs={8} sm={8} md={5} lg={3.5} m={2}
      sx={{
        minHeight: '10em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '.5em',
        borderRadius: '.5em',
        fontWeight: "bold",
        padding: ".5em",
        boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)',
        bgcolor: 'background.paper',
        "&:hover": {
          bgcolor: 'primary.light',
          color: 'text.secondary',
          cursor: 'pointer',
          transform: 'scale(1.01)',
        }
      }}
      onClick={handleClick}
    >
      <div>{titulo}</div>
    </Grid>
  );
};

export default Window;