import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from '../../../hooks/useModal';

// Componentes
import Rotulo from '../../Rotulo/Rotulo';
import ModalComponent from '../../ModalComponent/ModalComponent';
import Pie from '../../Pie/Pie';

// Actions
import { buscar_por_dni } from '../../../redux/action-types/usuarioActions'; 
import { editarHorario } from '../../../redux/action-types/horarioActions';

// Funciones de ayuda
import { obtenerHorario } from '../../../utils/obtenerHorario';
import { obtenerHorasPorDia } from '../../../utils/obtenerHoras';

// Componentes de MUI
import { 
  Box, 
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography, 
} from '@mui/material';

// MUI iconos
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

const initialState = {
  horaLEntrada: '',
  horaL: '',
  horaMEntrada: '',
  horaM: '',
  horaMiEntrada: '',
  horaMi: '',
  horaJEntrada: '',
  horaJ: '',
  horaVEntrada: '',
  horaV: '',
  horaSEntrada: '',
  horaS: '',
  horaDEntrada: '',
  horaD: '',
};

const EditarHorario = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dni } = useParams();
  
  const { status, text, usuario, flag } = useSelector(state => state.usuario);
  const dniActual  = useSelector(state => state.acceso.dni);

  const [ state, setState ] = useState(initialState);
  const [ timeExceeded, setTimeExceeded ] = useState(false);

  const [ isOpenModal, openModal, closeModal ] = useModal();

  const handleChange = event => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleReplica = () => {
    setState({
      ...state,
      horaMEntrada: state.horaLEntrada,
      horaM: state.horaL,
      horaMiEntrada: state.horaLEntrada,
      horaMi: state.horaL,
      horaJEntrada: state.horaLEntrada,
      horaJ: state.horaL,
      horaVEntrada: state.horaLEntrada,
      horaV: state.horaL,
    })
  };

  const handleClick = (event) => {
    navigate(`/perfil/${dni}`);
    setState(initialState);
  };

  const handleSubmit = event => {
    event.preventDefault();
    const data = {
      dniInput: dni, 
      usuarioActualInput: dniActual,
      horarios: [
        { 
          diaInput: "Lunes",
          entradaInput: state.horaLEntrada,
          horasInput: state.horaL,
        },
        {
          diaInput: "Martes",
          entradaInput: state.horaMEntrada,
          horasInput: state.horaM,
        },
        {
          diaInput: "Miercoles",
          entradaInput: state.horaMiEntrada,
          horasInput: state.horaMi,
        },
        {
          diaInput: "Jueves",
          entradaInput: state.horaJEntrada,
          horasInput: state.horaJ,
        },
        {
          diaInput: "Viernes",
          entradaInput: state.horaVEntrada,
          horasInput: state.horaV,
        },
        {
          diaInput: "Sabado",
          entradaInput: state.horaSEntrada,
          horasInput: state.horaS,
        },
        {
          diaInput: "Domingo",
          entradaInput: state.horaDEntrada,
          horasInput: state.horaD,
        },
      ],
    };
    dispatch(editarHorario(data));
    openModal();
    setTimeout(() => {
      navigate(`/perfil/${dni}`);
    }, 3000);
    setState(initialState);
  };

  useEffect(() => {
    dispatch(buscar_por_dni(dni));
   
    
  }, [dispatch, dni]);

  useEffect(() => {
    // Configura el tiempo límite
    const timeoutId = setTimeout(() => {
      setTimeExceeded(true);
      if(!usuario) {
        setTimeout(() => {
          openModal();
          setTimeout(() => {
            navigate("/buscar-usuario");
          }, 3000);
        }, 0);
      };
    }, 5000);
    // Limpia el temporizador
    return () => clearTimeout(timeoutId);
  }, [usuario, openModal, navigate]);

  // Si no hay datos del usuario
  if(!usuario?.horario && !timeExceeded) {
    return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={'Editar Horario.'} />
        <CircularProgress 
          sx={{
            width: "auto",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />  
        {/* Copmponente de Pie de Pag. */}
        <Pie />
      </>
    );
  };
  // Si el usuario no puede acceder a esa informacion
  if(!usuario?.horario && timeExceeded && !flag) {
    return (
      <>
        {/* Navegación */}
        <Rotulo titulo={"Editar Horario."} />
        {/* Componente de Notificaciones */}
        <ModalComponent open={isOpenModal} handleClose={closeModal} >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Redireccionando...
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {text}
          </Typography>
        </ ModalComponent>
        {/* Pie de Pág */}
        <Pie />
      </>
    ); 
  };
  // Si tiene autorizacion
  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={'Editar Horario'} />
      {/* Aca arranca el componente Editar Horario*/}
      <Grid container component="form" noValidate onSubmit={handleSubmit} spacing={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* Contenedor Grid de los Items   */}
        <Grid item container xs={12} sm={10} md={8} lg={6} spacing={6}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item xs={10} sm={10} md={8} lg={7}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em", mt:"2em"}}> 
              <AccessTimeFilledIcon />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                Horario
              </Typography>
            </Box> 

            <Box sx={{width: "100%", display: "flex"}}>
              <Box sx={{width: "30%", display: "flex"}}></Box>
              <Box sx={{width: "70%", display: "flex", justifyContent: "space-between", mb: 2}}>
                { (state.horaLEntrada === initialState?.horaLEntrada && state.horaL === initialState?.horaL ) 
                  ?
                  <Button        
                    variant="contained"
                    disabled
                    sx={{
                      fontSize: ".8em",
                      fontWeight: "bold",
                      bgcolor: 'background.paper',
                      '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                      }
                    }}
                    onClick={handleReplica}
                  >Replicar Lunes a Viernes</Button>
                :
                <Button        
                    variant="contained"
                    sx={{
                      fontSize: ".8em",
                      fontWeight: "bold",
                      bgcolor: 'background.paper',
                      '&:hover': {
                        bgcolor: 'background.paper',
                        transform: 'scale(1.01)',
                      }
                    }}
                    onClick={handleReplica}
                  >Replicar Lunes a Viernes</Button>
                }
              </Box>
            </Box>

            <Box sx={{display: "flex", width:"100%"}}>
              <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", mb: 1}}>
                <Typography  fontWeight={"bold"}>
                  Lunes
                </Typography>
                <Box sx={{width: "70%", display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                    <Typography >
                      Entrada:
                    </Typography>
                    <TextField 
                      id="horaLEntrada"
                      name="horaLEntrada"
                      type="time"
                      value={state.horaLEntrada ? state.horaLEntrada : obtenerHorasPorDia(usuario?.horario, "Lunes") === '0' ? "" : obtenerHorario("Lunes", usuario?.horario).slice(0, 5)}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                  <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                    <Typography  >
                      Horas:
                    </Typography>
                    <TextField 
                      name="horaL"
                      select
                      value={state.horaL}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            {
                              state.horaL
                              ?
                              null
                              :
                              <span style={{ color: 'black' }}> {obtenerHorasPorDia(usuario?.horario, "Lunes")} </span>
                            }
                          </InputAdornment>
                        ),
                      }}
                    >
                      <MenuItem key={'0'} value={'0'}>0</MenuItem>
                      <MenuItem key={'1'} value={'1'}>1</MenuItem>
                      <MenuItem key={'2'} value={'2'}>2</MenuItem>
                      <MenuItem key={'3'} value={'3'}>3</MenuItem>
                      <MenuItem key={'4'} value={'4'}>4</MenuItem>
                      <MenuItem key={'5'} value={'5'}>5</MenuItem>
                      <MenuItem key={'6'} value={'6'}>6</MenuItem>
                      <MenuItem key={'7'} value={'7'}>7</MenuItem>
                      <MenuItem key={'8'} value={'8'}>8</MenuItem>
                      <MenuItem key={'9'} value={'9'}>9</MenuItem>
                      <MenuItem key={'10'} value={'10'}>10</MenuItem>
                    </TextField>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", mb: 1}}>
              <Typography  fontWeight={"bold"}>
                Martes
              </Typography>
              <Box sx={{width: "70%", display: "flex", justifyContent: "space-between" }}>
                <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                  <Typography >
                    Entrada:
                  </Typography>
                  <TextField 
                    name="horaMEntrada"
                    type="time"
                    value={state.horaMEntrada ? state.horaMEntrada : obtenerHorasPorDia(usuario?.horario, "Martes") === '0' ? "" : obtenerHorario("Martes", usuario?.horario).slice(0, 5)}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                  <Typography  >
                    Horas:
                  </Typography>
                  <TextField 
                    name="horaM"
                    select
                    value={state.horaM}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          {
                            state.horaM
                            ?
                            null
                            :
                            <span style={{ color: 'black' }}> {obtenerHorasPorDia(usuario?.horario, "Martes")} </span>
                          }
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem key={'0'} value={'0'}>0</MenuItem>
                    <MenuItem key={'1'} value={'1'}>1</MenuItem>
                    <MenuItem key={'2'} value={'2'}>2</MenuItem>
                    <MenuItem key={'3'} value={'3'}>3</MenuItem>
                    <MenuItem key={'4'} value={'4'}>4</MenuItem>
                    <MenuItem key={'5'} value={'5'}>5</MenuItem>
                    <MenuItem key={'6'} value={'6'}>6</MenuItem>
                    <MenuItem key={'7'} value={'7'}>7</MenuItem>
                    <MenuItem key={'8'} value={'8'}>8</MenuItem>
                    <MenuItem key={'9'} value={'9'}>9</MenuItem>
                    <MenuItem key={'10'} value={'10'}>10</MenuItem>
                  </TextField>
                </Box>
              </Box>
            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", mb: 1}}>
              <Typography  fontWeight={"bold"}>
                Miércoles
              </Typography>
              <Box sx={{width: "70%", display: "flex", justifyContent: "space-between" }}>
                <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                  <Typography >
                    Entrada:
                  </Typography>
                  <TextField 
                    name="horaMiEntrada"
                    type="time"
                    value={state.horaMiEntrada ? state.horaMiEntrada : obtenerHorasPorDia(usuario?.horario, "Miercoles") === '0' ? "" : obtenerHorario("Miercoles", usuario?.horario).slice(0, 5)}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                  <Typography  >
                    Horas:
                  </Typography>
                  <TextField 
                    name="horaMi"
                    select
                    value={state.horaMi}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          {
                            state.horaMi
                            ?
                            null
                            :
                            <span style={{ color: 'black' }}> {obtenerHorasPorDia(usuario?.horario, "Miercoles")} </span>
                          }
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem key={'0'} value={'0'}>0</MenuItem>
                    <MenuItem key={'1'} value={'1'}>1</MenuItem>
                    <MenuItem key={'2'} value={'2'}>2</MenuItem>
                    <MenuItem key={'3'} value={'3'}>3</MenuItem>
                    <MenuItem key={'4'} value={'4'}>4</MenuItem>
                    <MenuItem key={'5'} value={'5'}>5</MenuItem>
                    <MenuItem key={'6'} value={'6'}>6</MenuItem>
                    <MenuItem key={'7'} value={'7'}>7</MenuItem>
                    <MenuItem key={'8'} value={'8'}>8</MenuItem>
                    <MenuItem key={'9'} value={'9'}>9</MenuItem>
                    <MenuItem key={'10'} value={'10'}>10</MenuItem>
                  </TextField>
                </Box>
              </Box>
            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", mb: 1}}>
              <Typography  fontWeight={"bold"}>
                Jueves
              </Typography>
              <Box sx={{width: "70%", display: "flex", justifyContent: "space-between" }}>
                <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                  <Typography >
                    Entrada:
                  </Typography>
                  <TextField 
                    name="horaJEntrada"
                    type="time"
                    value={state.horaJEntrada ? state.horaJEntrada : obtenerHorasPorDia(usuario?.horario, "Jueves") === '0' ? "" : obtenerHorario("Jueves", usuario?.horario).slice(0, 5)}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                  <Typography  >
                    Horas:
                  </Typography>
                  <TextField 
                    name="horaJ"
                    select
                    value={state.horaJ}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          {
                            state.horaJ
                            ?
                            null
                            :
                            <span style={{ color: 'black' }}> {obtenerHorasPorDia(usuario?.horario, "Jueves")} </span>
                          }
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem key={'0'} value={'0'}>0</MenuItem>
                    <MenuItem key={'1'} value={'1'}>1</MenuItem>
                    <MenuItem key={'2'} value={'2'}>2</MenuItem>
                    <MenuItem key={'3'} value={'3'}>3</MenuItem>
                    <MenuItem key={'4'} value={'4'}>4</MenuItem>
                    <MenuItem key={'5'} value={'5'}>5</MenuItem>
                    <MenuItem key={'6'} value={'6'}>6</MenuItem>
                    <MenuItem key={'7'} value={'7'}>7</MenuItem>
                    <MenuItem key={'8'} value={'8'}>8</MenuItem>
                    <MenuItem key={'9'} value={'9'}>9</MenuItem>
                    <MenuItem key={'10'} value={'10'}>10</MenuItem>
                  </TextField>
                </Box>
              </Box>
            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", mb: 1}}>
              <Typography  fontWeight={"bold"}>
                Viernes
              </Typography>
              <Box sx={{width: "70%", display: "flex", justifyContent: "space-between" }}>
                <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                  <Typography >
                    Entrada:
                  </Typography>
                  <TextField 
                    name="horaVEntrada"
                    type="time"
                    value={state.horaVEntrada ? state.horaVEntrada : obtenerHorasPorDia(usuario?.horario, "Viernes") === '0' ? "" : obtenerHorario("Viernes", usuario?.horario).slice(0, 5)}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                  <Typography  >
                    Horas:
                  </Typography>
                  <TextField 
                    name="horaV"
                    select
                    value={state.horaV}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          {
                            state.horaV
                            ?
                            null
                            :
                            <span style={{ color: 'black' }}> {obtenerHorasPorDia(usuario?.horario, "Viernes")} </span>
                          }
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem key={'0'} value={'0'}>0</MenuItem>
                    <MenuItem key={'1'} value={'1'}>1</MenuItem>
                    <MenuItem key={'2'} value={'2'}>2</MenuItem>
                    <MenuItem key={'3'} value={'3'}>3</MenuItem>
                    <MenuItem key={'4'} value={'4'}>4</MenuItem>
                    <MenuItem key={'5'} value={'5'}>5</MenuItem>
                    <MenuItem key={'6'} value={'6'}>6</MenuItem>
                    <MenuItem key={'7'} value={'7'}>7</MenuItem>
                    <MenuItem key={'8'} value={'8'}>8</MenuItem>
                    <MenuItem key={'9'} value={'9'}>9</MenuItem>
                    <MenuItem key={'10'} value={'10'}>10</MenuItem>
                  </TextField>
                </Box>
              </Box>
            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", mb: 1}}>
              <Typography  fontWeight={"bold"}>
                Sábado
              </Typography>
              <Box sx={{width: "70%", display: "flex", justifyContent: "space-between" }}>
                <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                  <Typography >
                    Entrada:
                  </Typography>
                  <TextField 
                    name="horaSEntrada"
                    type="time"
                    value={state.horaSEntrada ? state.horaSEntrada : obtenerHorasPorDia(usuario?.horario, "Sabado") === '0' ? "" : obtenerHorario("Sabado", usuario?.horario).slice(0, 5)}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                  <Typography  >
                    Horas:
                  </Typography>
                  <TextField 
                    name="horaS"
                    select
                    value={state.horaS}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          {
                            state.horaS
                            ?
                            null
                            :
                            <span style={{ color: 'black' }}> {obtenerHorasPorDia(usuario?.horario, "Sabado")} </span>
                          }
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem key={'0'} value={'0'}>0</MenuItem>
                    <MenuItem key={'1'} value={'1'}>1</MenuItem>
                    <MenuItem key={'2'} value={'2'}>2</MenuItem>
                    <MenuItem key={'3'} value={'3'}>3</MenuItem>
                    <MenuItem key={'4'} value={'4'}>4</MenuItem>
                    <MenuItem key={'5'} value={'5'}>5</MenuItem>
                    <MenuItem key={'6'} value={'6'}>6</MenuItem>
                    <MenuItem key={'7'} value={'7'}>7</MenuItem>
                    <MenuItem key={'8'} value={'8'}>8</MenuItem>
                    <MenuItem key={'9'} value={'9'}>9</MenuItem>
                    <MenuItem key={'10'} value={'10'}>10</MenuItem>
                  </TextField>
                </Box>
              </Box>
            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", mb: 1}}>
              <Typography  fontWeight={"bold"}>
                Domingo
              </Typography>
              <Box sx={{width: "70%", display: "flex", justifyContent: "space-between" }}>
                <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                  <Typography >
                    Entrada:
                  </Typography>
                  <TextField 
                    name="horaDEntrada"
                    type="time"
                    value={state.horaDEntrada ? state.horaDEntrada : obtenerHorasPorDia(usuario?.horario, "Domingo") === '0' ? "" : obtenerHorario("Domingo", usuario?.horario).slice(0, 5)}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <Box sx={{width: "45%", display: "flex", flexDirection: "column"}} >
                  <Typography  >
                    Horas:
                  </Typography>
                  <TextField 
                    name="horaD"
                    select
                    value={state.horaD}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          {
                            state.horaD
                            ?
                            null
                            :
                            <span style={{ color: 'black' }}> {obtenerHorasPorDia(usuario?.horario, "Domingo")} </span>
                          }
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem key={'0'} value={'0'}>0</MenuItem>
                    <MenuItem key={'1'} value={'1'}>1</MenuItem>
                    <MenuItem key={'2'} value={'2'}>2</MenuItem>
                    <MenuItem key={'3'} value={'3'}>3</MenuItem>
                    <MenuItem key={'4'} value={'4'}>4</MenuItem>
                    <MenuItem key={'5'} value={'5'}>5</MenuItem>
                    <MenuItem key={'6'} value={'6'}>6</MenuItem>
                    <MenuItem key={'7'} value={'7'}>7</MenuItem>
                    <MenuItem key={'8'} value={'8'}>8</MenuItem>
                    <MenuItem key={'9'} value={'9'}>9</MenuItem>
                    <MenuItem key={'10'} value={'10'}>10</MenuItem>
                  </TextField>
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* Item 2 */}
          <Grid item container xs={10} sm={10} md={8} lg={7}>
            <Button 
              variant="contained"
              sx={{
                mb: 2,
                mr: 1,
                fontSize: ".8em",
                fontWeight: "bold",
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
              onClick={handleClick}
            >
              Cancelar
            </Button>
            {
              ((state.horaLEntrada === '' && state.horaMEntrada === '' && state.horaMiEntrada === '' && state.horaJEntrada === ''
                && state.horaVEntrada === '' && state.horaSEntrada === '' && state.horaDEntrada === '' && state.horaL === ''
                && state.horaM === '' && state.horaMi === '' && state.horaJ === '' && state.horaV === ''
                && state.horaS === '' && state.horaD === ''))
              ?
                <Button
                  type="submit"
                  variant="contained"
                  disabled
                  sx={{
                    mb: 2,
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Guardar
                </Button>
              :
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mb: 2,
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Guardar
                </Button>
            }
          </Grid>
        </Grid>
      </Grid>
      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {status}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {text}
        </Typography>
      </ ModalComponent>
      {/* Copmponente de Pie de Pag. */}
      <Pie />
    </>
  );
};

export default EditarHorario;
