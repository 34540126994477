import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { useModal } from '../../../hooks/useModal';

//Componentes
import ModalComponent from '../../ModalComponent/ModalComponent';

// Actions
import { 
  obtener_dependencias, 
  obtener_cabeceras, 
  añadir_dependencia, 
  borrar_dependencia, 
  eliminar_role,
  restaurar_estado, 
  restaurar_mensajes
} from '../../../redux/action-types/rolActions';

// MUI componentes
import { 
  Button,
  Box,
  Grid,
  Switch,
  Typography,
} from '@mui/material';

// Utils
import { convertirCadena } from '../../../utils/convertirCadena';

const initialState = {
  delete: false,
};

const AcercaRol = ({ index, id, nombre, permisos, dependencyRoles, actualizarDependencias }) => {
  const dispatch = useDispatch();

  const { dni } = useParams();

  const dniActual  = useSelector(state => state.acceso.dni);

  const { cabeceras, asignadas, status, mensaje } = useSelector(state => state.rol);
  const [isOpenModal, openModal, closeModal] = useModal();

  const [ switchStates, setSwitchStates ] = useState({});
  const [ state, setState  ] = useState(initialState);

  const handleSwitchChange = (dependenciaId) => {
    setSwitchStates((prevState) => ({
      ...prevState,
      [dependenciaId]: !prevState[dependenciaId],
    }));
    switchStates[dependenciaId] ? handleClickBorrar(dependenciaId) : handleClickAñadir(dependenciaId); 
    setTimeout(() => {
      actualizarDependencias();
      dispatch(obtener_dependencias(id));
    }, 500);    
  };

  const handleEliminar = () => {
    setState({
      ...state,
      delete: true,
    });
    dispatch(restaurar_mensajes());
    openModal();
  };

  const handleDelete = () => {
    const data = {...state, modificado_por:dniActual}
    dispatch(eliminar_role(id, data));
    setTimeout(() => {        
      closeModal();
      actualizarDependencias();
      dispatch(obtener_dependencias(id));
    }, 3000);
    dispatch(restaurar_mensajes());
  };

  const handleClickAñadir = (dependenciaId) => {
    const data = { dependencia: dependenciaId, id_rol: id, modificado_por:dniActual };
    dispatch(añadir_dependencia(data));
  };

  const handleClickBorrar = (dependenciaId) => {
    const data = {...state, modificado_por: dniActual}
    dispatch(borrar_dependencia(dni, id, dependenciaId, data));
  };

  useEffect(() => {
    dispatch(obtener_cabeceras());
    if (id) dispatch(obtener_dependencias(id));

    return () => {
      dispatch(restaurar_estado());
    };
  }, [dispatch, id]);

  useEffect(() => {
    // Verificar que cabeceras y asignadas estén definidas antes de inicializar el estado
    if (cabeceras && asignadas && dependencyRoles && dependencyRoles[index]) {
      const initialSwitchStates = {};
      
      // Obtener las asignadas específicas del rol actual
      const asignadasDelRol = dependencyRoles[index]?.dependencies || [];
  
      cabeceras.forEach((dependencia) => {
        // Utiliza el método some para verificar si hay al menos un objeto en asignadasDelRol con el mismo id
        initialSwitchStates[dependencia.id] = asignadasDelRol.some(
          (asignada) => asignada.id === dependencia.id
        );
      });
  
      setSwitchStates(initialSwitchStates);
    }
  }, [cabeceras, asignadas, dependencyRoles, index]);

  return (
    <Grid item container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        padding: "1em",
      }}
    > 
      <Grid item sx={{display: "flex", flexDirection: "column", marginBottom:"1em", marginTop:"1em"}}>
        <Typography fontWeight={"bold"}>
          Nombre:
        </Typography>
        <Typography>
          {nombre ? nombre : "Sin rol"}
        </Typography>
      </Grid>
      <Grid item sx={{display: "flex", flexDirection: "column"}} >
        <Typography fontWeight={"bold"}>
          Permisos:
        </Typography>
        {
          permisos ?
            <ul>
              {
                permisos.map((permiso, index) => {
                  const titulo = convertirCadena(permiso.name);
                  return (
                    <Grid 
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography>
                        {titulo}
                      </Typography>
                    </Grid>
                  )
                })
              }
            </ul>
          :
          <Typography>
            Sin permisos
          </Typography>
        }
      </Grid>

      <Grid item sx={{display: "flex", flexDirection: "column"}} >
        <Typography mt={"1em"} fontWeight={"bold"}>
          Dependencias:
        </Typography>
        {
          id ? 
            cabeceras && cabeceras.length > 0 ?
              <ul>
                {
                  cabeceras.map((dependencia, index) => {
                    return (
                      <Grid 
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>
                          {dependencia.name}
                        </Typography>
                        <Switch 
                          checked={switchStates[dependencia.id] || false}
                          onChange={() => handleSwitchChange(dependencia.id)}
                        />
                      </Grid>
                    )
                  })
                }
              </ul>
            :
            <Typography>
              Sin dependencias
            </Typography>
          :
            <Typography>
              Sin dependencias
            </Typography>
        }
      </Grid>
      <Box 
        marginBottom='5em'>
      <Button 
        variant="contained"
        
        sx={{
          fontSize: ".6em",
          fontWeight: "bold",
          bgcolor: 'background.paper',
          '&:hover': {
            bgcolor: 'background.paper',
            transform: 'scale(1.01)',
          }
        }}
        onClick={handleEliminar}
      >
        Eliminar rol
      </Button>
      </Box>
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
      {
        /*Si hay mensaje y status, se muestra eso*/
        status
        ?
        <>
          <Typography  id="modal-modal-title" component="h2"sx={{ fontSize: '1.3em' }}>
            {mensaje}
          </Typography>
        </>
        :
        /*Si no hay mensaje y el flag de delete esta en true, se muestra el componente para eliminar */
        state.delete ?
        <>
          <Typography id="modal-modal-title" component="h2" sx={{ fontSize: '1.3em' }}>
            Esta por eliminar la designacion. ¿Desea continuar?
          </Typography>
          <Button
            type="submit"
            variant="contained"
            onClick={closeModal}
            sx={{ 
              fontSize: ".8em",
              fontWeight: "bold",
              marginTop: '1em',
              marginRight: '1em',
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'background.paper',
                transform: 'scale(1.01)',
              }
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            onClick={handleDelete}
            sx={{ 
              fontSize: ".8em",
              fontWeight: "bold",
              marginTop: '1em',
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'secondary.light',
                transform: 'scale(1.01)',
              }
            }}
          >
            Eliminar
          </Button>
        </>
        /*FIN DE MODAL DE ELIMINACION */
          :
          null
        }
      </ModalComponent>
    </Grid>
  );
};  

export default AcercaRol;