import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from '../../hooks/useModal';

// Actions
import { crearUsuario } from '../../redux/action-types/usuarioActions';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import ModalComponent from '../ModalComponent/ModalComponent';
import CrearUsuario from './Components/CrearUsuario';
import CrearDesignacion from './Components/CrearDesignacion';
import CrearHorario from './Components/CrearHorario';
import Pie from '../Pie/Pie';

// MUI componentes
import {
  Box,
  Button,
  Grid,
  Step,
  Stepper,
  StepLabel,
  Typography,
} from '@mui/material';

// REGEXs
const ALPHA = /^[\p{L}\s]+$/u;
const TEL = /(\+54|0054|54)?[ -]*([0-9][ -]*){7}/;

const steps = ['Datos Personales', 'Designación', 'Horario'];

const initialStateDatos = {
  nombreInput: '',
  apellidoInput: '',
  dniInput: '',
  mailInput: '',
  nacimientoInput: '',
  generoInput: '',
  paisInput: '',
  ciudadInput: '',
  direccionInput: '',
  telFijoInput: '',
  celularInput: '',
};

const validateDatos = (state) => {
  let errorsDatos = {};

  if(state.nombreInput === '') {
    errorsDatos.nombreInput = '';
  } else if(state.nombreInput.length < 3) {
    errorsDatos.nombreInput = 'El nombre debe tener al menos 3 caracteres...';
  } else if(!ALPHA.test(state.nombreInput)) {
    errorsDatos.nombreInput = 'Solo se aceptan letras y espacios...';
  };

  if(state.apellidoInput === '') {
    errorsDatos.apellidoInput = '';
  } else if(state.apellidoInput.length < 3) {
    errorsDatos.apellidoInput = 'El apellido debe tener al menos 3 caracteres...';
  } else if(!ALPHA.test(state.apellidoInput)) {
    errorsDatos.apellidoInput = 'Solo se aceptan letras y espacios...';
  };

  if(state.dniInput === '') {
    errorsDatos.dniInput = '';
  } else if(state.dniInput.length < 7) {
    errorsDatos.dniInput = 'El DNI debe al menos 7 caracteres...';
  } else if(state.dniInput.length > 8) {
    errorsDatos.dniInput = 'El DNI no debe poseer más de 8 caracteres...';
  };

  if(state.mailInput === '') {
    errorsDatos.mailInput = '';
  } else if(!state.mailInput.split('@')[0]) {
    errorsDatos.mailInput = 'Ingresa el mail...';
  } else if (!state.mailInput.includes('@')) {
    errorsDatos.mailInput = 'La dirección debe contener un "@"...';
  } else if (!state.mailInput.endsWith('unlp.edu.ar')) {
    errorsDatos.mailInput = 'La dirección debe terminar con el formato "unlp.edu.ar"';
  };

  if(state.nacimientoInput === '') {
    errorsDatos.nacimientoInput = '';
  };

  if(state.paisInput === '') {
    errorsDatos.paisInput = '';
  };

  if(state.ciudadInput === '') {
    errorsDatos.ciudadInput = '';
  };

  if(state.direccionInput === '') {
    errorsDatos.direccionInput = '';
  }

  if(state.telFijoInput === '') {
    errorsDatos.telFijoInput = '';
  } else if(!TEL.test(state.telFijoInput)) {
    errorsDatos.telFijoInput = 'Formato incorrecto o faltan números...'
  };

  if(state.celularInput === '') {
    errorsDatos.celularInput = '';
  } else if(!TEL.test(state.celularInput)) {
    errorsDatos.celularInput = 'Formato incorrecto o faltan números...'
  };

  return errorsDatos;
};

const initialStateDesignacion = {
  dependenciaInput: '',
  hijoInput: '',
  objhijoInput: null,
  objcargoInput: null,
  cargoInput: '',
  tipoInput: '',
  fecha_inicioInput: ''
};

const validateDesignacion = (designacion) => {
  let errorsDesignacion = {};

  if(designacion.fecha_inicioInput === '') {
    errorsDesignacion.fecha_inicioInput = '';
  };

  if(designacion.dependenciaInput === '') {
    errorsDesignacion.dependenciaInput = '';
  };

  if(designacion.cargoInput === '') {
    errorsDesignacion.cargoInput = '';
  };

  if(designacion.tipoInput) {
    errorsDesignacion.tipoInput = '';
  };

  return errorsDesignacion;
};

const initialStateHorario = {
  horaLEntrada: '',
  horaL: '',
  horaMEntrada: '',
  horaM: '',
  horaMiEntrada: '',
  horaMi: '',
  horaJEntrada: '',
  horaJ: '',
  horaVEntrada: '',
  horaV: '',
  horaSEntrada: '',
  horaS: '',
  horaDEntrada: '',
  horaD: '',
};

const LinearStepper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dniActual  = useSelector(horarios => horarios.acceso.dni);
  const { status, text } = useSelector(state => state.usuario);

  // Paginado
  const [ activeStep, setActiveStep ] = useState(0);
  const [ skipped, setSkipped ] = useState(new Set());

  // Datos del usuario nuevo
  const [ datos, setDatos ] = useState(initialStateDatos);
  const [ errorsDatos, setErrorsDatos ] = useState(initialStateDatos);

  // Datos de la nueva designacion
  const [ designacion, setDesignacion ] = useState(initialStateDesignacion);
  const [ errorsDesignacion, setErrorsDesignacion ] = useState(initialStateDesignacion);

  // Datos del horario
  const [ horarios, setHorarios ] = useState(initialStateHorario);

  // Modal
  const [isOpenModal, openModal, closeModal] = useModal();

  const isStepOptional = (step) => {
    return step === -1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if(isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    };

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleCancel = () => {
    navigate(`/`);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSubmit = event => {
    event.preventDefault();

    const dataHorario = {
      dniInput: datos.dniInput,
      usuarioActualInput: dniActual,
      horarios: [
        {
          diaInput: "Lunes",
          entradaInput: horarios.horaLEntrada || "00:00:00",
          horasInput: horarios.horaL || "0",
        },
        {
          diaInput: "Martes",
          entradaInput: horarios.horaMEntrada || "00:00:00",
          horasInput: horarios.horaM || "0",
        },
        {
          diaInput: "Miercoles",
          entradaInput: horarios.horaMiEntrada || "00:00:00",
          horasInput: horarios.horaMi || "0",
        },
        {
          diaInput: "Jueves",
          entradaInput: horarios.horaJEntrada || "00:00:00",
          horasInput: horarios.horaJ || "0",
        },
        {
          diaInput: "Viernes",
          entradaInput: horarios.horaVEntrada || "00:00:00",
          horasInput: horarios.horaV || "0",
        },
        {
          diaInput: "Sabado",
          entradaInput: horarios.horaSEntrada || "00:00:00",
          horasInput: horarios.horaS || "0",
        },
        {
          diaInput: "Domingo",
          entradaInput: horarios.horaDEntrada || "00:00:00",
          horasInput: horarios.horaD || "0",
        },
      ],
    };

    dispatch(crearUsuario({...datos, ...designacion, dataHorario, usuarioActualInput: dniActual}));

    openModal();

    setTimeout(() => {
      navigate(`/perfil/${datos.dniInput}`);
    }, 3000);

    setDatos(initialStateDatos);
    setErrorsDatos(initialStateDatos);
    setDesignacion(initialStateDesignacion);
    setErrorsDesignacion(initialStateDesignacion);
    setHorarios(initialStateHorario);
  };

  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={'Crear Usuario'} />
      <Grid container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3vh",
        }}
      >
        <Grid item container xs={12} sm={10} md={8} lg={8}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stepper activeStep={activeStep}>
            {
              steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                  );
                };
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                };
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>
                      {label}
                    </StepLabel>
                  </Step>
                );
              })
            }
          </Stepper>
          {
            activeStep === steps.length ?
              <>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  Usuario creado!
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reiniciar</Button>
                </Box>
              </>
            :
              <>
                <Grid id={"crearHorario"} container
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {
                    activeStep === 0 ?
                      <CrearUsuario
                        state={datos}
                        setState={setDatos}
                        errors={errorsDatos}
                        setErrors={setErrorsDatos}
                        validate={validateDatos}
                      />
                    : activeStep === 1 ?
                      <CrearDesignacion
                        designacion={designacion}
                        setDesignacion={setDesignacion}
                        errorsDesignacion={errorsDesignacion}
                        setErrorsDesignacion={setErrorsDesignacion}
                        validate={validateDesignacion}
                      />
                    : activeStep === 2 ?
                      <CrearHorario horarios={horarios} setHorarios={setHorarios}/>
                    :
                      null
                  }
                </Grid>
                <Grid item container
                  sx={{ display: 'flex', justifyContent: "center", pt: 2 }}
                >
                  <Button
                    variant="contained"
                    onClick={activeStep === 0 ? handleCancel : handleBack}
                    sx={{
                      mb: 2,
                      mr: 2,
                      fontSize: ".8em",
                      fontWeight: "bold",
                      bgcolor: 'background.paper',
                      '&:hover': {
                        bgcolor: 'background.paper',
                        transform: 'scale(1.01)',
                      }
                    }}
                  >
                    {activeStep === 0 ? "Cancelar" : "Anterior"}
                  </Button>
                  {
                    activeStep !== steps.length - 1 ?
                      <Button
                          type="submit"
                          variant="contained"
                          onClick={handleNext}
                          disabled={
                            (activeStep === 0 && (
                              (errorsDatos?.nombreInput?.length > 0 || errorsDatos?.apellidoInput?.length > 0 || errorsDatos?.dniInput?.length > 0
                              || errorsDatos?.mailInput?.length > 0 || errorsDatos?.generoInput?.length > 0 || errorsDatos?.paisInput?.length > 0
                              || errorsDatos?.ciudadInput?.length > 0 || errorsDatos?.direccionInput?.length > 0)
                              ||
                              (datos.nombreInput === '' || datos.apellidoInput === '' || datos.dniInput === '' || datos.mailInput === ''
                              || datos.generoInput === '' || datos.paisInput === ''|| datos.ciudadInput === ''|| datos.direccionInput === '')
                            )) 
                            ||
                            (activeStep === 1 && (
                              (activeStep >= 1 && (errorsDesignacion?.dependenciaInput?.length > 0 || errorsDesignacion?.cargoInput?.length > 0 || errorsDesignacion?.tipoInput?.length > 0 ))
                              ||
                              (activeStep >= 1 && (designacion?.dependenciaInput === '' || designacion?.cargoInput === '' || designacion?.tipoInput === ''))
                            ))
                          }
                          sx={{
                            mb: 2,
                            ml: 2,
                            fontSize: ".8em",
                            fontWeight: "bold",
                            bgcolor: 'background.paper',
                            '&:hover': {
                              bgcolor: 'background.paper',
                              transform: 'scale(1.01)',
                            }
                          }}
                        >
                          Siguiente
                        </Button>
                    :
                    (errorsDatos?.nombreInput?.length > 0 || errorsDatos?.apellidoInput?.length > 0 || errorsDatos?.dniInput?.length > 0
                    || errorsDatos?.mailInput?.length > 0 || errorsDatos?.generoInput?.length > 0 || errorsDatos?.paisInput?.length > 0
                    || errorsDatos?.ciudadInput?.length > 0 || errorsDatos?.direccionInput?.length > 0)
                    ||
                    (datos.nombreInput === '' || datos.apellidoInput === '' || datos.dniInput === '' || datos.mailInput === ''
                    || datos.generoInput === '' || datos.paisInput === ''|| datos.ciudadInput === ''|| datos.direccionInput === '')
                    ||
                    (errorsDesignacion?.dependenciaInput?.length > 0 || errorsDesignacion?.cargoInput?.length > 0 || errorsDesignacion?.tipoInput?.length > 0 )
                    ||
                    (designacion?.dependenciaInput === '' || designacion?.cargoInput === '' || designacion?.tipoInput === '')
                    ||
                    ((horarios?.horaLEntrada === '' && horarios?.horaMEntrada === '' && horarios?.horaMiEntrada === '' && horarios?.horaJEntrada === ''
                    && horarios?.horaVEntrada === '' && horarios?.horaSEntrada === '' && horarios?.horaDEntrada === '')
                    ||
                    (horarios?.horaL === '' && horarios?.horaM === '' && horarios?.horaMi === '' && horarios?.horaJ === '' && horarios?.horaV === ''
                    && horarios?.horaS === '' && horarios?.horaD === ''))
                    ?
                      <Button
                        type="submit"
                        variant="contained"
                        disabled
                        sx={{
                          mb: 2,
                          fontSize: ".8em",
                          fontWeight: "bold",
                          bgcolor: 'background.paper',
                          '&:hover': {
                            bgcolor: 'background.paper',
                            transform: 'scale(1.01)',
                          }
                        }}
                      >
                        Crear
                      </Button>
                    :
                      <Button
                        type="submit"
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{
                          mb: 2,
                          fontSize: ".8em",
                          fontWeight: "bold",
                          bgcolor: 'background.paper',
                          '&:hover': {
                            bgcolor: 'background.paper',
                            transform: 'scale(1.01)',
                          }
                        }}
                      >
                        Crear
                      </Button>
                  }
                </Grid>
              </>
          }
        </Grid>
      </Grid>
      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {status}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {text}
        </Typography>
      </ ModalComponent>
      {/* Copmponente de Pie de Pag. */}
      <Pie />
    </>
  );
};

export default LinearStepper;
