import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  time: 0,
  fechaInicial: null,
  fechaFinal: null,
};

const clockSlice = createSlice({
  name: 'clockReducer',
  initialState,
  reducers: {
    setClockTime: (state, action) => {
      state.time = action.payload;
    },
    setInitialDateTime: (state, action) => {
      state.fechaInicial = action.payload;
    },
    setFinalDateTime: (state, action) => {
      state.fechaFinal = action.payload;
    },
  },
});

export const { setClockTime, setInitialDateTime, setFinalDateTime } = clockSlice.actions;
export default clockSlice.reducer;