import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';

//Actions
import { actualizacion_anual, restaurar_mensajes } from '../../redux/action-types/vacacionesActions';

// MUI componentes
import { 
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';

import ModalComponent from '../ModalComponent/ModalComponent';

const SpecialWindow = ({ titulo }) => {
    const hoy = new Date();
    const añoActual = hoy.getFullYear();
    const primeroDiciembre = new Date(añoActual, 11, 1);

    // Calcular la diferencia en milisegundos
    const diferenciaMilisegundos = primeroDiciembre - hoy;
    // Convertir la diferencia a días
    const diasFaltantes = Math.ceil(diferenciaMilisegundos / (1000 * 60 * 60 * 24));

    const [ isProcessing, setIsProcessing ] = useState(false);
    const [isOpenModal, openModal, closeModal] = useModal();

    const {  mensaje } = useSelector(state => state.vacaciones);


  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    openModal();
  };

  const handleClose = () =>{
    closeModal();
  };

  const handleCron = () => {
    dispatch(restaurar_mensajes());
    setIsProcessing(true);
    dispatch(actualizacion_anual()).then(() => {
      setIsProcessing(false);
      setTimeout(() => {         
        dispatch(restaurar_mensajes());
        closeModal();   
      }, 3000);
    });
    
  }

  return (
    <>
    <Grid item xs={8} sm={8} md={5} lg={3.5} m={2}
      sx={{
        minHeight: '10em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '.5em',
        borderRadius: '.5em',
        fontWeight: "bold",
        padding: ".5em",
        boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)',
        bgcolor: 'background.paper',
        "&:hover": {
          bgcolor: diasFaltantes == 0 ? 'primary.light' : 'auditory.fourth',
          color: 'text.secondary',
          cursor: 'pointer',
          transform: 'scale(1.01)',
        }
      }}
      onClick={handleClick}
    >
    <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'}}>
      <Typography sx={{fontWeight: "bold"}}>{titulo}</Typography>
      <Typography>{diasFaltantes == 0 ? "Clickear HOY" : `Clickear en ${diasFaltantes} días`}</Typography>
      </Box>
    </Grid>

    {/* Modal */}
    <ModalComponent open={isOpenModal} handleClose={closeModal} >      
    <>
    <Grid>
      {mensaje ? 
      <Typography id="modal-modal-title" variant="h6" component="h2">
          {mensaje}
      </Typography>
      : isProcessing ?
      <Grid container 
      sx={{
        display: "flex",
      }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
              Generando...
          </Typography>
          <CircularProgress sx={{ marginLeft: "3em" }} size={30}/>
      </Grid>
      :
      <Grid>
      <Box sx={{marginBottom: "1em", width:"100%"}}>      
        <Typography id="modal-modal-title" component="h2" sx={{marginBottom:"0.5em" }}>
            Esta acción actualizará las vacaciones de todos los usuarios para el período Dic.{hoy.getFullYear()} - Nov.{hoy.getFullYear() + 1}. 
            </Typography>
        <Typography>
            {diasFaltantes > 0? ` No debe activarlo hasta dentro de ${diasFaltantes} días` : " Seguro que desea continuar?"}
        </Typography>
        
    </Box>
      <Box>
        <Button
          variant="contained"
          onClick={handleClose}
          sx={{ 
            fontSize: ".8em",
            fontWeight: "bold",
            marginTop: '1em',
            marginRight: '1em',
            bgcolor: 'background.paper',
            '&:hover': {
              bgcolor: 'background.paper',
              transform: 'scale(1.01)',
            }
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleCron}
          variant="contained"
          // disabled={diasFaltantes > 0}
          sx={{ 
            fontSize: ".8em",
            fontWeight: "bold",
            marginTop: '1em',
            bgcolor: 'background.paper',
            '&:hover': {
              bgcolor: 'primary.light',
              transform: 'scale(1.01)',
            }
          }}
        >
          Confirmar
        </Button>
        </Box>
        </Grid>
      }    
      </Grid>
      </>
  </ ModalComponent>
  </>
  );
};

export default SpecialWindow;