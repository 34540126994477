export const calcularDiasEntreFechas = (fecha1, fecha2) => {
    // Convertir las fechas a objetos Date
    const fechaInicio = new Date(fecha1);
    const fechaFin = new Date(fecha2);
    
    // Calcular la diferencia en milisegundos
    const diferenciaMilisegundos = fechaFin - fechaInicio;
    
    // Convertir la diferencia de milisegundos a días
    const milisegundosPorDia = 1000 * 60 * 60 * 24;
    const diferenciaDias = diferenciaMilisegundos / milisegundosPorDia;
    
    return Math.round(diferenciaDias) + 1;
  }