import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from '../../hooks/useModal';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import ModalComponent from '../ModalComponent/ModalComponent';
import Pie from '../Pie/Pie';

// Actions
import { crearCargo, listar_tipos ,restaurarMensajes } from '../../redux/action-types/cargoActions'; 

// MUI componentes
import { 
  Box,
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

// MUI iconos
import AssignmentIcon from '@mui/icons-material/Assignment';

const validate = (state) => {
  let errors = {};

  if (state.codigoInput === '') {
    errors.codigoInput = '';
  } else if(state.codigoInput.length < 2) {
    errors.codigoInput = 'El nombre debe tener al menos 2 caracteres...';
  };

  if (state.descripcionInput === '') {
    errors.descripcionInput = '';
  } else if (state.descripcionInput.length > 100) {
    errors.descripcionInput = 'La descripcion no puede superar los 100 caracteres'
  };

  if (state.tipoInput === '') {
    errors.tipoInput = '';
  };

  return errors;
};

const initialState = {
  codigoInput: '',
  descripcionInput: '',
  tipoInput: '',
};

const CrearCargo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dniActual  = useSelector(state => state.acceso.dni);
  const { tipos, status, mensaje } = useSelector(state => state.cargos);

  const [ state, setState ] = useState(initialState);
  const [ errors, setErrors ] = useState(initialState);

  const [ isOpenModal, openModal, closeModal ] = useModal();

  const handleChange = event => {
    const { name, value } = event.target;
    setErrors(validate({
      ...state,
      [name]: value
    }));
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleClickCancelar = () => {
    navigate('/administrar-cargos');
  };

  const handleSubmit = event => {
    event.preventDefault();
    const data = {...state, usuarioActualInput: dniActual};
    dispatch(crearCargo(data));
    openModal();
    setTimeout(() => {
      closeModal();
      navigate('/administrar-cargos');
    }, 3000);
    setState(initialState);
    dispatch(restaurarMensajes())
  };


  useEffect(() => {
    dispatch(listar_tipos());
  }, [dispatch]);

  return (
    <>
      {/* Rotulo de la Pág.*/}
      <Rotulo titulo={"Crear Cargo"} />
      <Grid id={"crearCargo"} container component="form" noValidate onSubmit={handleSubmit} spacing={6} 
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3vh",
        }}
      >
        {/* Contenedor Grid de los Items   */}
        <Grid item container xs={12} sm={10} md={8} lg={6} spacing={6}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        > 
          {/* Item 1 */}
          <Grid item container xs={10} sm={10} md={8} lg={6} flexDirection={"column"}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}> 
              <AssignmentIcon />
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                Información
              </Typography>
            </Box>   
             
            {/* Formulario para Crear */}
            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Código*:
              </Typography>
              <TextField 
                id='codigoInput' 
                name="codigoInput"
                type='text'
                value={state.codigoInput}
                placeholder={"Ingrese el codigo..."} 
                error={!!errors.codigoInput}
                onChange={handleChange}
                inputProps={{
                  autoComplete: 'off'
                }}
                InputLabelProps={{
                  sx: {
                    color: 'rgb(150, 150, 150)',
                  }
                }}
              />
              {
                errors.codigoInput && 
                <FormHelperText
                  sx={{
                    color: "red",
                  }}
                >
                  {errors.codigoInput}
                </FormHelperText>
              }
            </Box>

            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}} >
              <Typography mr={"1em"} fontWeight={"bold"}>
                  Descripción:
              </Typography>
              <TextField 
                id='descripcionInput' 
                name="descripcionInput"
                type='text'
                value={state.descripcionInput}
                error={!!errors.descripcionInput}
                placeholder={"Ingrese una descripcion (máximo 250 caracteres)"} 
                onChange={handleChange}
                inputProps={{
                  autoComplete: 'off'
                }}
                InputLabelProps={{
                  sx: {
                    color: 'rgb(150, 150, 150)',
                  }
                }}
              />
              {
                errors.descripcionInput && 
                <FormHelperText
                  sx={{
                    color: "red",
                  }}
                >
                  {errors.descripcionInput}
                </FormHelperText>
              }
            </Box>

            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}} >
              <Typography mr={"1em"} fontWeight={"bold"}>
                  Tipo*:
              </Typography>
              <TextField 
                id='tipoInput' 
                name="tipoInput"
                type='text'
                value={state.tipoInput}
                error={!!errors.tipoInput}
                placeholder={"Ingrese una descripcion (máximo 250 caracteres)"} 
                onChange={handleChange}
                select
                InputLabelProps={{
                  sx: {
                    color: 'rgb(150, 150, 150)',
                  }
                }}
              >
              {
                tipos && tipos.map(tipo => {
                  return (
                    <MenuItem key={tipo}
                      value={tipo}
                    >
                      {tipo}
                    </MenuItem>
                  )
                })
              }
              </TextField>
              {
                errors.tipoInput && 
                <FormHelperText
                  sx={{
                    color: "red",
                  }}
                >
                  {errors.tipoInput}
                </FormHelperText>
              }
            </Box> 
          </Grid>

          {/* Item 3 */}
          <Grid item container xs={10} sm={10} md={8} lg={6}>
            <Button 
                variant="contained"
                sx={{
                  mr: 1,
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleClickCancelar}
              >
               Cancelar
            </Button>
            { 
              ((!errors.codigoInput && !errors.descripcionInput && !errors.tipoInput) 
                && 
              (errors.codigoInput !== '' && errors.tipoInput !== ''))
              ?
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Crear
                </Button>
              :
                <Button
                  type="submit"
                  disabled
                  variant="contained"
                  sx={{
                    fontSize: ".8em",
                    fontWeight: "bold",
                    bgcolor: 'background.paper',
                    '&:hover': {
                      bgcolor: 'background.paper',
                      transform: 'scale(1.01)',
                    }
                  }}
                >
                  Crear
                </Button>
            }
          </Grid>
        </Grid>
      </Grid>
      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {status}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }} >
          {mensaje}
        </Typography>
      </ ModalComponent>
      {/* Pie de Pág. */}
      <Pie />
    </>
  );
};

export default CrearCargo;